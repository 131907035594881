<template>
    <v-container v-if="this.$store.state.user.components.includes(69)">
        <v-row dense class="ml-3 pa-1">
            <v-btn small @click="addDialog=true">
                <v-icon>mdi-plus</v-icon>
                Add Item
            </v-btn>
        </v-row>
        <crud
            ref="reflex"
            label="Reflex+ Rules"
            :headers="headers"
            model="ReflexRule"
            :canEdit="false"
            :canAdd="false"
            :conditions="[{deleted:{is:null}}, {facility_id:{eq:facility_id}}]"
            :initialValues="initialValues"
            >
            <template v-slot:item.POC.name="obj">
                <span v-if="obj.item.poc_id == -1">Always (POC Not Considered)</span>
                <span v-else>{{obj.item.POC.name}}</span>
            </template>
        </crud>

        <v-dialog v-model="addDialog" max-width="60vw">
            <v-card>
                <v-toolbar dense :elevation="0">
                    <v-toolbar-title>New Rule</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="validated">
                    <v-row dense>
                        <v-col cols="4">
                            <v-text-field
                            background-color="yellow lighten-4"
                            solo
                            dense
                            v-model="rule.name"
                            label="Rule Name"
                            :rules="[fieldRequired]"
                            hide-details=""
                            ></v-text-field>
                        </v-col>
                        
                        <v-col cols="5" offset="1">
                            <autocomplete solo dense label="Physician" model="User" v-model="rule.physician_id" hide-details></autocomplete>
                        </v-col>
                    </v-row>
                    
                    <v-row justify="center" class="mt-12" dense>
                        <v-col cols="4">
                            <v-select solo dense label="POC Result Positive or NA For:" :items="poc" item-text="name" item-value="id" v-model="rule.data[0].poc_id" :rules="[fieldRequired]"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[0].poc_id == -1" :items="medications" item-value="medication_id" item-text="Medication.name" v-model="rule.data[0].medications" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[0].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name" :rules="[fieldRequired]"></v-select>
                        </v-col>
                    </v-row>
                    <v-row justify="center" dense>
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[1].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[1].poc_id == -1" v-model="rule.data[1].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[1].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[2].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[2].poc_id == -1" v-model="rule.data[2].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[2].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[3].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[3].poc_id == -1" v-model="rule.data[3].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[3].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[4].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[4].poc_id == -1" v-model="rule.data[4].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[4].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>

                    <v-row dense justify="center" v-if="visible>4">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[5].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[5].poc_id == -1" v-model="rule.data[5].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[5].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>4">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[6].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[6].poc_id == -1" v-model="rule.data[6].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[6].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>4">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[7].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[7].poc_id == -1" v-model="rule.data[7].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[7].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>4">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[8].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[8].poc_id == -1" v-model="rule.data[8].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[8].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>4">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[9].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[9].poc_id == -1" v-model="rule.data[9].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[9].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>

                    <v-row dense justify="center" v-if="visible>9">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[10].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[10].poc_id == -1" v-model="rule.data[10].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[10].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>9">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[11].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[11].poc_id == -1" v-model="rule.data[11].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[11].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>9">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[12].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[12].poc_id == -1" v-model="rule.data[12].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[12].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>9">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[13].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[13].poc_id == -1" v-model="rule.data[13].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[13].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>9">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[14].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[14].poc_id == -1" v-model="rule.data[14].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[14].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>

                    <v-row dense justify="center" v-if="visible>14">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[15].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[15].poc_id == -1" v-model="rule.data[15].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[15].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>14">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[16].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[16].poc_id == -1" v-model="rule.data[16].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[16].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>14">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[17].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[17].poc_id == -1" v-model="rule.data[17].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[17].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>14">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[18].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[18].poc_id == -1" v-model="rule.data[18].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[18].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>14">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[19].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[19].poc_id == -1" v-model="rule.data[19].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[19].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>

                    <v-row dense justify="center" v-if="visible>19">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[20].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[20].poc_id == -1" v-model="rule.data[20].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[20].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>19">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[21].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[21].poc_id == -1" v-model="rule.data[21].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[21].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>19">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[22].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[22].poc_id == -1" v-model="rule.data[22].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[22].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>19">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[23].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[23].poc_id == -1" v-model="rule.data[23].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[23].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>19">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[24].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[24].poc_id == -1" v-model="rule.data[24].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[24].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>

                    <v-row dense justify="center" v-if="visible>24">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[25].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[25].poc_id == -1" v-model="rule.data[25].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[25].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>24">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[26].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[26].poc_id == -1" v-model="rule.data[26].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[26].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>24">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[27].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[27].poc_id == -1" v-model="rule.data[27].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[27].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>24">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[28].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[28].poc_id == -1" v-model="rule.data[28].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[28].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center" v-if="visible>24">
                        <v-col cols="4">
                            <v-select solo dense label="POC" :items="poc" item-text="name" item-value="id" v-model="rule.data[29].poc_id"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Require Medications" v-show="rule.data[29].poc_id == -1" v-model="rule.data[29].medications" :items="medications" item-value="medication_id" item-text="Medication.name" multiple></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-select solo dense label="Confirm Tests" v-model="rule.data[29].general_codes" multiple hide-details :items="generalTests" item-value="id" item-text="name"></v-select>
                        </v-col>
                    </v-row>

                    <v-row dense justify="center" v-if="visible<30">
                        <v-btn text small color="primary" @click="visible+=5">More</v-btn>
                    </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-2">
                    <v-row justify="end" dense>
                        <v-btn color="" class="mr-2" @click="addDialog=false">Cancel</v-btn>
                        <v-btn color="primary" :disabled="!validated" @click="saveRule()">Save</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import crud from "../../../components/CRUD";
import autocomplete from "../../../components/AutoComplete";
import gql from "graphql-tag";

export default {
    props: ["facility_id"],
    components: {
        crud,
        autocomplete
    },
    data() {
        return {
            addDialog: false,
            validated: false,
            fieldRequired: function(val) {
                return !!val || "This is a required field!";
            },
            visible: 0,
            rule: {
                name: "Default",
                physician: null,
                data: [
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                    {poc_id: null, medications: null, general_codes: null},
                ]
            },
            poc: [],
            medications: [],
            generalTests: [],
            headers: [
                {text: "Rule Name", value: "name", searchable: true, mandatory: true},
                {text: "Facility", value: "facility_id", visible:false, editable:false, searchable: true},
                {text: "Physician", value: "Physician.name", model: "User", modelId: "physician_id", modelFields: {value: "id", text: "name"}, searchable: true},
                {text: "POC", value: "POC.name", type: "select", model: "POC", modelId: "poc_id", modelFields: {value: "id", text: "name"}, slot:true, searchable: true, mandatory: true},
                {text: "Medications Required", value: "require_medications", editable:false, searchable: true},
                {text: "Order Tests", value: "order_tests", editable:false, searchable: true, mandatory: true,},
            ],
            initialValues: {facility_id: this.facility_id},
        };
    },
    methods: {
        saveRule() {
            this.$apollo.mutate({
                mutation: gql`mutation ReflexRulesSave($data: [ReflexRuleSave]!) {
                    ReflexRulesSave(data: $data) {
                        name
                        facility_id
                        physician_id
                        poc_id
                        general_codes
                        medications
                    }
                }`,
                variables: {
                    data: this.parseRule()
                }
            }).then(()=>{
                this.visible = 0;
                this.addDialog = false;
                this.$refs.reflex.$apollo.queries.DataPG.refetech();
            })
        },
        parseRule() {
            var app = this;
            let arr = this.rule.data.reduce((acc, v) => {
                if(v.poc_id != null && v.general_codes != null) acc.push(v);
                    return acc;
            }, []);

            return arr.map(item => {
                return Object.assign({}, {name: app.rule.name, facility_id: app.facility_id, physician_id: app.rule.physician_id, poc_id: item.poc_id, medications: (item.medications != null ? item.medications.toString() : null), general_codes: item.general_codes.toString()});
            });
        }
    },
    mounted() {
        // POC
        this.$apollo.query({
            query: gql`query POCs($wh: [POCConditionAND]!) {
                POCs(where: $wh) {
                    id
                    name
                }
            }`,
            variables: {
                wh: [{deleted:{is:null}}],
            },
            fetchPolicy: "no-cache"
        }).then(res => {
            this.poc = [{id:-1, name:"Always (POC Not Considered)"}, ...res.data.POCs.slice(0)];
        });
        // General Order Codes
        this.$apollo.query({
            query: gql`query GeneralTests($wh: [GeneralTestConditionAND]!) {
                GeneralTests(where: $wh) {
                    id
                    name
                }
            }`,
            variables: {
                wh: [{deleted:{is:null}}],
            },
            fetchPolicy: "no-cache"
        }).then(res => {
            this.generalTests = res.data.GeneralTests;
        });
        // Facility Medications
        this.$apollo.query({
            query: gql`query FacilityMedications($wh: [FacilityMedicationConditionAND]!) {
                FacilityMedications(where: $wh) {
                    medication_id
                    Medication {
                        name
                    }
                }
            }`,
            variables: {
                wh: [{deleted:{is:null}}, {facility_id:{eq:this.facility_id}}, {subscribed: {eq: 1}}],
            },
            fetchPolicy: "no-cache"
        }).then(res => {
            this.medications = res.data.FacilityMedications;
        })
    },
    watch: {
    }
};
</script>