<template>
    <div>
        <v-dialog v-bind="$attrs" v-model="state" v-on="$listeners" max-width="500px">
            <v-card>
                <v-card-title>
                    Visibility Settings
                    <v-spacer></v-spacer>
                    <v-btn icon small v-on:click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text v-if="!loading && !error">
                    <v-form v-model="validated">
                        <v-select
                            :items="locationItemsFiltered"
                            v-model="location"
                            label="Your Location"
                            class="required"
                            :rules="[(val)=>!!val||'Required']"
                        ></v-select>Visible Facilities:
                        <v-checkbox
                            v-for="(fc,fk) in location_items"
                            :key="fk"
                            :label="fc.text"
                            v-model="facilities"
                            :value="fc.value"
                            dense
                            hide-details="auto"
                        ></v-checkbox>
                    </v-form>
                </v-card-text>
                <v-card-actions v-if="!loading && !error">
                    <v-spacer></v-spacer>
                    <v-btn color="success" :disabled="!validated" v-on:click="saveFacilities()">Save</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-on:click="close()">Close</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>

                <v-card-text v-if="loading">
                    <v-progress-linear :indeterminate="true"></v-progress-linear>
                </v-card-text>
                <v-card-text v-if="error">{{error}}</v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" :timeout="3000" color="success" top right>
            <v-icon dark>mdi-check-circle-outline</v-icon>Visibility set Successfully!
        </v-snackbar>
    </div>
</template>
<script>
import gql from "graphql-tag";
export default {
    inheritAttrs: false,
    props: ["value"],
    data() {
        return {
            state: false,
            location_items: [],
            location: null,
            facilities: [],
            loading: false,
            error: false,
            snackbar: false,
            validated: false,
        };
    },
    computed: {
        locationItemsFiltered() {
            return this.location_items.filter((v) =>
                this.facilities?.includes(v.value)
            );
        },
    },
    methods: {
        close() {
            this.state = false;
            this.$emit("input", false);
        },
        saveFacilities() {
            this.loading = true;
            this.error = false;

            this.$apollo
                .mutate({
                    mutation: gql`mutation {
                        Localize(location:${this.location}, locations:[${this.facilities}]) {
                            token
                            components
                        }
                    }`,
                })
                .then((res) => {
                    if (res.data.Localize) {
                        this.$store.dispatch("logIn", res.data.Localize);
                        this.close();

                        this.snackbar = true;
                        this.$router.go();
                    }
                })
                .catch((err) => {
                    this.error = "Error :" + err;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadFacilities() {
            this.loading = true;
            this.error = false;

            this.$apollo
                .query({
                    query: gql`
                        {
                            Facilitys {
                                id
                                name
                            }
                        }
                    `,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.Facilitys) {
                        this.location_items = res.data.Facilitys.map((row) => {
                            return { value: row.id, text: row.name };
                        });
                    }
                })
                .catch(() => {
                    this.error = "Error Loading Data";
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    watch: {
        value(n) {
            this.state = n;
            if (n) {
                this.loadFacilities();
                this.location = this.$store.state.user.payload.l;
                this.facilities = this.$store.state.user.payload.ls || [];
            }
        },
        facilities(n) {
            if(!n.includes(this.location)) this.location = null;
        }
    },
};
</script>