<template>
    <v-container v-show="id" v-if="this.$store.state.user.components.includes(47)" fluid>
        <v-alert v-if="!$store.state.user.payload.ls.includes(String(id))" type="error" value>Access Restricted to this Facility</v-alert>
        <v-card v-else>
            <v-card-title class="display-1 font-italic white--text primary">
                {{facility.name}}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-subtitle class="font-italic white--text primary">
                {{facility.address1}}, {{facility.city}}, {{facility.state}}, {{facility.zip}}
            </v-card-subtitle>
            <imageupload
                v-model="logo"
                :placeholder="require('../assets/login_bar_bg.jpg')"
                :owner-id="facility.id"
                owner-type="facilities"
                type="logo"
                accept=".gif, .jpg, .jpeg, .bmp, .svg, .png"
                max-height="250px"
                :uploadOnClick="false"
                :position="bgPositionX + '% ' + bgPositionY +'%'"
                @mousemove.native="mousemove"
                @mousedown.native="mousedown"
                @mouseup.native="mouseup"
                style="cursor: move"
            >
                <template v-slot:default="{upload}">
                    <v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn color="white" class="mr-5" @click="upload()">Change Logo</v-btn>
                    </v-card-title>
                </template>
            </imageupload>

            <v-toolbar>
                <v-row dense>
                    <v-col align="center">
                        <v-chip
                            label
                            dark
                            :color="facility.status=='Active'? 'green lighten-2' : (facility.status=='Inactive' ? 'red' : 'gray') "
                        >
                            <v-icon v-if="facility.status=='Active'" left>mdi-checkbox-marked-circle</v-icon>
                            <v-icon v-else-if="facility.status=='Inactive'" left>mdi-close</v-icon>
                            <v-icon v-else left>mdi-timer-sand</v-icon>
                            Status: {{facility.status}}
                        </v-chip>
                    </v-col>

                    <v-col align="center">
                        <v-chip label color="orange lighten-1" dark>
                            <v-icon left>mdi-medal</v-icon>
                            Rank: #{{facility.rank}}
                        </v-chip>
                    </v-col>

                    <v-col align="center">
                        <v-chip label color="purple lighten-2" dark>
                            <v-icon v-if="facility.type=='Laboratory'" left>mdi-flask</v-icon>
                            <v-icon v-else-if="facility.type=='Client'" left>mdi-hospital-building</v-icon>
                            <v-icon v-else-if="facility.type=='Clinic'" left>mdi-hospital-building</v-icon>
                            <v-icon v-else left>mdi-office-building</v-icon>
                            Type: {{facility.type}}
                        </v-chip>
                    </v-col>

                    <v-col align="center">
                        <v-chip label color="primary lighten-2">
                            <v-icon left>mdi-test-tube</v-icon>
                            Role: {{facility.role}}
                        </v-chip>
                    </v-col>

                    <v-col align="center">
                        <v-chip label color="purple darken-2" dark>
                            <v-icon left>mdi-calendar</v-icon>
                            Added on: {{facility.created}}
                        </v-chip>
                    </v-col>
                </v-row>
            </v-toolbar>

            <v-card-text>
                <v-tabs grow v-model="tab">
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab v-if="this.$store.state.user.components.includes(48)">
                        <v-spacer></v-spacer>Intro
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="readonly && tab==0"
                            @click.stop="readonly = !readonly"
                            icon
                            outlined
                            x-small
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            v-else-if="tab==0"
                            color="green"
                            small
                            outlined
                            @click.stop="updateProfile()"
                        >Save</v-btn>
                    </v-tab>
                    <v-tab v-if="this.$store.state.user.components.includes(49)">
                        <v-spacer></v-spacer>Contact Information
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="readonly && tab==1"
                            @click.stop="readonly = !readonly"
                            icon
                            outlined
                            x-small
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            v-else-if="tab==1"
                            color="green"
                            small
                            outlined
                            @click.stop="updateProfile()"
                        >Save</v-btn>
                    </v-tab>
                    <v-tab v-if="this.$store.state.user.components.includes(50)">Users & Access</v-tab>
                    <v-tab v-if="this.$store.state.user.components.includes(51)">Settings</v-tab>
                    <v-tab v-if="this.$store.state.user.components.includes(52)">Posts</v-tab>
                    <v-tab v-if="this.$store.state.user.components.includes(53)">Files</v-tab>

                    <v-tab-item v-if="this.$store.state.user.components.includes(48)">
                        <v-card flat>
                            <v-card-text v-if="readonly" class="body-1" v-html="htmlIntro"></v-card-text>
                            <v-card-text v-else>
                                <v-textarea v-model="facility.intro" rows="15"></v-textarea>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item v-if="this.$store.state.user.components.includes(49)">
                        <tabContacts v-model="facility" :readonly="readonly"></tabContacts>
                    </v-tab-item>

                    <v-tab-item v-if="this.$store.state.user.components.includes(50)">
                        <tabUsers :facility_id="facility.id"></tabUsers>
                    </v-tab-item>

                    <v-tab-item v-if="this.$store.state.user.components.includes(51)">
                        <tabSettingsReceiver v-if="facility.role=='Receiver'" :facility_id="id"></tabSettingsReceiver>
                        <tabSettingsSender
                            v-else-if="facility.role=='Sender'"
                            :facility_id="id"
                            :facility="facility"
                        ></tabSettingsSender>
                        <tabSettingsOther v-else :facility_id="facility.id"></tabSettingsOther>
                    </v-tab-item>

                    <v-tab-item v-if="this.$store.state.user.components.includes(52)">
                        <tabPosts :facility_id="facility.id"></tabPosts>
                    </v-tab-item>

                    <v-tab-item v-if="this.$store.state.user.components.includes(53)">
                        <tabGallery :facility_id="facility.id"></tabGallery>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import imageupload from "../components/ImageUpload";

import tabContacts from "../views/facility/tabContacts";
import tabUsers from "../views/facility/tabUsers";
import tabSettingsReceiver from "../views/facility/tabSettingsReceiver";
import tabSettingsSender from "../views/facility/tabSettingsSender";
import tabSettingsOther from "../views/facility/tabSettingsOther";
import tabPosts from "../views/facility/tabPosts";
import tabGallery from "../views/facility/tabGallery";

export default {
    components: {
        imageupload,
        tabContacts,
        tabUsers,
        tabSettingsReceiver,
        tabSettingsSender,
        tabSettingsOther,
        tabPosts,
        tabGallery,
    },
    data() {
        return {
            bgPositionX: 50,
            bgPositionY: 70,
            dragging: false,

            tab: null,
            id: 0,
            facility: {},
            readonly: true,

            logo: null,
        };
    },
    computed: {
        htmlIntro() {
            return this.facility.intro
                ? "<p>" +
                      this.facility.intro
                          .replace(/(?:\r\n|\r|\n)/g, "</p><p>")
                          .replace(/\s/g, "&nbsp;")
                          .replace(
                              /\t/g,
                              "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                          ) +
                      "</p>"
                : null;
        },
    },
    methods: {
        updateProfile() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation FacilityUpdate(
                            $inputdata: FacilityInput!
                            $wh: [FacilityConditionAND]!
                        ) {
                            FacilityUpdate(data: $inputdata, where: $wh) {
                                intro
                                address1
                                address2
                                city
                                state
                                zip
                                phone
                                fax
                                email
                                lat
                                lng
                            }
                        }
                    `,
                    variables: {
                        inputdata: {
                            intro: this.facility.intro,
                            address1: this.facility.address1,
                            address2: this.facility.address2,
                            city: this.facility.city,
                            state: this.facility.state,
                            zip: this.facility.zip,
                            phone: this.facility.phone,
                            fax: this.facility.fax,
                            email: this.facility.email,
                            lat: this.facility.lat,
                            lng: this.facility.lng
                        },
                        wh: [{ id: { eq: this.facility.id } }],
                    },
                })
                .then(() => {
                    this.readonly = true;
                });
        },
        /*searchAddress: function(address) {
            var geocoder = new gmapApi.maps.Geocoder();
            geocoder.geocode({ address: address }, (results, status) => {
                if (status === "OK") {
                    this.location.lat = results[0].geometry.location.lat();
                    this.location.lng = results[0].geometry.location.lng();
                }
            });
        },*/

        mouseup() {
            this.dragging = false;
        },
        mousedown() {
            this.dragging = true;
        },
        mousemove(data) {
            if (this.dragging) {
                let y = this.bgPositionY - data.movementY / 10;
                if (y > 100) y = 100;
                if (y < 0) y = 0;
                this.bgPositionY = y;

                let x = this.bgPositionX + data.movementX / 10;
                if (x > 100) x = 100;
                if (x < 0) x = 0;
                this.bgPositionX = x;
            }
        },
    },
    mounted() {
        this.id = parseInt(this.$route.params.id);

        if (this.id > 0) {
            this.$apollo
                .query({
                    query: gql`
                        query Facility($id: ID!) {
                            Facility(id: $id) {
                                id
                                type
                                role
                                name
                                address1
                                address2
                                city
                                state
                                zip
                                phone
                                fax
                                email
                                lat
                                lng
                                pref_lab_id
                                status
                                rank
                                intro
                                created
                            }
                        }
                    `,
                    variables: {
                        id: this.id,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((res) => {
                    this.facility = res.data.Facility;

                    /*let place = "";
                    if (this.facility.address1)
                        place = this.facility.address1;
                    if (this.facility.city)
                        place += " " + this.facility.city;
                    if (this.facility.state)
                        place += " " + this.facility.state + ", USA";

                    this.searchAddress(place);*/
                });
            // Files
            this.$apollo
                .query({
                    query: gql`
                        query Files($wh: [FileConditionAND]) {
                            Files(
                                where: $wh
                                options: {
                                    page: 1
                                    itemsPerPage: 1
                                    sortBy: [id]
                                    sortDesc: [true]
                                }
                            ) {
                                id
                            }
                        }
                    `,
                    variables: {
                        wh: [
                            { type: { eq: "logo" } },
                            { owner_type: { eq: "facilities" } },
                            { owner_id: { eq: this.id } },
                            { status: { eq: "OK" } },
                            { deleted: { is: null } },
                        ],
                    },
                })
                .then((res) => {
                    if (res.data.Files.length > 0)
                        this.logo = res.data.Files[0].id;
                });
        }
    },
};
</script>