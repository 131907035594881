<template>
    <v-card fluid>
        <crud :headers="headers" model="Application" label="Applications" :multi-sort="true" :can-edit="false" :can-add="false" :conditions="[{deleted:{is:null}}]">
            <template v-slot:item.btn="obj">
                <v-btn color="primary" :to="'/application/'+obj.item.id">View</v-btn>
            </template>
        </crud>
    </v-card>
</template>
<script>
import crud from "../components/CRUD.vue";
export default {
    components: {
        crud
    },
    data() {
        return {
            headers: [
                {
                    text: "ID",
                    value: "id",
                    visible: false,
                    isId: true
                },
                {
                    text: "Type",
                    value: "type",
                    visible: false
                },
                {
                    text: "Organization",
                    value: "name",
                    visible: true
                },
                {
                    text: "NPI",
                    value: "npi",
                    visible: true
                },
                {
                    text: "City",
                    value: "city",
                },
                {
                    text: "State",
                    value: "state",
                },
                {
                    text: "ZIP",
                    value: "zip",
                },
                {
                    text: "First Name",
                    value: "authorized_first_name",
                },
                {
                    text: "Last Name",
                    value: "authorized_last_name",
                },
                {
                    text: "Position",
                    value: "authorized_position",
                },
                {
                    text: "Integration",
                    value: "integration",
                },
                {
                    text: "System Name",
                    value: "system_name",
                },
                {
                    text: "Status",
                    value: "status",
                },
                {
                    text: "Date",
                    value: "created",
                    type: "date",
                    editable: false,
                    visible: true
                },
                {
                    text: "View",
                    value: "btn",
                    query: false,
                    slot: true,
                }
            ]
        };
    }
};
</script>