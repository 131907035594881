<template>
    <v-container fluid>
        <v-card :loading="loading">
            <v-card-title>
                Ereq Settings
                <v-spacer></v-spacer>
                <v-btn icon @click="$apollo.queries.settings.refresh()">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-switch
                            hide-details
                            label="Require Patient Signature"
                            v-model="sts.patSignature.value"
                            :disabled="loading"
                            true-value="1"
                            false-value="0"
                        ></v-switch>
                    </v-col>
                    <v-col>
                        <v-switch
                            hide-details
                            label="Require Physician Signature"
                            v-model="sts.phySignature.value"
                            :disabled="loading"
                            true-value="1"
                            false-value="0"
                        ></v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            hide-details
                            outlined
                            label="Disclaimer"
                            v-model="sts.disclaimer.value"
                            :disabled="loading"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="loading || !needTosave"
                    @click="save()"
                    color="red"
                    outlined
                    width="200"
                >Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
export default {
    data() {
        return {
            settings: [],
            saving: false,
            stsInitial: {
                patSignature: { value: null },
                phySignature: { value: null },
                disclaimer: { value: null },
            },
            sts: {
                patSignature: { value: null },
                phySignature: { value: null },
                disclaimer: { value: null },
            },
            stsCopy: "",
        };
    },
    props: ["facility_id"],
    computed: {
        loading() {
            return this.saving || this.$apollo.queries.settings.loading;
        },
        needTosave() {
            return this.stsCopy != JSON.stringify(this.sts);
        },
    },
    watch: {
        sts: {
            handler() {
                console.log("changed");
            },
            deep: true,
        },
    },
    methods: {
        save() {
            this.saving = true;

            let data = [];

            data.push({
                ...this.sts.patSignature,
                type: "PatientSignatureRequire",
                facility_id: this.facility_id,
                __typename: undefined,
            });

            data.push({
                ...this.sts.phySignature,
                type: "PhysicianSignatureRequire",
                facility_id: this.facility_id,
                __typename: undefined,
            });

            data.push({
                ...this.sts.disclaimer,
                type: "Disclaimer",
                facility_id: this.facility_id,
                __typename: undefined,
            });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ($data: [facilitySettingSave]!) {
                            facilitySettingsSave(data: $data) {
                                id
                                type
                                value
                            }
                        }
                    `,
                    variables: { data: data },
                })
                .then((res) => {
                    if (res.data.facilitySettingsSave instanceof Array) {
                        this.sts = JSON.parse(JSON.stringify(this.stsInitial));
                        res.data.facilitySettingsSave.forEach((row) => {
                            if (row.type == "PatientSignatureRequire")
                                this.sts.patSignature = row;
                            if (row.type == "PhysicianSignatureRequire")
                                this.sts.phySignature = row;
                            if (row.type == "Disclaimer")
                                this.sts.disclaimer = row;
                        });
                        this.stsCopy = JSON.stringify(this.sts);

                        this.settings = res.data.facilitySettingsSave;
                    }
                })
                .finally(() => {
                    this.saving = false;
                });
        },
    },
    apollo: {
        settings: {
            query: gql`
                query ($where: [facilitySettingConditionAND]) {
                    facilitySettings(where: $where) {
                        id
                        type
                        value
                    }
                }
            `,
            variables() {
                return {
                    where: [
                        {
                            type: {
                                in: [
                                    "PatientSignatureRequire",
                                    "PhysicianSignatureRequire",
                                    "Disclaimer",
                                ],
                            },
                        },
                        { facility_id: { eq: this.facility_id } },
                        { deleted: { is: null } },
                    ],
                };
            },
            fetchPolicy: "network-only",
            update(res) {
                if (res.facilitySettings instanceof Array) {
                    this.sts = JSON.parse(JSON.stringify(this.stsInitial));
                    res.facilitySettings.forEach((row) => {
                        if (row.type == "PatientSignatureRequire")
                            this.sts.patSignature = row;
                        if (row.type == "PhysicianSignatureRequire")
                            this.sts.phySignature = row;
                        if (row.type == "Disclaimer") this.sts.disclaimer = row;
                    });
                    this.stsCopy = JSON.stringify(this.sts);
                }

                return res.facilitySettings;
            },
        },
    },
};
</script>