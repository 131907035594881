<template>
    <v-container fluid v-if="$store.state.user.components.includes(87)">
        <v-btn v-if="cart.length>0" fab @click="showCart=true" fixed right bottom="">
            <v-icon large color="green lighten-1">mdi-cart</v-icon>
        </v-btn>
        
        <v-row>
            <v-col cols=12>
                <v-card height="100%" color="rgba(255, 255, 255, 0.65)">
                    <v-toolbar flat color="primary" dark>
                        <div style="margin:auto;">
                            <v-btn
                                v-for="(filter, index) in categories"
                                :key="index"
                                class="ma-2"
                                small
                                tile
                                color="blue lighten-1"
                                dark
                                @click="filterByCategory(filter)"
                            >{{filter}}</v-btn>
                        </div>
                        <v-spacer></v-spacer>
                        <v-select placeholder="Vendor" v-model="vendor" hide-details :items="vendors" item-text="name" item-value="id" @input="filterByVendor"></v-select>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="searchText"
                            append-icon="mdi-magnify"
                            hide-details
                            single-line
                            label="Search"
                        ></v-text-field>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                                lg="2"
                                v-for="item in items"
                                :key="item.id"
                            >
                                <v-card class="pa-2 text-center" height="100%">
                                    <v-toolbar flat dark dense height="30" color="primary lighten-2" class="mb-2">
                                        <span class="text-center">{{item.Facility.name}}</span>
                                    </v-toolbar>
                                    <v-img
                                        v-if="item.File"
                                        contain
                                        aspect-ratio="1.7"
                                        :src="'https://lab-synapse.com/gql/storage/?id='+item.File.id"
                                    ></v-img>
                                      <v-img v-else :src="require('../../assets/no-image.jpg')" contain></v-img>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h4>{{item.name}}</h4>
                                            <span>{{item.qty_descr}}</span>
                                            <h3>${{item.price}}</h3>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-card-actions>
                                        <v-row justify="center">
                                        <v-btn
                                            color="primary"
                                            @click="addToCart(item.id)"
                                        >Add to Cart</v-btn>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <!--
            <v-pagination :length="length" v-model="page" @input="refreshPage"></v-pagination>
                    -->
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="showCart" max-width="60vw">
            <v-card height="100%">
                <v-toolbar flat color="primary" dark>
                    <v-toolbar-title>
                        <v-icon color="white">mdi-cart</v-icon>{{checkout==0 ? " Order Details" : " Checkout"}}
                    </v-toolbar-title>
                </v-toolbar>

                <v-card-text v-if="checkout==0">
                    <v-data-table :headers="cart_headers" :items="cart" item-key="cart.id">
                        <template v-slot:item.qty="{ item }">
                            <v-btn
                                class="ma-2"
                                tile
                                color="pink"
                                icon
                                x-small
                                @click="cartUpdate('minus', item.id)"
                            >
                                <v-icon>mdi-minus-box</v-icon>
                            </v-btn>
                            {{item.qty}}
                            <v-btn
                                class="ma-2"
                                tile
                                color="teal"
                                icon
                                x-small
                                @click="cartUpdate('plus', item.id)"
                            >
                                <v-icon>mdi-plus-box</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.unitprice="{ item }">
                            <span>{{ Number(item.price).toFixed(2) }}</span>
                        </template>
                        <template v-slot:item.price="{ item }">
                            <span>{{ Number(item.price*item.qty).toFixed(2) }}</span>
                        </template>
                        <template v-slot:item.remove="{ item }">
                            <v-btn
                                class="ma-2"
                                tile
                                color="pink"
                                icon
                                x-small
                                @click="removeFromCart(item.id)"
                            >
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>

                    <h3 style="text-align:right;" v-if="cart_total > 0.0">Cart Total: ${{ Number(cart_total).toFixed(2) }}</h3>
                    <v-btn v-if="cart_total > 0.0" large tile dark block color="green lighten-2" class="mt-4" @click="checkout++">Checkout</v-btn>
                </v-card-text>

                <v-card-text v-else>
                    <v-form v-model="validated">
                    <h2 class="pb-2 pt-2">Ship To Address</h2>
                    
                    <v-row dense>
                        <v-col cols="4">
                            <v-text-field label="Organization" v-model="shipTo.name" :rules="[fieldRequired]"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="Address1" v-model="shipTo.address1" :rules="[fieldRequired]"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="Address2" v-model="shipTo.address2"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="4">
                            <v-text-field label="City" v-model="shipTo.city" :rules="[fieldRequired]"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                             <v-select
                                    v-model="shipTo.state"
                                    label="State"
                                    :items="this.$store.state.usStatesAbrv"
                                    :rules="[fieldRequired]"
                                ></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="Zip" v-model="shipTo.zip" :rules="[fieldRequired]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12">
                            <v-textarea label="Notes" v-model="orderNotes" rows="3"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-btn large tile dark block color="green lighten-2" class="mt-4" :disabled="!validated" @click="submitOrder()">Submit</v-btn>
                    </v-form>
                </v-card-text>
                
            </v-card>
            <v-overlay :value="loading"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
        </v-dialog>
        <v-snackbar v-model="snackbar" :top="true" :timeout="2000" color="success">Order has been sent successfully!</v-snackbar>
    </v-container>
</template>

<script>
import gql from "graphql-tag";

export default {
    data() {
        return {
            showCart: false,
            checkout: 0,
            orderNotes: null,
            validated: true,
            fieldRequired: function(val) {
                return !!val || "This is a required field!";
            },
            shipTo: {},
            selected_category: "All",
            cart: [],
            cart_total: 0.0,
            categories: ["All", "Toxicology", "Chemistry", "Office", "Misc"],
            vendors: [],
            vendor: null,
            items_all: [],
            items: [],
            cart_headers: [
                { text: "Item", sortable: true, value: "item" },
                { text: "Unit Price", sortable: true, value: "unitprice"},
                { text: "Qty", sortable: true, value: "qty" },
                { text: "Price", sortable: true, value: "price" },
                { text: "Remove", value: "remove", sortable:false, }
            ],
            snackbar: false,
            loading: false,
            page: 1,
            length: 4,
            limit: 8,
            searchText: ""
        };
    },
    methods: {
        refreshPage() {
            this.filterByCategory(this.selected_category);
            this.items = this.items.splice(
                (this.page - 1) * this.limit,
                this.limit
            );
        },
        addToCart(id) {
            var found = -1;
            var index = -1;
            for(var i = 0; i < this.cart.length; i++) {
                if(this.cart[i].id == id) {
                    found = i;
                    break;
                }
            }
            for(var j = 0; j < this.items_all.length; j++) {
                if (this.items_all[j].id == id) {
                    index = j;
                    break;
                }
            }
            if(found != -1) this.cart[found].qty++;
            else
                this.cart.push({
                    id: this.items_all[index].id,
                    item: this.items_all[index].name,
                    qty: 1,
                    price: this.items_all[index].price
                });

            this.refreshTotal();
        },
        removeFromCart(id) {
            var index = -1;
            for (var i = 0; i < this.cart.length; i++) {
                if (this.cart[i].id == id) {
                    index = i;
                    break;
                }
            }
            if (index != -1) this.cart.splice(index, 1);

            this.refreshTotal();
        },
        cartUpdate(op, id) {
            for (var i = 0; i < this.cart.length; i++) {
                if (this.cart[i].id == id) {
                    if(op == "plus") this.cart[i].qty++;
                    if(op == "minus") {
                        if (this.cart[i].qty > 1) this.cart[i].qty--;
                        else this.removeFromCart(id);
                    }
                    break;
                }
            }
            this.refreshTotal();
        },
        refreshTotal() {
            var total = 0;
            for(var i = 0; i < this.cart.length; i++) {
                total += this.cart[i].price * this.cart[i].qty;
            }
            this.cart_total = total;
        },
        filterByCategory(category) {
            this.selected_category = category;
            this.items = this.items_all.slice();

            if(category != "All") {
                this.items = this.items.filter(function(el) {
                    return el.category == category;
                });
            }
            else this.vendor = null;
        },
        filterByName(txt) {
            this.selected_category = "All";
            this.items = this.items_all.slice();

            if (txt.length > 0) {
                this.items = this.items.filter(function(el) {
                    return (
                        el.name.toLowerCase().indexOf(txt.toLowerCase()) != -1
                    );
                });
            }
        },
        filterByVendor(vid) {
            this.selected_category = "All";
            this.items = this.items_all.slice();

            this.items = this.items.filter(function(el) {
                return (el.Facility.id == vid);
            });
        },
        submitOrder() {
            this.loading = true;
            var shipAddress = this.shipTo.address1+"\n";
            if(this.shipTo.address2) shipAddress += this.shipTp.address2+"\n";
            shipAddress += this.shipTo.city+", "+this.shipTo.state+", "+this.shipTo.zip;

            this.$apollo.mutate({
                mutation: gql`mutation($data: [SupplyOrderSave]!) {
                    SupplyOrdersSave(data: $data) {
                        id
                    }
                }`,
                variables: {
                    data: [{facility_id: this.$store.state.user.payload.l, user_id: this.$store.state.user.payload.id, notes: this.orderNotes, amount: Number(this.cart_total).toFixed(2), shipTo: shipAddress, status: "Pending"}]
                }
            }).then(res => {
                if(res.data.SupplyOrdersSave.length > 0) {
                    this.$apollo.mutate({
                        mutation: gql`mutation($data: [SupplyOrderDetailSave]!) {
                            SupplyOrderDetailsSave(data: $data) {
                                id
                            }
                        }`,
                        variables: {
                            data: this.cart.map(obj => {
                                return Object.assign({},{
                                    order_id: res.data.SupplyOrdersSave[0].id,
                                    item_id: obj.id,
                                    qty: obj.qty,
                                    price: obj.price,
                                });
                            })
                        }
                    }).then(()=>{
                        this.showCart = false;
                        this.cart_total = 0.0;
                        this.checkout = 0;
                        this.cart = [];
                        this.snackbar = true;
                    })
                }
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    mounted() {
        var app = this;

        this.$apollo.query({
            query: gql`query($wh: [SupplyItemConditionAND]!, $opt: SupplyItemOptions) {
                SupplyItems(where: $wh, options: $opt) {
                    id
                    name
                    category
                    qty
                    qty_descr
                    price
                    Facility {
                        id
                        name
                    }
                    File {
                        id
                    }
                }
            }`,
            variables: {
                wh: [{deleted:{is:null}}, {facility_id:{more:0}}],
                opt: {sortBy:["name"], sortDesc:[false]}
            }
        }).then(res => {
            app.items_all = res.data.SupplyItems
            app.items = res.data.SupplyItems
            for(let i=0;i<res.data.SupplyItems.length;i++) {
                this.vendors.push(res.data.SupplyItems[i].Facility)
            }
        });

        this.$apollo.query({
            query: gql`query($id: ID!) {
                Facility(id: $id) {
                    id
                    name
                    address1
                    address2
                    city
                    state
                    zip
                }
            }`,
            variables: {
                id: this.$store.state.user.payload.l,
            }
        }).then(res => {
            this.shipTo = res.data.Facility
        })
    },
    watch: {
        searchText: function() {
            if (this.searchText.length > 2 || this.searchText.length == 0) {
                this.filterByName(this.searchText);
            }
        }
    }
};
</script>