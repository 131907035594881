<template>
    <v-container v-if="this.$store.state.user.components.includes(60)">
        <v-row>
            <v-col cols="8">
                <v-row dense>
                    <autocomplete
                        hint="Search our database of payers and add companies you accept to the list below"
                        persistent-hint
                        dense
                        model="InsuranceCompany"
                        outlined
                        v-model="company"
                        label="Search for an insurance company"
                        return-object
                    ></autocomplete>
                </v-row>
                <v-row dense class="float-right">
                    <v-btn small @click="insDialog=true">View Full List</v-btn>
                </v-row>
                <v-row class="mt-12">
                    <crud
                        ref="fins"
                        label="Accepted Insurance Companies"
                        :headers="headers3"
                        model="FacilityPayer"
                        :can-edit="false"
                        :can-add="false"
                        :can-delete="false"
                        :conditions="[{facility_id:{eq:facility_id}}]"
                    >
                        <template v-slot:item.subscribed="obj">
                            <v-switch
                                v-model="obj.item.subscribed"
                                true-value="1"
                                false-value="0"
                                inset
                                @change="updateFIns(obj.item)"
                            ></v-switch>
                        </template>
                        <template v-slot:item.in_network="obj">
                            <v-switch
                                v-model="obj.item.in_network"
                                true-value="1"
                                false-value="0"
                                inset
                                @change="updateFIns(obj.item)"
                            ></v-switch>
                        </template>
                        <template v-slot:item.group_id="obj">
                            <autocomplete
                                v-model="obj.item.group_id"
                                model="InsuranceGroup"
                                clearable
                                :initial-items="obj.item.group_id && obj.item.group_name ? [{id:obj.item.group_id, name:obj.item.group_name}] : undefined"
                                @input="updateFIns({...obj.item,group_id:$event||null})"
                            ></autocomplete>
                        </template>
                    </crud>
                </v-row>

                <v-dialog v-model="insDialog" max-width="60vw">
                    <v-card fluid>
                        <v-toolbar dark dense color="primary" :elevation="0">
                            <v-toolbar-title>Insurance Companies</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon small @click="insDialog=false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <v-card-text>
                            <crud
                                :headers="headers4"
                                model="InsuranceCompany"
                                :conditions="[{deleted:{is:null}},{status:{eq:'Active'}}]"
                                :can-add="false"
                                :can-edit="false"
                                :can-delete="false"
                                label="Insurance Companies"
                            ></crud>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col cols="4">
                <crud
                    model="InsuranceGroup"
                    :headers="group_headers"
                    :initialValues="initialValues"
                    :conditions="[{deleted:{is:null}},{facility_id:{eq:facility_id}}]"
                    label="Groups"
                ></crud>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import crud from "../../../components/CRUD";
import autocomplete from "../../../components/AutoComplete";
export default {
    props: ["facility_id"],
    data() {
        return {
            insDialog: false,
            company: null,

            headers3: [
                { text: "ID", value: "id", isId: true, visible: false },
                {
                    text: "Company",
                    value: "Company.name",
                    model: "InsuranceCompany",
                    modelId: "company_id",
                    modelFields: { value: "id", text: "name" }
                },
                { text: "Subscribed", value: "subscribed", slot: true },
                { text: "In Network", value: "in_network", slot: true },
                { text: "Group", value: "group_id", slot: true },
                {
                    text: "Group",
                    value: "group_name",
                    visible: false,
                    editable: false
                }
            ],
            headers4: [
                { text: "Name", value: "name", searchable: true },
                { text: "Alias", value: "alias", searchable: true },
                { text: "City", value: "city", searchable: true },
                { text: "State", value: "state", searchable: true },
                { text: "Phone", value: "phone", searchable: true }
            ],

            group_headers: [
                {
                    text: "ID",
                    value: "id",
                    isId: true,
                    visible: false,
                    editable: false
                },
                { text: "Name", value: "name", searchable: true },
                {
                    text: "Facility",
                    value: "facility_id",
                    visible: false,
                    editable: false
                }
            ],
            initialValues: {
                facility_id: this.facility_id
            }
        };
    },
    methods: {
        updateFIns(row) {
            console.log(row);
            
            this.$apollo.mutate({
                mutation: gql`
                    mutation($inputdata: [FacilityPayerSave]!) {
                        FacilityPayersSave(data: $inputdata) {
                            id
                            facility_id
                            subscribed
                            in_network
                            group_id
                        }
                    }
                `,
                variables: {
                    inputdata: [
                        {
                            id: row.id,
                            subscribed: row.subscribed,
                            in_network: row.in_network,
                            group_id: row.group_id,
                            facility_id: this.facility_id
                        }
                    ]
                }
            });
        }
    },
    watch: {
        company(obj) {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($inputdata: [FacilityPayerSave]!) {
                            FacilityPayersSave(data: $inputdata) {
                                id
                            }
                        }
                    `,
                    variables: {
                        inputdata: [
                            {
                                facility_id: this.facility_id,
                                company_id: obj.id,
                                subscribed: 1,
                                in_network: 1
                            }
                        ]
                    }
                })
                .then(() => {
                    this.$refs.fins.$apollo.queries.DataPG.refetch();
                });
        }
    },
    components: {
        crud,
        autocomplete
    }
};
</script>