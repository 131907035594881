<template>
    <v-container v-if="this.$store.state.user.components.includes(64)">
        <v-row v-if="loadingItems">
            <v-col>
                {{loadingItems}}
                <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-col>
        </v-row>

        <v-row v-if="!loadingItems">
            <v-col cols="auto">
                <v-text-field label="Search or Add CPT" v-model="search" clearable>
                    <template v-slot:append-outer>
                        <v-btn v-if="search" v-on:click="addCPT()" small>
                            <v-icon>mdi-database-plus</v-icon>Add
                        </v-btn>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="auto">
                <v-text-field label="Search Test" v-model="searchTest"></v-text-field>
            </v-col>

            <v-col cols="auto">
                <v-autocomplete label="Payer Group" :items="payer_groups" item-value="id" item-text="name"  v-model="searchPG"></v-autocomplete>
            </v-col>

            <v-col cols="auto">
                <v-btn v-on:click="saveTestMap()">Save</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn v-on:click="getTestMap()">Reload</v-btn>
            </v-col>
        </v-row>

        <v-row v-if="!loadingItems">
            <v-col v-for="item in items" :key="item.id" cols="12" lg="6" xl="4">
                <v-card>
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="7">
                                <v-row dense>
                                    <v-col cols="auto">
                                        <h2>{{item.cptRecord.cpt}}</h2>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto">
                                        <v-switch
                                            dense
                                            hide-details="auto"
                                            style="padding:0; margin:0"
                                            label="Accept"
                                            v-model="item.cptRecord.acceptable"
                                            value="1"
                                        ></v-switch>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="2">
                                        <v-btn icon small v-on:click="remCPT(item.cptRecord)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="5">
                                <v-select
                                    dense
                                    hide-details="auto"
                                    label="Add Test"
                                    :items="testItems"
                                    item-text="name"
                                    item-value="id"
                                    v-on:input="addTest(item.cptRecord.id, $event)"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="7">
                                <v-row dense>
                                    <v-col cols="8">
                                        <autocomplete
                                            v-model="item.cptRecord.payer_group_id"
                                            model="InsuranceGroup"
                                            hide-details="auto"
                                            :items="payer_groups"      
                                        ></autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            hide-details="auto"
                                            label="Priority"
                                            v-model="item.cptRecord.priority"
                                            type="number"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="8">
                                        <v-select
                                            hide-details="auto"
                                            label="Type"
                                            :items="type_items"
                                            v-model="item.cptRecord.type"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            hide-details="auto"
                                            label="Price ($)"
                                            v-model="item.cptRecord.price"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <v-text-field
                                            hide-details="auto"
                                            label="Description"
                                            v-model="item.cptRecord.description"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <v-text-field
                                            type="number"
                                            hide-details="auto"
                                            label="Valid From"
                                            v-model="item.cptRecord.count_from"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            type="number"
                                            hide-details="auto"
                                            label="Valid To"
                                            v-model="item.cptRecord.count_to"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <dp
                                            hide-details="auto"
                                            label="Active From"
                                            v-model="item.cptRecord.active_from"
                                        ></dp>
                                    </v-col>
                                    <v-col>
                                        <dp
                                            hide-details="auto"
                                            label="Active To"
                                            v-model="item.cptRecord.active_to"
                                        ></dp>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="5">
                                <v-list dense>
                                    <v-list-item-group v-for="(group, gk) in item.groups" :key="gk">
                                        <v-subheader>
                                            <h3>{{group.name}}</h3>
                                        </v-subheader>
                                        <v-list-item
                                            v-for="(test, tk) in group.tests"
                                            :key="tk"
                                            style="height:2em"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <li>{{test.item.name}}</li>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn icon small v-on:click="remTest(test.map)">
                                                    <v-icon small>mdi-delete</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import dp from "../../../components/DatePickerInput";
import gql from "graphql-tag";
import autocomplete from "../../../components/AutoComplete";
export default {
    data() {
        return {
            loadingItems: false,
            search: null,
            searchPG:null,
            searchTest:null,
            map: [],
            testItems: [],
            cptItems: [],
            testsMap: {},
            cptsMap: {},
            type_items: [
                { text: "Group Based", value: "group_based" },
                { text: "Tests Based", value: "test_based" }
            ],
            payer_groups: [{name:"ALL", id:null}],
            pgs :{}
        };
    },
    methods: {
        remCPT(cptRecord) {
            this.map.forEach(v => {
                if (v.cpt_id == cptRecord.id) this.remTest(v);
            });
            if (cptRecord.id) {
                this.$set(cptRecord, "_delete", true);
            } else this.cptItems.splice(this.map.indexOf(cptRecord), 1);
        },
        remTest(mapRecord) {
            if (mapRecord.id) {
                this.$set(mapRecord, "_delete", true);
            } else this.map.splice(this.map.indexOf(mapRecord), 1);
        },
        addTest(cpt_id, test_id) {
            this.map.push({ cpt_id, test_id });
        },
        addCPT() {
            if (this.search) {
                this.cptItems.push({
                    cpt: this.search,
                    facility_id: this.facility_id
                });
                //this.search = null;
            }
        },
        saveTestMap() {
            this.loadingItems = "Saving ...";
            this.map.forEach(v => {
                v["facility_id"] = this.facility_id;
                delete v["__typename"];
                delete v["deleted"];
                delete v["deleted_by"];
            });
            this.cptItems.forEach(v => {
                v["facility_id"] = this.facility_id;
                delete v["__typename"];
                delete v["deleted"];
                delete v["deleted_by"];
                delete v["payer_group_name"];
            });
            this.map = this.map.filter(
                v => v.test_id != null && v.cpt_id != null
            );

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation(
                            $mapData: [CptTestMapSave]!
                            $cptData: [CptMapSave]!
                        ) {
                            CptTestMapsSave(data: $mapData) {
                                id
                                facility_id
                                test_id
                                cpt_id
                                deleted
                            }
                            CptMapsSave(data: $cptData) {
                                id
                                facility_id
                                payer_group_id
                                payer_group_name
                                priority
                                type
                                cpt
                                description
                                acceptable
                                price
                                count_from
                                count_to
                                active_from
                                active_to
                            }
                        }
                    `,
                    variables: {
                        mapData: this.map,
                        cptData: this.cptItems
                    }
                })
                .then(res => {
                    if (res.data.CptMapsSave instanceof Array) {
                        this.cptItems = res.data.CptMapsSave;
                        this.cptsMap = {};
                        res.data.CptMapsSave.forEach(row => {
                            this.cptsMap[row.id] = row;
                        });
                    }
                    if (res.data.CptTestMapsSave instanceof Array) {
                        this.map = res.data.CptTestMapsSave;
                    }
                })
                .finally(() => {
                    this.loadingItems = false;
                });
        },
        getTestMap() {
            this.loadingItems = "Loading ...";
            this.$apollo
                .query({
                    query: gql`{
                        CptMaps(where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                            id
                            facility_id
                            payer_group_id
                            payer_group_name
                            priority
                            type
                            cpt
                            description
                            price
                            acceptable
                            count_from
                            count_to
                            active_from
                            active_to
                        }
                        CptTestMaps(where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                            id
                            facility_id
                            test_id
                            cpt_id
                            deleted
                        }
                        CptTests(where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                            id
                            facility_id
                            name
                            group_id
                            group_name
                        }
                        
                    }`,
                    fetchPolicy: "no-cache"
                })
                .then(res => {
                    if (res.data.CptTests instanceof Array) {
                        this.testItems = res.data.CptTests;
                        this.testsMap = {};
                        res.data.CptTests.forEach(row => {
                            this.testsMap[row.id] = row;
                        });
                    }
                    if (res.data.CptMaps instanceof Array) {
                        this.cptItems = res.data.CptMaps;
                        this.cptsMap = {};
                        res.data.CptMaps.forEach(row => {
                            this.cptsMap[row.id] = row;

                            if(this.pgs[row.payer_group_id]===undefined) {
                                this.payer_groups.push({id:row.payer_group_id,name:row.payer_group_name});
                            }
                            this.pgs[row.payer_group_id] = row.payer_group_id;
                        });
                    }
                    if (res.data.CptTestMaps instanceof Array) {
                        this.map = res.data.CptTestMaps;
                    }
                })
                .finally(() => {
                    this.loadingItems = false;
                });
        },
        searchForTest(needle, haystack) {
            let res = false;
            if(haystack instanceof Array) {
                haystack.forEach(v=>{
                    if(v.tests instanceof Array) {
                        v.tests.forEach(vv=>{
                            if(vv.item.name.search(new RegExp(needle, "i")) >= 0) res = true;
                        });
                    }
                });
            }
            return res
        }
    },
    computed: {
        items() {
            let newMap = {};

            this.map.forEach(row => {
                if (!row["deleted"] && !row["_delete"]) {
                    if (newMap[row["cpt_id"]] === undefined)
                        newMap[row["cpt_id"]] = [];
                    newMap[row["cpt_id"]].push(row);
                }
            });
            Object.keys(newMap).forEach(k => {
                let groups = {};
                let gmap = {};
                newMap[k].forEach(v => {
                    let gID = this.testsMap[v.test_id].group_id;
                    let gName = this.testsMap[v.test_id].group_name;
                    gmap[gID] = gName;

                    if (groups[gID] === undefined) groups[gID] = [];
                    groups[gID].push({
                        item: this.testsMap[v.test_id],
                        map: v
                    });
                });
                let grs = [];
                Object.keys(groups).forEach(gk => {
                    grs.push({
                        id: gk,
                        name: gmap[gk],
                        tests: groups[gk]
                    });
                });
                newMap[k] = grs;
            });

            let res = [];
            
            this.cptItems.forEach(v => {
                if (
                    !v["deleted"] &&
                    !v["_delete"] &&
                    (!this.search ||
                        v.cpt.search(new RegExp(this.search, "i")) >= 0) &&
                        (!this.searchPG || v.payer_group_id == this.searchPG) &&
                        (!this.searchTest || this.searchForTest(this.searchTest, newMap[v.id]))
                    
                )
                    res.push({
                        cptRecord: v,
                        groups: newMap[v.id] || [{ tests: [] }]
                    });
            });
            return res;
        }
    },
    props: ["facility_id"],
    components: {
        dp,
        autocomplete
    },
    mounted() {
        this.getTestMap();
    }
};
</script>