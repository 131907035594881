<template>
    <div id="container">
        <label>
            <span id="label_span">{{label}}</span>
            <input id="input" type="text" :value="value" v-on="$events" />
        </label>
    </div>
</template>

<script>
export default {
    props: ["label","value"],
    data() {
        return {};
    }
};
</script>


<style scoped>
#container {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    position: relative;
}
#input {
    width:100%;
    height: 35px;
    padding: 15px 5px 5px 5px;
    color: black;
    font-size:larger;
}
#label_span {
    position:absolute;
    color: blue;
    font-size: smaller;
    top: -2px;
    left: 5px;
}
</style>