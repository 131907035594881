<template>
    <v-container fluid>
        <v-data-table
            v-bind="$attrs"
            v-on="$listeners"
            :headers="gridHeaders"
            :items="result.data"
            :options.sync="options"
            :server-items-length="result.totalRecords"
            :loading="loading || $apollo.queries.result.loading!=0"
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-btn v-if="canAdd" small :disabled="loading" v-on:click="editItem(null)">
                        <v-icon>mdi-plus</v-icon>Add Item
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{label}}</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <div v-if="canSearch" style="width:20%">
                        <v-text-field
                            v-model="searchText"
                            label="Search"
                            single-line
                            hide-details
                            :append-icon="searchCondition ? 'mdi-close': 'mdi-magnify'"
                            @click:append="toggleSearch()"
                            @keyup.enter.native="doSearch()"
                            @keyup.esc.native="cancelSearch()"
                        ></v-text-field>
                    </div>
                    <v-btn small icon class="ml-3" v-on:click="filtersBox=!filtersBox">
                        <v-icon>mdi-unfold-more-horizontal</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-if="canEdit || canDelete" v-slot:item.__actions="{item}">
                <v-container class="text-no-wrap">
                    <v-icon v-if="canEdit" @click="editItem(item)" :disabled="loading">mdi-pencil</v-icon>
                    <v-icon
                        v-if="canDelete"
                        class="ml-3"
                        @click="deleteItem(item)"
                        :disabled="loading"
                    >mdi-delete</v-icon>
                </v-container>
            </template>
            <template v-for="s in slots" v-slot:[s]="obj">
                <slot :name="s" v-bind="obj"></slot>
            </template>
        </v-data-table>
        <slot
            name="edit"
            v-bind="{item:itemBeingEdited,dialog:editDialog,headers:formHeaders,reload:$apollo.queries.result.refetch}"
        >
            <v-dialog v-model="editDialog" persistent max-width="80vw" width="800">
                <v-card>
                    <v-card-title>
                        {{itemBeingEdited.id?'Edit':'Add'}} Item
                        <v-spacer></v-spacer>
                        <v-btn icon @click="editDialog=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <slot
                            name="editForm"
                            v-bind="{item:itemBeingEdited,dialog:editDialog,headers:formHeaders,reload:$apollo.queries.result.refetch}"
                        ></slot>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" outlined @click="saveNow()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </slot>
        <slot
            name="delete"
            v-bind="{item:itemBeingDeleted,dialog:deleteDialog,headers:formHeaders}"
        >
            <v-dialog v-model="deleteDialog" persistent width="400" max-width="80vw">
                <v-card>
                    <v-card-title>
                        Deleting Item !
                        <v-spacer></v-spacer>
                        <v-btn icon @click="deleteDialog=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>Are you sure you want to delete this record?</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" outlined @click="deleteDialog=false">Cancel</v-btn>
                        <v-btn color="red darken-1" outlined @click="deleteNow()">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </slot>
        <v-snackbar
            v-model="snackBar"
            top
            right
            :timeout="2000"
            :color="snackBarColor"
        >{{snackBarText}}</v-snackbar>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
export default {
    inheritAttrs: false,
    props: {
        label: String,
        model: String,
        headers: Array,
        conditions: { type: Array },
        canAdd: { type: Boolean, default: true },
        canEdit: { type: Boolean, default: true },
        canDelete: { type: Boolean, default: true },
        canSearch: { type: Boolean, default: true },
    },
    data() {
        return {
            result: { data: [], totalRecords: 0 },
            options: {},
            loading: false,
            searchText: null,
            searchCondition: null,
            itemBeingEdited: {},
            editDialog: false,
            itemBeingDeleted: {},
            deleteDialog: false,
            originalItem: null,
            snackBar: false,
            snackBarColor: null,
            snackBarText: null,
            errors: [],
            beforeSave: null,
        };
    },
    methods: {
        reload() {
            this.$apollo.queries.result.refetch();
        },
        editItem(item) {
            this.originalItem = item;
            this.itemBeingEdited = item ? this.clean(item) : {};
            this.editDialog = true;
        },
        deleteItem(item) {
            this.itemBeingDeleted = this.clean(item);
            this.deleteDialog = true;
        },
        deleteNow() {
            this.loading = true;
            let theItem = JSON.parse(JSON.stringify(this.itemBeingDeleted));
            this.$apollo
                .mutate({
                    mutation: gql`mutation ($wh: [${this.model}ConditionAND]!) {
                        ${this.model}Delete(where:$wh) {
                            id
                            deleted
                            deleted_by
                        }
                    }`,
                    // Parameters
                    variables: {
                        wh: [{ id: { eq: theItem.id } }],
                    },
                })
                .then((res) => {
                    if (
                        res.data[this.model + "Delete"] instanceof Array &&
                        res.data[this.model + "Delete"][0].id == theItem.id
                    ) {
                        this.$apollo.queries.result.refetch();
                        this.snackBarColor = "success";
                        this.snackBarText = "Deleted Successfully!";
                        this.snackBar = true;
                    } else throw new Error("Not Deleted");
                })
                .catch(() => {
                    this.errors.push(theItem);
                    this.snackBarColor = "error";
                    this.snackBarText = "ERROR : Not Deleted !";
                    this.snackBar = true;
                })
                .finally(() => {
                    this.loading = false;
                });
            this.deleteDialog = false;
        },
        clean(val) {
            if (val instanceof Array) {
                return val.map((v) => this.clean(v));
            } else if (val instanceof Object) {
                let obj = {};
                val.__typename = undefined;
                Object.keys(val).forEach((k) => {
                    obj[k] = this.clean(val[k]);
                });
                return obj;
            } else return val;
        },
        saveNow() {
            Object.keys(this.itemBeingEdited).forEach((k) => {
                if (this.itemBeingEdited[k] === undefined)
                    this.$set(this.itemBeingEdited, k, null);
            });

            if (this.$listeners.save) {
                this.$emit("save", {
                    item: this.itemBeingEdited,
                    save: this.save,
                });
            } else {
                const theItem = {};
                this.formHeaders.forEach((h) => {
                    let parts = h.value.split(".");
                    theItem[parts[0]] = this.itemBeingEdited[parts[0]];
                    theItem.id = this.itemBeingEdited.id;
                });

                this.save(theItem);
            }
        },
        save(theItem) {
            this.loading = true;

            //Object.keys(theItem).forEach((k) => {
            //if (k[0] == "_") theItem[k] = undefined;
            //});

            this.$apollo
                .mutate({
                    mutation: gql`mutation ($data: [${this.model}Save]!) {
                        ${this.model}sSave(data:$data) {
                            ${this.gqlFields}
                        }
                    }`,
                    variables: {
                        data: [theItem],
                    },
                })
                .then((res) => {
                    if (this.originalItem === null) {
                        this.$apollo.queries.result.refetch();
                        this.snackBarColor = "success";
                        this.snackBarText = "Inserted Successfully !";
                        this.snackBar = true;
                    } else {
                        this.originalItem = res.data[this.model + "sSave"][0];

                        //this.$apollo.queries.DataPG.refetch();
                        this.snackBarColor = "success";
                        this.snackBarText = "Updated Successfully !";
                        this.snackBar = true;
                    }
                })
                .catch(() => {
                    if (this.originalItem === null) {
                        this.snackBarColor = "error";
                        this.snackBarText = "ERROR: Not Inserted !";
                        this.snackBar = true;
                    } else {
                        this.snackBarColor = "error";
                        this.snackBarText = "ERROR: Not Updated !";
                        this.snackBar = true;
                        this.errors.push(theItem);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
            this.editDialog = false;
        },
        _parseDottedField(value) {
            let parts = value.split(".");

            let str = parts[parts.length - 1];
            for (let i = parts.length - 2; i >= 0; i--) {
                if (str != "id") str = "id " + str;
                str = `${parts[i]} { ${str} }`;
            }
            return str;
        },
        toggleSearch() {
            if (this.searchCondition) {
                this.cancelSearch();
            } else {
                this.doSearch();
            }
        },
        doSearch() {
            if (this.searchText == null) return;
            let conds = [];
            this.gqlHeaders.forEach((v) => {
                if (v.searchable !== false)
                    conds.push({
                        [v.value]: { like: "%" + this.searchText + "%" },
                    });
            });
            if (conds.length > 1) this.searchCondition = [{ OR: conds }];
            else this.searchCondition = conds;
            this.$set(this.options, "page", 1);
        },
        cancelSearch() {
            this.searchText = null;
            this.searchCondition = null;
            this.$set(this.options, "page", 1);
        },
    },
    computed: {
        gqlFields() {
            return this.gqlHeaders.map(
                (v) => v.src || this._parseDottedField(v.value)
            );
        },
        gqlHeaders() {
            return this.headers.filter((v) => v.value[0] != "_");
        },
        slots() {
            return this.headers
                .filter((v) => v.slot)
                .map((v) => "item." + v.value);
        },
        gridHeaders() {
            return [
                ...this.headers.filter((v) => v.visible !== false),
                { value: "__actions", text: "", align: "end" },
            ];
        },
        formHeaders() {
            return this.headers.filter((v) => v.editable !== false);
        },
        totalConditions() {
            let conds = [];
            if (this.conditions instanceof Array) conds = this.conditions;
            if (this.searchCondition instanceof Array)
                conds = [...conds, ...this.searchCondition];
            return conds;
        },
    },
    apollo: {
        result: {
            query() {
                return gql`query($opts:${this.model}Options,$wh:[${this.model}ConditionAND]) {
                    ${this.model}sPG(options:$opts,where:$wh){
                        totalRecords
                        data {
                            ${this.gqlFields}
                        }
                    }
                }`;
            },
            fetchPolicy: "network-only",
            skip() {
                return Object.keys(this.options).length < 1;
            },
            variables() {
                return {
                    opts: this.options,
                    wh: this.totalConditions, //[{ deleted: { is: null } }]
                };
            },
            update(data) {
                return data[this.model + "sPG"];
            },
        },
    },
};
/**
        GRID PROPERTIES 

            label (mandatory) - Sets GRIDs Label
            model (mandatory) - Defines GraphQL Model's name
            headers (array of objects) Described Below
            conditions (array of objects) "[{ deleted: { is: null } }]" - sets incomming conditions for the CRUD   

            can-add (default true) - CRUD can add record
            can-edit (default true) - CRUD can edit record
            can-delete (default true) - CRUD can delete record
            can-search (default true) - CRUD can search records


        HEADERS {
            text: string,
            value: string,
            align?: 'start' | 'center' | 'end',
            sortable?: boolean,
            filterable?: boolean,
            groupable?: boolean,
            divider?: boolean,
            class?: string | string[],
            cellClass?: string | string[],
            width?: string | number,
            filter?: (value: any, search: string, item: any) => boolean,
            sort?: (a: any, b: any) => number
        }
*/
</script>

 