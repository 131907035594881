<template>
  <v-container style="padding: 0">
    <label>
      <span>{{label}}</span><br>
      <input class="inputField" id="input" :type="type" />
    </label>
  </v-container>
</template>
<script>
export default {
  props: ["label", "type"],
  data() {
      return {

      }
  }
};
</script>
<style scoped>
    .inputField {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      height: 0px;
      padding: 7px 5px;
      color: #444;
      font-size: 12px;
    }

    span {
      font-size: 12px;
    }

    .inputField:focus {
      border-bottom-color: #1976d2;
      transition: 0.8s;
    }
</style>
