<template>
  <v-container>
    <v-card>
      <v-card-text style="padding: 8px 10px">
        <v-row>
          <v-row>
            <v-col cols="12" style="padding: 0 20px">
              <h4>Patient Details</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <inputField type="text" label="First Name" />
            </v-col>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <inputField type="text" label="Last Name" />
            </v-col>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <inputField type="text" label="Date of Birth" />
            </v-col>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <inputField type="text" label="Gender" />
            </v-col>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <inputField type="text" label="Address 1" />
            </v-col>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <v-btn small align="left">Edit Patient</v-btn>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-row>
            <v-col cols="12" style="padding: 0 20px">
              <h4>Insurance Details</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <inputField type="text" label="First Name" />
            </v-col>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <inputField type="text" label="Last Name" />
            </v-col>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <inputField type="text" label="Date of Birth" />
            </v-col>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <inputField type="text" label="Gender" />
            </v-col>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <inputField type="text" label="Address 1" />
            </v-col>
            <v-col cols="12" md="2" style="padding: 0 20px">
              <v-btn small align="left">Edit Patient</v-btn>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" style="padding: 0 10px">
            <v-row>
              <v-col cols="12" md="6" style="padding: 0 20px">
                <h4>Prescribed Medications</h4>
              </v-col>
              <v-col cols="12" md="6" style="padding: 0 20px">
                <v-btn small="">Add Medication</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" style="padding:0 20px">
                <v-row>
                  <v-col cols="12" md="3" style="padding:0 20px">
                    <inputField type="text" label="Medication" />
                  </v-col>
                  <v-col cols="12" md="3" style="padding:0 20px">
                    <inputField type="text" label="Dose" />
                  </v-col>
                  <v-col cols="12" md="3" style="padding:0 20px">
                    <inputField type="text" label="Unit" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" style="padding:0 20px">
                    <inputField type="text" label="Medication" />
                  </v-col>
                  <v-col cols="12" md="3" style="padding:0 20px">
                    <inputField type="text" label="Dose" />
                  </v-col>
                  <v-col cols="12" md="3" style="padding:0 20px">
                    <inputField type="text" label="Unit" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" style="padding:0 20px">
                    <inputField type="text" label="Medication" />
                  </v-col>
                  <v-col cols="12" md="3" style="padding:0 20px">
                    <inputField type="text" label="Dose" />
                  </v-col>
                  <v-col cols="12" md="3" style="padding:0 20px">
                    <inputField type="text" label="Unit" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" style="padding: 0 20px">
            <v-data-table
              :headers="poc_headers"
              :items="pocs"
              :items-per-page="3"
              dense
            >
              <template v-slot:item.pos="{ item }">
                <v-checkbox
                  hide-details="auto"
                  style="margin:0 0 3px 0"
                  v-model="item.result"
                  value="pos"
                ></v-checkbox>
              </template>
              <template v-slot:item.neg="{ item }">
                <v-checkbox
                  hide-details="auto"
                  style="margin:0 0 3px 0"
                  height="1"
                  v-model="item.result"
                  value="neg"
                ></v-checkbox>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="5" style="padding: 0 10px">
            <v-card height="150px" style="padding: 0 20px">
              <h4>Laboratories &amp; Containers</h4>

              <v-card-text style="height:110px; overflow-y: scroll; padding: 0">
                <v-list two-line dense subheader>
                  <v-list-item-group multiple v-model="selectedLabs">
                    <v-list-item v-for="lab in labs" :key="lab.id" :value="lab">
                      <v-list-item-action>
                        <v-checkbox
                          readonly
                          color="primary"
                          v-model="selectedLabIDs"
                          :value="lab.id"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ lab.name }}</v-list-item-title>
                        <v-list-item-subtitle
                          v-if="lab.inNetwork"
                          style="color:green;"
                          >In Network</v-list-item-subtitle
                        >
                        <v-list-item-subtitle v-else style="color:red;">
                          Out of Network
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-rating
                            dense
                            x-small
                            half-increments
                            color="primary"
                            :value="(lab.rank / 100) * 6"
                          ></v-rating>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          :to="'facility/' + lab.id"
                          icon
                          color="primary"
                          target="_blank"
                        >
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="7" style="padding: 0 20px">
            <h4>Laboratory Tests &amp; Container</h4>
            <v-row>
              <v-col cols="12" md="4" style="padding: 0 20px">
                <h5>Select Category</h5>
                <v-treeview
                  return-object
                  dense
                  selectable
                  open-on-click
                  v-model="selectedTests"
                  :items="groupedOrderCodes"
                ></v-treeview>
              </v-col>
              <v-col cols="12" md="8" style="padding: 0">
                <h5>Collcetion Date</h5>
                <v-row>
                  <v-col cols="12" md="6" style="padding: 0">
                    <inputField type="date" label="Date" />
                  </v-col>
                  <v-col cols="12" md="6" style="padding: 0">
                    <inputField type="time" label="Time" />
                  </v-col>
                </v-row>
                <h5>Daginosis</h5>
                <v-row dense>
                  <v-col cols="12" md="4" style="padding: 0">
                    <inputField type="text" label="Diagnosis" />
                  </v-col>
                  <v-col cols="12" md="4" style="padding: 0">
                    <inputField type="text" label="Diagnosis" />
                  </v-col>
                  <v-col cols="12" md="4" style="padding: 0">
                    <inputField type="text" label="Diagnosis" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" style="padding: 0">
                    <inputField type="text" label="Diagnosis" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" style="padding: 0 10px">
            <h4>Containers</h4>
            <v-row dense>
              <v-col cols="12" md="6" style="padding: 0 10px">
                <h5>Attached Documents</h5>
              </v-col>
              <v-col cols="12" md="6" style="padding: 0 10px">
                <v-btn small>File</v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="3" style="padding: 5px">
                <v-avatar tile height="80" width="80px">
                  <v-img src="../assets/noPicture.jpg"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12" md="3" style="padding: 5px">
                <v-avatar tile height="80" width="80px">
                  <v-img src="../assets/noPicture.jpg"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12" md="3" style="padding: 5px">
                <v-avatar tile height="80" width="80px">
                  <v-img src="../assets/noPicture.jpg"></v-img>
                </v-avatar>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" style="padding: 5px">
            <h4>Physician &amp; Collector</h4>
            <v-row>
              <v-col cols="12" md="6">
                <inputField type="text" label="Collector"/>
              </v-col>
              <v-col cols="12" md="6">
                
              </v-col>
            </v-row>
            <SignPad></SignPad>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import inputField from "../components/custom/inputField";
import SignPad from "../components/SignPad";
import gql from "graphql-tag";
export default {
  props: ["label", "type"],
  components: {
    inputField,
    SignPad
  },
  data() {
    return {
      pocs: [],
      poc_headers: [
        { value: "name", text: "Name" },
        { value: "pos", text: "Positive" },
        { value: "neg", text: "Negative" },
      ],
      stepAllowed: true,
    };
  },
  methods: {
    getPocs() {
      this.$apollo
        .query({
          query: gql`
            {
              POCs(where: [{ deleted: { is: null } }]) {
                id
                name
                abbrv
              }
            }
          `,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          if (res.data.POCs instanceof Array) {
            this.pocs = res.data.POCs;
          }
        });
    },
  },
  mounted() {
    this.getPocs();
  },
};
</script>
