import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

Vue.use(Vuex)
import usStates from './usStates.js';


export default new Vuex.Store({
    state: {
        user:{isAuthenticated:false,token:null,loading:true,payload:null,timeout:0,components:[]},
        nav_drawer: false,
        genders:['Male','Female','Other'],
        shippingCarriers: ['UPS'],
        usStates:usStates.getNames(),
        usStatesAbrv:usStates.getAbrv(),
        relogin: false,
    },
    mutations: {
        initialiseStore(state,{token,timeout,components, relogin}) {
            if(token) {
                let payload = parseJwt(token);
                if(payload instanceof Object) state.user.payload = payload;
                state.user.token = token;
                state.user.timeout = timeout;
                state.user.components = components;
                state.relogin = relogin;
                if(timeout > Date.now()) state.user.isAuthenticated = true;
                else state.relogin = false;
            }
        
            state.user.loading = false;
        },
        toggle_drawer: (state) => {
            state.nav_drawer = !state.nav_drawer;
        },
        logIn: (state,{token,payload,timeout,components}) => {
            //let goToPage = '/';
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('apollo-token', token);
                localStorage.setItem('token-timeout', timeout);
                localStorage.setItem('location', JSON.stringify(payload.l || null));
                localStorage.setItem('locations', JSON.stringify(payload.ls || null));
                localStorage.setItem('app-components', JSON.stringify(components || null));
                localStorage.setItem('relogin', JSON.stringify(false));
                
                //let gotoPage = localStorage.getItem('goto-page');
                //localStorage.removeItem('goto-page');
                //if(gotoPage) goToPage = gotoPage;
            }
            if(!(payload instanceof Object)) payload = null;
            state.user.payload = payload;
            state.user.token = token;
            state.user.isAuthenticated = true;
            state.user.timeout = timeout;
            state.user.loading = false;
            state.user.components = components;
            state.relogin = false;
            //router.push(goToPage);
        },
        relogIn: (state) => {
            state.relogin = true;
            localStorage.setItem('relogin', JSON.stringify(true));
        },
        logOut: (state) => {
            if (typeof localStorage !== 'undefined') {
                localStorage.removeItem('apollo-token');
                localStorage.removeItem('token-timeout');
                localStorage.removeItem('goto-page');
                localStorage.removeItem('relogin');
            }

            state.user = {};
            state.user.isAuthenticated = false;
            state.user.loading = false;
            state.user.token = null;
            state.user.timeout = 0;
            state.user.payload = null;
            state.relogin = false;

            if (router.currentRoute.path !== '/login') router.push('/login');
        },
    },
    actions: {
        initialiseStore(context) {
            if (typeof localStorage !== 'undefined') {
                let token = localStorage.getItem('apollo-token');
                let timeout = localStorage.getItem('token-timeout');
                let components = JSON.parse(localStorage.getItem('app-components'));
                let relogin = JSON.parse(localStorage.getItem('relogin'));

                if(timeout > Date.now()) {
                    setTimeout(() => {
                        context.dispatch('logOut');
                    }, timeout - Date.now());
                }

                context.commit('initialiseStore',{token, timeout, components, relogin});
            }
        },
        logIn(context,{token,components}) {
            if(token) {
                let payload = parseJwt(token);
                let timeout = Date.now()+12*3600000;
                
                setTimeout(() => {
                    context.dispatch('logOut');
                }, timeout - Date.now());

                context.commit('logIn',{token,timeout,payload,components});
            }  
        },
        logOut(context) {
            context.commit('logOut');

            if (typeof localStorage !== 'undefined') {
                let int = setInterval(() => {
                    let token = localStorage.getItem('apollo-token');
                    let timeout = localStorage.getItem('token-timeout');
                    if(token && timeout > Date.now()) {
                        clearInterval(int);
                        context.dispatch('logIn',token);
                    }
                }, 1000);
            }
        },
    },
    modules: {}
})

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}