<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="1" md="10" lg="8">
                 <v-card>
                    <div align="center">
                        <v-img width="15vw" min-width="150px" align="center" src="../assets/Logo-02.png" />
                    </div>

                    <div>
                        <v-progress-linear indeterminate color="cyan" :active="loading"></v-progress-linear>
                    </div>
                    <div>
                        <v-alert v-if="error" type="error" text>{{error_text}}</v-alert>
                    </div>
                <!--
                <v-card flat v-if="stage==0">
                    <v-card-title style="text-decoration: underline;" class="pa-5">
                        We must first start by registering the practice or laboratory with which you're associated.
                    </v-card-title>

                    <v-row justify="center" align="center" class="pa-5">
                        <v-col align="center" cols="12" lg="6">
                            <GmapMap :center="myLatLng" :zoom="12" map-type-id="terrain"
                                style="width: 100%; height: 300px" ref="mapRef">
                                <GmapMarker v-for="(mp,key) in marker_positions" :key="key" :position="mp.position"
                                    :title="mp.title"
                                    :icon="key==0?null:'http://maps.google.com/mapfiles/ms/icons/green-dot.png'"
                                    :clickable="true" />

                            </GmapMap>

                        </v-col>

                        <v-col align="center" cols="12" lg="6">
                            <v-row>
                                <v-col>
                                    <p>It looks like you're at
                                        <b>
                                            {{marker_positions[0].title}},
                                            {{marker_positions[0].address}},
                                            {{marker_positions[0].city}}, NPI:
                                            {{marker_positions[0].npi}}
                                        </b>. Is this the practice you'd like to register?
                                    </p><br />
                                    <v-btn v-on:click="marker_positions[0].npi-0>0 ? stage=2 : stage=1">Register This Practice</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <h4>OR</h4>

                                    <v-autocomplete label="Select another Practice in the area"
                                        :items="google_places_items" :search-input.sync="search_google_places"
                                        :loading="google_places_loading" v-model="google_places_selected" v-on:change="selected_place()">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col align="center">
                            <v-btn rounded v-on:click="nextPage()">
                                Register Different Practice
                                <v-icon>mdi-arrow-right-bold</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
                -->
                <v-card flat v-if="stage==1">
                    <div class="pa-5 text-center">
                        <strong>Please enter the following information:</strong>
                    </div>

                    <v-row justify="center" align="center" class="pa-5">
                        <v-col align="center" cols="12" lg="5">
                            <v-text-field label="NPI" v-model="facility_npi" :rules="[rules.npi]"></v-text-field>
                        </v-col>
                        <v-col align="center" cols="12" lg="1">
                            OR
                        </v-col>
                        <v-col align="center" cols="12" lg="5">
                            <v-text-field dense label="Organization Name" v-model="facility_name" :rules="[rules.name]"></v-text-field>
                            <v-text-field dense label="ZIP Code" v-model="facility_zip" :rules="[rules.zip]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col align="center">
                            <v-btn color="primary" v-on:click="NPPES_Search()">
                                NEXT
                                <v-icon>mdi-arrow-right-bold</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-dialog v-model="facility_dialog" max-width="45vw">
                        <v-card fluid flat>
                            <v-toolbar dark dense color="primary" :elevation="0">
                                <v-btn icon small @click="facility_dialog=false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>
                                    Select your organization
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-list>
                                <v-list-item-group v-model="facility_index" :mandatory="true">
                                    <v-list-item v-for="(item, key) in facilities" :key="key">
                                        <v-list-item-icon>
                                            <v-icon>mdi-hospital-marker</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <strong>{{item.name}}</strong>{{item.address1}}, {{item.city}}, {{item.state}} {{item.zip}}<br/>{{item.taxonomy}}
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <v-card-actions>
                                <v-row justify="center">
                                    <v-btn color="primary" @click="stage++">Proceed</v-btn>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card>

                <v-card flat v-if="stage==2">
                    <div class="pa-5 text-center">
                        <strong>Here is the facility information we found. Please verify the information is correct and fill in any missing details.</strong>
                    </div>

                    <v-row justify="center" align="center" class="pa-5">
                        <v-col align="center" cols="12">
                            <v-text-field dense label="Organization Name" v-model="facility_name"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field dense label="NPI" v-model="facility_npi"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field dense label="CLIA ID" v-model="facility_clia"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense label="Taxonomy" v-model="facility_taxonomy"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense label="Address 1" v-model="facility_address1"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense label="Address 2" v-model="facility_address2"></v-text-field>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field dense label="City" v-model="facility_city"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-select dense label="State" v-model="facility_state" :items="this.$store.state.usStatesAbrv"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field dense label="ZIP" v-model="facility_zip"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense label="Phone" v-model="facility_phone"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense label="Fax" v-model="facility_fax"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-switch label="Mailing address is same as Primary address" v-model="same_address">
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row v-if="!same_address" justify="center" align="center" class="pa-5">
                        <v-col cols="6">
                            <v-text-field dense label="Address 1" v-model="facility_mailing_address1">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense label="Address 2" v-model="facility_mailing_address2">
                            </v-text-field>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field dense label="City" v-model="facility_mailing_city"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-select dense label="State" v-model="facility_mailing_state" :items="this.$store.state.usStatesAbrv"></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field dense label="ZIP" v-model="facility_mailing_zip"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense label="Phone" v-model="facility_mailing_phone"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense label="Fax" v-model="facility_mailing_fax"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col align="center">
                            <v-btn v-on:click="prevPage()">
                                <v-icon>mdi-arrow-left-bold</v-icon> BACK
                            </v-btn>
                            
                            <v-btn color="primary" v-on:click="nextPage()" class="ml-6"> NEXT <v-icon>mdi-arrow-right-bold</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card flat v-if="stage==3">
                    <v-card-title class="pa-5 text-center">
                        Let's get to know each other.
                    </v-card-title>

                    <v-row justify="center" align="center" class="pa-5">
                        <v-col align="center" cols="4">
                            <v-text-field dense label="Your First Name" v-model="authorized_first_name"></v-text-field>
                        </v-col>
                        <v-col align="center" cols="4">
                            <v-text-field dense label="Your Last Name" v-model="authorized_last_name"></v-text-field>
                        </v-col>
                        <v-col align="center" cols="4">
                            <v-text-field dense label="Your Middle Name" v-model="authorized_middle_name"></v-text-field>
                        </v-col>
                        <v-col align="center" cols="4">
                            <v-text-field dense label="Phone Number" v-model="authorized_phone"></v-text-field>
                        </v-col>
                        <v-col align="center" cols="4">
                            <v-text-field dense label="Mobile Number" v-model="authorized_mobile"></v-text-field>
                        </v-col>
                        <v-col align="center" cols="4">
                            <v-text-field dense label="Email Address" v-model="authorized_email" :rules="[rules.mandatory]"></v-text-field>
                        </v-col>
                        <v-col align="center" cols="12">
                            <v-text-field dense label="Your Position" v-model="authorized_position"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col align="center">
                             <v-btn v-on:click="prevPage()">
                                <v-icon>mdi-arrow-left-bold</v-icon> BACK
                            </v-btn>
                            <v-btn color="primary" v-on:click="nextPage()" class="ml-6">
                                NEXT
                                <v-icon>mdi-arrow-right-bold</v-icon>

                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card flat v-if="stage==4">
                    <div class="pa-5 text-center">
                        <strong>Next, let's get some contact information:</strong>
                    </div>

                    <v-row justify="center" align="center" class="pa-5">
                        <v-col align="center" cols="11" lg="4">
                            Primary Contact Information
                            <v-switch label="That's me" v-model="primary_switch"></v-switch>
                            <v-text-field dense label="Contact Name" v-model="primary_contact_name"></v-text-field>
                            <v-text-field dense label="Phone" v-model="primary_contact_phone"></v-text-field>
                            <v-text-field dense label="Email" v-model="primary_contact_email"></v-text-field>
                        </v-col>
                        <v-col align="center" cols="11" lg="4">
                            Critical Contact Information
                            <v-switch label="Same as Primary contact" v-model="critical_switch"></v-switch>
                            <v-text-field dense label="Contact Name" v-model="critical_contact_name"></v-text-field>
                            <v-text-field dense label="Phone Number" v-model="critical_contact_phone"></v-text-field>
                            <v-text-field dense label="Email address" v-model="critical_contact_email"></v-text-field>
                        </v-col>
                        <v-col align="center" cols="11" lg="4">
                            Invoicing Contact Information
                            <v-switch label="Same as Primary contact" v-model="invoicing_switch"></v-switch>
                            <v-text-field dense label="Contact Name" v-model="invoicing_contact_name"></v-text-field>
                            <v-text-field dense label="Phone Number" v-model="invoicing_contact_phone"></v-text-field>
                            <v-text-field dense label="Email Address" v-model="invoicing_contact_email"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col align="center">
                            <v-btn v-on:click="prevPage()">
                                <v-icon>mdi-arrow-left-bold</v-icon> BACK
                            </v-btn>
                            <v-btn color="primary" v-on:click="nextPage()" class="ml-6">
                                NEXT
                                <v-icon>mdi-arrow-right-bold</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
                <!--
                <v-card flat v-if="stage==5">
                    <v-card-title style="text-decoration: underline;" class="pa-5">
                        Do you have a preferred laboratory? We will try to use this lab first for all lab orders, over
                        all other laboratories that may match what's needed.
                    </v-card-title>

                    <v-row justify="center" class="pa-5">
                        <v-col class="text-center text-md-right" cols="12" md="6" lg="5">
                            <v-btn v-on:click="find_lab=true" :color="find_lab===true ? 'primary' : null">Yes, lets find
                                it</v-btn>
                            <br /><br /><br />
                            <v-autocomplete v-if="find_lab===true" multiple label="Select Laboratory"></v-autocomplete>
                            <v-btn v-if="find_lab===true" v-on:click="stage++">I can't find my prefered lab! </v-btn>
                        </v-col>
                        <v-col class="text-center text-md-left" cols="12" md="6" lg="5">
                            <v-btn v-on:click="find_lab=false" :color="find_lab===false ? 'primary' : null">NO, I don't
                            </v-btn>

                            <br /><br /><br />
                            <p v-if="find_lab===false">That's okay! We have a large network of laboratories that
                                will all be utilized to best meet your needs. You may also set a preferred
                                laboratory at any time in your practice settings
                            </p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col align="center">
                            <v-btn rounded v-on:click="nextPage()">
                                NEXT
                                <v-icon>mdi-arrow-right-bold</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card flat v-if="stage==7">
                    <v-card-title style="text-decoration: underline;" class="pa-5">
                        Next, please tell us about your payer mix. This will help us provide you with the best service
                        possible.
                    </v-card-title>

                    <v-row justify="center" class="pa-5">
                        <v-col class="text-center" cols="12" md="6" lg="5">
                            % of Patient Population
                            <v-text-field v-for="(opt,key) in getOddEven(mix_options,'odd')" :key="key" :label="opt"
                                :rules="[rules.percent]"></v-text-field>
                        </v-col>
                        <v-col class="text-center" cols="12" md="6" lg="5">
                            % of Patient Population
                            <v-text-field v-for="(opt,key) in getOddEven(mix_options,'even')" :key="key" :label="opt"
                                :rules="[rules.percent]"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col align="center">
                            <v-btn rounded v-on:click="nextPage()">
                                NEXT
                                <v-icon>mdi-arrow-right-bold</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
                
                <v-card flat v-if="stage==8">
                    <v-card-title style="text-decoration: underline;" class="pa-5">
                        Please tell us about the types and volumes of testing you order.
                    </v-card-title>

                    <v-row justify="center" class="pa-5">
                        <v-col class="text-center" cols="12" md="10" lg="8">
                            # Ordered Per Month
                            <v-text-field v-for="(opt,key) in orders_options" :key="key" :label="opt"
                                :rules="[rules.int]"></v-text-field>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col align="center">
                            <v-btn rounded v-on:click="nextPage()">
                                NEXT
                                <v-icon>mdi-arrow-right-bold</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
                -->
                <v-card flat v-if="stage==5">
                    <div class="pa-6 text-center "><strong>
                        Would you like us to initiate an interface to your LIS system? Please keep in mind that your LIS
                        vendor may require you to initiate the request from your side too!</strong>
                    </div>

                    <v-row justify="center" class="pa-5">
                        <v-col class="text-center text-md-right" cols="12" md="6" lg="5">
                            <v-btn v-on:click="integrateLIS=true" :color="integrateLIS===true ? 'primary' : null">Yes, I do</v-btn>
                        </v-col>
                        <v-col class="text-center text-md-left" cols="12" md="6" lg="5">
                            <v-btn v-on:click="integrateLIS=false" :color="integrateLIS===false ? 'primary' : null">NO, I don't</v-btn>
                        </v-col>
                    </v-row>
                    <v-row justify="center" class="pa-5">

                    </v-row>
                        <v-col class="text-center pa-5" cols="12"> 
                            <v-text-field v-if="integrateLIS===true" label="Name of Your LIS" v-model="LIS_name"></v-text-field>
                            <p v-if="integrateLIS===false">That's okay! You can request it additionally in future!</p>
                        </v-col>
                    <v-row>
                        <v-col align="center">
                            <v-btn v-on:click="prevPage()">
                                <v-icon>mdi-arrow-left-bold</v-icon> BACK
                            </v-btn>
                            <v-btn color="primary" v-on:click="nextPage()" class="ml-6">
                                NEXT
                                <v-icon>mdi-arrow-right-bold</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card flat v-if="stage==6">
                    <div class="pa-5 text-center">
                        Would you like to invite your co-workers to join the party?
                    </div>

                    <v-row justify="center" align="center" class="pa-5" v-for="(mcontact,key) in more_contacts" :key="key" dense>
                        <v-col class="text-center" cols="12" md="6" lg="5">
                            <v-text-field dense label="Email Address" v-model="mcontact.email"></v-text-field>
                        </v-col>
                        <v-col class="text-center" cols="12" md="6" lg="5">
                            <v-select label="Role at laboratory" v-model="mcontact.role" dense :items="roles">
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row justify="center" class="pa-5" dense>
                        <v-col cols="10" align="left">
                            <v-btn small v-on:click="more_contacts.push({})">
                                <v-icon>mdi-plus</v-icon>Add More
                            </v-btn>
                            <v-btn small v-on:click="more_contacts.splice(key,1)" class="ml-5">
                                <v-icon>mdi-minus</v-icon>Remove
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col align="center">
                            <v-btn v-on:click="prevPage()">
                                <v-icon>mdi-arrow-left-bold</v-icon> BACK
                            </v-btn>
                            <v-btn color="primary" v-on:click="saveData()" class="ml-6">
                                FINISH
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card flat v-if="stage==7">
                    <div class="pa-5 text-center">
                        Thank you for registering with LabSynapse! One of our team members will be reaching out to you shortly to continue the setup process. Please start gathering the following items in preparation:<br/>
                        a.	Test Menu<br/>
                        b.	Payer List<br/>
                        c.	Medication List<br/>
                        d.	Shipping Information (carrier, account numbers, etc.)<br/>
                        e.	Detailed Client List<br/>
                    </div>
                    <v-row>
                        <v-col align="center">
                            <v-btn v-on:click="stage=1">
                                Start new Registration
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>

                 </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {gmapApi} from 'vue2-google-maps';
    import gql from "graphql-tag";
    export default {

        data: function () {
            return {
                stage: 1,
                loading: false,
                same_address: true,
                error: false,
                error_text: "",
                facility_type: "Laboratory",
                facility_name: "",
                 facility_npi: "",
                facility_address1: "",
                facility_address2: "",
                facility_city: "",
                facility_state: "",
                facility_zip: "",
                facility_fax: "",
                facility_phone: "",
                facility_mailing_address1: "",
                facility_mailing_address2: "",
                facility_mailing_city: "",
                facility_mailing_state: "",
                facility_mailing_zip: "",
                facility_mailing_phone: "",
                facility_mailing_fax: "",
                facility_clia: "",
                facility_taxonomy: "",

                authorized_first_name: "",
                authorized_last_name: "",
                authorized_middle_name: "",
                authorized_position: "",
                authorized_phone: "",
                authorized_email: "",
                authorized_mobile: "",

                primary_contact_name: "",
                primary_contact_phone: "",
                primary_contact_email: "",
                critical_contact_name: "",
                critical_contact_phone: "",
                critical_contact_email: "",
                invoicing_contact_name: "",
                invoicing_contact_phone: "",
                invoicing_contact_email: "",

                primary_switch: false,
                critical_switch: false,
                invoicing_switch: false,

                facility_index: -1,
                facility_dialog: false,
                facilities:[],

                integrateLIS: null,
                LIS_name: "",

                more_contacts: [{
                    email: "",
                    role: ""
                }],

                search_google_places: null,
                google_places_loading: false,
                google_places_selected: null,
                google_places_items: [],
                marker_positions: [{
                    position: null,
                    title: null,
                    address: null,
                    city: null,
                    npi: null
                }],
                current_zip: null,

                role_at_practice: "",
                rules: {
                    npi: value => value.length == 0 || value.length == 10 || "NPI should be 10 characters",
                    percent: value => (value - 0 >= 0 && value - 0 <= 100) || "Value should be between 0 and 100",
                    int: value => (value - 0 >= 0 && Number.isInteger(value)) || "Value should be between 0 and 100",
                    name: value => (value.length > 2) || "Value should be at least 3 characters",
                    zip: value => (value.length > 4 && value.length < 10 && !Number.isNaN(value*1)) || "Invalid ZIP code!",
                    mandatory: value => (value.length > 0 || "This is a required field!")
                },
                roles: [
                    "Office Manager",
                    "Medical Provider",
                    "Medical Staff",
                    "Phlebotomist/Collector",
                ],
                /*
                mix_options: [
                    "Aetna",
                    "Blue Cross Blue Shield",
                    "Centene",
                    "Cigna",
                    "Coventry",
                    "Health Net",
                    "Humana",
                    "Medicaid",
                    "Medicare",
                    "Molina",
                    "Tricare",
                    "United Healthcare",
                ],
                orders_options: [
                    "Toxicology",
                    "Routine Blood work (CMPs, CBCs, etc)",
                    "Infectious Disesease (STI)",
                    "Molecular Testing",
                    "PGx Testing",
                    "Urology Testing (UTI, FISH)"
                ],
                myLatLng: {
                    lat: 39.97398,
                    lng: -83.03949
                }
                */
            }
        },
        watch: {
            facility_index(newVal) {
                this.facility_name = this.facilities[newVal].name;
                this.facility_npi = this.facilities[newVal].npi;
                this.facility_address1 = this.facilities[newVal].address1;
                this.facility_address2 = this.facilities[newVal].address2;
                this.facility_city = this.facilities[newVal].city;
                this.facility_state = this.facilities[newVal].state;
                this.facility_zip = this.facilities[newVal].zip;
                this.facility_fax = this.facilities[newVal].fax;
                this.facility_phone = this.facilities[newVal].phone;

                this.facility_mailing_address1 = this.facilities[newVal].mailing_address1;
                this.facility_mailing_address2 = this.facilities[newVal].mailing_address2;
                this.facility_mailing_city = this.facilities[newVal].mailing_city;
                this.facility_mailing_state = this.facilities[newVal].mailing_state;
                this.facility_mailing_zip = this.facilities[newVal].mailing_zip;
                this.facility_mailing_phone = this.facilities[newVal].mailing_phone;
                this.facility_mailing_fax = this.facilities[newVal].mailing_fax;

                this.authorized_first_name = this.facilities[newVal].authorized_first_name;
                this.authorized_last_name = this.facilities[newVal].authorized_last_name;
                this.authorized_middle_name = this.facilities[newVal].authorized_middle_name;
                this.authorized_phone = this.facilities[newVal].authorized_phone;
                this.authorized_position = this.facilities[newVal].authorized_position;

                this.facility_taxonomy = this.facilities[newVal].taxonomy;
                this.facility_clia = this.facilities[newVal].clia;
            },
            search_google_places(val) {
                val && val !== this.google_places_selected && this.query_google_places(val)
            },
            primary_switch(newVal) {
                if(newVal) {
                    this.primary_contact_name = this.authorized_first_name+" "+this.authorized_last_name;
                    this.primary_contact_phone = this.authorized_phone;
                    this.primary_contact_email = this.authorized_email;
                }
                else {
                    this.primary_contact_name = "";
                    this.primary_contact_phone = "";
                    this.primary_contact_email = "";                    
                }
            },
            critical_switch(newVal) {
                if(newVal) {
                    this.critical_contact_name = this.primary_contact_name;
                    this.critical_contact_phone = this.primary_contact_phone;
                    this.critical_contact_email = this.primary_contact_email;
                }
                else {
                    this.critical_contact_name = "";
                    this.critical_contact_phone = "";
                    this.critical_contact_email = "";
                }
            },
            invoicing_switch(newVal) {
                if(newVal) {
                    this.invoicing_contact_name = this.primary_contact_name;
                    this.invoicing_contact_phone = this.primary_contact_phone;
                    this.invoicing_contact_email = this.primary_contact_email;
                }
                else {
                    this.invoicing_contact_name = "";
                    this.invoicing_contact_phone = "";
                    this.invoicing_contact_email = "";
                }
            },
            google: gmapApi
        },
        methods: {
            nextPage() {
                this.stage++;
            },
            prevPage() {
                this.stage--;
            },
            saveData() {
                this.loading = true;
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation($inputdata: ApplicationInput!) {
                                ApplicationInsert(data: $inputdata) {
                                    id
                                    type
                                    name
                                    npi
                                    clia
                                    address1
                                    address2
                                    city
                                    state
                                    zip
                                    phone
                                    fax
                                    email
                                    taxonomy
                                    authorized_first_name
                                    authorized_last_name
                                    authorized_middle_name
                                    authorized_phone
                                    authorized_mobile
                                    authorized_email
                                    authorized_position
                                    primary_contact_name
                                    primary_contact_phone
                                    primary_contact_email
                                    critical_contact_name
                                    critical_contact_phone
                                    critical_contact_email
                                    invoicing_contact_name
                                    invoicing_contact_phone
                                    invoicing_contact_email
                                    integration
                                    system_name
                                    status
                                    created
                                }
                            }
                        `,
                        variables: {
                            inputdata: {
                                type: "Laboratory",
                                name: this.facility_name,
                                npi: this.facility_npi,
                                clia: this.facility_clia,
                                address1: this.facility_address1,
                                address2: this.facility_address2,
                                city: this.facility_city,
                                state: this.facility_state,
                                zip: this.facility_zip,
                                phone: this.facility_phone,
                                fax: this.facility_fax,
                                email: this.facility_email,
                                taxonomy: this.facility_taxonomy,
                                authorized_first_name: this.authorized_first_name,
                                authorized_last_name: this.authorized_last_name,
                                authorized_middle_name: this.authorized_middle_name,
                                authorized_phone: this.authorized_phone,
                                authorized_mobile: this.authorized_mobile,
                                authorized_email: this.authorized_email,
                                authorized_position: this.authorized_position,
                                primary_contact_name: this.primary_contact_name,
                                primary_contact_phone: this.primary_contact_phone,
                                primary_contact_email: this.primary_contact_email,
                                critical_contact_name: this.critical_contact_name,
                                critical_contact_phone: this.critical_contact_phone,
                                critical_contact_email: this.critical_contact_email,
                                invoicing_contact_name: this.invoicing_contact_name,
                                invoicing_contact_phone: this.invoicing_contact_phone,
                                invoicing_contact_email: this.invoicing_contact_email,
                                invitation: JSON.stringify(this.more_contacts),
                                integration: this.integrateLIS,
                                system_name: this.LIS_name,
                                status: "Pending",
                                created: new Date()
                            }
                        }
                    })
                    .then(res => {
                        if(res.data.ApplicationInsert.id > 0) {
                            this.loading = false;
                            this.stage++;
                        }
                    });
            },
            NPPES_Search() {
                if((this.facility_npi.length < 10) && (this.facility_name.length < 3 || this.facility_zip.length < 5)) {
                    this.error = true;
                    this.error_text = "Please enter a valid NPI number OR Organization Name and ZIP code!";
                    return;
                }
                else {
                    this.loading = true;
                    this.error = false;

                    let url = 'https://npiregistry.cms.hhs.gov/api/';
                    let params = {version: '2.1'};
                    if(this.facility_npi.length == 10) params.number = this.facility_npi;
                    if(this.facility_name.length > 2) params.organization_name = this.facility_name +'*';
                    if(this.facility_zip) params.postal_code = this.facility_zip;
                    this.axios.get('https://digitaldevelopments.bg/proxy.php?url='+url, {params:params}).then(res => {
                        if(res.data.result_count == 1) {
                            this.facility_name = res.data.results[0].basic.organization_name;
                            this.facility_npi = res.data.results[0].number;
                            this.authorized_first_name = res.data.results[0].basic.authorized_official_first_name;
                            this.authorized_last_name = res.data.results[0].basic.authorized_official_last_name;
                            this.authorized_middle_name = res.data.results[0].basic.authorized_official_middle_name;
                            this.authorized_phone = res.data.results[0].basic.authorized_official_telephone_number;
                            this.authorized_position = res.data.results[0].basic.authorized_official_title_or_position;
                            this.facility_clia = res.data.results[0].taxonomies[0].license;
                            this.facility_taxonomy = res.data.results[0].taxonomies[0].desc;

                            for(let i=0;i<res.data.results[0].addresses.length;i++) {
                                if(res.data.results[0].addresses[i].address_purpose == 'LOCATION') {
                                    this.facility_address1 = res.data.results[0].addresses[i].address_1;
                                    this.facility_address2 = res.data.results[0].addresses[i].address_2;
                                    this.facility_city = res.data.results[0].addresses[i].city;
                                    this.facility_state = res.data.results[0].addresses[i].state;
                                    this.facility_zip = res.data.results[0].addresses[i].postal_code;
                                    this.facility_fax = res.data.results[0].addresses[i].fax_number;
                                    this.facility_phone = res.data.results[0].addresses[i].telephone_number;
                                }
                                else if(res.data.results[0].addresses[i].address_purpose == 'MAILING') {
                                    this.facility_mailing_address1 = res.data.results[0].addresses[i].address_1;
                                    this.facility_mailing_address2 = res.data.results[0].addresses[i].address_2;
                                    this.facility_mailing_city = res.data.results[0].addresses[i].city;
                                    this.facility_mailing_state = res.data.results[0].addresses[i].state;
                                    this.facility_mailing_zip = res.data.results[0].addresses[i].postal_code;
                                    this.facility_mailing_fax = res.data.results[0].addresses[i].fax_number;
                                    this.facility_mailing_phone = res.data.results[0].addresses[i].telephone_number;
                                }
                            }

                            this.stage++;
                        }
                        else if(res.data.result_count > 1) {
                            this.facilities = res.data.results.map(item=>{
                                let primary = {};
                                let mailing = {};
                                for(let i=0;i<item.addresses.length;i++) {
                                    if(item.addresses[i].address_purpose == 'LOCATION') {
                                        primary = {address1: item.addresses[i].address_1, address2: item.addresses[i].address_2, city: item.addresses[i].city, state:item.addresses[i].state, zip: item.addresses[i].postal_code, fax:item.addresses[i].fax_number, phone:item.addresses[i].telephone_number};
                                    }
                                    else if(item.addresses[i].address_purpose == 'MAILING') {
                                        mailing = {address1: item.addresses[i].address_1, address2: item.addresses[i].address_2, city: item.addresses[i].city, state:item.addresses[i].state, zip: item.addresses[i].postal_code, fax:item.addresses[i].fax_number, phone:item.addresses[i].telephone_number};
                                    }
                                }
                                return {
                                    name: item.basic.organization_name,
                                    authorized_first_name: item.basic.authorized_official_first_name,
                                    authorized_last_name: item.basic.authorized_official_last_name,
                                    authorized_middle_name: item.basic.authorized_official_middle_name,
                                    authorized_phone: item.basic.authorized_official_telephone_number,
                                    authorized_position: item.basic.authorized_official_title_or_position,
                                    npi: item.number,
                                    address1: primary.address1,
                                    address2: primary.address2,
                                    city: primary.city,
                                    state: primary.state,
                                    zip: primary.zip,
                                    fax: primary.fax,
                                    phone: primary.phone,
                                    mailing_address1: mailing.address1,
                                    mailing_address2: mailing.address2,
                                    mailing_city: mailing.city,
                                    mailing_state: mailing.state,
                                    mailing_zip: mailing.zip,
                                    mailing_fax: mailing.fax,
                                    mailing_phone: mailing.phone,
                                    clia: item.taxonomies[0].license,
                                    taxonomy: item.taxonomies[0].desc
                                }
                            });
                            this.facility_dialog = true;
                        }
                        this.loading = false;
                    });
                }
            },
            /*
            query_google_places() {
                this.google_places_loading = true;

                this.google_places_loading = false;
            },
            searchNPI(org) {
                org = org.split(' ').join('%2B');
                //city = city.split(' ').join('%2B');
                this.axios.get('https://anestorov.com/labhub/proxy.php?proxy=npi&organization_name=' + org.trim())
                    .then(res => {

                        this.marker_positions[0].npi = res.data.results[0].number;
                        this.$set(this.marker_positions, 0, this.marker_positions[0]);


                    });
            },
            getAllNPI(zip) {
                this.google_places_loading = true;
                this.axios.get('https://anestorov.com/labhub/proxy.php?proxy=npi&postal_code=' + zip.trim())
                    .then(res => {
                        if (res.data.results) {
                            res.data.results.forEach(v => {
                                this.google_places_items.push({
                                    text: v.basic.name + ', '+ v.number,
                                    value: v.number
                                });
                            });
                        }

                    })
                    .finally(()=>{
                        this.google_places_loading = false;
                    });
            },
            getOddEven(property, type) {
                let opts = [];
                property.forEach((v, k) => {
                    if (type == "odd" && k % 2 != 0) opts.push(v);
                    if (type == "even" && k % 2 == 0) opts.push(v);
                });
                return opts;
            },
            selected_place(){
                this.facility_npi = this.google_places_selected;
                this.stage++
            }
            */
        },
        mounted() {
            /*
            this.axios.get('https://anestorov.com/labhub/proxy.php?proxy=gplace&stype=nearbysearch&location=' + this
                    .myLatLng.lat + ',' + this.myLatLng.lng + '&type=hospital&rankby=distance')
                .then(res => {
                    //window.console.log(res.data)
                    if (res.data.results) {
                        res.data.results.forEach((v, k) => {
                            let vicinity = v.vicinity.split(",");
                            this.marker_positions[k] = {
                                position: v.geometry.location,
                                title: v.name,
                                address: vicinity[0],
                                city: vicinity[1],
                                npi: 'NA'
                            };
                            if (k == 0) this.searchNPI(v.name);
                        })
                    }
                });

            this.axios.get('https://anestorov.com/labhub/proxy.php?proxy=gplace&stype=nearbysearch&location=' + this
                    .myLatLng.lat + ',' + this.myLatLng.lng + '&type=postal_code&rankby=distance')
                .then(res => {
                    if (res.data.results) {
                        this.current_zip = res.data.results[0].name;
                        this.getAllNPI(this.current_zip);
                    }
                });
                */
        }
    };
</script>