<template>
    <v-container v-if="this.$store.state.user.components.includes(61)">
        <v-tabs v-model="tab">
            <v-tab v-if="this.$store.state.user.components.includes(62)">Tests & Groups</v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(63)">Tests Map</v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(64)">CPT Map</v-tab>
            <!--<v-tab>Accepted CPTs</v-tab>-->
            <v-tab v-if="this.$store.state.user.components.includes(65)">Test Map</v-tab>

            <v-tab-item v-if="this.$store.state.user.components.includes(62)">
                <v-row>
                    <v-col cols="12" lg="6">
                        <crud
                            model="CptTest"
                            :headers="headers1"
                            :initialValues="initialValues"
                            label="Tests"
                            :conditions="[{deleted:{is:null}}, {facility_id:{eq:facility_id}}]"
                        ></crud>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <crud
                            model="CptTestGroup"
                            :headers="headers2"
                            :initialValues="initialValues"
                            label="Tests Groups"
                            :conditions="[{deleted:{is:null}}, {facility_id:{eq:facility_id}}]"
                        ></crud>
                    </v-col>
                </v-row>
            </v-tab-item>

            
            <v-tab-item v-if="this.$store.state.user.components.includes(63)">
                <map1 :facility_id="facility_id"></map1>
            </v-tab-item>

            <v-tab-item v-if="this.$store.state.user.components.includes(64)">
                <map2 :facility_id="facility_id"></map2>
            </v-tab-item>

            <!--<v-tab-item>
                <v-row dense>
                    <v-col v-for="cpt in cpts" :key="cpt.code" cols="12" sm="6" md="4" lg="3">
                        <v-row no-gutters>
                            <v-col align-self="end">CPT {{cpt.code}}</v-col>
                            <v-col>
                                <v-switch
                                    hide-details="auto"
                                    inset
                                    v-model="cpt.value"
                                    class="my-0"
                                    dense
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
            </v-tab-item>-->

            <v-tab-item v-if="this.$store.state.user.components.includes(65)">
                <test :facility_id="facility_id"></test>
            </v-tab-item>

        </v-tabs>
    </v-container>
</template>

<script>
import crud from "../../../components/CRUD";
import map1 from "./cptCodesMap1"
import map2 from "./cptCodesMap2"
import test from "./cptCodesTest"
export default {
    data() {
        return {
            tab: null,
    
            headers1: [
                {
                    text: "ID",
                    value: "id",
                    isId: true,
                    visible: false,
                    editable: false
                },
                {
                    text: "Facility",
                    value: "facility_id",
                    visible: false,
                    editable: false
                },
                { text: "Name", value: "name", searchable: true },
                {
                    text: "Group",
                    value: "group_name",
                    type: "autocomplete",
                    model: "CptTestGroup",
                    modelId: "group_id",
                    modelFields: { value: "id", text: "name" }
                }
            ],
            headers2: [
                {
                    text: "ID",
                    value: "id",
                    isId: true,
                    visible: false,
                    editable: false
                },
                {
                    text: "Facility",
                    value: "facility_id",
                    visible: false,
                    editable: false
                },
                { text: "Name", value: "name", searchable: true }
            ],
            initialValues: {
                facility_id: this.facility_id
            }
        };
    },
    methods: {
    },
    computed: {
        /*cpts() {
            let res = [{ code: "G0483", value: false }];
            let codes = ["G0483"];
            for (let i = 0; i < 50; i++) {
                let code =
                    (Math.random() > 0.5 ? "G0" : "80") +
                    String(Math.round(Math.random() * 999)).padStart(3, "0");
                while (codes.indexOf(code) >= 0) {
                    code =
                        (Math.random() > 0.5 ? "G0" : "80") +
                        String(Math.round(Math.random() * 999)).padStart(
                            3,
                            "0"
                        );
                }
                codes.push(code);
                res.push({ code, value: Math.random() > 0.5 });
            }
            return res.sort((a, b) => a.code > b.code);
        }*/
    },
    watch: {
    },
    components: {
        crud, map1, map2, test
    },
    props: ["facility_id"],
};
</script>