<template>
    <span>
        <GRID model="UserToxPanel" :headers="gh" :conditions="[{deleted:{is:null}}]" @save="save">
            <template v-slot:item.Map="{item}">
                <v-row dense v-for="(m,k) in item['Map']" :key="k" v-show="!m._delete">
                    <v-col>
                        <li v-if="m.OrderCode">
                            <b class="mr-2">{{m.OrderCode.order_name}}</b>
                            <i class="mr-2">{{m.OrderCode.sample_name}}</i>
                            <i class="mr-2">{{m.OrderCode.container_name}}</i>
                            <i class="mr-2">{{m.OrderCode.group_name}}</i>
                            <b
                                v-if="m.OrderCode.Facility"
                                class="mr-2"
                            >{{m.OrderCode.Facility.name}}</b>
                        </li>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:editForm="{item,headers}">
                <v-form v-if="item && headers">
                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field label="Panel Name" v-model="item['name']"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <h4>Order Codes</h4>
                            <v-row dense v-for="(m,k) in item['Map']" :key="k" v-show="!m._delete">
                                <v-col>
                                    <li v-if="m.OrderCode">
                                        <b class="mr-2">{{m.OrderCode.order_name}}</b>
                                        <i class="mr-2">{{m.OrderCode.sample_name}}</i>
                                        <i class="mr-2">{{m.OrderCode.container_name}}</i>
                                        <i class="mr-2">{{m.OrderCode.group_name}}</i>
                                        <b
                                            v-if="m.OrderCode.Facility"
                                            class="mr-2"
                                        >{{m.OrderCode.Facility.name}}</b>
                                    </li>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn x-small icon @click="del(item['Map'],k)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>
                                    <DropDown
                                        model="Laboratory"
                                        :conditions="[{deleted:{is:null}},{role:{eq:'Receiver'}}]"
                                        v-model="facility"
                                        label="Select Laboratory"
                                        clearable
                                    ></DropDown>
                                </v-col>
                                <v-col>
                                    <Model
                                        model="LabOrderCode"
                                        :conditions="[{deleted:{is:null}},{facility_id:{eq:facility}}]"
                                        src="id,name,order_name,sample_name,container_name,group_name,Facility{name}"
                                    >
                                        <template v-slot="{data,loading,conditions}">
                                            <v-autocomplete
                                                :items="data"
                                                item-value="id"
                                                item-text="name"
                                                label="Add Order Code"
                                                :loading="loading"
                                                v-model="orderCode"
                                                :disabled="!facility"
                                                return-object
                                                @update:search-input="search($event,conditions)"
                                                @input="addCode($event,item)"
                                            ></v-autocomplete>
                                        </template>
                                    </Model>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </GRID>
    </span>
</template>
<script>
import GRID from "../../../components/GRID.vue";
import DropDown from "../../../components/DropDown.vue";
import Model from "../../../components/Model.vue";
export default {
    components: {
        GRID,
        DropDown,
        Model,
    },

    methods: {
        del(arr, k) {
            if (arr[k].id) {
                this.$set(arr[k], "_delete", true);
            } else arr.splice(k, 1);
        },
        save({ item, save }) {
            let map = item.Map.map((v) => {
                return {
                    id: v.id,
                    order_code_id: v.order_code_id,
                    _delete: v._delete,
                };
            });
            save({ id: item.id, name: item.name, Map: map });
        },
        search(v, fn) {
            if (v && v.length >= 3 && v != this.orderCode?.name) {
                fn([{ name: { like: "%" + v + "%" } }]);
            }
        },
        addCode(code, item) {
            if (!(item.Map instanceof Array)) this.$set(item, "Map", []);

            item.Map.push({
                order_code_id: code.id,
                OrderCode: {
                    order_name: code.order_name,
                    sample_name: code.sample_name,
                    container_name: code.container_name,
                    group_name: code.group_name,
                    Facility: { name: code.Facility.name },
                },
            });
            setTimeout(() => {
                this.orderCode = null;
            }, 10);
        },
    },
    data() {
        return {
            gh: [
                { value: "id", text: "id", visible: false, editable: false },

                { value: "name", text: "Panel Name" },
                {
                    value: "Map",
                    src: "Map{id,order_code_id,OrderCode{order_name,sample_name,container_name,group_name,Facility{name}}}",
                    text: "Order Codes",
                    slot: true,
                },
            ],
            facility: null,
            orderCode: null,
        };
    },
};
</script>