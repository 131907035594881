<template>
    <v-container fluid>
        <v-row dense>
            <SchedulePickup @pickup-requested="$refs.pickup.$apollo.queries.DataPG.refetch()" />
        </v-row>
        <crud
            :canAdd="false"
            :canEdit="false"
            :canDelete="false"
            :headers="headers"
            label="Scheduled Carrier Pickups"
            model="ShippingPickup"
            ref="pickup"
        >
            <template v-slot:item.created="obj">
                {{new Date(obj.item.created.replace(/-/g, '/')).toLocaleString()}}
            </template>
            <template v-slot:item.cancel="obj">
                <CancelPickup :carrier="obj.item.carrier" :prn="obj.item.prn"></CancelPickup>
            </template>
        </crud>
    </v-container>
</template>
<script>
import crud from "../../components/CRUD.vue";
import SchedulePickup from "./schedulePickup.vue";
import CancelPickup from "./cancelPickup.vue";
export default {
    components: {
        crud,
        SchedulePickup,
        CancelPickup,
    },
    data() {
        return {
            headers: [
                { text: "ID", value: "id", isId: true, visible: false, editable: false },
                { text: "Pickup Location", value: "Facility.name", model: "Facility"},
                { text: "Carrier", value: "carrier" },
                { text: "Packages", value: "packages" },
                { text: "PRN", value: "prn" },
                { text: "Status", value: "status" },
                { text: "Request Date", value: "created", slot: true },
                { text: "Cancel", value: "cancel", query: false, slot: true, sortable: false },
            ],
        }
    },
    methods: {
    }
}
</script>