<template>
    <v-autocomplete
        v-bind="$attrs"
        :value="localValue"
        :items="proposedItems"
        :item-value="itemValue"
        :item-text="itemText"
        :loading="($apollo.queries.proposedItems.loading && proposedItems.length == 0) || $attrs.loading"
        :append-icon="appendIcon"
        :disabled="disabled"
        v-on="$listeners"
        ref="myComponent"
    >
        <template v-if="$attrs.mandatory!==undefined && $attrs.mandatory!==false" v-slot:label>
            {{$attrs.label}}
            <span style="color:red">*</span>
        </template>
        <template v-if="refreshable" v-slot:prepend-item>
            <v-btn
                x-small
                outlined
                width="100%"
                class="mb-2"
                @click="refresh()"
                :disabled="$apollo.queries.proposedItems.loading"
            >
                {{$apollo.queries.proposedItems.loading ? 'loading ...' : 'reload options'}}
                <v-icon x-small>mdi-database-refresh</v-icon>
            </v-btn>
        </template>
    </v-autocomplete>
</template>
<script>
import gql from "graphql-tag";
export default {
    inheritAttrs: false,
    props: {
        model: { type: String, required: true },

        itemValue: { type: String, default: "id" },
        itemText: { type: String, default: "name" },

        conditions: Array,
        maxResults: { type: Number, default: null },

        appendIcon: { type: String, default: "mdi-menu-down" },

        refreshable: [Boolean],
        autoOpen: [Boolean],
        fetchPolicy: { type: String, default: "cache-and-network" },

        /** This value will be set if and only it match conditions*/
        trySet: [Object, String, Number, Array],
        value: [Object, String, Number, Array],
        disabled: [String, Boolean],

        setWhenSingleResult: [Boolean, String],
    },
    data() {
        return {
            proposedItems: [],
            localValue: this.value,
        };
    },
    methods: {
        refresh() {
            this.$apollo.queries.proposedItems.refresh();
        },
    },

    apollo: {
        proposedItems: {
            query() {
                return gql`
                query($opts:${this.model}Options,$wh:[${this.model}ConditionAND]) {
                    ${this.model}s(where: $wh, options: $opts) {
                        ${this.itemValue}
                        ${this.itemText}
                    }
                }
            `;
            },
            update(res) {
                let key = Object.keys(res)[0];

                if (res[key] instanceof Array) {
                    if (this.trySet !== undefined) {
                        let found = res[key].filter((e) => {
                            if (e instanceof Object)
                                return e[this.itemValue] == this.trySet;
                            else return e == this.trySet;
                        });

                        if (found.length == 1) {
                            this.localValue = this.trySet;
                            this.$emit("input", this.trySet);
                        }
                    }

                    if (
                        this.setWhenSingleResult !== undefined &&
                        res[key].length == 1
                    ) {
                        if (
                            res[key][0] instanceof Object &&
                            this.$attrs.returnObject == undefined
                        ) {
                            this.localValue = res[key][0][this.itemValue];
                            this.$emit("input", res[key][0][this.itemValue]);
                        } else {
                            this.localValue = res[key][0];
                            this.$emit("input", res[key][0]);
                        }
                    }
                }
                return res[key];
            },
            result({ loading }) {
                if (!loading) {
                    //this.$refs.myComponent.isMenuActive = true;
                } else {
                    this.localValue = null;
                    this.$emit("input", null);
                }
            },
            skip: true,
            variables() {
                return {
                    wh: this.conditions,
                    opts:
                        this.maxResults == null
                            ? null
                            : { page: 1, itemsPerPage: this.maxResults },
                };
            },
            //pollInterval : 1000
        },
    },
    mounted() {
        if (this.autoOpen) this.$refs.myComponent.isMenuActive = true;
        this.$apollo.queries.proposedItems.options.fetchPolicy =
            this.fetchPolicy;
        if (!this.disabled) this.$apollo.queries.proposedItems.skip = false;
    },
    watch: {
        value(n) {
            this.localValue = n;
        },
        disabled(n) {
            this.$apollo.queries.proposedItems.skip = n;
        },
        fetchPolicy: {
            handler(v) {
                this.$apollo.queries.proposedItems.options.fetchPolicy = v;
            },
        },
    },
};
</script>