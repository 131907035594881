import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "../auth/authGuard";

import Dashboard from '../views/Dashboard.vue'
import LoginPage from '../views/LoginPage.vue'
import Recovery from '../views/Recovery.vue'
import Register from '../views/Register.vue'
import LabtestList from '../views/LabtestList.vue'
import SettingsLabs from '../views/settings/Labs.vue'
import Shipping from '../views/Shipping.vue'
import NewSupplyOrder from '../views/supplies/NewOrder.vue'
import SupplyOrders from '../views/supplies/Orders.vue'
import Patients from '../views/Patients.vue'
import FacilityProfile from '../views/FacilityProfile.vue'
import PatientDashboard from '../views/patients/Dashboard.vue'
import Forgotten from '../views/Forgotten.vue'
import Binder from '../views/binder.vue'
import Applications from '../views/Applications.vue'
import Application from '../views/Application.vue'
import Ereq from '../views/Ereq.vue'
import EreqTable from '../views/EreqTable.vue'
import EreqPage from '../views/EreqPage.vue'
import AccountSettings from '../views/account/settings.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/labtestNew',
    name: 'labtestNew',
    component: () => import(/* webpackChunkName: "labtestNew" */'../views/LabtestNew.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/labtestNew/:ereq_id',
    name: 'labtestEdit',
    component: () => import(/* webpackChunkName: "labtestNew" */'../views/LabtestNew.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/labtestNewP/:patient_id',
    name: 'labtestNewPatient',
    component: () => import(/* webpackChunkName: "labtestNew" */'../views/LabtestNew.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/labtestList',
    name: 'labtestList',
    component: LabtestList,
    beforeEnter: authGuard,
  },
  {
    path: '/labtestorder/:ereq_id',
    name: 'LabTestOrder',
    component: () => import(/* webpackChunkName: "labtestNew" */'../views/LabTestOrder.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/labtestresult',
    name: 'LabTestResult',
    component: () => import(/* webpackChunkName: "labtestNew" */'../views/LabTestResult.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/settings/labs',
    name: 'Lab Settings',
    component: SettingsLabs,
    beforeEnter: authGuard,
  },
  {    
    path: '/shipping',
    name: 'Shipping',
    component: Shipping,
    beforeEnter: authGuard,
  },
  {
    path: '/supplies/new',
    name: 'NewSupplyOrder',
    component: NewSupplyOrder,
    beforeEnter: authGuard,
  },
  {
    path: '/supplies/orders/:status?',
    name: 'SupplyOrders',
    component: SupplyOrders,
    beforeEnter: authGuard,
  },
  {
    path: '/patients',
    name: 'Patients',
    component: Patients,
    beforeEnter: authGuard,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "demo" */'../views/demo.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/settings/facilities',
    name: 'Settings Facilities',
    component: () => import(/* webpackChunkName: "labs" */'../views/settings/Facilities'),
    beforeEnter: authGuard,
  },
  {
    path: '/settings/users',
    name: 'Settings Users',
    component: () => import(/* webpackChunkName: "users" */'../views/settings/Users'),
    beforeEnter: authGuard,
  },
  {
    path: '/settings/payers',
    name: 'Settings Payers',
    component: () => import(/* webpackChunkName: "payers" */'../views/settings/Payers'),
    beforeEnter: authGuard,
  },
  {
    path: '/settings/diagnosis',
    name: 'Settings Diagnosis',
    component: () => import(/* webpackChunkName: "diagnosis" */'../views/settings/Diagnosis'),
    beforeEnter: authGuard,
  },
  {
    path: '/settings/medications',
    name: 'Settings Medications',
    component: () => import(/* webpackChunkName: "diagnosis" */'../views/settings/Medications'),
    beforeEnter: authGuard,
  },
  {
    path: '/facility/:id',
    name: 'Facility Profile',
    component: FacilityProfile,
    beforeEnter: authGuard,
  },
  {
    path: '/patients/dashboard/:id',
    name: 'Patient Dashboard',
    component: PatientDashboard,
    beforeEnter: authGuard,
  },
  {
    path: '/forgotten',
    name: 'forgotten',
    component: Forgotten,
  },
  {
    path: '/recovery/:token',
    name: 'recovery',
    component: Recovery,
  },
  {
    path: '/binder',
    name: 'binder',
    component: Binder,
  },
  {
    path: '/applications',
    name: 'Applications',
    component: Applications,
    beforeEnter: authGuard,
  },
  {
    path: '/application/:id',
    name: 'Application',
    component: Application,
    beforeEnter: authGuard,
  },
  {
    path: '/ereq',
    name: 'Ereq',
    component: Ereq,
    beforeEnter: authGuard,
  },
  {
    path: '/ereqtable',
    name: 'EreqTable',
    component: EreqTable,
    beforeEnter: authGuard,
  },
  {
    path: '/ereqpage',
    name: 'EreqPage',
    component: EreqPage,
    beforeEneter: authGuard
  },
  {
    path: '/account/settings',
    name: 'AccountSettings',
    component: AccountSettings,
    beforeEneter: authGuard
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//router.beforeEach(authGuard);

export default router
