<template>
    <v-container>
         <v-toolbar dark dense color="primary" :elevation="0">
            <v-toolbar-title>
                Image Capture
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon small @click="closeDialog()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card>
            <v-row justify="center"><h2 class="primary--text pt-4">Capture {{cardType == "Id" ? "ID" : "Insurance"}} Card {{side.toUpperCase()}}</h2></v-row>
             <v-row justify="center">
                 <v-col cols="12">
                    <v-alert v-if="isMobile()" tile text type="info" class="ma-2">Capture the image against a solid-colored background to provide high contrast. Please make sure the card covers 80% of the overall image and image is captured in clear focus without blurring, glare, shadow, or holographic reflection.</v-alert>
                 </v-col>
             </v-row>
            
            <v-card class="text-center pa-6" flat>
                <div v-if="!isMobile()" id="dwtcontrolContainer" v-show="false"></div>
                <canvas class="canvas" ref="canvas" :width="cameraWidth" :height="cameraHeight" style="display:none"></canvas>
                <video v-show="isStarted" class="video" playsinline srcObject="" ref="video"></video>
                <img :src="imageSrc" alt="" class="camera-output" ref="output" />
            </v-card>
            <v-card-actions>
                <v-row justify="center">
                    <v-btn v-show="isStarted" class="capture" text color="grey" @click="triggerCapture()"><v-icon x-large>mdi-camera-iris</v-icon></v-btn>
                </v-row>
            </v-card-actions>
            <v-bottom-navigation v-model="bottomNav" dark>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="prevStep()" :disabled="step<=0">
                        <span>Back</span>
                        <v-icon>mdi-chevron-double-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Back</span>
                </v-tooltip>

                 <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="uploadImage()" v-on="on">
                        <span>Upload</span>
                        <v-icon>mdi-file-upload</v-icon>
                        </v-btn>
                    </template>
                    <span>Upload Image</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="cameraStart()" v-on="on" v-if="isMobile()">
                        <span>Start Camera</span>
                        <v-icon>mdi-camera</v-icon>
                        </v-btn>
                    </template>
                    <span>Start Camera</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="DynamsoftAcquireImage()" v-on="on" v-if="!isMobile()">
                        <span>Start Scanner</span>
                        <v-icon>mdi-scanner</v-icon>
                        </v-btn>
                    </template>
                    <span>Start Scanner</span>
                </v-tooltip>

                 <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="removeImage()" v-on="on">
                        <span>Remove Image</span>
                        <v-icon>mdi-image-remove</v-icon>
                        </v-btn>
                    </template>
                    <span>Remove Image</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="cropImage()">
                        <span>Smart Crop</span>
                        <v-icon>mdi-crop</v-icon>
                        </v-btn>
                    </template>
                    <span>Smart Crop Image</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="sharpenImage()">
                        <span>Sharpen</span>
                        <v-icon>mdi-image</v-icon>
                        </v-btn>
                    </template>
                    <span>Sharpen Image</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="rotateImage(2)">
                        <span>Rotate Left</span>
                        <v-icon>mdi-rotate-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Rotate Left</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="rotateImage(-2)">
                        <span>Rotate Right</span>
                        <v-icon>mdi-rotate-right</v-icon>
                        </v-btn>
                    </template>
                    <span>Rotate Right</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="restoreImage()">
                        <span>Restore</span>
                        <v-icon>mdi-restore</v-icon>
                        </v-btn>
                    </template>
                    <span>Restore Original</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="step<1" v-on="on" @click="nextStep()">
                        <span>Next</span>
                        <v-icon>mdi-chevron-double-right</v-icon>
                        </v-btn>
                        <v-btn v-else-if="step>0 && ocr && cardFront!=null" v-on="on" @click="OCR()">
                            <span>OCR</span>
                            <v-icon>mdi-ocr</v-icon>
                        </v-btn>
                        <v-btn v-else v-on="on" @click="saveImages()">
                            <span>Next</span>
                            <v-icon>mdi-chevron-double-right</v-icon>
                        </v-btn>
                    </template>
                    <span v-if="step<1 || (step > 0 && !ocr)">Next</span>
                    <span v-else>Extract Data</span>
                </v-tooltip>

            </v-bottom-navigation>
        </v-card>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import Dynamsoft from "dwt";

export default {
    props: {
        ocr: {type: Boolean, required: true, default: () => true},
        cardType: {type: String, required: true, in: ["Id", "Ins"], default: () => "Ins" },
    },
    data() {
        return {
            step: 0,
            cardFrontOriginal: null,
            cardBackOriginal: null,
            cardFront: null,
            cardBack: null,
            isStarted: false,
            isDuplexScanner: false,
            cameraWidth: 0,
            cameraHeight: 0,
            aspectRatio: 0,
            constraints: {
                video: {
                    facingMode: {exact: "environment"},
                    height: {ideal: 1440},
                    width: {ideal: 2600},
                },
                audio: false
            },
            bottomNav: 0,
            video: null,
            canvas: null,
            output: null,
            DWObject: false,
            loading : false,
            imageSrc: null,
        }
    },
    computed: {
        side() {
            return this.step==0 ? "front" : "back";
        },
    },
    methods: {
        isIOS() {
            return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        },
        isSafari() {
            var ua = navigator.userAgent.toLowerCase(); 
            if(ua.indexOf('safari') != -1) { 
                if(ua.indexOf('chrome') > -1) {
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        },
        isMobile() {
            var check = false;
            var str = navigator.userAgent || navigator.vendor || window.opera;
            if(/(android|bb[0-9]+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(str) 
                || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(str.substr(0, 4))) 
                check = true;
            
            return check || this.isIOS();
        },
        start() {
             this.cameraStart(this.constraints);
            /*
            var app = this;
            if(this.isSafari()) {
                this.cameraStart(app.constraints);
            }
            else {
                this.$refs.video.requestFullscreen().then(function() {
                    this.cameraStart(app.constraints);
                });
            }
            */
        },
        cameraStart() {
            var app = this;
            if(!this.isStarted) {
                navigator.mediaDevices.getUserMedia(app.constraints).then(function(stream) {
                    let track = stream.getTracks()[0];
                    let cameraSettings = track.getSettings();
                    
                    app.cameraWidth = cameraSettings.width;
                    app.cameraHeight = cameraSettings.height;
                    app.aspectRatio = cameraSettings.aspectRatio;

                    app.isStarted = true;
                    app.canvas.width = cameraSettings.width;
                    app.canvas.height = cameraSettings.height;
                    app.video.srcObject = stream;
                    app.video.play();
                })
                .catch(function(error) {
                    alert("Oops. Something is broken." + error);
                });
            }
        },
        cameraStop() {
            if(this.isStarted) {
                this.isStarted = false;
                this.video.srcObject.getTracks().forEach((t) => {
                    t.stop();
                });
            }
        },
        triggerCapture() {
            let context = this.canvas.getContext('2d');
            let b64data = null;

            context.mozImageSmoothingEnabled = false;
            context.webkitImageSmoothingEnabled = false;
            context.msImageSmoothingEnabled = false;
            context.imageSmoothingEnabled = false;

            context.drawImage(this.video, 0, 0, this.cameraWidth, this.cameraHeight);
            b64data = this.canvas.toDataURL("image/jpeg", 1);
            this.imageSrc = b64data;

            if(this.step == 0) {
                this.cardFrontOriginal = b64data.split(",")[1];
                this.cardFront = this.cardFrontOriginal;
            }
            if(this.step == 1) {
                this.cardBackOriginal = b64data.split(",")[1];
                this.cardBack = this.cardBackOriginal;
            }
            
            this.cameraStop();
        },
        uploadImage() {
            let manualCaptureInput = null;
            manualCaptureInput = document.createElement("input");
            manualCaptureInput.type = "file";
            manualCaptureInput.capture = "environment";
            manualCaptureInput.accept = "image/*";
            manualCaptureInput.onchange = this.onManualCapture;
            manualCaptureInput.click();
        },
        onManualCapture(event) {
            let file = event.target;
            let reader = new FileReader();
            var app = this;

            reader.onload = function(e) {
                app.imageSrc = e.target.result;
                if(app.step == 0) {
                    app.cardFrontOriginal = e.target.result.split(",")[1];
                    app.cardFront = app.cardFrontOriginal;
                }
                else {
                    app.cardBackOriginal = e.target.result.split(",")[1];
                    app.cardBack = app.cardBackOriginal;
                }
            }
            reader.readAsDataURL(file.files[0]);
        },
        nextStep() {
            if(this.step == 0 && this.cardFront == null) {
                alert("Please capture the front side of card!");
                return;
            }
            this.step++;
            if(this.cardBack != null) this.imageSrc = "data:image/jpeg;base64,"+this.cardBack;
            else this.imageSrc = require("../assets/CardBack.png");
        },
        prevStep() {
            if(this.step > 0) this.step--;
            this.imageSrc = "data:image/jpeg;base64,"+this.cardFront;
        },
        restoreImage() {
            if(this.cardFrontOriginal != null) this.cardFront = this.cardFrontOriginal;
            if(this.cardBackOriginal != null) this.cardBack = this.cardBackOriginal;

            if(this.step == 0) {
                this.imageSrc = this.cardFront!=null ? "data:image/jpeg;base64,"+this.cardFront : require("../assets/CardFront.png");
            }
            else {
                this.imageSrc = this.cardBack!=null ? "data:image/jpeg;base64,"+this.cardBack : require("../assets/CardBack.png");
            }
        },
        removeImage() {
            if(this.isDuplexScanner) {
                this.cardFront = null;
                this.cardFrontOriginal = null;
                this.cardBack = null;
                this.cardBackOriginal = null;
                if(this.DWObject != null) {
                    this.DWObject.RemoveAllImages();
                }
                this.step = 0;
                this.imageSrc = require("../assets/CardFront.png");
            }
            else {
                if(this.step == 0) {
                    this.cardFront = null;
                    this.cardFrontOriginal = null;
                    if(this.DWObject != null) {
                        this.DWObject.RemoveImage(0);
                    }
                    this.imageSrc = require("../assets/CardFront.png");
                }
                else {
                    this.cardBack = null;
                    this.cardBackOriginal = null;
                    if(this.DWObject != null) {
                        this.DWObject.RemoveImage(1);
                    }
                    this.imageSrc = require("../assets/CardBack.png");
                }
            }
        },
        cropImage() {
            this.loading = true;

            this.$apollo.mutate({
                mutation: gql`mutation($image: String!) {
                    ImageCrop(Image: $image) {
                        Status
                        Output
                    }
                }`,
                variables: {
                    image: (this.step == 0 ? this.cardFront : this.cardBack),
                }
            }).then(res => {
                this.loading = false;

                if(res.data.ImageCrop.Status == 'Success') {
                    if(this.step == 0) {
                        this.cardFront = res.data.ImageCrop.Output;
                        this.imageSrc = "data:image/jpeg;base64,"+this.cardFront;
                    }
                    else {
                        this.cardBack = res.data.ImageCrop.Output;
                        this.imageSrc = "data:image/jpeg;base64,"+this.cardBack;
                    }
                }
                else alert(res.data.ImageCrop.Status);
            });
        },
        rotateImage(degrees) {
            this.loading = true;

            this.$apollo.mutate({
                mutation: gql`mutation($image: String!, $angle: Int!) {
                    ImageRotate(Image: $image, Angle: $angle) {
                        Status
                        Output
                    }
                }`,
                variables: {
                    image: (this.step == 0 ? this.cardFront : this.cardBack),
                    angle: degrees
                }
            }).then(res => {
                this.loading = false;

                if(res.data.ImageRotate.Status == 'Success') {
                    if(this.step == 0) {
                        this.cardFront = res.data.ImageRotate.Output;
                        this.imageSrc = "data:image/jpeg;base64,"+this.cardFront;
                    }
                    else {
                        this.cardBack = res.data.ImageRotate.Output;
                        this.imageSrc = "data:image/jpeg;base64,"+this.cardBack;
                    }
                }
                else alert(res.data.ImageRotate.Status);
            });
        },
        sharpenImage() {
            this.loading = true;

            this.$apollo.mutate({
                mutation: gql`mutation($image: String!) {
                    ImageSharpen(Image: $image) {
                        Status
                        Output
                    }
                }`,
                variables: {
                    image: (this.step == 0 ? this.cardFront : this.cardBack),
                }
            }).then(res => {
                this.loading = false;

                if(res.data.ImageSharpen.Status == 'Success') {
                    if(this.step == 0) {
                        this.cardFront = res.data.ImageSharpen.Output;
                        this.imageSrc = "data:image/jpeg;base64,"+this.cardFront;
                    }
                    else {
                        this.cardBack = res.data.ImageSharpen.Output;
                        this.imageSrc = "data:image/jpeg;base64,"+this.cardBack;
                    }
                }
                else alert(res.data.ImageSharpen.Status);
            });
        },
        DynamsoftInit() {
            Dynamsoft.DWT.ResourcesPath = "../../dwt-resources";    // Relative path
            Dynamsoft.DWT.AutoLoad = false;
            Dynamsoft.DWT.ProductKey = "t0149KQMAAAOOMoCajFOaNxaj8RoRV59D0h90BzrLaIjdl/MisrzbQJbX3uvQtmLpc5107iLWveVLFvcz/8KQqxO0pB9M8m+MDdEwiCYCineVYTEfM4qr52+f29isf+aL8tHpUxlOGB0b20/fNDNfO2Q7c204YXRsmpkr095zyEPcrpB0eukZThgdmzPz09BtkYzrAUcOosI=";
            Dynamsoft.DWT.Containers = [
                {
                    WebTwainId: "dwtObject",
                    ContainerId: "dwtcontrolContainer",
                    Width: "100%",
                    Height: "260px"
                }
            ];
            
            Dynamsoft.DWT.RegisterEvent("OnWebTwainReady", () => {
                this.DynamsoftOnReady();
            });

            Dynamsoft.DWT.Load();
        },
        DynamsoftOnReady() {
            this.DWObject = Dynamsoft.DWT.GetWebTwain();
            this.loading = false;
            this.DynamsoftAcquireImage();
        },
        DynamsoftAcquireImage() {
            if(this.DWObject) {
                let app = this;
                this.DWObject.SelectSource(function() {
                    // Callbacks
                    var OnAcquireImageSuccess,
                        OnAcquireImageFailure,
                        asyncB64SuccessFunc0,
                        asyncB64SuccessFunc1,
                        asyncB64FailureFunc;

                    OnAcquireImageSuccess = function() {
                        if(app.DWObject.HowManyImagesInBuffer == 1) {
                            if(app.DWObject.GetImageBitDepth(app.DWObject.CurrentImageIndexInBuffer) == 1) {
                                /* B/W image, convert to GRAYSCALE */
                                app.DWObject.ConvertToGrayScale(app.DWObject.CurrentImageIndexInBuffer);
                            }
                            app.DWObject.ConvertToBase64([0], 1, asyncB64SuccessFunc0, asyncB64FailureFunc);
                        }
                        else if(app.DWObject.HowManyImagesInBuffer == 2) {
                            for(var i=0; i<app.DWObject.HowManyImagesInBuffer; i++) {
                                app.DWObject.CurrentImageIndexInBuffer = i;

                                if(app.DWObject.GetImageBitDepth(app.DWObject.CurrentImageIndexInBuffer) == 1) {
                                    /* B/W image, convert to GRAYSCALE */
                                    app.DWObject.ConvertToGrayScale(app.DWObject.CurrentImageIndexInBuffer);
                                }
                            }
                            // Handle full duplex devices
                            if(app.DWObject.Duplex == 1 && app.cardFront == null) {
                                app.DWObject.ConvertToBase64([0], 1, asyncB64SuccessFunc0, asyncB64FailureFunc);
                            }
                            app.DWObject.ConvertToBase64([1], 1, asyncB64SuccessFunc1, asyncB64FailureFunc);
                            app.DWObject.CloseSource();
                        }
                    };
                    OnAcquireImageFailure = function() {
                        app.DWObject.CloseSource();
                    };
                    asyncB64SuccessFunc0 = function(base64Result) {
                        app.cardFrontOriginal = base64Result.getData(0, base64Result.getLength());
                        app.cardFront = app.cardFrontOriginal;
                        app.imageSrc = "data:image/jpeg;base64,"+app.cardFront;
                    };
                    asyncB64SuccessFunc1 = function(base64Result) {
                        app.cardBackOriginal = base64Result.getData(0, base64Result.getLength());
                        app.cardBack = app.cardBackOriginal;
                        app.imageSrc = "data:image/jpeg;base64,"+app.cardBack;
                        app.step = 1;
                    };
                    asyncB64FailureFunc = function(errorCode, errorString) {
                        alert("ErrorCode: " + errorCode + "\r\n" + "ErrorString: " + errorString);
                    };

                    app.DWObject.OpenSource();
                    app.DWObject.IfDisableSourceAfterAcquire = true;
                    app.DWObject.IfShowUI = false;
                    app.DWObject.PixelType = 2; // 0: B/W; 1: GRAY; 2: RGB; 3: PALLETE; 4: CMY; 5: CMYK; 6: YUV; 7: YUVK; 8: CIEXYZ; 9: LAB; 10: SRGB; 11: SCRGB; 16: INFRARED;
                    app.DWObject.BitDepth = 24;
                    app.DWObject.Resolution = 300;
                    if(app.DWObject.Duplex != 0) {
                        app.DWObject.IfDuplexEnabled = true;
                        if(app.DWObject.Duplex == 1) app.isDuplexScanner = true;
                    }
                    app.DWObject.MaxImagesInBuffer = 2;
                    app.DWObject.AcquireImage(OnAcquireImageSuccess, OnAcquireImageFailure);
                });
            }
        },
        OCR() {
            if(this.DWObject) {
                this.DWObject.CloseSource();
            }
            this.loading = true;
            var app = this;
            var fields = null;
            if(this.cardType == "Ins" || !this.cardType) fields = "MemberId, MemberName, FirstName, MiddleName, LastName, PlanType, PlanProvider, PayerId, GroupName, GroupNumber, RxBin, EffectiveDate, ExpirationDate";
            else fields = "BirthDate, Sex, FirstName MiddleName, Surname, IssuingStateCode, DocumentClassName, Height, Weight, ExpirationDate, DocumentNumber, AddressLine1, AddressLine2, AddressCity, AddressState, AddressPostalCode";
            this.$apollo.mutate({
                mutation: gql`mutation($front: String!, $back: String) {
                    Acuant${this.cardType}OCR(CardFront: $front, CardBack: $back) {
                        ErrorCode
                        ErrorDescription
                        Context
                        ${fields}
                    }
                }`,
                variables: {
                    front: this.cardFront,
                    back: this.cardBack
                },
                update: (cache, {data}) => {
                    try {
                        app.loading = false;
                        app.$emit('dataExtracted', data["Acuant"+app.cardType+"OCR"], app.cardFront, app.cardBack);
                    }
                    catch(error) {
                        console.error(error);
                        app.loading = false;
                    }
                }
            });
        },
        saveImages() {
            if(this.DWObject) {
                this.DWObject.CloseSource();
            }
            this.$emit('captureComplete', this.cardFront, this.cardBack);
        },
        closeDialog() {
            if(this.DWObject) {
                this.DWObject.CloseSource();
            }
            
            this.$emit('close-dialog');
        }
    },
    created() {
    },
    mounted() {
        this.canvas = this.$refs.canvas;
        this.video = this.$refs.video;
        this.output = this.$refs.output;

        this.imageSrc = require("../assets/CardFront.png");

        if(!this.isMobile()) this.DynamsoftInit();
    }
}
</script>
<style scoped>
#dwtcontrolContainer{
    display:none !important;
}
.video, .camera-output {
    max-width: 320px;
}
</style>