<template>
    
    <v-card :color="color" dark height="100%" elevation="12">
        <v-list-item  class="text-center" three-line>
            <v-list-item-content>
                <v-list-item-title class="overline mb-3">{{title}}</v-list-item-title>
                <v-list-item-title class="headline mb-2">{{valuePrefix}}{{value}}{{valueSuffix}}</v-list-item-title>
                <v-divider></v-divider>
                <v-list-item-subtitle><v-icon :color="iconColor" :style="{transform: 'rotate('+iconRotate+'deg)'}" small>mdi-triangle</v-icon> {{changePrefix}}{{change}}{{changeSuffix}}</v-list-item-subtitle>
                <v-list-item-subtitle>{{changeText}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>

</template>
<script>

export default {
    props: {
        title: {type: String, required:false},
        color: {type: String, required: false, default: "#29c0b1"},
        value: {type: String, default: ""},
        valuePrefix: {type: String, required:false, default:null},
        valueSuffix: {type: String, required:false, default:null},
        change: {type: String, required:false, default:""},
        changePrefix: {type: String, required:false, default:null},
        changeSuffix: {type: String, required:false, default:null},
        changeText: {type: String, required: false, default:null},
    },
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
        iconColor: function() {
            if(this.change == 0) return "yellow";
            else if(this.change > 0) return "green";
            else return "red";
        },
        iconRotate: function() {
            if(this.change == 0) return 90;
            else if(this.change > 0) return 0;
            else return 180;
        }
    },
    watch: {
    },
    mounted() {
    }
};
</script>