<template>
    <v-card v-if="this.$store.state.user.components.includes(67)">
        <crud :headers="headers" label="Supply Items" model="SupplyItem" :initialValues="{'facility_id': facility_id}" :conditions="[{facility_id:{eq:facility_id}},{deleted:{is:null}}]" ref="supplies">
            <template v-slot:item.File.id="obj">
                <v-tooltip bottom v-if="obj.item.File==null">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            dark
                            x-small
                            class="mx-1"
                            color="red lighten-1"
                            @click.stop="uploadImage(obj.item.id, null)"
                        >
                        <v-icon v-on="on">mdi-image</v-icon>
                        </v-btn>
                    </template>
                    <span>Click to upload image</span>
                </v-tooltip>
                <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on }">
                        <img v-on="on" :src="'https://lab-synapse.com/gql/storage/?id='+obj.item.File.id" width="64" style="cursor:pointer;" @click.stop="uploadImage(obj.item.id, obj.item.File.id)" />
                    </template>
                    <span>Click to upload new image</span>
                </v-tooltip>
            </template>
        </crud>
        <input type="file" name="file" style="visibility: hidden;" ref="fileInput" accept="image/*" />
    </v-card>
</template>

<script>
import crud from "../../../components/CRUD.vue"
import gql from "graphql-tag";
export default {
     props: {
         facility_id: {type: Number, required: true},
     },
     components: {
         crud,
     },
    data() {
        return {
            rowId: 0,
            headers: [
                {text: "ID", value: "id", editable: false, visible: false, isId: true},
                {text: "Facility", value: "facility_id", visible:false, editable:false},
                {text: "Name", value: "name", searchable:true, mandatory: true},
                {text: "Category", value: "category", type: "select", items: ["Toxicology", "Chemistry", "Office", "Misc"], mandatory: true},
                {text: "Qty In Box", value: "qty", mandatory: true},
                {text: "Qty Description", value:"qty_descr"},
                {text: "Price", value: "price", mandatory: true},
                {text: "Image", value: "File.id", editable:false, slot:true}
            ]
        }
    },
    methods: {
        uploadImage(id, file_id) {
            this.rowId = id;

            if(file_id != null) this._removeImage(file_id);

            this.$refs.fileInput.value = null;
            this.$refs.fileInput.click();

            this.$refs.fileInput.removeEventListener("change", this._uploadImage);
            this.$refs.fileInput.addEventListener("change", this._uploadImage);
        },
        _uploadImage(e) {
            var app = this;
            const file = e.target.files[0];
            if(file && this.rowId > 0) {
                var formData = new FormData();
                formData.append("file", file, file.filename);
                this.axios.post(
                    "https://lab-synapse.com/gql/storage/?type=SupplyItem&ownerType=supply_items&ownerID="+this.rowId,
                    formData
                )
                .then(res => {
                    if(res.data.status == "uploaded") {
                        app.$refs.supplies.$apollo.queries.DataPG.refetch();
                    }
                    else alert("Uploading failed!");
                });
            }
        },
        _removeImage(fid) {
            this.$apollo.mutate({
                mutation: gql`mutation($data: [FileSave]!) {
                    FilesSave(data: $data) {
                        id
                    }
                }`,
                variables: {
                    data: [{id:fid, _delete:true}]
                }
            })
        }
    }
}
</script>