<template>
     <v-card
        style="max-width: 860px;"
        class="mx-auto mt-12"
        v-resize="resized"
        :elevation="4"
    >
        <v-card-text class="pa-0 overflow-hidden">
            <v-row>
            <v-col
                class="order-last order-sm-1 order-md-1 pt-12 pb-12 pr-0 order-lg-0 order-xl-0 white"
                cols="12"
                md="12"
                lg="6"
            >   
                <div class="pa-3">
                    <v-form autocomplete="off" v-model="valid">
                        <h2 class="text-center">Password Reset</h2>

                        <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    solo
                                    type="password"
                                    label="New Password"
                                    placeholder="New Password"
                                    name="password1"
                                    autocomplete="new-password"
                                    v-model="password1"
                                    class="ml-4 mr-4 mt-10 mb-2"
                                    :rules="[r1]"
                                    dense
                                    v-on="on"
                                >
                                    <v-icon slot="append" :color="color1">{{icon1}}</v-icon>
                                </v-text-field>
                                </template>
                                <span>
                                <ul>
                                    <li>Password must be at least <b>8 characters</b> in length.</li>
                                    <li>Password must not be reused for at least <b>6 times</b>.</li>
                                    <li>Password must not contain significant portions <br/><b>(3 or more contiguous characters)</b> of your username or full name.</li>
                                    <li>Password must contain characters from <b>at least three</b> of the following<br/>four categories arranged in any order:</li>
                                        <ul>
                                            <li>English uppercase characters <b>(A through Z)</b></li>
                                            <li>English lowercase characters <b>(a through z)</b></li>
                                            <li>Digits <b>(0 through 9)</b></li>
                                            <li>Non-alphabetic characters: <b>~!@#$%^*&;?.+_</b></li>
                                        </ul>
                                </ul>
                                </span>
                            </v-tooltip>
                        <v-text-field
                            solo
                            type="password"
                            label="Confirm Password"
                            placeholder="Confirm Password"
                            name="password2"
                            v-model="password2"
                            autocomplete="new-password"
                            class="ml-4 mr-4"
                            :rules="[r1]"
                            dense
                        >
                            <v-icon slot="append" :color="color2">{{icon2}}</v-icon>
                        </v-text-field>
                    </v-form>
                </div>

                <div>
                    <v-row dense>
                        <v-col cols="12" md="12" >
                            <v-row justify="center">
                                <v-btn @click="resetPass()" color="#a0bff8" :disabled="!valid || password1!=password2">Reset Password</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <div>
                        <v-alert class="mt-3" :type="alertType" v-if="message">{{message}}</v-alert>
                    </div>
                    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                    <i class="material-icons-new sharp-assessment"></i>
                </div>
            </v-col>
            
            <v-col
                class="login-welcome order-first order-sm-0 order-md-0 pt-12 pb-12 order-lg-1 order-xl-1"
                cols="12"
                md="12"
                lg="6"
            >
                <div class="welcome-container">
                    <h3 class="text-center white--text">Welcome to</h3>
                    <img
                        src="../assets/Logo-07.png"
                        height="220"
                        alt="Lab Logo"
                        class="d-block mx-auto mt-12"
                    />
                </div>
            </v-col>
        </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
export default {
    data() {
        return {
            landscape: true,
            password1: null,
            password2: null,
            valid: false,
            message: null,
            loading: false,
            token: null,
            type: "password",
            alertType: "error",
            r1: function(v) {
                return (v || "").length > 7 || "Password doesn't meet complexity requirements!";
            },
        };
    },
    computed: {
        complexity() {
            let cnt = 0;
            if(/[0-9]{1,}/g.test(this.password1 || "")) cnt++
            if(/[a-z]{1,}/g.test(this.password1 || "")) cnt++;
            if(/[A-Z]{1,}/g.test(this.password1 || "")) cnt++;
            if(/[\W_]{1,}/g.test(this.password1 || "")) cnt++
            if(this.password1?.length >= 8) cnt += 2;

            return cnt;
        },
        color1() {
            return ["error", "success"][Math.floor(this.complexity/5)];
        },
        icon1() {
            if(this.complexity == 0) return null;
            if(this.complexity >= 5) return "mdi-check";
            return "mdi-alert";
        },
        color2() {
            return ["error", "success"][((this.password1 && this.password1 == this.password2) ? 1 : 0)];
        },
        icon2() {
            if(!this.password2) return null;
            if(this.password2 && this.password1 == this.password2) return "mdi-check";
            else return "mdi-alert";
        }
    },
    methods: {
        resized() {
            this.landscape = window.innerWidth > window.innerHeight;
        },
        resetPass() {
            this.message = null;
            this.loading = true;
            
            this.$apollo.mutate({
                mutation: gql`mutation ResetPassword($token: String!, $pass1: String!, $pass2: String!) {
                    ResetPassword(token: $token, new_password1: $pass1, new_password2: $pass2) {
                        Status
                        Message
                    }
                }`,
                variables: {
                    token: this.token,
                    pass1: this.password1,
                    pass2: this.password2,
                }
            }).then(res => {
                this.message = res.data.ResetPassword.Message;
                if(res.data.ResetPassword.Status == "Fail") this.alertType = "error";
                else {
                    this.alertType = "success";
                    var app = this;
                    window.setTimeout(function(){
                        app.$router.push('/');
                    }, 2000)
                }
            }).finally(() => {
                this.loading = false;
                //this.password1 = null;
                //this.password2 = null;
            });
        },
    },
    mounted() {
        this.token = this.$route.params.token;
    }
};
</script>
<style scoped>
.login-welcome {
    background-image: url("../assets/login_bar_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

</style>