<template>
    <v-container v-if="this.$store.state.user.components.includes(66)">
        <crud ref="shipping"
            @current-items="currentItemsFn"
            model="ShippingSetting"
            label="Shipping Accounts"
            :headers="headers"
            :conditions="[{facility_id:{eq:facility_id}}, {deleted:{is:null}}]"
            :initialValues="{'facility_id': facility_id}"
            :can-add="allowAdd"
        >
        </crud>
    </v-container>
</template>
<script>
import crud from "../../../../components/CRUD";

export default {
    props: [
        'facility_id',
    ],
    components: {
        crud,
    },
    data() {
        return {
            currentItems: [],
            menu: true,
            headers: [
                {text: "ID", value: "id", isId: true, visible: false, editable: false, },
                {text: "Facility ID", value: "facility_id", visible: false, editable: false},
                {text: "Carrier", value: "carrier", type: "radio", items: [], searchable: true, mandatory:true},
                {text: "Bill Type", value: "bill_type", type: "radio", items: ["Bill Shipper", "Bill Receiver"], mandatory: true},
                {text: "Account #", value: "account", searchable: true, mandatory: true},
                {text: "Attention Name", value: "attn_name", mandatory: true},
                {text: "Office Close Time", value: "close_time", type: "time", fieldOptions:{format:"24hr"}, mandatory: false, menu: this.menu},
            ]
        }
    },
    computed: {
        allowAdd: function() {
            return (this.currentItems.length < this.$store.state.shippingCarriers.length);
        }
    },
    methods: {
        currentItemsFn: function(items){
            this.currentItems = items;
        }
    },
    mounted: function() {
        this.headers[2].items = this.$store.state.shippingCarriers;
    }
}
</script>
