<template>
    <v-container class="fill-height">
        <v-card v-resize="resized" elevation="4" width="100%" max-width="900" class="mx-auto">
            <v-card-text class="py-0 px-3">
                <v-row>
                    <v-col
                        class="py-md-12 px-8 order-last order-sm-first"
                        cols="12"
                        sm="6"
                        v-if="invalidLogins < 5"
                    >
                        <v-row>
                            <v-col>
                                <v-alert text type="info" v-if="info">{{info}}</v-alert>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h2 class="text-center text-h5">Login</h2>
                                <p class="text-center">Sign in to your account</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-form autocomplete="off" v-model="valid">
                                    <v-text-field
                                        v-if="!auth2"
                                        outlined
                                        label="Username"
                                        placeholder=" "
                                        autocomplete="off"
                                        name="username"
                                        v-model="username"
                                        :disabled="loading"
                                        ref="username"
                                        :rules="[userRequired]"
                                        @keydown.enter="username && password && login()"
                                        dense
                                        class="detectAutoFill"
                                    ></v-text-field>
                                    <v-text-field
                                        v-if="!auth2"
                                        outlined
                                        type="password"
                                        label="Password"
                                        placeholder=" "
                                        name="password"
                                        autocomplete="off"
                                        v-model="password"
                                        :disabled="loading"
                                        ref="password"
                                        :rules="[passRequired]"
                                        @keydown.enter="username && password && login()"
                                        dense
                                        class="detectAutoFill"
                                    ></v-text-field>
                                    <v-text-field
                                        v-if="auth2"
                                        outlined
                                        label="Passcode"
                                        maxlength="6"
                                        v-model="passcode"
                                        dense
                                        type="tel"
                                        v-mask="'######'"
                                        :rules="[passCodeRequired]"
                                    ></v-text-field>
                                </v-form>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn
                                    plain
                                    block
                                    v-on:click="login()"
                                    v-if="!$store.state.user.isAuthenticated"
                                    :disabled="!valid"
                                    color="#a0bff8"
                                >Login</v-btn>
                                <v-btn
                                    outlined
                                    block
                                    v-on:click="logout()"
                                    v-else
                                    color="#a0bff8"
                                >Logout</v-btn>
                            </v-col>
                            <v-col v-if="auth2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            @click="sendSMS()"
                                            color="#a0bff8"
                                            outlined
                                            block
                                            v-on="on"
                                        >Send SMS</v-btn>
                                    </template>
                                    <span>Can't access your email? Click to send a new code to your mobile phone.</span>
                                </v-tooltip>
                            </v-col>
                            <v-col v-if="!auth2">
                                <v-btn
                                    outlined
                                    block
                                    v-on:click="$router.push('register')"
                                    color="#a0bff8"
                                >Register</v-btn>
                            </v-col>
                            <v-col v-if="!auth2">
                                <v-btn
                                    outlined
                                    block
                                    text
                                    v-on:click.prevent="$router.push('forgotten')"
                                >Forgot Password?</v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="error">
                            <v-col>
                                <v-alert class="mt-3" type="error">{{error}}</v-alert>
                            </v-col>
                        </v-row>
                        <v-row v-if="loading">
                            <v-col>
                                <v-progress-linear indeterminate></v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="py-12 order-last order-sm-first" cols="12" sm="6" v-else>
                        <v-row align="center" class="fill-height">
                            <v-alert
                                class="pa-4 ma-4"
                                type="error"
                                text
                            >Your IP address was blocked because of too many login attempt failures!</v-alert>
                        </v-row>
                    </v-col>
                    <v-col
                        class="login-welcome pa-6 pa-sm-12 order-first order-sm-last"
                        cols="12"
                        sm="6"
                    >
                        <h3 class="text-center white--text text-h5 mb-0 mb-sm-12">Welcome to</h3>
                        <v-spacer />
                        <v-img
                            src="../assets/Logo-07.png"
                            max-width="300"
                            class="mx-auto mt-3 mt-sm-12"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <captcha
            action="login"
            sitekey="6LdC26kZAAAAACW3DgK_DN9rqyywZWTjJpUxKxg1"
            @reCAPTCHA3="getToken"
        />
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import { mask } from "vue-the-mask";
import captcha from "../components/reCAPTCHAv3.vue";
export default {
    directives: {
        mask,
    },
    components: {
        captcha,
    },
    data() {
        return {
            landscape: true,
            username: null,
            password: null,
            captcha: null,
            passcode: null,
            error: null,
            info: null,
            valid: false,
            loading: false,
            ipAddress: null,
            invalidLogins: 0,
            auth2: false,

            userRequired: function (val) {
                return !!val || "This is a required field!";
            },
            passRequired: function (val) {
                //return val.length > 7 || "Incorrect password!"
                return !!val || "This is a required field!";
            },
        };
    },
    computed: {
        passCodeRequired() {
            if (this.auth2) {
                return (val) =>
                    (val || "").length == 6 || "A 6-digit code is required!";
            } else return true;
        },
    },
    methods: {
        getToken(t) {
            this.captcha = t;
        },
        resized() {
            this.landscape = window.innerWidth > window.innerHeight;
        },
        login() {
            this.error = null;
            this.info = null;
            this.loading = true;
            let location = null;
            let locations = null;
            if (typeof localStorage !== "undefined") {
                let l = localStorage.getItem("location");
                let ls = localStorage.getItem("locations");
                try {
                    if (l != "undefined") location = JSON.parse(l);
                    if (ls != "undefined") locations = JSON.parse(ls);
                } catch (e) {
                    console.log(e);
                }
            }

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation(
                            $username: String!
                            $password: String!
                            $captcha: String!
                            $passcode: String
                            $location: ID
                            $locations: [ID]
                        ) {
                            Authorize(
                                username: $username
                                password: $password
                                captcha: $captcha
                                passcode: $passcode
                                location: $location
                                locations: $locations
                            ) {
                                token
                                auth2
                                components
                            }
                        }
                    `,
                    variables: {
                        username: this.username,
                        password: this.password,
                        captcha: this.captcha,
                        passcode: this.passcode,
                        location: location,
                        locations: locations,
                    },
                })
                .then((res) => {
                    if (res.data.Authorize.token) {
                        this.username = null;
                        this.password = null;
                        this.passcode = null;

                        this.$store.dispatch("logIn", res.data.Authorize);
                        let gotoPage = localStorage.getItem("last-page");
                        this.$router.push(gotoPage || "/");
                    } else if (res.data.Authorize.auth2.r) {
                        this.auth2 = true;
                        this.info = res.data.Authorize.auth2.t;
                    } else {
                        this.error =
                            "Wrong username/password or captcha error!";
                        this.invalidLogins++;

                        this.username = null;
                        this.password = null;
                        this.passcode = null;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // Log the user out
        logout() {
            this.$store.dispatch("logOut");
        },
        forgotten() {
            alert("forgotten");
        },
        sendSMS() {
            this.info = null;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation SendSMS($usr: String!) {
                            SendSMS(username: $usr) {
                                Status
                                Description
                                ErrorCode
                                ErrorDescription
                            }
                        }
                    `,
                    variables: {
                        usr: this.username,
                    },
                })
                .then((res) => {
                    this.info = res.data.SendSMS.Description;
                });
        },
        fixAutoFill() {
            setTimeout(() => {
                this.$refs.password.$el.querySelector("input").click();
            }, 10);
            setTimeout(() => {
                document.body.click();
            }, 20);
            setTimeout(() => {
                this.$refs.username.$el.querySelector("input").click();
            }, 2000);
        },
    },
};
</script>

<style scoped>
.login-welcome {
    margin-right: -5px;
    background-image: url("../assets/login_bar_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
:-webkit-autofill {
    animation-name: onAutoFillStart;
    transition: background-color 5s ease-in-out 5s;
}
:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
}
</style>