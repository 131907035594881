<template>
    <v-container>
        <v-card>
            <v-card-text>
                <v-row no-gutters>
                    <v-col class="custom-section">
                        <v-row no-gutters>
                            <v-col>Patient Details</v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="4">
                                <textfield label="First Name" value="Asparuh" />
                            </v-col>
                            <v-col cols="4">
                                <textfield label="Last Name" value="Nestorov" />
                            </v-col>
                            <v-col cols="4">
                                <dropdown
                                    label="gender"
                                    :options="[{label:'Please Select'},{label:'Male'},{label:'Female'}]"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-for="i in 3" :key="i" class="custom-section">
                        <v-row no-gutters>
                            <v-col>Insurance {{i}}</v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="4">
                                <textfield label="First Name" value="Asparuh" />
                            </v-col>
                            <v-col cols="4">
                                <textfield label="Last Name" value="Nestorov" />
                            </v-col>
                            <v-col cols="4">
                                <dropdown
                                    label="gender"
                                    :options="[{label:'Please Select'},{label:'Male'},{label:'Female'}]"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import textfield from "../components/custom/textField";
import dropdown from "../components/custom/dropDown";
export default {
    data() {
        return {};
    },
    components: {
        textfield,
        dropdown
    }
};
</script>

<style scoped>
.custom-section {
    border: 1px solid #00ff002c;
    padding: 5px;
}
</style>