import gql from "graphql-tag";
import { copy, getScalarsFromObject } from "../tools/copy";
export const gqlBinder = {
    data() {
        return {
            gqlBinder: {
                data:{},
                datacopy:{},
                mutations: {},
                model: {},
                loading: false,
                error: null,
                automutate:false,
                pending:false,
                mutate: () => this.$_mutate()
            },
        };
    },
    
    methods: {
        $_stringify() {
            if (this.gqlBinder.model instanceof Object) {
                return this.$_stringifyObject(this.gqlBinder.model);
            } else {
                console.error(
                    "Unknow GraphQL structure!",
                    this.gqlBinder.model
                );
            }
        },
        $_stringifyObject(obj) {
            let res = [];
            let params = [];
            Object.keys(obj).forEach(k => {
                if (k[0] == "$") {
                    params.push(
                        k.substring(1) + ":" + this.$_stringifyInput(obj[k])
                    );
                } else if (k[0] == "_") {
                    null;
                } else {
                    if (obj[k] instanceof Array) {
                        console.error("Unknow GraphQL Array structure!");
                    }
                    if (obj[k] instanceof Object) {
                        if (obj[k]["_alias"])
                            res.push(k + ":" + obj[k]["_alias"]);
                        else res.push(k);

                        res.push(this.$_stringifyObject(obj[k]));
                    } else {
                        if (obj[k]) res.push(k + ":" + obj[k]);
                        else res.push(k);
                    }
                }
            });
            return (
                (params.length > 0 ? "(" + params.join(", ") + ") " : "") +
                "{ " +
                res.join(" ") +
                " }"
            );
        },

        $_stringifyInput(v) {
            if (v instanceof Array) {
                return this.$_stringifyInputArray(v);
            }
            if (v instanceof Object) {
                return this.$_stringifyInputObject(v);
            } else {
                return v;
            }
        },
        $_stringifyInputObject(obj) {
            let res = [];

            Object.keys(obj).forEach(k => {
                if (obj[k] instanceof Array) {
                    res.push(this.$_stringifyInputArray(obj[k]));
                }
                if (obj[k] instanceof Object) {
                    res.push(k + ": " + this.$_stringifyInputObject(obj[k]));
                } else {
                    if (typeof obj[k] == "boolean")
                        res.push(k + ": " + String(obj[k]));
                    else if (typeof obj[k] == "string")
                        res.push(k + ': "' + obj[k] + '"');
                    else if (typeof obj[k] == "number")
                        res.push(k + ": " + obj[k]);
                    else if (obj[k]) res.push(k + ': "' + obj[k] + '"');
                    else res.push(k);
                }
            });
            return "{ " + res.join(" ") + " }";
        },
        $_stringifyInputArray(arr) {
            let res = [];
            arr.forEach(v => {
                if (v instanceof Array) {
                    res.push(this.$_stringifyInputArray(v));
                }
                if (v instanceof Object) {
                    res.push(this.$_stringifyInputObject(v));
                } else {
                    res.push('"' + v + '"');
                }
            });

            return "[ " + res.join(",") + " ]";
        },

        $_lookupArray(n, o) {
            let nids = [];
            let oids = [];
            let oobjs = {};
            let res = { added: [], removed: [], matching: [] };
            o.forEach(v => {
                if (v instanceof Object) {
                    oids.push(v.id);
                    oobjs[v.id] = v;
                } else {
                    console.error("Non-Object in Array");
                    return { added: [], removed: [], matching: [] };
                }
            });
            n.forEach(v => {
                if (v instanceof Object) {
                    nids.push(v.id);
                } else {
                    console.error("Non-Object in Array");
                    return { added: [], removed: [], matching: [] };
                }
            });

            res.added = n.filter(v => !v.id || oids.indexOf(v.id) < 0);
            res.removed = o.filter(v => nids.indexOf(v.id) < 0);

            let matching = [];
            n.forEach(v => {
                if (oobjs[v.id]) {
                    matching.push({ n: v, o: oobjs[v.id] });
                }
            });
            res.matching = matching;

            //console.warn("changed", res);
            return res;
        },

        $_findMutations(m, n, o, model) {
            Object.keys(model).forEach(k => {
                if (k[0] != "$" && k[0] != "_") {
                    let key = k;
                    if (model[k] instanceof Object) {
                        //if (model[k]["_alias"]) key = model[k]["_alias"];

                        if (
                            n[key] instanceof Array &&
                            o[key] instanceof Array
                        ) {
                            let res = this.$_lookupArray(n[key], o[key]);

                            res.added.forEach(v => {
                                if (!m[model[k]["_model"]])
                                    m[model[k]["_model"]] = [];
                                let row = copy(v);
                                delete row.__typename;
                                m[model[k]["_model"]].push(row);
                            });

                            res.removed.forEach(v => {
                                if (!m[model[k]["_model"]])
                                    m[model[k]["_model"]] = [];
                                m[model[k]["_model"]].push({
                                    id: v.id,
                                    _delete: true
                                });
                            });

                            res.matching.forEach(vv => {
                                this.$_findMutations(m, vv.n, vv.o, model[k]);
                            });
                        } else if (
                            n[key] instanceof Object &&
                            o[key] instanceof Object
                        ) {
                            this.$_findMutations(m, n[key], o[key], model[k]);
                        } else
                            console.error(
                                "Model vs Data mismatch!",
                                n[key],
                                o[key]
                            );
                    } else {
                        //if (model[k]) key = model[k];
                        if (
                            !(n[key] instanceof Object) &&
                            !(o[key] instanceof Object)
                        ) {
                            if (n[key] != o[key]) {
                                if (!m[model["_model"]])
                                    m[model["_model"]] = [];

                                let row = getScalarsFromObject(n);
                                delete row.__typename;
                                let exists = m[model["_model"]].filter(
                                    v => v.id == n.id
                                );
                                if (exists.length < 1) {
                                    m[model["_model"]].push(row);
                                } else {
                                    exists[0][k] = n[key];
                                }
                            }
                        } else
                            console.error(
                                "Model vs Data mismatch!",
                                n[key],
                                o[key]
                            );
                    }
                }
            });
        },
        $_loadData() {
            this.gqlBinder.loading = true;
            this.gqlBinder.error = null;
            this.$apollo
                .query({
                    query: gql`
                    query ${this.$_stringify()}
                `,
                    fetchPolicy: "network-only"
                })
                .then(res => {
                    
                    this.gqlBinder.datacopy = res.data;
                    this.gqlBinder.data = copy(res.data);
                }).catch((err)=>{
                    this.gqlBinder.error = err;
                }).finally(()=>{
                    this.gqlBinder.loading = false;
                });
        },
        $_mutate() {
            this.gqlBinder.loading = true;
            this.gqlBinder.error = null;
            this.gqlBinder.pending = false;
            let promises = [];
            Object.keys(this.gqlBinder.mutations).forEach(k=>{
                promises.push(
                    this.$apollo.mutate({
                        mutation: gql`
                            mutation($data:[${k}Save]!) {
                                ${k}sSave(data:$data) {id}
                            }
                        `,
                        
                        variables: {
                            data: this.gqlBinder.mutations[k],
                        }
                    })
                )
            });

            Promise.all(promises).then(()=>{
                this.gqlBinder.datacopy = copy(this.gqlBinder.data);
            }).catch((err)=>{
                this.gqlBinder.error = err;
                //this.gqlBinder.data = copy(this.gqlBinder.datacopy);
            }).finally(()=>{
                this.gqlBinder.mutations = {};
                this.$_findMutations(
                    this.gqlBinder.mutations,
                    this.gqlBinder.data,
                    this.gqlBinder.datacopy,
                    this.gqlBinder.model
                );
                this.gqlBinder.loading = false;
                this.$_loadData(); // can be made better
            });
        }
    },
    watch: {
        "gqlBinder.data": {
            handler() {
                
                this.gqlBinder.mutations = {};
                this.$_findMutations(
                    this.gqlBinder.mutations,
                    this.gqlBinder.data,
                    this.gqlBinder.datacopy,
                    this.gqlBinder.model
                );
                
                if(this.gqlBinder.automutate) {
                    this.$_mutate();
                }
            },
            deep: true
        },
        "gqlBinder.model": {
            handler() {
                this.$_loadData();
            },
            deep: true
        },
        "gqlBinder.mutations": {
            handler() {
                this.gqlBinder.pending = Object.keys(this.gqlBinder.mutations).length > 0;
            },
            deep: true
        }
    }
}