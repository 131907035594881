<template>
    <v-card flat fluid :elevation="0">
        <v-toolbar dark dense color="primary" :elevation="0">
            <v-toolbar-title>Box Contents</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon small @click="$emit('box-contents-dialog-close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card flat>
            <v-row>
                <v-col cols="12">
                    <v-progress-linear v-if="loading" color="primary" indeterminate active></v-progress-linear>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                     <v-row dense>
                        <v-card-subtitle class="pa-0 ml-4">Destination:</v-card-subtitle>
                    </v-row>
                    <v-row dense>
                        <v-card-title class="pa-0 ml-4">{{box.Destination.name}}</v-card-title>
                    </v-row>
                    <v-row dense>
                        <v-card-text class="font-italic pa-0 ml-4">
                            {{box.Destination.address1}},<br />
                            <span v-if="box.Destination.address2">{{box.Destination.address2}},<br /></span>
                            {{box.Destination.city}} {{box.Destination.state}}, {{box.Destination.zip}}
                        </v-card-text>
                    </v-row>

                    <v-row dense>
                        <hr />
                        <v-card-text class="pa-0 ml-4 mt-2">
                            Carrier: <strong>{{box.carrier}}</strong>
                        </v-card-text>
                    </v-row>
                </v-col>

                <v-col cols="4">
                    <v-row dense>
                        <v-card-title class="pa-0">Box #{{box_id}}</v-card-title>
                    </v-row>
                    <v-row dense>
                        <v-card-text class="font-italic pa-0">Status: {{box.status}}</v-card-text>
                    </v-row>
                    <v-row dense>
                        <v-card-text class="font-italic pa-0">Date Created: {{new Date(box.created).toLocaleDateString()}}</v-card-text>
                    </v-row>
                    <v-row dense>
                        <v-card-text class="font-italic pa-0">Created By: {{box.User.first_name}} {{box.User.last_name}}</v-card-text>
                    </v-row>
                    <v-card-actions>
                        <PrintPackingSlip v-if="box.label" :label="box.label" />
                        <PrintManifest :box_id="box_id" />
                        <VoidShipment v-if="box.status=='Pending'" :tracking="box.tracking" />
                    </v-card-actions>
                </v-col>

                <v-col cols="4">
                    <v-row dense v-if="box.status=='Saved'">
                        <v-btn color="primary" small class="ma-1" :disabled="boxCount >= capacity" @click="addDialog=true">Add Samples</v-btn>
                        <v-btn color="green lighten-3" small class="ma-1" :disabled="box.carrier==null" @click="shipBox()">Ship Samples</v-btn>
                    </v-row>
                    <v-row v-if="box.status=='Saved'">         
                        <v-col cols="12" class="px-4">
                            <v-progress-linear v-model="full" height="15" reactive rounded :color="fullColor">
                                <template v-slot="{ value }">
                                    <strong>{{ Math.ceil(value) }}% full</strong>
                                </template>
                            </v-progress-linear>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="box.tracking">
                        <v-card-text class="font-italic pa-0">Tracking No: {{box.tracking}}</v-card-text>
                    </v-row>
                    <v-row dense v-if="box.ship_date">
                        <v-card-text class="font-italic pa-0">Ship Date: {{(box.ship_date ? new Date(box.ship_date).toLocaleDateString() : "")}}</v-card-text>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <v-card>
            <crud
                :headers="headers"
                :canAdd="false"
                :canEdit="false"
                :canDelete="box.status=='Saved'"
                :conditions="[{deleted:{is:null}}, {box_id:{eq: box_id}}]"
                model="ShippingBoxContent"
                label="Box Contents"
                ref="contents"
            >
                <template v-slot:[`item.Sample.collected`]="obj">
                    {{new Date(obj.item.Sample.collected.replace(/-/g, '/')).toLocaleString()}}
                </template>
            </crud>
        </v-card>

        <v-dialog v-model="addDialog" v-if="addDialog" persistent max-width="40vw">
            <v-card>
                <v-card-title>
                    <span class="headline">Add Sample</span>
                </v-card-title>

                <v-card-text
                    v-if="!(boxCount < capacity)"
                    class="warning"
                    justify="start"
                    align="center"
                >Cannot add more samples to this box!</v-card-text>

                <v-card-text>
                    <autocomplete
                        v-if="boxCount < capacity"
                        label="Search"
                        v-model="sampleToAdd"
                        model="ShippingSample"
                        item-text="search"
                        :conditions="[{facility_id:{eq:box.Destination.id}}]"
                    ></autocomplete>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" outlined text @click="addDialog=false">Close</v-btn>
                    <v-btn
                        color="blue darken-2"
                        dark
                        :disabled="!(boxCount < capacity)"
                        @click="addItem()"
                    >Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import PrintManifest from "./printManifest.vue";
import PrintPackingSlip from "./printPackingSlip.vue";
import VoidShipment from "./voidShipment.vue";
import crud from "../../components/CRUD.vue";
import autocomplete from "../../components/AutoComplete.vue";
import gql from "graphql-tag";

export default {
    props: {
        box_id: String,
        capacity: {type: Number, default: 5},
    },
    components: {
        crud,
        autocomplete,
        PrintManifest,
        PrintPackingSlip,
        VoidShipment,
    },
    data() {
        return {
            box: {label:null, status:null, carrier:null, tracking: null, prn: null, created: null, ship_date:null, Destination:{name: null, address1:null, address2:null, city:null, state:null, zip:null}, User: {first_name:null, last_name: null}},
            headers:[
                { text: "ID", value: "id", isId: true, visible: false, editable: false },
                { text: "Label", value: "Sample.label", model: "toxSample", modelId:"sample_id", modelFields: {text:"id", value:"name"}},
                { text: "Patient Name", value: "patient", editable: false },
                { text: "Patient DOB", value: "dob", editable: false },
                { text: "Sample Type", value: "LabSample", visible: true, editable: false },
                { text: "Container", value: "LabContainer", visible: true, editable: false },
                { text: "Container #", value: "Sample.num", model: "toxSample", modelId:"sample_id", modelFields: {text:"id", value:"name"}},
                { text: "Collected", value: "Sample.collected", model: "toxSample", modelId:"sample_id", modelFields: {text:"id", value:"name"}, slot: true},  
            ],
            sampleToAdd: 0,
            shipLabel: null,
            boxLabel: null,
            boxCount: null,
            addDialog: false,
            loading: false,
        }
    },
    methods: {
        shipBox() {
            if(this.box_id < 1) return;

            this.$apollo.mutate({
                mutation: gql`mutation($box_id: Int!) {
                    ${this.box.carrier}ShipRequest(box_id: $box_id) {
                        ErrorCode
                        ErrorMessage
                        Status
                        ShippingLabel
                        LabelFileId
                    }
                }`,
                variables: {
                    box_id: this.box_id
                }
            }).then(res=>{
                if(res.data[this.box.carrier+"ShipRequest"].Status == 'Success') {
                    this.shipLabel = res.data[this.box.carrier+"ShipRequest"].ShippingLabel;
                    this.boxLabel = res.data[this.box.carrier+"ShipRequest"].LabelFileId;

                    this.$apollo.mutate({
                        mutation: gql`mutation($data: ShippingBoxInput!, $wh: [[ShippingBoxConditionAND]!]) {
                            ShippingBoxUpdate(data: $data, where: $wh) {
                                status
                            }
                        }`,
                        variables: {
                            data: {status: "Pending"},
                            wh: [{id: {eq: this.box_id}}]
                        }
                    }).then(result => {
                        this.box.status = result.data.ShippingBoxUpdate.status;
                    }).finally(() => {
                        this.printLabel();
                    });
                }
                else alert("Ship request failed! " + res.data[this.box.carrier+"ShipRequest"].ErrorMessage);
            });
        },
        printLabel() {
            let mywindow = window.open('', 'Print', 'height=400,width=800');
            mywindow.document.write('<html><head><title>Print Shipping Label</title>');
            mywindow.document.write('</head><body >');
            mywindow.document.write('<img src="data:image/gif;base64,'+this.shipLabel+'" width="100%" />');
            mywindow.document.write('</body></html>');
            setTimeout(() => {
                mywindow.document.close();
                mywindow.focus();
                mywindow.print();
                mywindow.close();
            }, 1200);
        },
        addItem() {
            if (this.sampleToAdd > 0) {
                this.loading = true;

                this.$apollo.query({
                    query: gql`query toxSample($id: ID!) {
                        toxSample(id: $id) {
                            id
                            tox_id
                            test_type
                        }
                    }`,
                    variables: {
                        id: this.sampleToAdd
                    }
                }).then(res => {
                    let sample = res.data.toxSample;
                    
                    this.$apollo.mutate({
                        mutation: gql`mutation ShippingBoxContentsSave($data: [ShippingBoxContentSave]!) {
                            ShippingBoxContentsSave(data: $data) {
                                id
                            }
                        }`,
                        variables: {
                            data: [{'box_id': this.box_id, 'tox_id': sample.tox_id, 'sample_id': sample.id, 'test_type': sample.test_type}]
                        }
                    }).then(() => {
                        this.$apollo.mutate({
                            mutation: gql`mutation toxSamplesSave($inputdata: [toxSampleSave]!) {
                                toxSamplesSave(data: $inputdata) {
                                    id
                                }
                            }`,
                            variables: {
                                inputdata: [{ 'id': sample.id, 'ship': 1 }]
                            }
                        }).then(() => {
                            this.$refs.contents.$apollo.queries.DataPG.refetch();
                            this.sampleToAdd = 0;
                            this.loading = false;
                        });
                    });
                });
            }
        },
    },
    computed: {
        full: function() {
            return 100 * (this.boxCount / this.capacity);
        },
        fullColor: function() {
            if (this.full == 100) return "red lighten-2";
            else if ((this.full < 100) & (this.full > 60))
                return "orange lighten-2";
            else return "green lighten-2";
        },
    },
    mounted() {
        this.loading = true;
        
        this.$watch("$refs.contents.DataPG.totalRecords", (new_value) => {
            this.boxCount = new_value;
        });

        this.$apollo.query({
            query: gql`query($id: ID!) {
                ShippingBox(id: $id) {
                    status
                    carrier
                    label
                    tracking
                    prn
                    created
                    ship_date
                    Destination {
                        id
                        name
                        address1
                        address2
                        city
                        state
                        zip
                    }
                    User {
                        first_name
                        last_name
                    }
                }
            }`,
            variables: {
                id: this.box_id,
            },
            fetchPolicy: "network-only",
        }).then(res => {
            this.box = res.data.ShippingBox;
        }).finally(()=>{
            this.loading = false;
        });
    },
}
</script>
