<template>
    <v-container>
        <v-card>
            <v-tabs v-model="tab">
                <v-tab>Change Password</v-tab>
                <v-tab>User Signatures</v-tab>
                <v-tab>Lab Panels</v-tab>
                

                <v-tab-item>
                    <pwd :force="false"></pwd>
                </v-tab-item>

                <v-tab-item>
                    <v-container>
                        <v-row>
                            <v-col>
                                <h3>User Signatures</h3>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn outlined color="primary" @click="signatureDialog=true">
                                    <v-icon>mdi-plus</v-icon>Add Signature
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                md="3"
                                lg="2"
                                v-for="(s,k) in signatures"
                                :key="k"
                            >
                                <v-card height="100%">
                                    <v-card-title>
                                        Signature
                                        <v-spacer />
                                        <v-btn icon color="red" @click="delSignature(s.id)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-subtitle
                                        class="green--text"
                                        v-if="k==signatures.length-1"
                                    >In Use</v-card-subtitle>
                                    <v-card-subtitle class="red--text" v-else>Obsolete</v-card-subtitle>
                                    <v-img :src="s.src">
                                        <template v-slot:placeholder>
                                            <v-progress-circular indeterminate class="ma-auto"></v-progress-circular>
                                        </template>
                                    </v-img>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-dialog v-model="signatureDialog" width="600">
                            <SignPad
                                @input="saveSignature"
                                :loading="loadingSignatures"
                                @close="signatureDialog=false"
                            />
                        </v-dialog>
                        <v-progress-linear active indeterminate striped v-if="loadingSignatures"></v-progress-linear>
                    </v-container>
                </v-tab-item>

                <!--<v-tab-item>
                    <v-data-table
                        :headers="panels_headers"
                        :items="remDeleted(panels)"
                        :loading="panels_loading"
                        :options.sync="panels_options"
                        :server-items-length="panels_total_records"
                    >
                        <template v-slot:item.actions="{ item }">
                            <v-icon class="mr-2" @click="editRow(item)">mdi-pencil</v-icon>
                            <v-icon @click="delRow(item)">mdi-delete</v-icon>
                        </template>

                        <template v-slot:item.Map="{ item }">
                            <span v-for="(map, mk) in item.Map" :key="mk">{{map.general_test_name}},</span>
                        </template>

                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-toolbar-title>Tox Order Panels</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-on:click="newItem()"
                                >New Item</v-btn>
                            </v-toolbar>
                        </template>
                    </v-data-table>

                    <v-dialog v-model="panels_dialog" max-width="700px">
                        <v-card>
                            <v-card-title>
                                <span class="headline">Map for {{panels_form.name}}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-form>
                                    <v-text-field label="Panel Name" v-model="panels_form.name"></v-text-field>
                                    <autocomplete
                                        label="Add General Test"
                                        model="GeneralTest"
                                        item-value="id"
                                        item-text="name"
                                        return-object
                                        clearable
                                        v-model="panels_code_to_add"
                                    ></autocomplete>
                                    <v-row
                                        v-for="(map,mk) in remDeleted(panels_form.Map)"
                                        :key="mk"
                                        dense
                                    >
                                        <v-col>
                                            <b>
                                                <li>{{map.general_test_name}}</li>
                                            </b>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn small icon>
                                                <v-icon v-on:click="deleteItem(map)">mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="saveDialog">Save</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-tab-item>-->

                <v-tab-item>
                    <orderCodes></orderCodes>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
//import autocomplete from "../../components/AutoComplete";
import pwd from "../../components/Password";
import SignPad from "../../components/SignPad.vue";
import orderCodes from "./settings/orderCodes.vue";
export default {
    data() {
        return {
            tab: 0,
            panels: [],
            panels_headers: [
                { value: "name", text: "Name" },
                { value: "Map", text: "Map" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            panels_loading: false,
            panels_dialog: false,
            panels_form: { name: null, Map: [] },
            panels_code_to_add: null,
            panels_mode_edit: false,
            panels_options: null,
            panels_type: null,
            panels_total_records: 0,

            signatures: [],
            loadingSignatures: false,
            signatureDialog: false,
        };
    },
    methods: {
        parseVal(val) {
            let res = val?.Parents?.filter((v) => !v._delete).map(
                (v) => v.parent
            );
            return res;
        },
        setVal(val, vals) {
            let v = JSON.parse(JSON.stringify(val));

            if (!(vals.Parents instanceof Array)) {
                this.$set(vals, "Parents", []);
            }
            vals.Parents.forEach((obj, pos) => {
                if (v.indexOf(obj.parent) < 0) {
                    if (obj.id) this.$set(obj, "_delete", true);
                    else vals.Parents.splice(pos, 1);
                } else {
                    if (obj._delete) obj._delete = undefined;
                    v.splice(v.indexOf(obj.parent), 1);
                }
            });
            v.forEach((selected) => {
                if (selected) vals.Parents.push({ parent: selected });
            });
        },
        delSignature(id) {
            if (!id) return;
            this.loadingSignatures = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                            mutation {
                                FileDelete(where: [{ id: { eq: "${id}" } }]) {
                                    id
                                }
                            }
                        `,
                })
                .then((res) => {
                    if (res.data.FileDelete instanceof Array) {
                        res.data.FileDelete.forEach((v) => {
                            this.signatures.splice(
                                this.signatures.findIndex((s) => s.id == v.id),
                                1
                            );
                        });
                    }
                })
                .finally(() => {
                    this.loadingSignatures = false;
                });
        },
        saveSignature(data) {
            if (data) {
                this.loadingSignatures = true;
                fetch(data)
                    .then((res) => res.blob())
                    .then((blob) =>
                        this.uploadFile(
                            blob,
                            "MySignature.png",
                            "signature",
                            "users",
                            this.$store.state.user.payload.id
                        )
                    )
                    .then((res) => {
                        this.signatures.push(res);
                        this.signatureDialog = false;
                    })
                    .catch((error) => alert(error))
                    .finally(() => {
                        this.loadingSignatures = false;
                    });
            }
        },
        async uploadFile(blob, fileName, type, ownerType, ownerID) {
            const formData = new FormData();
            formData.append("file", blob, fileName);
            let res = null;

            res = await this.axios.post(
                `https://lab-synapse.com/gql/storage/?type=${type}&ownerType=${ownerType}&ownerID=${ownerID}`,
                formData
            );

            if (res.data.status == "uploaded") {
                return {
                    id: res.data.fileID,
                    src: `https://lab-synapse.com/gql/storage?id=${res.data.fileID}`,
                };
            } else throw new Error(res.data.error);
        },
        getSignatures() {
            this.loadingSignatures = true;
            this.$apollo
                .query({
                    query: gql`
                        query ($where: [FileConditionAND]) {
                            Files(where: $where, options: { sortBy: id }) {
                                id
                            }
                        }
                    `,
                    fetchPolicy: "network-only",
                    variables: {
                        where: [
                            { type: { eq: "signature" } },
                            { owner_type: { eq: "users" } },
                            {
                                owner_id: {
                                    eq: this.$store.state.user.payload.id,
                                },
                            },
                            { deleted: { is: null } },
                            { status: { eq: "OK" } },
                        ],
                    },
                })
                .then((res) => {
                    if (res.data.Files instanceof Array)
                        this.signatures = res.data.Files.map((v) => {
                            return {
                                ...v,
                                src: `https://lab-synapse.com/gql/storage?id=${v.id}`,
                            };
                        });
                })
                .finally(() => {
                    this.loadingSignatures = false;
                });
        },

        newItem() {
            let item = { name: null, Map: [] };
            this.panels_form = item;
            this.panels_mode_edit = false;
            this.panels_dialog = true;
        },
        remDeleted(arr) {
            if (arr instanceof Array)
                return arr.filter((v) => v["_delete"] != true);
        },
        closeDialog() {
            this.panels_mode_edit = false;
            this.panels_dialog = false;
        },
        saveDialog() {
            this.saveData(this.panels_form, this.panels_mode_edit);
            this.panels_dialog = false;
        },
        deleteItem(item) {
            this.$set(item, "_delete", true);
        },
        editRow(row) {
            this.panels_form = JSON.parse(JSON.stringify(row));
            this.panels_mode_edit = row;
            this.panels_dialog = true;
        },
        delRow(row) {
            this.$set(row, "_delete", true);
            row.Map.forEach((m) => {
                this.$set(m, "_delete", true);
            });
            this.saveData(row);
        },
        getPanels() {
            this.panels_loading = true;
            this.$apollo
                .query({
                    query: gql`

                        query($opts:UserToxPanelOptions)    {
                            UserToxPanelsPG(options:$opts, where: {deleted:{is:null}, user_id: { eq: ${this.$store.state.user.payload.id} }}) {
                                totalRecords
                                data {
                                    id
                                    name
                                    user_id
                                    Map(where: {deleted:{is:null}}) {
                                        id
                                        general_test_id
                                        general_test_name
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        opts: this.panels_options,
                    },
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.UserToxPanelsPG instanceof Object) {
                        this.panels = res.data.UserToxPanelsPG.data;
                        this.panels_total_records =
                            res.data.UserToxPanelsPG.totalRecords;
                        if (
                            res.data.UserToxPanelsPG.data.Map instanceof Array
                        ) {
                            res.data.UserToxPanelsPG.data.Map.forEach();
                        }
                    }

                    //console.log(this.panels);
                })
                .finally(() => {
                    this.panels_loading = false;
                });
        },
        saveData(data /*,item*/) {
            this.panels_loading = true;

            data.Map.forEach((v) => {
                delete v["general_test_name"];
                delete v["__typename"];
            });
            data.user_id = this.$store.state.user.payload.id;
            delete data["__typename"];

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ($data: [UserToxPanelSave]!) {
                            UserToxPanelsSave(data: $data) {
                                id
                            }
                        }
                    `,
                    variables: {
                        data: [data],
                    },
                })
                .then((res) => {
                    //console.log(res, item);
                    if (res.data.UserToxPanelsSave instanceof Array) {
                        //item.GeneralTestMap = res.data.generalTestMapsSave.filter(
                        //v => v.deleted === null
                        //);
                    }
                })
                .finally(() => {
                    this.panels_loading = false;
                    this.getPanels();
                });
        },
    },
    watch: {
        tab(n) {
            if (n == 1) this.getPanels();
        },
        panels_code_to_add(n) {
            if (n) {
                this.panels_form.Map.push({
                    general_test_id: n.id,
                    general_test_name: n.name,
                });

                setTimeout(() => {
                    this.panels_code_to_add = null;
                }, 10);
            }
        },
        panels_options() {
            this.getPanels();
        },
    },
    components: {
        //autocomplete,
        pwd,
        SignPad,
        orderCodes,
    },
    mounted() {
        this.getSignatures();
    },
};
</script>