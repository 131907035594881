var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('GRID',{attrs:{"model":"UserToxPanel","headers":_vm.gh,"conditions":[{deleted:{is:null}}]},on:{"save":_vm.save},scopedSlots:_vm._u([{key:"item.Map",fn:function(ref){
var item = ref.item;
return _vm._l((item['Map']),function(m,k){return _c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!m._delete),expression:"!m._delete"}],key:k,attrs:{"dense":""}},[_c('v-col',[(m.OrderCode)?_c('li',[_c('b',{staticClass:"mr-2"},[_vm._v(_vm._s(m.OrderCode.order_name))]),_c('i',{staticClass:"mr-2"},[_vm._v(_vm._s(m.OrderCode.sample_name))]),_c('i',{staticClass:"mr-2"},[_vm._v(_vm._s(m.OrderCode.container_name))]),_c('i',{staticClass:"mr-2"},[_vm._v(_vm._s(m.OrderCode.group_name))]),(m.OrderCode.Facility)?_c('b',{staticClass:"mr-2"},[_vm._v(_vm._s(m.OrderCode.Facility.name))]):_vm._e()]):_vm._e()])],1)})}},{key:"editForm",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [(item && headers)?_c('v-form',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Panel Name"},model:{value:(item['name']),callback:function ($$v) {_vm.$set(item, 'name', $$v)},expression:"item['name']"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Order Codes")]),_vm._l((item['Map']),function(m,k){return _c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!m._delete),expression:"!m._delete"}],key:k,attrs:{"dense":""}},[_c('v-col',[(m.OrderCode)?_c('li',[_c('b',{staticClass:"mr-2"},[_vm._v(_vm._s(m.OrderCode.order_name))]),_c('i',{staticClass:"mr-2"},[_vm._v(_vm._s(m.OrderCode.sample_name))]),_c('i',{staticClass:"mr-2"},[_vm._v(_vm._s(m.OrderCode.container_name))]),_c('i',{staticClass:"mr-2"},[_vm._v(_vm._s(m.OrderCode.group_name))]),(m.OrderCode.Facility)?_c('b',{staticClass:"mr-2"},[_vm._v(_vm._s(m.OrderCode.Facility.name))]):_vm._e()]):_vm._e()]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.del(item['Map'],k)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('DropDown',{attrs:{"model":"Laboratory","conditions":[{deleted:{is:null}},{role:{eq:'Receiver'}}],"label":"Select Laboratory","clearable":""},model:{value:(_vm.facility),callback:function ($$v) {_vm.facility=$$v},expression:"facility"}})],1),_c('v-col',[_c('Model',{attrs:{"model":"LabOrderCode","conditions":[{deleted:{is:null}},{facility_id:{eq:_vm.facility}}],"src":"id,name,order_name,sample_name,container_name,group_name,Facility{name}"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var loading = ref.loading;
var conditions = ref.conditions;
return [_c('v-autocomplete',{attrs:{"items":data,"item-value":"id","item-text":"name","label":"Add Order Code","loading":loading,"disabled":!_vm.facility,"return-object":""},on:{"update:search-input":function($event){return _vm.search($event,conditions)},"input":function($event){return _vm.addCode($event,item)}},model:{value:(_vm.orderCode),callback:function ($$v) {_vm.orderCode=$$v},expression:"orderCode"}})]}}],null,true)})],1)],1)],2)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }