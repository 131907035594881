<template>
    <v-container v-if="$store.state.user.components.includes(76)">
        <v-card>
            <v-card-text>
                <v-tabs v-model="tab">
                    <v-tab v-if="$store.state.user.components.includes(77)">General Tests</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(78)">Test Methods</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(79)">Test Samples</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(80)">Test Containers</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(85)">Test Map</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(86)">POC Tests</v-tab>

                    <v-tab-item v-if="$store.state.user.components.includes(77)">
                        <crud model="GeneralTest" :headers="gtests_headers" label="General Tests"></crud>
                    </v-tab-item>

                    <v-tab-item v-if="$store.state.user.components.includes(78)">
                        <crud
                            model="GeneralTestMethod"
                            :headers="gmethods_headers"
                            label="Test Methods"
                        ></crud>
                    </v-tab-item>

                    <v-tab-item v-if="$store.state.user.components.includes(79)">
                        <crud
                            model="GeneralTestSample"
                            :headers="gsamples_headers"
                            label="Test Samples"
                        ></crud>
                    </v-tab-item>

                    <v-tab-item v-if="$store.state.user.components.includes(80)">
                        <crud
                            model="GeneralTestContainer"
                            :headers="gtests_containers"
                            label="Test Containers"
                        ></crud>
                    </v-tab-item>

                    <!--<v-tab-item v-if="$store.state.user.components.includes(85)">
                        <v-row>
                            <v-col>
                                <autocomplete
                                    model="Facility"
                                    label="Select Lab"
                                    placeholder="Select First"
                                    :conditions="fconds"
                                    v-model="fmodel"
                                    clearable
                                ></autocomplete>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    label="Search"
                                    placeholder="ALL"
                                    v-model="fSearch"
                                    clearable
                                ></v-text-field>
                            </v-col>
                            <v-col align="center">
                                <v-btn :disabled="!fmodel" @click="addItem()" outlined class="mr-3">
                                    <v-icon>mdi-database-plus</v-icon>New
                                </v-btn>

                                <v-btn
                                    :disabled="!fmapChanged || fLoading"
                                    color="red"
                                    @click="saveMap()"
                                    outlined
                                >
                                    <v-icon>mdi-content-save</v-icon>Save
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(item,ikey) in fmapPaginated"
                                :key="ikey"
                                cols="12"
                                sm="6"
                            >
                                <v-card height="100%">
                                    <v-card-title class="grey lighten-3">
                                        <template v-if="item.edit">
                                            <v-text-field v-model="item.description"></v-text-field>
                                            <v-btn icon @click="$set(item, 'edit', undefined)">
                                                <v-icon>mdi-content-save</v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-else>
                                            {{item.description}}
                                            <v-btn icon @click="$set(item, 'edit', true)">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="delItem(item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row dense>
                                            <v-col cols="5">
                                                <v-row
                                                    dense
                                                    v-for="(gCode, gck) in item.general_codes"
                                                    :key="gCode.id"
                                                >
                                                    <v-col cols="10">
                                                        <li>{{gCode.name}}</li>
                                                    </v-col>
                                                    <v-col align-self="center" cols="2">
                                                        <v-btn
                                                            icon
                                                            small
                                                            v-on:click="item.general_codes.splice(gck,1)"
                                                        >
                                                            <v-icon small>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                            <v-col cols="2" align="center">
                                                <v-divider vertical></v-divider>
                                            </v-col>

                                            <v-col cols="5">
                                                <v-row
                                                    v-for="(lCode,lck) in item.lab_codes"
                                                    :key="lCode.id"
                                                >
                                                    <v-col cols="10">
                                                        <li>{{lCode.name}}</li>
                                                    </v-col>
                                                    <v-col align-self="center" cols="2">
                                                        <v-btn
                                                            icon
                                                            small
                                                            v-on:click="item.lab_codes.splice(lck,1)"
                                                        >
                                                            <v-icon small>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <autocomplete
                                                    label="Add General Code"
                                                    dense
                                                    hide-details="auto"
                                                    model="GeneralTest"
                                                    return-object
                                                    v-model="gCode"
                                                    @input="addGCode(item)"
                                                ></autocomplete>
                                            </v-col>
                                            <v-col>
                                                <autocomplete
                                                    label="Add Lab Code"
                                                    dense
                                                    hide-details="auto"
                                                    model="LabOrderCode"
                                                    return-object
                                                    :conditions="[{ deleted: { is: null } }, { facility_id: { eq:  fmodel} }]"
                                                    v-model="lCode"
                                                    @input="addLCode(item)"
                                                ></autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-pagination :length="fPages" total-visible="10" v-model="fPage"></v-pagination>
                    </v-tab-item>-->
                    <v-tab-item v-if="$store.state.user.components.includes(85)">
                        <v-dialog v-model="mTestDialog" width="800px" persistent>
                            <v-card>
                                <v-card-title class="primary lighten-4 py-1 mb-1">
                                    Edit Orderable Test Item
                                    <v-spacer />
                                    <v-btn icon small @click="mTestDialog=false">
                                        <v-icon>mdi-window-close</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <v-row dense>
                                        <v-col cols="5">
                                            <v-text-field
                                                dense
                                                label="Name"
                                                class="required"
                                                hide-details="auto"
                                                v-model="mItem.name"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                dense
                                                label="Description"
                                                hide-details="auto"
                                                v-model="mItem.description"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                dense
                                                label="Group"
                                                hide-details="auto"
                                                v-model="mItem.group_id"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="5">
                                            <h4>General Tests</h4>
                                            <tree
                                                v-model="mItem.general_codes"
                                                :canAdd="0"
                                                :canEdit="0"
                                                labelField="name"
                                                deleteWithParam="delete"
                                            ></tree>
                                        </v-col>
                                        <v-col>
                                            <h4>Methods, Sample Types, Containers</h4>
                                            <tree
                                                v-model="mItem.general_methods"
                                                :canAdd="2"
                                                :canEdit="0"
                                                labelField="name"
                                                deleteWithParam="delete"
                                            >
                                                <template v-slot:editing="r">
                                                    <dropdown
                                                        :model="mgetModel(r.level)"
                                                        :value="msetValue(r.item)"
                                                        @input="msetItem(r,$event)"
                                                        :conditions="mgetConditions(r)"
                                                        :label="mgetLable(r.level)"
                                                        dense
                                                        return-object
                                                        autofocus
                                                        autoOpen
                                                        refreshable
                                                    ></dropdown>
                                                </template>
                                                <template v-slot:label="r">
                                                    {{r.item.name}}
                                                    <template
                                                        v-if="r.level==1"
                                                    >[{{r.item.code}}]</template>
                                                </template>
                                            </tree>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="5">
                                            <v-row>
                                                <v-col>
                                                    <autocomplete
                                                        dense
                                                        hide-details="auto"
                                                        class="required"
                                                        label="Add General Tests"
                                                        model="GeneralTest"
                                                        v-model="mGeneralTest"
                                                        :conditions="mTestConditions"
                                                        return-object
                                                        no-filter
                                                    ></autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col>
                                            <v-row>
                                                <v-col>
                                                    <dropdown
                                                        dense
                                                        hide-details="auto"
                                                        class="required"
                                                        label="Add Test Methods"
                                                        model="GeneralTestMethod"
                                                        v-model="mGeneralTestMethod"
                                                        :conditions="mTestConditions"
                                                        return-object
                                                        refreshable
                                                    ></dropdown>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        dense
                                                        hide-details="auto"
                                                        label="Order Code"
                                                        v-model="mGeneralTestCode"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-btn small icon @click="addMethod">
                                                        <v-icon>mdi-database-plus</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn
                                        outlined
                                        color="green"
                                        width="110"
                                        @click="saveAndClose()"
                                    >
                                        <v-icon>mdi-content-save</v-icon>Save
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn outlined @click="mTestDialog=false">
                                        <v-icon>mdi-cancel</v-icon>Cancel
                                    </v-btn>
                                    <v-spacer />
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-data-table
                            :items="mMapData"
                            :headers="mMapHeaders"
                            :options.sync="mOptions"
                            :server-items-length="mTotalItemsCount"
                            :loading="mLoading"
                        >
                            <template v-slot:item.general_codes="{item}">
                                <tree
                                    :value="item.general_codes"
                                    :canAdd="0"
                                    :canDelete="0"
                                    :canEdit="0"
                                    labelField="name"
                                ></tree>
                            </template>
                            <template v-slot:item.general_methods="{item}">
                                <tree
                                    :value="item.general_methods"
                                    :canAdd="0"
                                    :canDelete="0"
                                    :canEdit="0"
                                    labelField="name"
                                >
                                    <template v-slot:label="r">
                                        {{r.item.name}}
                                        <template v-if="r.level==1">[{{r.item.code}}]</template>
                                    </template>
                                </tree>
                            </template>
                            <template v-slot:item.actions="{item}">
                                <div style="white-space:nowrap">
                                    <v-btn icon small @click="editMap(item)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon small @click="delMap(item)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                            <template v-slot:top>
                                <v-row dense>
                                    <v-col>
                                        <dropdown
                                            model="Facility"
                                            label="Select Facility"
                                            v-model="mFacility"
                                            hide-details="auto"
                                            clearable
                                        ></dropdown>
                                    </v-col>
                                    <v-col></v-col>
                                    <v-col cols="auto">
                                        <v-btn outlined @click="addMap()" :disabled="!mFacility">
                                            <v-icon>mdi-plus</v-icon>Add Item
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-tab-item>

                    <v-tab-item v-if="$store.state.user.components.includes(86)">
                        <crud model="POC" :headers="poc_headers" label="POC Tests"></crud>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
        <v-dialog :value="fLoading" persistent width="300px" max-width="90vw">
            <v-card>
                <v-card-title class="info white--text">Loading Data ...</v-card-title>
                <v-card-actions>
                    <v-progress-linear indeterminate />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import crud from "../../components/CRUD.vue";
import autocomplete from "../../components/AutoComplete";
import tree from "../../components/Tree.vue";
import gql from "graphql-tag";
import dropdown from "../../components/DropDown.vue";
export default {
    components: {
        crud,
        autocomplete,
        tree,
        dropdown,
    },
    data() {
        return {
            tab: 4,

            fmap: [],
            fmapTemp: [],
            fmodel: null,
            gCode: null,
            lCode: null,
            fconds: [{ deleted: { is: null } }, { role: { eq: "Receiver" } }],
            fSearch: null,
            fLoading: false,
            fPage: 1,
            fItemsPerPage: 10,

            ttitems: [{ id: 1, label: "Test1" }],
            titems: [
                {
                    id: 1,
                    label: "SPCMA",
                    bold: true,
                    children: [
                        {
                            id: 2,
                            label: "Urine",
                            children: [
                                {
                                    id: 5,
                                    label: "Red Container C5",
                                    italic: true,
                                },
                                {
                                    id: 5,
                                    label: "Red Container C8",
                                    italic: true,
                                },
                            ],
                        },

                        {
                            id: 3,
                            label: "Blood",
                            children: [
                                {
                                    id: 5,
                                    label: "Red Container C5",
                                    italic: true,
                                },
                                {
                                    id: 5,
                                    label: "Red Container C8",
                                    italic: true,
                                },
                            ],
                        },
                    ],
                },
            ],

            gtests_headers: [
                {
                    value: "id",
                    text: "ID",
                    visible: false,
                    editable: false,
                    isId: true,
                },
                {
                    value: "name",
                    text: "Name",
                    mandatory: true,
                    searchable: true,
                },
            ],
            gmethods_headers: [
                {
                    value: "id",
                    text: "ID",
                    visible: false,
                    editable: false,
                    isId: true,
                },
                {
                    value: "name",
                    text: "Name",
                    mandatory: true,
                    searchable: true,
                },
            ],
            gsamples_headers: [
                {
                    value: "id",
                    text: "ID",
                    visible: false,
                    editable: false,
                    isId: true,
                },
                {
                    value: "name",
                    text: "Name",
                    mandatory: true,
                    searchable: true,
                },
            ],
            gtests_containers: [
                {
                    value: "id",
                    text: "ID",
                    visible: false,
                    editable: false,
                    isId: true,
                },
                {
                    value: "name",
                    text: "Name",
                    mandatory: true,
                    searchable: true,
                },
                {
                    value: "sample_name",
                    text: "Sample",
                    type: "select",
                    model: "GeneralTestSample",
                    modelId: "sample_id",
                    modelFields: { value: "id", text: "name" },
                    mandatory: true,
                    searchable: true,
                },
                {
                    value: "volume",
                    text: "Volume (ul)",
                    mandatory: true,
                    searchable: true,
                },
            ],
            poc_headers: [
                {
                    value: "id",
                    text: "ID",
                    visible: false,
                    editable: false,
                    isId: true,
                },
                {
                    value: "name",
                    text: "Name",
                    mandatory: true,
                    searchable: true,
                },
                {
                    value: "abbrv",
                    text: "abbreviation",
                    mandatory: true,
                    searchable: true,
                },
            ],

            mGeneralTest: null,
            mGeneralTestCode: null,
            mGeneralTestMethod: null,
            mItem: { general_codes: [], general_methods: [] },
            mTestDialog: false,
            mTestConditions: [{ deleted: { is: null } }],
            mLoading: false,
            mFacility: null,
            mOptions: {},
            mTotalItemsCount: 0,

            mMapData: [],
            mMapHeaders: [
                { value: "name", text: "Name",  },
                { value: "description", text: "Description" },
                { value: "general_codes", text: "General Codes", sortable:false },
                { value: "general_methods", text: "General Methods", sortable:false  },
                { value: "actions", text: "Actions", sortable:false, align:"end" },
            ],
        };
    },
    watch: {
        mOptions() {
            this.getTheMap();
        },
        mFacility(n) {
            if (n) this.getTheMap();
            else this.mMapData = [];
        },
        mGeneralTest(n) {
            if (n) {
                if (!(this.mItem.general_codes instanceof Array))
                    this.mItem.general_codes = [];
                this.mItem.general_codes.push({ ref_id: n.id, name: n.name });
                setTimeout(() => {
                    this.mGeneralTest = null;
                }, 10);
            }
        },
        mTestDialog(n) {
            if (!n) {
                this.mItem = { general_codes: [], general_methods: [] };
                this.mGeneralTestMethod = undefined;
                this.mGeneralTestCode = undefined;
            }
        },
        fmodel(n) {
            if (n) {
                this.fPage = 1;
                this.getMap();
            } else {
                this.fmapTemp = [];
                this.fmap = [];
            }
        },
        fSearch(n) {
            if (n) this.fPage = 1;
        },
        fPages(n) {
            if (this.fPage > n) this.fPage = n;
        },
    },
    computed: {
        fPages() {
            return Math.ceil(this.fmapFiltered.length / this.fItemsPerPage);
        },
        fmapPaginated() {
            return this.fmapFiltered.filter(
                (v, k) =>
                    k >= (this.fPage - 1) * this.fItemsPerPage &&
                    k < this.fPage * this.fItemsPerPage
            );
        },
        fmapFiltered() {
            return this.fmap.filter((row) => {
                let match = !this.fSearch;
                if (this.fSearch) {
                    let search = new RegExp(this.fSearch, "i");
                    if (row.general_codes instanceof Array) {
                        row.general_codes.forEach((v) => {
                            if (v.name.search(search) > -1) match = true;
                        });
                    }
                    if (row.lab_codes instanceof Array) {
                        row.lab_codes.forEach((v) => {
                            if (v.name.search(search) > -1) match = true;
                        });
                    }
                }
                return !row.delete && !row.deleted && match;
            });
        },
        fmapChanged() {
            return JSON.stringify(this.fmap) != JSON.stringify(this.fmapTemp);
        },
    },
    methods: {
        addMethod() {
            if (!(this.mItem.general_methods instanceof Array))
                this.mItem.general_methods = [];
            this.mItem.general_methods.push({
                ref_id: this.mGeneralTestMethod.id,
                name: this.mGeneralTestMethod.name,
                code: this.mGeneralTestCode,
            });

            this.mGeneralTestMethod = null;
            this.mGeneralTestCode = null;
        },
        msetItem(r, e) {
            this.$set(r.item, "ref_id", e.id);
            this.$set(r.item, "name", e.name);
            r.stopEditing(r.item);
        },
        msetValue(i) {
            return i.id ? { id: i.id, name: i.name } : undefined;
        },
        mgetModel(l) {
            if (l == 1) return "GeneralTestMethod";
            if (l == 2) return "GeneralTestSample";
            if (l == 3) return "GeneralTestContainer";
        },
        mgetConditions(r) {
            if (r.level == 1) return [{ deleted: { is: null } }];
            if (r.level == 2) return [{ deleted: { is: null } }];
            if (r.level == 3)
                return [
                    { deleted: { is: null } },
                    r.parent
                        ? { sample_id: { eq: r.parent.ref_id } }
                        : undefined,
                ];
        },
        mgetLable(l) {
            if (l == 1) return "Method";
            if (l == 2) return "Sample";
            if (l == 3) return "Container";
        },
        addItem() {
            this.fmap.push({
                facility_id: this.fmodel,
                description: null,
                general_codes: [],
                lab_codes: [],
            });
        },
        delItem(item) {
            if (item.id) this.$set(item, "delete", true);
            else {
                let pos = this.fmap.indexOf(item);
                if (pos >= 0) this.fmap.splice(pos, 1);
            }
        },
        addGCode(item) {
            if (this.gCode) {
                item.general_codes.push({
                    id: this.gCode.id,
                    name: this.gCode.name,
                });
                setTimeout(() => {
                    this.gCode = null;
                }, 10);
            }
        },
        addLCode(item) {
            if (this.lCode) {
                item.lab_codes.push({
                    id: this.lCode.id,
                    name: this.lCode.name,
                });
                setTimeout(() => {
                    this.lCode = null;
                }, 10);
            }
        },
        saveMap() {
            this.fLoading = true;
            let data = []; //JSON.parse(JSON.stringify(this.fmap));

            this.fmap.forEach((row) => {
                if (!row.id) data.push(row);
                else {
                    let found = this.fmapTemp.filter((v) => v.id == row.id)[0];
                    if (JSON.stringify(found) != JSON.stringify(row))
                        data.push(row);
                }
            });

            data.forEach((row) => {
                row.__typename = undefined;
                row.edit = undefined;
                if (row.facility_id != this.fmodel) return;
                if (row.general_codes instanceof Array) {
                    row.general_codes = row.general_codes.map((v) => v.id);
                }
                if (row.lab_codes instanceof Array) {
                    row.lab_codes = row.lab_codes.map((v) => v.id);
                }
            });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ($data: [generalTestMapSave]!) {
                            generalTestMapSave(data: $data) {
                                id
                                deleted
                            }
                        }
                    `,
                    variables: {
                        data: data,
                    },
                })
                .finally(() => {
                    this.getMap();
                });
        },
        getMap() {
            this.fLoading = true;
            this.$apollo
                .query({
                    query: gql`
                    {
                        generalTestMap(facility_id:${this.fmodel},deleted:false) {
                            id
                            facility_id
                            description
                            general_codes {
                                id
                                name
                            }
                            lab_codes {
                                id
                                name
                            }
                        }
                    }
                `,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.generalTestMap instanceof Array) {
                        this.fmap = JSON.parse(
                            JSON.stringify(res.data.generalTestMap)
                        );
                        this.fmap.forEach((row) => {
                            row.__typename = undefined;
                            if (row.general_codes instanceof Array) {
                                row.general_codes.forEach((v) => {
                                    v.__typename = undefined;
                                });
                            }
                            if (row.lab_codes instanceof Array) {
                                row.lab_codes.forEach((v) => {
                                    v.__typename = undefined;
                                });
                            }
                        });

                        this.fmapTemp = JSON.parse(JSON.stringify(this.fmap));
                    }
                })
                .finally(() => {
                    this.fLoading = false;
                });
        },
        getTheMap() {
            if(this.mFacility == null) return;
            this.mLoading = true;
            //this.mMapData = [];
            
            this.$apollo
                .query({
                    query: gql`
                        query (
                            $facilityId: ID!
                            $options: GeneralTestOrderOptions
                        ) {
                            GeneralTestOrders(
                                facility_id: $facilityId
                                options: $options
                            ) {
                                totalItems
                                data {
                                    id
                                    facility_id
                                    group_id
                                    name
                                    description
                                    general_codes {
                                        id
                                        ref_id
                                        name
                                    }
                                    general_methods {
                                        id
                                        ref_id
                                        name
                                        code
                                        children: samples {
                                            id
                                            ref_id
                                            name
                                            children: containers {
                                                id
                                                ref_id
                                                name
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    fetchPolicy: "network-only",
                    variables: {
                        facilityId: this.mFacility,
                        options: this.mOptions,
                    },
                })
                .then((res) => {
                    this.mMapData = res?.data?.GeneralTestOrders?.data;
                    this.mTotalItemsCount =
                        res?.data?.GeneralTestOrders?.totalItems;
                })
                .finally(() => {
                    this.mLoading = false;
                });
        },
        saveTheMap(item) {
            this.mLoading = true;

            item.__typename = undefined;
            item.facility_id = this.mFacility;

            if (item.general_codes instanceof Array) {
                item.general_codes.forEach((code) => {
                    code.__typename = undefined;
                    code.name = undefined;
                });
            }
            if (item.general_methods instanceof Array) {
                item.general_methods.forEach((method) => {
                    method.__typename = undefined;
                    method.name = undefined;
                    if (method.children instanceof Array) {
                        method.children.forEach((sample) => {
                            sample.__typename = undefined;
                            sample.name = undefined;
                            if (sample.children instanceof Array) {
                                sample.children.forEach((container) => {
                                    container.__typename = undefined;
                                    container.name = undefined;
                                });
                                sample.containers = sample.children;
                            }
                            sample.children = undefined;
                        });
                        method.samples = method.children;
                    }
                    method.children = undefined;
                });
            }

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ($data: [GeneralTestOrderSave]!) {
                            GeneralTestOrderSave(data: $data) {
                                id
                            }
                        }
                    `,
                    variables: {
                        data: [item],
                    },
                })
                .then(() => {
                    this.mLoading = false;
                    this.getTheMap();
                })
                .finally(() => {});
        },
        editMap(i) {
            this.mItem = JSON.parse(JSON.stringify(i));
            if (this.mItem == null)
                this.mItem = { general_codes: [], general_methods: [] };
            this.mTestDialog = true;
        },
        delMap(i) {
            if(!confirm("Your are going to delete an Item! Are you sure?")) return;
            let item = JSON.parse(JSON.stringify(i));
            this.$set(item, "delete", true);
            this.saveTheMap(item);
        },
        addMap() {
            this.mItem = { general_codes: [], general_methods: [] };
            this.mTestDialog = true;
        },
        saveAndClose() {
            this.saveTheMap(this.mItem);
            this.mTestDialog = false;
        },
    },
    mounted() {
        //this.getTheMap();
    },
};
</script>