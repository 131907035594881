
<template>
    <v-img v-bind="$attrs" :src="thisSrc" @click.stop="uploadOnClick ? uploadFile() : null">
        <v-row v-if="error!==false || uploading" no-gutters justify="center" align="stretch" class="fill-height">
            <v-col cols="auto" align-self="center">
                <v-alert v-if="error!==false" type="error" dense>{{error}}</v-alert>
                <v-progress-circular v-if="uploading" color="error"  indeterminate>Uploading...</v-progress-circular>
            </v-col>
        </v-row>
        <slot v-else v-bind="{uploading,error,src:thisSrc,upload:uploadFile}"></slot>
        <template v-slot:placeholder>
            <v-row no-gutters justify="center" align="stretch" class="fill-height">
                <v-col cols="auto" align-self="center">
                    <v-progress-circular color="error" indeterminate>Loading...</v-progress-circular>
                </v-col>
            </v-row>
        </template>
        <input type="file" name="file" style="visibility: hidden;" ref="fileInput" />
    </v-img>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        value: [Number,String],

        ownerId: { type: String, mandatory: true },
        ownerType: { type: String, mandatory: true },
        type: { type: String, mandatory: true },
        accept: { type: String, default: ".gif,.jpg,.jpeg,.bmp,.svg" },
        endPoint: {
            type: String,
            default: "https://lab-synapse.com/gql/storage/"
        },
        placeholder: String,
        uploadOnClick: {type:Boolean, default:true}
    },
    data() {
        return {
            uploading: false,
            error: false,
            thisSrc: undefined
        };
    },
    watch: {
        placeholder: {
            handler(n) {
                if (!this.thisSrc) {
                    this.thisSrc = n;
                }
            },
            immediate: true
        },
        value: {
            handler(n) {
                if (n) {
                    this.thisSrc = this.endPoint + "?id=" + n;
                } else {
                    this.thisSrc = this.placeholder;
                }
            },
            immediate: true
        },
        error(n) {
            if(n!==false) {
                setTimeout(()=>{this.error = false},3000);
            }
        }
    },
    methods: {
        uploadFile() {
            this.$refs.fileInput.value = null;
            this.$refs.fileInput.accept = this.accept;
            this.$refs.fileInput.click();

            this.$refs.fileInput.removeEventListener(
                "change",
                this._uploadFile
            );
            this.$refs.fileInput.addEventListener("change", this._uploadFile);
        },
        _uploadFile(e) {
            const file = e.target.files[0];
            if (file && this.type && this.ownerType && this.ownerId) {
                this.uploading = true;
                this.error = false;

                const formData = new FormData();
                formData.append("file", file, file.filename);
                this.axios
                    .post(
                        this.endPoint +
                            "?type=" +
                            this.type +
                            "&ownerType=" +
                            this.ownerType +
                            "&ownerID=" +
                            this.ownerId,
                        formData
                    )
                    .then(res => {
                        if (res.data.status == "uploaded") {
                            this.thisSrc =
                                "https://lab-synapse.com/gql/storage?id=" +
                                res.data.fileID;
                            this.$emit("input", res.data.fileID);
                        } else this.error = "Uploading failed!";
                    })
                    .catch(res => {
                        this.error = "Uploading failed!";
                        this.debug = res;
                    })
                    .finally(() => {
                        this.uploading = false;
                        e.target.value = null;
                    });
            } else {
                this.error = "Missing Upload Parameter!";
            }
        }
    }
};
</script>