<template>
  <v-container>
    <v-card>
      <v-row justify="center"
        ><v-card-title>Application Details</v-card-title></v-row
      >
      <v-card-text>
        <v-alert
          border="left"
          color="#6201ee"
          colored-border="true"
          flat
          tile
          dense
          class="mb-0"
        >
          <v-row dense>
            <v-col cols="3">
              <v-card-text>
                Organization:<br /><strong>{{ application.name }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text>
                NPI: <br /><strong>{{ application.npi }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text>
                Organization Type:<br /><strong>{{ application.type }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text>
                CLIA:<br /><strong>{{ application.clia }}</strong>
              </v-card-text>
            </v-col>
          </v-row>
        </v-alert>
        <v-divider></v-divider>
        <v-alert
          border="left"
          color="#29c0b1"
          colored-border="true"
          flat
          tile
          dense
          class="mb-0 mt-5"
        >
          <v-row dense>
            <v-col cols="4">
              <v-card-text>
                Address 1: <br />
                <strong>{{ application.address1 }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Address 2: <br />
                <strong>{{ application.address2 }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Taxonomy:<br /><strong>{{ application.taxonomy }}</strong>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-card-text>
                City: <br />
                <strong>{{ application.city }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                State: <br />
                <strong>{{ application.state }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                ZIP: <br />
                <strong>{{ application.zip }}</strong>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-card-text>
                Phone: <br />
                <strong>{{ application.phone }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Fax: <br />
                <strong>{{ application.fax }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Email: <br />
                <strong>{{ application.email }}</strong>
              </v-card-text>
            </v-col>
          </v-row>
        </v-alert>
        <v-divider></v-divider>
        <v-alert
          border="left"
          color="#2c50ee"
          colored-border="true"
          flat
          tile
          dense
          class="mb-0 mt-5"
        >
          <v-row dense>
            <v-col cols="3">
              <v-card-text>
                Authorized First Name: <br />
                <strong>{{ application.authorized_first_name }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text>
                Authorized Middle Name: <br />
                <strong>{{ application.authorized_middle_name }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text>
                Authorized Last Name: <br />
                <strong>{{ application.authorized_last_name }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="3">
              <v-card-text>
                Authorized Position: <br />
                <strong>{{ application.authorized_position }}</strong>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-card-text>
                Authorized Phone: <br />
                <strong>{{ application.authorized_phone }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Authorized Mobile: <br />
                <strong>{{ application.authorized_mobile }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Authorized Email: <br />
                <strong>{{ application.authorized_email }}</strong>
              </v-card-text>
            </v-col>
          </v-row>
        </v-alert>
        <v-divider></v-divider>
        <v-alert border="left"
          color="#4d83ff"
          colored-border="true"
          flat
          tile
          dense
          class="mb-0 mt-5">
          <v-row dense>
            <v-col cols="4">
              <v-card-text>
                Primary Contact Name: <br />
                <strong>{{ application.primary_contact_name }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Primary Contact Phone: <br />
                <strong>{{ application.primary_contact_phone }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Primary Contact Email: <br />
                <strong>{{ application.primary_contact_email }}</strong>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-card-text>
                Critical Contact Name: <br />
                <strong>{{ application.critical_contact_name }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Critical Contact Phone: <br />
                <strong>{{ application.critical_contact_phone }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Critical Contact Email: <br />
                <strong>{{ application.critical_contact_email }}</strong>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-card-text>
                Invoicing Contact Name: <br />
                <strong>{{ application.invoicing_contact_name }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Invoicing Contact Phone: <br />
                <strong>{{ application.invoicing_contact_phone }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Invoicing Contact Email: <br />
                <strong>{{ application.invoicing_contact_email }}</strong>
              </v-card-text>
            </v-col>
          </v-row>
        </v-alert>
        <v-divider></v-divider>
        <v-alert border="left"
          color="#888"
          colored-border="true"
          flat
          tile
          dense
          class="mb-0 mt-5">
           <v-row dense>
            <v-col cols="4">
              <v-card-text>
                Integration: <br />
                <strong>{{
                  application.integration > 0 ? "Yes" : "No"
                }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                System Name: <br />
                <strong>{{ application.system_name }}</strong>
              </v-card-text>
            </v-col>
            <v-col cols="4">
              <v-card-text>
                Date: <br />
                <strong>{{ application.created }}</strong>
              </v-card-text>
            </v-col>
          </v-row>
        </v-alert>
        <v-divider></v-divider>
        <v-row justify="center" align="center" class="mt-5 mb-2">
          <v-btn color="primary">Accept</v-btn>
          <v-btn color="error" class="ml-4">Reject</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import gql from "graphql-tag";

export default {
  data() {
    return {
      application: {},
    };
  },
  mounted() {
    this.$apollo
      .query({
        query: gql`
          query Application($id: ID!) {
            Application(id: $id) {
              id
              type
              name
              npi
              clia
              address1
              address2
              city
              state
              zip
              phone
              fax
              email
              taxonomy
              authorized_first_name
              authorized_last_name
              authorized_middle_name
              authorized_phone
              authorized_mobile
              authorized_email
              authorized_position
              primary_contact_name
              primary_contact_phone
              primary_contact_email
              critical_contact_name
              critical_contact_phone
              critical_contact_email
              invoicing_contact_name
              invoicing_contact_phone
              invoicing_contact_email
              integration
              system_name
              status
              created
            }
          }
        `,
        variables: { id: this.$route.params.id },
      })
      .then((res) => {
        this.application = res.data.Application;
      });
  },
};
</script>
