<template>
    <v-tabs v-if="this.$store.state.user.components.includes(54)">
        <v-tab v-if="this.$store.state.user.components.includes(55)">Test Codes</v-tab>
        <v-tab v-if="this.$store.state.user.components.includes(56)">Test Groups</v-tab>
        <v-tab v-if="this.$store.state.user.components.includes(57)">Containers</v-tab>
        <v-tab v-if="this.$store.state.user.components.includes(58)">General Codes Map</v-tab>
        <v-tab v-if="this.$store.state.user.components.includes(93)">Price Plans</v-tab>

        <!---------------------------- Test Codes ------------------------------------>
        <v-tab-item v-if="this.$store.state.user.components.includes(55)"> 
            <crud
                model="LabOrderCode"
                :headers="headers1"
                :initialValues="initialValues"
                label="Order Codes"
                :conditions="[{deleted:{is:null}}, {facility_id:{eq:facility_id}}]"
            ></crud>
        </v-tab-item>

        <!---------------------------- Test Groups ------------------------------------>
        <v-tab-item v-if="this.$store.state.user.components.includes(56)">
            <crud
                model="LabOrderCodeGroup"
                :headers="headers2"
                :initialValues="initialValues"
                label="Code Groups"
                :conditions="[{deleted:{is:null}}, {facility_id:{eq:facility_id}}]"
            ></crud>
        </v-tab-item>

        <!---------------------------- Containers -------------------------------------->
        <v-tab-item v-if="this.$store.state.user.components.includes(57)">
            <crud
                model="labContainer"
                :headers="headers3"
                :initialValues="initialValues"
                label="Containers"
                :conditions="[{deleted:{is:null}}, {facility_id:{eq:facility_id}}]"
            ></crud>
        </v-tab-item>

        <!---------------------------- General Codes Map ----------------------------------->
        <v-tab-item v-if="this.$store.state.user.components.includes(58)">
            <v-data-table
                :headers="test_map_headers"
                :items="test_map"
                :loading="test_map_loading"
                :options.sync="test_map_options"
                :server-items-length="test_map_total_records"
            >
                <template v-slot:item.actions="{ item }">
                    <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                    <!--<v-icon @click="deleteItem(item)">mdi-delete</v-icon>-->
                </template>
                <template v-if="test_map_type" v-slot:item.GeneralTestMap="{ item }">
                    <span
                        v-for="(map, mk) in item.GeneralTestMap"
                        :key="mk"
                    >{{map.general_test_name}},  </span>
                </template>
                <template v-else v-slot:item.GeneralTestMap="{ item }">
                    <span
                        v-for="(map, mk) in item.GeneralTestMap"
                        :key="mk"
                    >{{map.lab_order_code_name}},  </span>
                </template>
                <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-toolbar-title>Codes Map</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-switch
                            :label="test_map_type?'Order Codes':'General Codes'"
                            v-model="test_map_type"
                        ></v-switch>
                        <!--<v-btn color="primary" dark class="mb-2" v-on:click="newItem()">New Item</v-btn>-->
                    </v-toolbar>
                </template>
            </v-data-table>
            <v-dialog v-model="test_map_dialog" max-width="700px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Map for {{test_map_form.name}}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-form>
                            <!--<v-text-field label="General Code" :disabled="true"  v-model="test_map_form.name"></v-text-field>-->
                            <autocomplete
                                v-show="test_map_type"
                                label="Add General Code"
                                model="GeneralTest"
                                item-value="id"
                                item-text="name"
                                return-object
                                clearable
                                v-model="test_map_gcode_to_add"
                                :conditions="[{deleted:{is:null}}]"
                            ></autocomplete>
                            <autocomplete
                                v-show="!test_map_type"
                                label="Add Order Code"
                                model="LabOrderCode"
                                item-value="id"
                                item-text="order_name"
                                return-object   
                                clearable           
                                v-model="test_map_ocode_to_add"
                                :conditions="[{facility_id:{eq:this.facility_id}},{deleted:{is:null}}]"
                            ></autocomplete>
                            <v-row
                                v-for="(map,mk) in remDeleted(test_map_form.GeneralTestMap)"
                                :key="mk"
                                dense
                            >
                                <v-col>
                                    <b>
                                        <li v-if="test_map_type">{{map.general_test_name}}</li>
                                        <li v-else>{{map.lab_order_code_name}}</li>
                                    </b>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn small icon>
                                        <v-icon v-on:click="delMap(map)">mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="saveDialog">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-tab-item>

        <!------------------------ Order Codes Price Plans ----------------------------->
        <v-tab-item v-if="this.$store.state.user.components.includes(93)">
            <crud
                label="Test Codes Price Plans"
                :headers="headers4"
                model="LabPricePlan"
                :canEdit="false"
                :conditions="[{deleted:{is:null}}, {facility_id:{eq:facility_id}}]"
                :initialValues="{facility_id: facility_id}"
                show-expand
                single-expand 
                item-expanded="loadDetails"
            >
                <template v-slot:expanded-item="{ item }">
                    <crud 
                        :headers="headersDetails"
                        :label="item.name + ' Plan Prices'"
                        :canSearch="true"
                        :canAdd="false"
                        :canEdit="true"
                        :canDelete="false"
                        model="LabOrderCodePrice"
                        :conditions="[{plan_id:{eq:item.id}},{facility_id:{eq:item.facility_id}}]"
                        :multi-sort="true"
                    >
                        <template v-slot:[`item.price`] = "obj">
                            {{parseFloat((obj.item.cost || 0.00) + (obj.item.markup || 0.00)).toFixed(2)}}
                        </template>
                    </crud>
                </template>
                <template v-slot:item.clients="obj">
                    <v-btn small color="primary" @click="showClients(obj.item)">Assign Clients</v-btn>
                </template>
            </crud>

            <v-dialog v-model="assignClientsDialog" max-width="40vw" persistent>
                <v-card>
                    <v-toolbar dense :elevation="0" color="primary" dark>
                        <v-toolbar-title>Assign Clients</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon small @click="closeClientsDialog()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-progress-linear indeterminate v-show="pricePlanLoading"></v-progress-linear>
                        <crud
                            :headers="headersClients"
                            :label="'Assign clients to '+pricePlan+' plan'"
                            model="Facility"
                            :conditions="[{status:{eq:'Active'}},{deleted:{is:null}},{role:{eq:'Sender'}}]"
                            :canAdd="false"
                            :canEdit="false"
                            :canDelete="false"
                            show-select
                            v-model="selectedClients"
                        >
                            <template v-slot:footer.prepend>
                                <v-btn small @click="saveASssigned">Assign Selected</v-btn>
                            </template>
                            <template v-slot:item.assigned="obj">
                                <v-tooltip bottom  v-if="clientAssigned(obj.item.id)">
                                    <template v-slot:activator="{ on }">
                                        <v-icon color="primary" v-on="on">mdi-checkbox-marked-circle</v-icon>
                                    </template>
                                    <span>Assigned</span>
                                </v-tooltip>
                                <v-tooltip bottom v-else-if="clientAssignedOther(obj.item.id)">
                                    <template v-slot:activator="{ on }">
                                        <v-icon color="red" v-on="on">mdi-cancel</v-icon>
                                    </template>
                                    <span>Assigned to other plan</span>
                                </v-tooltip>
                                <v-tooltip bottom v-else>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">mdi-close-circle</v-icon>
                                    </template>
                                    <span>Not assigned</span>
                                </v-tooltip>
                            </template>
                        </crud>
                        <v-row class="ma-2">
                            <v-btn small color="primary" @click="assign()" :disabled="selectedClients.length==0">Assign Selected</v-btn>
                            <v-btn small class="ml-4" @click="remove()" :disabled="selectedClients.length==0">Remove Selected</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-tab-item>
    </v-tabs>
</template>

<script>
import crud from "../../../components/CRUD";
import gql from "graphql-tag";
import autocomplete from "../../../components/AutoComplete";
export default {
    props: ["facility_id"],
    data() {
        return {
            headers1: [
                {
                    text: "ID",
                    value: "id",
                    isId: true,
                    visible: false,
                    editable: false
                },
                {
                    text: "Facility",
                    value: "facility_id",
                    visible: false,
                    editable: false
                },
                {
                    text: "Type",
                    value: "test_name",
                    type: "autocomplete",
                    model: "labTest",
                    modelId: "test_id",
                    modelFields: { value: "id", text: "name" },
                    mandatory: true
                },
                {
                    text: "Sample",
                    value: "sample_name",
                    type: "autocomplete",
                    model: "labSample",
                    modelId: "sample_id",
                    modelFields: { value: "id", text: "name" },
                    mandatory: true
                },
                {
                    text: "Container",
                    value: "container_name",
                    type: "select",
                    model: "labContainer",
                    modelId: "container_id",
                    modelFields: { value: "id", text: "name" },
                    mandatory: true,
                    conditions: [{facility_id:{eq:this.facility_id}}]
                },
                {
                    text: "Group",
                    value: "group_name",
                    type: "select",
                    model: "LabOrderCodeGroup",
                    modelId: "group_id",
                    modelFields: { value: "id", text: "name" },
                    mandatory: true,
                    conditions: [{facility_id:{eq:this.facility_id}}]
                },
                {
                    text: "Code",
                    value: "order_code",
                    searchable: true,
                    mandatory: true
                },
                {
                    text: "Abrv",
                    value: "order_abrv",
                    searchable: true,
                    mandatory: true
                },
                {
                    text: "Name",
                    value: "order_name",
                    searchable: true,
                    mandatory: true
                },
                { text: "Volume", value: "required_volume", mandatory: true },
                {text: "Cost", value: "cost", mandatory: false}
            ],
            headers2: [
                {
                    text: "ID",
                    value: "id",
                    isId: true,
                    visible: false,
                    editable: false,
                },
                {
                    text: "Facility",
                    value: "facility_id",
                    visible: false,
                    editable: false,
                },
                { text: "Name", value: "name", searchable: true, cols:6, },
                {
                    text: "Parent",
                    value: "parent_name",
                    type: "select",
                    model: "LabOrderCodeGroup",
                    modelId: "parent_id",
                    modelFields: { value: "id", text: "name" },
                    conditions:[{facility_id:{eq:this.facility_id}}],
                    cols:6,
                }
            ],
            headers3: [
                {
                    text: "ID",
                    value: "id",
                    isId: true,
                    visible: false,
                    editable: false
                },
                {
                    text: "Facility",
                    value: "facility_id",
                    visible: false,
                    editable: false
                },
                {
                    text: "Container Name",
                    value: "name",
                    searchable: true,
                    mandatory: true,
                    cols:3,
                },
                {
                    text: "Order Prefix",
                    value: "prefix",
                    searchable: true,
                    cols:3,
                },
                
                {
                    text: "Volume (ul)",
                    value: "volume",
                    searchable: true,
                    mandatory: true,
                    cols:3,
                },
                {
                    text: "Sample Type",
                    value: "sample_name",
                    type: "select",
                    model: "labSample",
                    modelId: "sample_id",
                    modelFields: { value: "id", text: "name" },
                    mandatory: true,
                    cols:3,
                }
            ],
            headers4: [
                {text: "ID", value: "id", isId: true, visible: false, editable: false,},
                {text: "Facility", value: "facility_id", visible: false, editable: false,},
                {text: "Plan Name", value: "name", mandatory: true},
                {text: "Assign Clients", value: "clients", query: false, slot: true}
            ],
            headersDetails: [
                {text: "ID", value: "id", isId: true, visible: false, editable: false,},
                {text: "Test Code", value: "LabOrderCode.name", mode:"LabOrderCode", modelId: "order_code_id",  modelFields: {value:"id", text:"name"}, type: "select", fieldOptions: {'disabled':'disabled'}},
                {text: "Cost, $", value: "cost", editable: false,},
                {text: "Markup, $", value: "markup", mandatory: true,},
                {text: "Price, $", value: "price", editable: false, query: false, slot: true},
            ],
            headersClients: [
                {text: "ID", value: "id", isId: true, visible: false, editable: false,},
                {text: "Name", value: "name"},
                {text: "Assigned", value: "assigned", query: false, slot: true,}
            ],
            assignClientsDialog: false,
            selectedClients: [],
            alreadyAssinged: [],
            assignedToOtherPlan: [],
            pricePlan: null,
            pricePlanId: 0,
            pricePlanLoading: false,
            initialValues: {
                facility_id: this.facility_id
            },

            test_map: [],
            test_map_headers: [
                { value: "name", text: "Code" },
                { value: "GeneralTestMap", text: "Map" },
                { text: "Actions", value: "actions", sortable: false }
            ],
            test_map_loading: false,
            test_map_dialog: false,
            test_map_form: { name: null, map: [] },
            test_map_gcode_to_add: null,
            test_map_ocode_to_add: null,
            test_map_mode_edit: false,
            test_map_options: null,
            test_map_type: null,
            test_map_total_records:0,
        };
    },
    watch: {
        test_map_gcode_to_add(n) {
            if (n) {
                this.test_map_form.GeneralTestMap.push({
                    general_test_id: n.id,
                    general_test_name: n.name,
                    facility_id: this.facility_id,
                    lab_order_code_id: this.test_map_form.id
                });
                setTimeout(()=>{this.test_map_gcode_to_add = null},10);
                
            }
        },
        test_map_ocode_to_add(n) {
            if (n) {
                this.test_map_form.GeneralTestMap.push({
                    lab_order_code_id: n.id,
                    lab_order_code_name: n.order_name,
                    facility_id: this.facility_id,
                    general_test_id: this.test_map_form.id
                });

                setTimeout(()=>{this.test_map_ocode_to_add = null},10);
               
            }
        },
        test_map_options(n) {
            if (n) {
                if (this.test_map_type) {
                    this.getOCodeData();
                } else {
                    this.getGCodeData();
                }
            }
        },
        test_map_type(n) {
            if (n) {
                this.getOCodeData();
            } else {
                this.getGCodeData();
            }
            this.test_map_options.page=1;
        }
    },

    methods: {
        newItem() {
            let item = { name: null, GeneralTestMap: [] };
            this.test_map_form = item;
            this.test_map_mode_edit = false;
            this.test_map_dialog = true;
        },
        editItem(item) {
            this.test_map_form = JSON.parse(JSON.stringify(item));
            this.test_map_mode_edit = item;
            this.test_map_dialog = true;
        },
        deleteItem(item) {
            this.$set(item, "_delete", true);
        },
        closeDialog() {
            this.test_map_mode_edit = false;
            this.test_map_dialog = false;
        },
        saveDialog() {
            if (this.test_map_mode_edit) {
                this.saveData(this.test_map_form, this.test_map_mode_edit);

                //this.test_map_mode_edit.name = this.test_map_form.name;
                //this.test_map_mode_edit.GeneralTestMap = this.test_map_form.GeneralTestMap;
            } else this.test_map.push(this.test_map_form);
            this.test_map_dialog = false;
        },
        delMap(i) {
            this.$set(i, "_delete", true);
        },
        remDeleted(arr) {
            if (arr instanceof Array)
                return arr.filter(v => v["_delete"] != true);
        },
        getOCodeData() {
            this.test_map_loading = true;
            this.$apollo
                .query({
                    query: gql`
                    query($opts: LabOrderCodeOptions) {
                        LabOrderCodesPG(options:$opts, where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                            totalRecords
                            data {
                                id
                                name: order_name
                                GeneralTestMap(where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                                    id
                                    facility_id
                                    general_test_id
                                    general_test_name
                                    lab_order_code_id
                                    lab_order_code_name

                                }
                            }
                        }
                    }
                `,
                    variables: {
                        opts: this.test_map_options
                    },
                    fetchPolicy: "network-only"
                })
                .then(res => {
                    if (res.data.LabOrderCodesPG.data instanceof Array) {
                        this.test_map = res.data.LabOrderCodesPG.data;
                    }
                    this.test_map_total_records = res.data.LabOrderCodesPG.totalRecords;
                })
                .finally(() => {
                    this.test_map_loading = false;
                });
        },
        getGCodeData() {
            this.test_map_loading = true;
            this.$apollo
                .query({
                    query: gql`
                    query($opts: GeneralTestOptions) {
                        GeneralTestsPG(options:$opts, where:[{deleted:{is:null}}]) {
                            totalRecords
                            data {
                                id
                                name
                                GeneralTestMap(where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                                    id
                                    facility_id
                                    general_test_id
                                    general_test_name
                                    lab_order_code_id
                                    lab_order_code_name

                                }
                            }
                        }
                    }
                `,
                    variables: {
                        opts: this.test_map_options
                    },
                    fetchPolicy: "network-only"
                })
                .then(res => {
                    if (res.data.GeneralTestsPG.data instanceof Array) {
                        this.test_map = res.data.GeneralTestsPG.data;
                    }
                    this.test_map_total_records = res.data.GeneralTestsPG.totalRecords;
                })
                .finally(() => {
                    this.test_map_loading = false;
                });
        },
        saveData(data, item) {
            if (!(data.GeneralTestMap instanceof Array)) return;

            this.test_map_loading = true;
            data.GeneralTestMap.forEach(v => {
                delete v["lab_order_code_name"];
                delete v["general_test_name"];
                delete v["__typename"];
            });
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($data: [generalTestMapSave]!) {
                            generalTestMapsSave(data: $data) {
                                id
                                facility_id
                                general_test_id
                                general_test_name
                                lab_order_code_id
                                lab_order_code_name
                                deleted
                            }
                        }
                    `,
                    variables: {
                        data: data.GeneralTestMap
                    }
                })
                .then(res => {
                    if (res.data.generalTestMapsSave instanceof Array) {
                        item.GeneralTestMap = res.data.generalTestMapsSave.filter(
                            v => v.deleted === null
                        );
                    }
                })
                .finally(() => {
                    this.test_map_loading = false;
                });
        },
        async showClients(obj) {
            let app = this;
            app.pricePlan = obj.name;
            app.pricePlanId = obj.id;

            app.$apollo.query({
                query: gql`query FacilityPricePlanMaps($wh: [FacilityPricePlanMapConditionAND]) {
                    FacilityPricePlanMaps(where: $wh) {
                        client_facility_id
                        lab_facility_id
                        plan_id
                    }
                }`,
                fetchPolicy: "network-only",
                variables: {
                    wh: [{deleted:{is:null}},{lab_facility_id:{eq:obj.facility_id}}]
                }
            }).then(res => {
                res.data.FacilityPricePlanMaps.forEach(function(el) {
                    if(el.plan_id == app.pricePlanId) app.alreadyAssinged.push(el.client_facility_id);
                    else app.assignedToOtherPlan.push(el.client_facility_id);
                });

                app.assignClientsDialog = true;
            })
        },
        closeClientsDialog() {
            this.assignClientsDialog = false;
            this.alreadyAssinged = [];
            this.assignedToOtherPlan = [];
            this.selectedClients = [];
        },
        clientAssigned(id) {
            if(this.alreadyAssinged.indexOf(id) != -1) return true;
            return false;
        },
        clientAssignedOther(id) {
            if(this.assignedToOtherPlan.indexOf(id) != -1) return true;
            return false;
        },
        assign() {
            let data = [];
            let app = this;
            this.selectedClients.forEach(function(el){
                if(app.assignedToOtherPlan.indexOf(el.id) == -1) data.push({client_facility_id: el.id, lab_facility_id: app.facility_id, plan_id: app.pricePlanId});
            });
            if(data.length > 0) {
                this.pricePlanLoading = true;

                this.$apollo.mutate({
                    mutation: gql`mutation FacilityPricePlanMapsSave($data: [FacilityPricePlanMapSave]!) {
                        FacilityPricePlanMapsSave(data: $data) {
                            client_facility_id
                            lab_facility_id
                            plan_id
                        }
                    }`,
                    variables: {
                        data: data,
                    }
                }).then(res => {
                    res.data.FacilityPricePlanMapsSave.forEach(obj => {
                        app.alreadyAssinged.push(obj.client_facility_id);
                    });
                    app.pricePlanLoading = false;
                })
            }
        },
        remove() {
            let app = this;
            let promises = [];

            this.pricePlanLoading = true;

            this.selectedClients.forEach(function(el) {
                promises.push(
                    /* Remove facility from plan */
                    app.$apollo.mutate({
                        mutation: gql`mutation FacilityPricePlanMapDelete($wh: [FacilityPricePlanMapConditionAND]!) {
                            FacilityPricePlanMapDelete(where: $wh) {
                                client_facility_id
                            }
                        }`,
                        variables: {
                            wh: [{plan_id: {eq: app.pricePlanId}}, {client_facility_id: {eq:el.id}}, {lab_facility_id: {eq: app.facility_id}}]
                        }
                    }).then(() => {
                        app.alreadyAssinged = app.alreadyAssinged.filter(v=> {
                            return (v != el.id);
                        });
                        /* Remove facility order codes prices */
                        app.$apollo.mutate({
                            mutation: gql`mutation ClientOrderCodePriceDelete($wh: [ClientOrderCodePriceConditionAND]!) {
                                ClientOrderCodePriceDelete(where: $wh) {
                                }
                            }`,
                            variables: {
                                wh: [{plan_id: {eq:app.pricePlanId}}, {client_facility_id:{eq:el.id}}, {lab_facility_id: {eq: app.facility_id}}]
                            }
                        })
                    })
                );
            });

            Promise.all(promises).then(() => { app.pricePlanLoading = false;});
        }
    },
    mounted() {
        //this.getData();
    },
    components: {
        crud,
        autocomplete
    }
};
</script>