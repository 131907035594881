<template>
    <v-container fluid v-if="this.$store.state.user.components.includes(35)">
        <v-card color="primary lighten-1" dark tile>
            <v-row>
                <v-col>
                    <v-row justify="center" align="center">
                        <v-icon class="step" color="orange lighten-3" x-large>mdi-package-variant</v-icon>
                    </v-row>
                    <v-row justify="center" align="center" dense class="ma-2">
                        <span>1. Create a new shipping box and add samples in it</span>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row justify="center" align="center">
                        <v-icon class="step" color="primary lighten-4" x-large>mdi-printer</v-icon>
                    </v-row>
                    <v-row justify="center" align="center" dense class="ma-2">
                        <span>2. Print shipping label and manifest</span>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row justify="center" align="center">
                        <v-icon class="step" color="pink lighten-3" x-large>mdi-file-multiple</v-icon>
                    </v-row>
                    <v-row justify="center" align="center" dense class="ma-2">
                        <span>3. Label goes on the box, and manifest goes inside the box</span>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row justify="center" align="center">
                        <v-icon class="step" color="green lighten-3" x-large>mdi-truck</v-icon>
                    </v-row>
                    <v-row justify="center" align="center" dense class="ma-2">
                        <span>4. Schedule a pickup or drop off box at nearest carrier location</span>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <v-tabs dark grow>
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-if="this.$store.state.user.components.includes(36)">Create a New Shipment</v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(37)">Manage Shipments</v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(94)">Pickup Requests</v-tab>

            <v-tab-item v-if="this.$store.state.user.components.includes(36)">
                <v-toolbar flat dense>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-text-field
                            v-model="searchText"
                            append-icon="mdi-magnify"
                            hide-details
                            single-line
                            label="Search"
                        ></v-text-field>
                </v-toolbar>
                <v-card
                    tile
                    fluid
                    v-for="(lab, index) in laboratories"
                    :key="index"
                    max-height="400"
                    elevation="4"
                    :loading="$apollo.queries.samples.loading!=0"
                >
                    <v-toolbar dark dense color="blue lighten-1" elevation="1">
                        <v-toolbar-title>{{lab.name}}</v-toolbar-title>
                    </v-toolbar>
                    <v-slide-group show-arrows class="pa-4">
                        <v-slide-item
                            v-for="(slide,k) in boxesPerLab(lab.id)"
                            :key="k"
                            v-slot:default="{}"
                            class="ma-2"
                        >
                            <ShipBox
                                @recalc-boxes="recalcBoxes()"
                                :contents="slide"
                                :destination="lab.id"
                                :carrier="carrier?carrier:'UPS'"
                            />
                        </v-slide-item>
                    </v-slide-group>
                </v-card>
                <v-card v-if="laboratories.length==0">
                    <v-alert color="warning" light>No boxes are currently available for shipping!</v-alert>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="this.$store.state.user.components.includes(37)">
                <v-card flat>
                    <crud
                        model="ShippingBox"
                        :headers="boxes_headers"
                        :canAdd="false"
                        :canEdit="false"
                        :canDelete="$store.state.user.components.includes(41)"
                        label="Shipments Management"
                        :conditions="[{deleted:{is:null}}]"
                    >
                        <template v-slot:item.btns="obj">
                            <PrintPackingSlip v-if="$store.state.user.components.includes(38) && obj.item.label" :label="obj.item.label" />
                            <PrintManifest v-if="$store.state.user.components.includes(39)" :box_id="obj.item.id" />
                        </template>
                        <template v-slot:item.void="obj">
                            <VoidShipment v-if="$store.state.user.components.includes(40) && obj.item.status=='Ready'" :tracking="obj.item.tracking" :carrier="obj.item.carrier" />
                        </template>
                        <template v-slot:item.contents="obj">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn small icon @click="showContents(obj.item.id)"><v-icon v-on="on" color="primary">mdi-details</v-icon></v-btn>
                                </template>
                                <span>View Contents</span>
                            </v-tooltip>
                        </template>
                    </crud>

                    <v-dialog v-model="contentsDialog" max-width="75vw" :elevation="0">
                        <BoxContents v-if="contentsDialog" :box_id="showBox" :capacity="boxCapacity" @box-contents-dialog-close="contentsDialog=false" />
                    </v-dialog>
                </v-card>
            </v-tab-item>

            <v-tab-item v-if="this.$store.state.user.components.includes(94)">
                <ShippingPickup />
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>
<style scoped>
</style>

<script>
import crud from "../components/CRUD.vue";
import ShipBox from "../components/ShipBox.vue";
import BoxContents from "./shipping/boxContents.vue";
import PrintManifest from "./shipping/printManifest.vue";
import PrintPackingSlip from "./shipping/printPackingSlip.vue";
import VoidShipment from "./shipping/voidShipment.vue";
import ShippingPickup from "./shipping/PickUp.vue";
import gql from "graphql-tag";

export default {
    components: {
        crud,
        ShipBox,
        PrintManifest,
        PrintPackingSlip,
        VoidShipment,
        BoxContents,
        ShippingPickup,
    },
    data() {
        return {
            boxCapacity: 5,
            samples: [],
            carrier: "UPS",
            boxes_headers: [],
            searchText:"",
            contentsDialog: false,
            showBox: 0,
        };
    },
    created() {
        this.boxes_headers = [
            { text: "Box #", value: "id", editable: false, isId: true, searchable: true, },
            { text: "Sender", value: "Origin.name", model: "Facility", modelId: "origin_id", modelFields: { value: "id", text: "name" }, searchable: true, editable: false, },
            { text: "Destination", value: "Destination.name", model: "Facility", modelId: "destination_id", modelFields: { value: "id", text: "name" }, searchable: true, editable: false, },
            { text: "Carrier", value: "carrier", editable: false },
            { text: "Label", value: "label", visible: false, editable: false },
            { text: "Status", value: "status", searchable: true },
            { text: "Tracking #", value: "tracking", searchable: true },
            { text: "Date", value: "created", type: "date" },
            { text: "Contents", value: "contents", query: false, slot: true, sortable: false,},
        ];

        if (this.$store.state.user.components.includes(38) || this.$store.state.user.components.includes(39)) {
            this.boxes_headers.push({text: "Print", value: "btns", query: false, slot: true, sortable: false,});
        }
        if (this.$store.state.user.components.includes(40)) {
            this.boxes_headers.push({text: "Void", value: "void", query: false, slot: true, sortable: false,});
        }
    },
    computed: {
        laboratories: function () {
            if (this.samples.length > 0) {
                return [
                    ...new Set(
                        this.samples.map((sample) => {
                            return JSON.stringify(sample.Destination);
                        })
                    ),
                ].map((item) => {
                    return JSON.parse(item);
                });
            } else return [];
        },
    },
    mounted() {},
    apollo: {
        samples: {
            query() {
                return gql`
                    query(
                        $wh: [ShippingSampleConditionAND]
                        $opt: ShippingSampleOptions
                    ) {
                        ShippingSamples(where: $wh, options: $opt) {
                            id
                            facility_id
                            tox_id
                            container_id
                            test_type
                            label
                            num
                            collected
                            status
                            Destination {
                                id
                                name
                                address1
                                address2
                                city
                                state
                                zip
                                status
                            }
                        }
                    }
                `;
            },
            variables() {
                return {
                    wh: [],
                    opt: {
                        sortBy: ["collected", "facility_id"],
                        sortDesc: [true, false],
                    },
                };
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data["ShippingSamples"];
            },
        },
    },
    methods: {
        recalcBoxes() {
            this.$apollo.queries.samples.refetch();
        },
        boxesPerLab(facility_id) {
            let app = this;
            let all = this.samples.filter(function (obj) {
                return ((obj.facility_id == facility_id) && (app.searchText == "" || (obj.label.indexOf(app.searchText) != -1)));
            });
            let chunks = [];
            let i = 0;
            let n = all.length;
            while (i < n) {
                chunks.push(all.slice(i, (i += this.boxCapacity)));
            }
            
            return chunks;
        },
        showContents(id) {
            this.contentsDialog = true;
            this.showBox = id;
        }
    },
};
</script>
<style scoped>
</style>