<template>
    <v-card flat tile>
        <crud label="Reminders" model="Reminder" :headers="reminders" :conditions="[{patient_id:{eq:pid}}, {deleted:{is:null}}]" :initialValues="{patient_id:pid, user_id:$store.state.user.payload.id}"></crud>
    </v-card>
</template>

<script>
import crud from "../../components/CRUD.vue"
export default {
    components: {
        crud,
    },
    data() {
        return {
            reminders: [
                {value: "id", editable: false, isId: true, visible:false},
                {value: "patient_id", editable: false, visible: false},
                {value: "user_id", editable: false, visible: false},
                {text: "Text", searchable: true, value: "text", mandatory:true},
                {text: "Popup", type: "radio", value: "popup", items: [{text:"Yes", value:"1"}, {text:"No", value:"0"}]},
            ],
            pid: 0,
        }
    },
    methods: {
    },
    mounted() {
        this.pid = this.$route.params.id;
    }
}
</script>