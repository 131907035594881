<template>
    <v-card v-if="this.$store.state.user.components.includes(70)">
        <crud label="Custom Fields" model="ToxCustomField" :headers="headers" :conditions="[{facility_id:{eq:facility_id}}, {deleted:{is:null}}]" :initialValues="{facility_id: facility_id}"></crud>
    </v-card>
</template>

<script>
import crud from "../../../components/CRUD";

export default {
    props: ["facility_id"],
    components: {
        crud
    },
    data() {
        return {
            headers: [
                {text: "ID", value: "id", isId: true, visible: false, editable: false},
                {text: "Test Type", value: "test_type", type: "select", model: "labTest", modelId: "test_type", modelFields:{value: "id", text: "name"}, mandatory: true},
                {text: "Field Title", value: "field_title", mandatory: true},
                {text: "Field Type", value: "field_type", type: "select", items:["text", "textarea", "DateTimePicker", "radio", "checkbox"], mandatory: true},
                {text: "Field Values", value: "value_list",},
                {text: "Rquired", value: "mandatory", type:"switch"},
                {text: "Result Code", value: "result_code"},
                {text: "Send", value: "send", type: "switch"},
            ]
        }
    }
}
</script>