<template>
    <v-container v-if="this.$store.state.user.components.includes(52)">
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-card>
                    <v-row no-gutters align="stretch">
                        <v-col cols="6" sm="4" md="3">
                            <v-img height="100%" width="100%" src="../../assets/bg5small.jpg"></v-img>
                        </v-col>
                        <v-col>
                            <v-card-title>New Artificial Inteligence implemented</v-card-title>
                            <v-card-subtitle>22/12/2019</v-card-subtitle>
                            <v-card-text>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit, quas nihil quos sapiente, minima voluptate, optio fugiat ratione itaque repellat at reprehenderit dolor quia natus sit cupiditate odio voluptatum pariatur?</v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-card>
                    <v-row no-gutters align="stretch">
                        <v-col order="2" cols="6" sm="4" md="3">
                            <v-img height="100%" width="100%" src="../../assets/bg7small.jpg"></v-img>
                        </v-col>
                        <v-col>
                            <v-card-title>Breakthrough Discovery</v-card-title>
                            <v-card-subtitle>30/11/2019</v-card-subtitle>
                            <v-card-text>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit, quas nihil quos sapiente, minima voluptate, optio fugiat ratione itaque repellat at reprehenderit dolor quia natus sit cupiditate odio voluptatum pariatur?</v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: ["facility_id"],
}
</script>