<template>
    <v-container v-if="this.$store.state.user.components.includes(59)">
        <div class="text-center">
            <v-progress-circular :size="50" color="primary" indeterminate class="progress" style="position:fixed;" v-if="loading"></v-progress-circular>
        </div>
        <v-row class="mt-4">
            <v-snackbar top v-model="error" timeout="2000">
                {{errorText}}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="error = false">Close</v-btn>
            </template>
            </v-snackbar>
            <v-row class="ma-2">
                <v-btn small color="primary" @click="subscribeAll()">Accept All</v-btn>
                <v-btn small class="ml-4" @click="subscribe()">Accept Selected</v-btn>
                <v-btn small class="ml-4" @click="remove()">Remove Selected</v-btn>
                <v-btn small class="ml-4" @click="removeAll()">Remove All</v-btn>
            </v-row>
            <crud
                ref="fm"
                label="Accepted Medications"
                :headers="headers"
                model="Medication"
                :can-edit="false"
                :can-add="false"
                :can-delete="false"
                :conditions="[{deleted:{is:null}}, {status:{eq:'Active'}}]"
                v-model="selected"
                show-select
            >
                <template v-slot:item.subscribed="obj">
                    <v-icon v-if="!!isSubscribed(obj.item.id)" color="primary" @click="updateFM(0, obj.item.id)">mdi-checkbox-marked-circle</v-icon>
                    <v-icon v-else @click="updateFM(1, obj.item.id)">mdi-close-circle</v-icon>
                </template>
            </crud>
        </v-row>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import crud from "../../../components/CRUD";

export default {
    props: ["facility_id"],
    data() {
        return {
            loading: false,
            selected: [],
            facilityMedications: [],
            medications: [],
            error: false,
            errorText: null,
            headers: [
                { text: "ID", value: "id", isId: true, visible: false },
                { text: "Name", value: "name", searchable: true },
                { text: "Alias", value: "alias", searchable: true },
                { text: "Subscribed", value: "subscribed", slot: true, query: false }
            ]
        };
    },
    methods: {
        updateFM(state, medication_id) {
            let id = null;
            let data = [];
            this.facilityMedications.forEach(function(item){
                if(item.medication_id == medication_id) id = item.id;
            });
            if(id == null) data = [{'facility_id': this.facility_id,'medication_id': medication_id, 'subscribed': state}];
            else data = [{'id': id, 'facility_id': this.facility_id,'medication_id': medication_id, 'subscribed': state}];

            this.saveFacilityMedications(data);
        },
        getFacilityMedications() {
            this.$apollo.query({
                query: gql`query FacilityMedications($wh: [FacilityMedicationConditionAND]) {
                    FacilityMedications(where: $wh) {
                        id
                        medication_id
                        subscribed
                    }
                }`,
                variables: {
                    wh: [{facility_id: {eq: this.facility_id}}, {deleted: {is:null}}]
                }
            }).then(res => {
                this.facilityMedications = res.data.FacilityMedications;
            });
        },
        saveFacilityMedications(dataSave) {
            if(dataSave.length > 0) {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: gql`mutation ($data: [FacilityMedicationSave]!) {
                        FacilityMedicationsSave(data: $data) {
                            id,
                            medication_id,
                            subscribed,
                        }
                    }`,
                    variables: {
                        data: dataSave
                    }
                }).then((res) => {
                    this.error = true;
                    this.errorText = "Success";
                    let saved = res.data.FacilityMedicationsSave;
                    let app = this;
                    saved.forEach(function(item) {
                        let item_id = item.id;
                        let found = false;
                        let key = -1;
                        app.facilityMedications.forEach(function(el, index) {
                            if(el.id == item_id) {
                                found = true;
                                key = index;
                            }
                        });
                        if(!found) {
                            app.facilityMedications.push(item);
                        }
                        else {
                            app.facilityMedications[key] = item;
                        }
                        app.loading = false;
                    })
                })
            }
        },
        getMedications() {
            this.$apollo.query({
                query: gql`query Medications($wh: [MedicationConditionAND]) {
                    Medications(where: $wh) {
                        id
                    }
                }`,
                variables: {
                    wh: [{status: {eq: "Active"}}, {deleted: {is:null}}]
                }
            }).then(res => {
                this.medications = res.data.Medications;
            });
        },
        isSubscribed(medication_id) {
            let found = false;
            this.facilityMedications.forEach(function(item) {
                if(item.medication_id == medication_id && item.subscribed == 1 && item.deleted == null) {
                    found = true;
                }
            });
            return found;
        },
        isInserted(medication_id) {
            let found = 0;
            this.facilityMedications.forEach(function(item) {
                if(item.medication_id == medication_id) {
                    found = item.id;
                }
            });
            return found;
        },
        subscribe() {
            if(this.selected.length == 0) {
                this.error = true;
                this.errorText = "Nothing selected!";
            }
            let data = [];
            let app = this;
            this.selected.forEach(function(el) {
                let id = app.isInserted(el.id);
                if(id > 0){
                    // update
                    data.push({"id": id,"facility_id": app.facility_id, "medication_id": el.id, "subscribed": 1});
                }
                else {
                    //insert
                    data.push({"facility_id": app.facility_id, "medication_id": el.id, "subscribed": 1});
                }
            });
            
            this.saveFacilityMedications(data);
        },
        subscribeAll() {
            let data = [];
            let app = this;

            this.medications.forEach(function(el) {
                let id = app.isInserted(el.id);
                if(id > 0){
                    // update
                    data.push({"id": id,"facility_id": app.facility_id, "medication_id": el.id, "subscribed": 1});
                }
                else {
                    //insert
                    data.push({"facility_id": app.facility_id, "medication_id": el.id, "subscribed": 1});
                }
            });
            
            this.saveFacilityMedications(data);
        },
        remove() {
            if(this.selected.length == 0) {
                this.error = true;
                this.errorText = "Nothing selected!";
            }
            let data = [];
            let app = this;

            this.selected.forEach(function(el) {
                let id = app.isInserted(el.id);
                if(id > 0) {
                    data.push({"id": id,"facility_id": app.facility_id, "medication_id": el.id, "subscribed": 0});
                }
            });

            this.saveFacilityMedications(data);
        },
        removeAll() {
            let data = [];

            this.facilityMedications.forEach(function(el) {
                data.push({"id": el.id,"facility_id": el.facility_id, "medication_id": el.medication_id, "subscribed": 0});
            });

            this.saveFacilityMedications(data);
        }
    },
    mounted() {
        this.getFacilityMedications();
        this.getMedications();
    },
    watch: {
    },
    components: {
        crud,
    }
};
</script>
<style scoped>
.progress {
    position: fixed;
    z-index: 5;
}
</style>