<template>
    <v-container v-if="$store.state.user.components.includes(33)">
        <v-card>
            <crud
                ref="tox"
                :headers="headers"
                :conditions="conditions"
                model="Tox"
                label="Lab Tests"
                sort-by="label"
                sort-desc
                multi-sort
                :canAdd="false"
                :canEdit="false"
                :canExport="true"
                :canFilter="true"
            >
                <template v-slot:filters>
                    <v-row dense>
                        <v-col cols="12">
                            <h5>Filters:</h5>
                        </v-col>
                        <v-row dense>
                            <v-col cols="2">
                                <datepicker
                                    dense
                                    :label="filters[0]['text']"
                                    v-model="filters[0].value"
                                ></datepicker>
                            </v-col>
                            <v-col cols="2">
                                <datepicker
                                    dense
                                    :label="filters[2]['text']"
                                    v-model="filters[2].value"
                                ></datepicker>
                            </v-col>
                            <v-col cols="2">
                                <v-select
                                    dense
                                    :label="filters[4]['text']"
                                    v-model="filters[4].value"
                                    :items="[{value: null, text: 'Any'}, {value: 'Sending', text: 'Sending'}, {value: 'Saved', text: 'Saved'}, {value: 'Complete', text: 'Complete'}]"
                                ></v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                    dense
                                    :label="filters[5]['text']"
                                    v-model="filters[5].value"
                                    :items="[{value: null, text: 'Any'}, {value: 'Insurance', text: 'Insurance'}, {value: 'Self Pay', text: 'Self Pay'}, {value: 'Client Pay', text: 'Client Pay'}, {value: 'Pre-Paid', text: 'Pre-Paid'}]"
                                ></v-select>
                            </v-col>
                            <v-col cols="3">
                                <autocomplete
                                    dense
                                    item-text="name"
                                    v-model="filters[6].value"
                                    label="Search Facility"
                                    model="Facility"
                                    :conditions="[{deleted: {is: null}}]"
                                ></autocomplete>
                            </v-col>

                            <v-row>
                                <v-col cols="2">
                                    <datepicker
                                        dense
                                        :label="filters[1]['text']"
                                        v-model="filters[1].value"
                                    ></datepicker>
                                </v-col>

                                <v-col cols="2">
                                    <datepicker
                                        dense
                                        :label="filters[3]['text']"
                                        v-model="filters[3].value"
                                    ></datepicker>
                                </v-col>
                                <v-col cols="2">
                                    <v-select
                                        dense
                                        :label="filters[7]['text']"
                                        v-model="filters[7].value"
                                        :items="[{value: null, text: 'Any'}, {value: 'Sent', text: 'Sent'}, {value: 'Charged', text: 'Charged'}, {value: 'Partially Paid', text: 'Partially Paid'}, {value: 'Paid', text: 'Paid'}, {value: 'Denied', text: 'Denied'}]"
                                    ></v-select>
                                </v-col>
                                <!--
                                <v-col cols="2">
                                    <v-text-field
                                        dense
                                        :label="filters[8]['text']"
                                        v-model="filters[8].value"
                                    ></v-text-field>
                                </v-col>
                                -->
                                <v-col cols="3">
                                    <v-select
                                        dense
                                        :label="filters[9]['text']"
                                        v-model="filters[9].value"
                                        :items="[{value: null, text: 'Any'}, {value: 'Archived', text: 'Archived'}, {value: 'New', text: 'New'}, {value: 'Bill Data OK', text: 'Bill Data OK'}, {value: 'Bill Data Incomplete', text: 'Bill Data Incomplete'}, {value: 'Bill Data ReEntered', text: 'Bill Data ReEntered'}, {value: 'Lab Results Problem', text: 'Lab Results Problem'}, {value: 'Written OFF (Queue)', text: 'Written OFF (Queue)'}, {value: 'Written OFF', text: 'Written OFF'}, {value: 'Helpdesk Needed', text: 'Helpdesk Needed'},]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="3">
                                    <autocomplete
                                        dense
                                        item-text="name"
                                        v-model="filters[10].value"
                                        label="Search Physician"
                                        model="User"
                                        :conditions="[{deleted: {is: null}}, {is_provider: {eq: 1}}]"
                                    ></autocomplete>
                                </v-col>
                            </v-row>
                        </v-row>

                        <v-row dense class="text-right">
                            <v-col cols="12">
                                <v-btn
                                    small
                                    color
                                    @click="$refs.tox.setConditions(resetConditions())"
                                >Reset</v-btn>
                                <v-btn
                                    small
                                    color="primary"
                                    class="ml-2"
                                    @click="$refs.tox.setConditions(filterConditions())"
                                >Apply</v-btn>
                            </v-col>
                        </v-row>
                    </v-row>
                </template>
                <template v-slot:item.label="{value,item}">
                    <router-link v-if="item.status == 'Saved'" :to="'labtestNew/'+item.id">{{value}}</router-link>
                    <b v-else-if="item.status == 'Complete'">{{value}}</b>
                    <i v-else>{{value}}</i>
                </template>
                <template v-slot:item.ereqPDF="{ item }">
                    <printEreqBtn :id="item.id"></printEreqBtn>
                </template>
                <template v-slot:item.labelPDF="{ item }">
                    <printEreqLabel :ids="[item.id]"></printEreqLabel>
                </template>
                <template v-slot:item.resPDF="{ item }">
                    <printEreqResult
                        :toxIds="[item.id]"
                        :autoprint="false"
                        :disabled="item.status!='Complete'"
                    ></printEreqResult>
                </template>
            </crud>
        </v-card>
        <v-dialog width="80vw" v-model="printDialog">
            <v-card style="height:90vh">
                <pdf v-if="printID" :value="printID" :print="true">LOADING ...</pdf>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { mask } from "vue-the-mask";
import crud from "../components/CRUD";
import pdf from "../views/LabTestOrder.vue";
import printEreqBtn from "./LabtestNew/printEreqBtn.vue";
import printEreqLabel from "./LabtestNew/printLabelBtn.vue";
import printEreqResult from "./LabtestNew/printResultBtn.vue";
import datepicker from "../components/DatePickerInput.vue";
import autocomplete from "../components/AutoComplete.vue";
export default {
    components: {
        crud,
        autocomplete,
        datepicker,
        pdf,
        printEreqBtn,
        printEreqLabel,
        printEreqResult,
    },
    directives: {
        mask,
    },
    data() {
        return {
            labelPrintDialog: false,
            search: "",
            printDialog: false,
            printID: null,

            headers: [
                { text: "id", value: "id", visible: false, isId: true },
                {
                    text: "eReq #",
                    value: "label",
                    slot: true,
                    searchable: true,
                },
                { text: "Status", value: "status", searchable: true },
                {
                    text: "Patient Name",
                    value: "patient_name",
                    searchable: true,
                },
                {
                    text: "Physician",
                    value: "physician_name",
                    searchable: true,
                },
                { text: "Facility", value: "facility_name", searchable: true },
                {
                    text: "Collected Date",
                    value: "collected",
                    searchable: true,
                },
                {
                    text: "DOB",
                    value: "Patient.dob",
                    searchable: true,
                },
                {
                    text: "",
                    value: "ereqPDF",
                    slot: true,
                    query: false,
                    align: "end",
                },
                {
                    text: "",
                    value: "labelPDF",
                    slot: true,
                    query: false,
                    align: "end",
                },
                {
                    text: "",
                    value: "resPDF",
                    slot: true,
                    query: false,
                    align: "end",
                },
            ],
            conditions: [{ deleted: { is: null } }],
            filters: [
                {
                    text: "Created From",
                    field: "created",
                    value: null,
                    op: "meq",
                },
                {
                    text: "Created To",
                    field: "created",
                    value: null,
                    op: "leq",
                },
                {
                    text: "Collected From",
                    field: "collected From",
                    value: null,
                    op: "meq",
                },
                {
                    text: "Collected To",
                    field: "collected To",
                    value: null,
                    op: "leq",
                },
                {
                    text: "eReq Status",
                    field: "status",
                    value: null,
                    op: "eq",
                },
                {
                    text: "Insurance Type",
                    field: "insurance_type",
                    value: null,
                    op: "eq",
                },
                {
                    text: "Facility",
                    field: "facility_id",
                    value: null,
                    op: "eq",
                },
                {
                    text: "Billing Status",
                    field: "billing_status",
                    value: null,
                    op: "eq",
                },
                {
                    text: "eReq #",
                    field: "label",
                    value: null,
                    op: "like",
                },
                {
                    text: "Bill Status",
                    field: "bill_status",
                    value: null,
                    op: "eq",
                },
                {
                    text: "Physician",
                    field: "physician_id",
                    value: null,
                    op: "eq",
                },
            ],
        };
    },

    methods: {
        filterConditions() {
            let conditions = this.resetConditions(false);

            this.filters.forEach(function (item) {
                if (item.value) {
                    if (item.op.indexOf("like") == -1)
                        conditions.push({
                            [item.field]: { [item.op]: item.value },
                        });
                    else
                        conditions.push({
                            [item.field]: { [item.op]: "%" + item.value + "%" },
                        });
                }
            });

            return conditions;
        },
        resetConditions(freset = true) {
            let app = this;
            let conditions = this.$refs.tox.totalConditions?.filter(function (
                obj
            ) {
                let notInitial = true;
                app.filters.forEach(function (item) {
                    if (freset) item.value = null;
                    if (Object.keys(obj)[0] == item.field) {
                        notInitial = false;
                    }
                });
                return notInitial;
            });

            return conditions;
        },
        result(result) {
            if (result == "Consistent") return { color: "lime" };
            else if (result == "Inconsistent") return { color: "red" };
            else return { color: "grey" };
        },
        deleteItem(item) {
            const index = this.items.indexOf(item);
            confirm("Are you sure you want to delete this item?") &&
                this.items.splice(index, 1);
        },
        printLabel(item) {
            this.selectedIndex = this.items.indexOf(item);
            this.selectedItem = Object.assign({}, item);
            this.labelPrintDialog = true;
        },
        getBarcode(ereq) {
            var string1 = ereq.replace("M", "E");
            var string2 = ereq.replace("M", "C");

            return [
                "http://www.barcode-generator.org/zint/api.php?bc_number=20&bc_data=" +
                    string1,
                "http://www.barcode-generator.org/zint/api.php?bc_number=20&bc_data=" +
                    string2,
            ];
        },
    },
};
</script>