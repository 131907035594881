<template>
    <v-card>
        
    </v-card>
</template>

<script>


export default {
    props: ["facility_id"],

}
</script>