<template>
    <v-navigation-drawer
        app
        mini-variant-width="80"
        mini-variant
        :permanent="$store.state.nav_drawer"
        expand-on-hover
        src="../assets/login_bg.jpg"
    >
        <v-row no-gutters class="fill-height">
            <v-col cols="12" align-self="start">
                <div align="center">
                    <router-link to="/">
                        <v-img src="../assets/Logo-02.png" class="ma-3" contain max-width="150" />
                    </router-link>
                </div>
                <!--<v-list-item id="sidebar-avatar">
                    <v-list-item-avatar tile>
                        <v-img :src="$store.state.user.picture" style="border-radius: 50%"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ $store.state.user.name }}</v-list-item-title>
                        <v-list-item-subtitle style="color: #fff;">{{ $store.state.user.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>-->

                <br />
                <v-divider></v-divider>
                <v-list dense nav v-for="(menu_item,id) in menu" :key="id" v-show="menu_item.show">
                    <v-list-group
                        v-if="menu_item.submenu.length>0"
                        :prepend-icon="menu_item.icon"
                        :value="menu_item.expanded"
                        link
                    >
                        <template v-slot:activator>
                            <v-list-item-title>{{ menu_item.title }}</v-list-item-title>
                        </template>

                        <v-list-item
                            v-for="(item,id) in menu_item.submenu"
                            :key="id"
                            link
                            :to="item.to"
                            v-show="item.show"
                        >
                            <v-list-item-icon class="pl-5">
                                <v-icon color="color">{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-action>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-else link :to="menu_item.to">
                        <v-list-item-icon>
                            <v-icon>{{ menu_item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ menu_item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>

            <v-col cols="12" align-self="end">
                <v-divider></v-divider>
                <v-list-item v-on:click.stop="toggleFullScreen()">
                    <v-list-item-icon>
                        <v-icon>mdi-overscan</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Full Screen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-on:click.stop="logout()">
                    <v-list-item-icon>
                        <v-icon>mdi-power</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Log Out</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
        </v-row>
    </v-navigation-drawer>
</template>
<script>
export default {
    data: () => {
        return {
            menu: [],
        };
    },
    created() {
        this.menu = [
            {
                title: "Dashboard",
                icon: "mdi-home",
                expanded: false,
                to: "/",
                submenu: [],
                show: this.$store.state.user.components.includes(1),
            },
            {
                title: "Patients",
                icon: "mdi-account-multiple",
                expanded: false,
                to: "/patients",
                submenu: [],
                show: this.$store.state.user.components.includes(17),
            },
            {
                title: "New Lab Tests",
                icon: "mdi-flask",
                to: "/labtestNew",
                show: this.$store.state.user.components.includes(34),
                submenu: [], //expanded: false,
            },
            {
                title: "Tests List",
                icon: "mdi-format-list-numbered",
                to: "/labtestList",
                show: this.$store.state.user.components.includes(33),
                submenu: [], //expanded: false,
            },
            {
                title: "Test Results",
                icon: "mdi-paperclip",
                to: "/labtestresult",
                show: this.$store.state.user.components.includes(90),
                submenu: [], //expanded: false,
            },
            {
                title: "Shipping",
                icon: "mdi-truck",
                expanded: false,
                submenu: [],
                to: "/shipping",
                show: this.$store.state.user.components.includes(35),
            },
            {
                title: "Supplies",
                icon: "mdi-store",
                expanded: false,
                submenu: [
                    {
                        title: "New Order",
                        icon: "mdi-cart",
                        to: "/supplies/new",
                        show: this.$store.state.user.components.includes(87),
                    },
                    {
                        title: "Orders",
                        icon: "mdi-format-list-bulleted",
                        to: "/supplies/orders",
                        show: true,
                    }
                ],
                show: this.$store.state.user.components.includes(88),
            },
            {
                title: "Settings",
                icon: "mdi-wrench",
                expanded: false,
                show: this.$store.state.user.components.includes(45),
                submenu: [
                    {
                        title: "Facilities",
                        icon: "mdi-domain",
                        to: "/settings/facilities",
                        show: this.$store.state.user.components.includes(46),
                    },
                    {
                        title: "Users & Roles",
                        icon: "mdi-account",
                        to: "/settings/users",
                        show: this.$store.state.user.components.includes(73),
                    },
                    {
                        title: "Lab Settings",
                        icon: "mdi-flask-outline",
                        to: "/settings/labs",
                        show: this.$store.state.user.components.includes(76),
                    },
                    {
                        title: "Diagnosis",
                        icon: "mdi-stethoscope",
                        to: "/settings/diagnosis",
                        show: this.$store.state.user.components.includes(81),
                    },
                    {
                        title: "Medications",
                        icon: "mdi-pill",
                        to: "/settings/medications",
                        show: this.$store.state.user.components.includes(82),
                    },
                    {
                        title: "Payers",
                        icon: "mdi-bank",
                        to: "/settings/payers",
                        show: this.$store.state.user.components.includes(83),
                    },
                ],
            },
        ];
    },
    methods: {
        toggleFullScreen() {
            var doc = window.document;
            var docEl = doc.documentElement;

            var requestFullScreen =
                docEl.requestFullscreen ||
                docEl.mozRequestFullScreen ||
                docEl.webkitRequestFullScreen ||
                docEl.msRequestFullscreen;
            var cancelFullScreen =
                doc.exitFullscreen ||
                doc.mozCancelFullScreen ||
                doc.webkitExitFullscreen ||
                doc.msExitFullscreen;

            if (
                !doc.fullscreenElement &&
                !doc.mozFullScreenElement &&
                !doc.webkitFullscreenElement &&
                !doc.msFullscreenElement
            ) {
                requestFullScreen.call(docEl);
            } else {
                cancelFullScreen.call(doc);
            }
        },
        logout() {
            this.$store.dispatch("logOut");
        },
    },
};
</script>