<template>
    <span>
        <slot
            v-bind="{
                data:result.data, 
                totalRecords:result.totalRecords, 
                loading:loading, 
                reload:()=>{getData()}, 
                conditions:(v)=>{localConditions=v},
                options:(v)=>{localOptions=v},
            }"
        ></slot>
    </span>
</template>
<script>
import gql from "graphql-tag";
export default {
    inheritAttrs: false,
    props: {
        model: String,
        src: String,
        conditions: Array,
        options: Object,
        autoload: {
            type: [Boolean,String],
            default: false,
        },
    },
    data() {
        return {
            result: { totalRecords: 0, data: [] },
            loading: false,
            localConditions: [],
            localOptions: { page: 1, itemsPerPage: 20 },
        };
    },
    computed: {
        gqlFields() {
            return this.src || "id";
        },
        totalConditions() {
            let c = this.conditions instanceof Array ? this.conditions : [];
            let lc =
                this.localConditions instanceof Array
                    ? this.localConditions
                    : [];
            return [...lc, ...c];
        },
        totalOptions() {
            let o = this.options instanceof Object ? this.options : {};
            let lo =
                this.localOptions instanceof Object ? this.localOptions : {};
            return { ...lo, ...o };
        },
    },
    watch: {
        totalConditions(n, o) {
            if (JSON.stringify(n) != JSON.stringify(o)) {
                this.getData();
            }
        },
        totalOptions(n, o) {
            if (JSON.stringify(n) != JSON.stringify(o)) {
                this.getData();
            }
        },
    },
    methods: {
        clean(val) {
            if (val instanceof Array) {
                return val.map((v) => this.clean(v));
            } else if (val instanceof Object) {
                let obj = {};
                val.__typename = undefined;
                Object.keys(val).forEach((k) => {
                    obj[k] = this.clean(val[k]);
                });
                return obj;
            } else return val;
        },
        getData() {
            this.loading = true;
            this.$apollo
                .query({
                    query: gql`query($opts:${this.model}Options,$wh:[${this.model}ConditionAND]) {
                        ${this.model}sPG(options:$opts,where:$wh){
                            totalRecords
                            data {
                                ${this.gqlFields}
                            }
                        }
                
                    }`,
                    fetchPolicy: "network-only",
                    variables: {
                        opts: this.totalOptions,
                        wh: this.totalConditions, //[{ deleted: { is: null } }]
                    },
                })
                .then(({ data }) => {
                    this.result = this.clean(data[this.model + "sPG"]);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        if (this.autoload) this.getData();
    },
};
</script>