<template>
    <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition">
        <template v-slot:activator="{on}">
            <v-text-field
                v-bind="$attrs"
                v-model="inputDate"
                prepend-inner-icon="mdi-clock"
                v-on="menuOn(on)"
                :readonly="isTouchScreen"
                :error-messages="errors"
                :rules="rules===undefined?[]:rules"
                v-mask="'##:##'"
                v-on:keyup.110.78="now()"
            >
                <template v-if="mandatory!==undefined && mandatory!==false" v-slot:label>
                    {{$attrs.label}}
                    <span style="color:red">*</span>
                </template>
            </v-text-field>
        </template>
        <v-time-picker v-model="pickerDate" @change="menu = false"></v-time-picker>
    </v-menu>
</template>
<script>
import isTouchScreen from "../plugins/detectTouchScreen";
import { mask } from "vue-the-mask";
export default {
    // NEED UTC source and write out
    inheritAttrs: false,
    props: {
        "value" : {type: String, default: () => null,}, 
        "rules" : {type: Array}, 
        "mandatory": {type: Boolean},
    },
    directives: {
        mask,
    },
    data() {
        return {
            menu: false,
            pickerDate: null,
            inputDate: null,
            jsDate: null,
            errors: [],
            isTouchScreen: isTouchScreen(),
        };
    },
    methods: {
        menuOn(on) {
            if (isTouchScreen()) return on;
            else {
                return {
                    "click:prepend-inner": on.click,
                };
            }
        },
        now() {
            let dt = new Date();
            let tm = dt.toTimeString().split(" ")[0].slice(0,5);
            this.jsDate = tm;
            this.inputDate = tm;
            this.pickerDate = tm;
        },
    },
    watch: {
        value: {
            handler(n) {
                if(n) {
                    this.jsDate = n;
                    this.inputDate = n;
                    this.pickerDate = n;
                }
            },
            immediate: true,
        },
        pickerDate(n) {
            this.jsDate = n;
            this.inputDate = n;
            this.pickerDate = n;
            this.$emit("input", n);
        },
        inputDate(n) {
            this.jsDate = n;
            this.inputDate = n;
            this.pickerDate = n;
            this.$emit("input", n);
        },
    },
    mounted() {
    },
};
</script>