<template>
    <v-app-bar app color="#ECF6FF" dense hide-on-scroll>
        
        <v-spacer></v-spacer>

        <v-btn small icon to="/supplies/orders/Pending" v-if="$store.state.user.components.includes(91) && pending_supply_orders>0" class="mr-2">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-badge :content="pending_supply_orders" overlap><v-icon v-on="on">mdi-cart</v-icon></v-badge>
                </template>
                <span>Pending Supply Orders</span>
            </v-tooltip>
        </v-btn>
        <v-menu left bottom v-if="$store.state.user.components.includes(84)">
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item dense>
                    <v-list-item-avatar>
                        <v-img v-if="$store.state.user.payload.picture" :src="$store.state.user.payload.picture"></v-img>
                        <v-img v-else src="../assets/avatars/2.svg"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ $store.state.user.payload.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{$store.state.user.payload.email}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="(item,id) in items" :key="id" link :to="item.to" v-on="item.action" dense>
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-action>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-menu>
        
        <visibility v-model="visibility_state" />
    </v-app-bar>
</template>

<script>
import visibility from '../views/account/visibility';
import gql from "graphql-tag";
export default {
    components: {
        visibility
    },
    methods: {
        toggle_navbar: function() {
            this.$store.commit("toggle_drawer", null);
        },
        logout() {
            this.$store.dispatch("logOut");
        },
        toggle_visibility() {
            this.visibility_state = !this.visibility_state;
        }
    },
    data() {
        return {
            visibility_state : false,
            pending_supply_orders: 0,
            items: [
                {
                    title: "My Account",
                    icon: "mdi-account",
                    to: "/account/settings"
                },
                {
                    title: "Visibility",
                    icon: "mdi-city",
                    action: {click: this.toggle_visibility}
                },
                {
                    title: "Log OUT",
                    icon: "mdi-power",
                    action: {click: this.logout}
                }
            ]
        };
    },
    mounted() {
        if(this.$store.state.user.components.includes(91)) {
            this.$apollo.query({
                query: gql`query SupplyOrders($wh: [SupplyOrderConditionAND]) {
                    SupplyOrders(where: $wh) {
                        id
                    }
                }`,
                variables: {
                    wh: [{status:{eq:"Pending"}}, {deleted: {is:null}}]
                }
            }).then(res => {
                this.pending_supply_orders = res.data.SupplyOrders.length;
            })
        }
    }
};
</script>