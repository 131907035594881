var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._t("default",null,null,{
            data:_vm.result.data, 
            totalRecords:_vm.result.totalRecords, 
            loading:_vm.loading, 
            reload:function (){_vm.getData()}, 
            conditions:function (v){_vm.localConditions=v},
            options:function (v){_vm.localOptions=v},
        })],2)}
var staticRenderFns = []

export { render, staticRenderFns }