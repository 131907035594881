<template>
    <v-app id="mainapp">
        <v-dialog persistent v-model="$store.state.relogin">
            <Relogin />
        </v-dialog>

        <AppBar v-if="$store.state.user.isAuthenticated" v-show="!$store.state.relogin" />
        <NavDrawer v-if="$store.state.user.isAuthenticated" v-show="!$store.state.relogin" />

        <v-main
            v-show="!$store.state.relogin"
            id="main-content"
            v-bind:style="{ backgroundImage: 'url(' + image + ')' }"
        >
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import NavDrawer from "./components/NavDrawer";
import Relogin from "./views/Relogin.vue";

export default {
    name: "App",
    components: {
        AppBar,
        NavDrawer,
        Relogin,
    },
    data() {
        return {
            image: null,
            pos: 0,
            images: [
                require("./assets/bg7.png"),
                require("./assets/bg6.png"),
                require("./assets/bg5.png"),
                require("./assets/bg4.png"),
                require("./assets/bg3.png"),
                require("./assets/bg2.png"),
            ],
        };
    },
    methods: {
        nextImg() {
            this.pos++;
            if (this.pos >= this.images.length) this.pos = 0;
            this.image = this.images[this.pos];
        },
    },
    mounted() {
        this.nextImg();
        setInterval(this.nextImg, 30000);
    },
    computed: {
        isIdle: {
            get: function () {
                if (this.$store.state.idleVue)
                    return this.$store.state.idleVue.isIdle;
                return false;
            },
        },
    },
    watch: {
        isIdle(newVal) {
            if (newVal == true && this.$store.state.user.isAuthenticated)
                this.$store.commit("relogIn");
        },
    },
};
</script>

<style scoped>
#main-content {
    background-color: #fafaff;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
</style>

<style>
.required label::after {
    content: " *";
    color: red;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar:hover {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(236, 246, 255)
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(152, 206, 253);
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(111, 168, 253);
  
}
</style>