<template>
    <v-container v-if="$store.state.user.components.includes(1)">
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn-toggle class="elevation-5">
                    <v-btn>
                        30
                        <span class="d-sm-none">D</span>
                        <span class="d-none d-sm-inline">Days</span>
                    </v-btn>
                    <v-btn>
                        3
                        <span class="d-sm-none">M</span>
                        <span class="d-none d-sm-inline">Months</span>
                    </v-btn>
                    <v-btn>
                        6
                        <span class="d-sm-none">M</span>
                        <span class="d-none d-sm-inline">Months</span>
                    </v-btn>
                    <v-btn>
                        1
                        <span class="d-sm-none">Y</span>
                        <span class="d-none d-sm-inline">Years</span>
                    </v-btn>
                </v-btn-toggle>
                <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text>
                <v-tabs v-model="tab" color="primary" background-color="rgba(0, 0, 0, 0.01)">
                    <v-tab
                        class="title font-italic"
                        v-if="$store.state.user.components.includes(2)"
                    >Home</v-tab>
                    <v-tab
                        class="title font-italic"
                        v-if="$store.state.user.components.includes(3)"
                    >Results</v-tab>
                    <v-tab
                        class="title font-italic"
                        v-if="$store.state.user.components.includes(4)"
                    >Trends</v-tab>
                    <v-tab
                        class="title font-italic"
                        v-if="$store.state.user.components.includes(5)"
                    >Insights</v-tab>

                    <v-tabs-items v-model="tab" style="background-color:rgba(0, 0, 0, 0.01)">
                        <v-tab-item v-if="$store.state.user.components.includes(2)">
                            <v-row>
                                <v-col
                                    v-for="(kpi,k) in kpis"
                                    :key="k"
                                    cols="6"
                                    sm="4"
                                    md="2"
                                    v-show="kpi.show"
                                >
                                    <kpi
                                        :color="kpi.color"
                                        :title="kpi.title"
                                        :value="kpi.value"
                                        :valuePrefix="kpi.valuePrefix"
                                        :valueSuffix="kpi.valueSuffix"
                                        :change="kpi.change"
                                        :changePrefix="kpi.changePrefix"
                                        :changeSuffix="kpi.changeSuffix"
                                        :changeText="kpi.changeText"
                                    ></kpi>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                    v-if="$store.state.user.components.includes(12)"
                                >
                                    <highcharts
                                        :options="chartOptions1"
                                        :updateArgs="updateArgs"
                                        class="elevation-3"
                                    ></highcharts>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                    v-if="$store.state.user.components.includes(13)"
                                >
                                    <highcharts
                                        :options="chartOptions2"
                                        :updateArgs="updateArgs"
                                        class="elevation-3"
                                    ></highcharts>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                    md="4"
                                    v-if="$store.state.user.components.includes(14)"
                                >
                                    <highcharts
                                        :options="chartOptions3"
                                        :updateArgs="updateArgs"
                                        class="elevation-3"
                                    ></highcharts>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="4"
                                    v-if="$store.state.user.components.includes(15)"
                                >
                                    <highcharts
                                        :options="chartOptions4"
                                        :updateArgs="updateArgs"
                                        class="elevation-3"
                                    ></highcharts>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="4"
                                    v-if="$store.state.user.components.includes(16)"
                                >
                                    <highcharts
                                        :options="chartOptions5"
                                        :updateArgs="updateArgs"
                                        class="elevation-3"
                                    ></highcharts>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <v-tab-item v-if="$store.state.user.components.includes(3)"></v-tab-item>

                        <v-tab-item v-if="$store.state.user.components.includes(4)"></v-tab-item>

                        <v-tab-item v-if="$store.state.user.components.includes(5)">
                            <v-row>
                                <v-col>
                                    <v-btn outlined @click="drawer=true">
                                        <v-icon>mdi-table-plus</v-icon>Add Chart
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    v-for="(c,k) in components"
                                    :key="k"
                                    :cols="c.cols"
                                    :order="c.order"
                                    @click="editComponent(c,k)"
                                >
                                    <component v-bind:is="c.type" v-bind="c.data"></component>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-card-text>
        </v-card>
        <v-navigation-drawer v-model="drawer" right app temporary src="../assets/login_bg.jpg">
            <v-container>
                <v-row v-for="(kpi,k) in kpis" :key="k">
                    <v-col @click="addSelected('kpi',kpi,2)">
                        <kpi
                            :color="kpi.color"
                            :title="kpi.title"
                            :value="kpi.value"
                            :valuePrefix="kpi.valuePrefix"
                            :valueSuffix="kpi.valueSuffix"
                            :change="kpi.change"
                            :changePrefix="kpi.changePrefix"
                            :changeSuffix="kpi.changeSuffix"
                            :changeText="kpi.changeText"
                        ></kpi>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        @click="addSelected('highcharts',{options:chartOptions1,updateArgs:updateArgs},6)"
                    >
                        <highcharts
                            :options="chartOptions1"
                            :updateArgs="updateArgs"
                            class="elevation-3"
                        ></highcharts>
                    </v-col>
                    <v-col
                        cols="12"
                        @click="addSelected('highcharts',{options:chartOptions2,updateArgs:updateArgs},6)"
                    >
                        <highcharts
                            :options="chartOptions2"
                            :updateArgs="updateArgs"
                            class="elevation-3"
                        ></highcharts>
                    </v-col>
                    <v-col
                        cols="12"
                        @click="addSelected('highcharts',{options:chartOptions3,updateArgs:updateArgs},6)"
                    >
                        <highcharts
                            :options="chartOptions3"
                            :updateArgs="updateArgs"
                            class="elevation-3"
                        ></highcharts>
                    </v-col>
                    <v-col
                        cols="12"
                        @click="addSelected('highcharts',{options:chartOptions4,updateArgs:updateArgs},6)"
                    >
                        <highcharts
                            :options="chartOptions4"
                            :updateArgs="updateArgs"
                            class="elevation-3"
                        ></highcharts>
                    </v-col>
                    <v-col
                        cols="12"
                        @click="addSelected('highcharts',{options:chartOptions5,updateArgs:updateArgs},6)"
                    >
                        <highcharts
                            :options="chartOptions5"
                            :updateArgs="updateArgs"
                            class="elevation-3"
                        ></highcharts>
                    </v-col>
                </v-row>
            </v-container>
        </v-navigation-drawer>
        <v-dialog v-model="dialog" width="20vw">
            <v-card>
                <v-toolbar color="primary" dark dense flat>
                    <v-toolbar-title>Change Size</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog=false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-select
                                :items="[1,2,3,4,5,6,7,8,9,10,11,12]"
                                v-model="componentEdit.cols"
                                label="Width"
                            ></v-select>
                        </v-col>
                        <v-col>
                            <v-select
                                :items="[1,2,3,4,5,6,7,8,9,10,11,12]"
                                :value="componentEdit.order"
                                @change="changeOrder"
                                label="Order"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="removeComponent()" color="alert">
                        <v-icon>mdi-delete</v-icon>Remove
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>


<script>
import kpi from "../components/KPI.vue";

export default {
    components: {
        kpi,
    },
    data() {
        return {
            drawer: false,
            dialog: false,
            componentEdit: { cols: null, order: null, key: null },

            components: [],
            tab: null,
            period: 1,
            kpis: [],

            updateArgs: [true, true, { duration: 1000 }],
            chartOptions1: {
                chart: {
                    type: "column",
                    backgroundColor: null,
                    plotBackgroundColor: null,
                },
                title: {
                    text: "Volume by Day",
                },
                series: [
                    {
                        data: [14, 2, 8, 2, 6, 4, 5, 5],
                        color: "#0140ff",
                    },
                ],
            },

            chartOptions2: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                    backgroundColor: null,
                },
                exporting: {
                    enabled: false,
                },
                title: {
                    text: "Volume by Payer",
                },
                series: [
                    {
                        data: [
                            {
                                name: "Medicare",
                                y: 29.9,
                                color: "#0140ff",
                            },
                            {
                                name: "Aetna Better Health",
                                y: 29.9,
                                color: "#6201ee ",
                            },
                            {
                                name: "Passport",
                                y: 29.94,
                                color: "#29c0b1",
                            },
                        ],
                    },
                ],
            },

            chartOptions3: {
                chart: {
                    type: "bar",
                    backgroundColor: null,
                    plotBackgroundColor: null,
                },
                yAxis: {},
                title: {
                    text: "Top Out of Network Payers",
                },
                series: [
                    {
                        data: [
                            {
                                name: "Anthem BC/BS",
                                y: 14,
                            },
                            {
                                name: "",
                                y: 1,
                            },
                            {
                                name: "Humana Caresource",
                                y: 8,
                            },
                            {
                                name: "Aetna",
                                y: 2,
                            },
                            {
                                name: "BC/BS of Tennessee",
                                y: 6,
                            },
                            {
                                name: "Medicaid Ohio",
                                y: 4,
                            },
                            {
                                name: "BC/BS of Colorado",
                                y: 5,
                            },
                            {
                                name: "Molina",
                                y: 5,
                            },
                        ],
                        color: "#0140ff",
                    },
                ],
            },

            chartOptions4: {
                chart: {
                    type: "bar",
                    backgroundColor: null,
                    plotBackgroundColor: null,
                },
                title: {
                    text: "Top Payers Inbound",
                },
                series: [
                    {
                        data: [
                            {
                                name: "Aetna Better Health",
                                y: 14,
                            },
                            {
                                name: "United Healthcare",
                                y: 8,
                            },
                            {
                                name: "Wellcare",
                                y: 2,
                            },
                            {
                                name: "Passport",
                                y: 6,
                            },
                            {
                                name: "Humana",
                                y: 4,
                            },
                            {
                                name: "Cigna",
                                y: 5,
                            },
                            {
                                name: "Meritain Health",
                                y: 5,
                            },
                        ],
                        color: "#29c0b1",
                    },
                ],
            },

            chartOptions5: {
                chart: {
                    type: "bar",
                    backgroundColor: null,
                    plotBackgroundColor: null,
                },
                title: {
                    text: "Top Tests Ordered (title)",
                },
                yAxis: {
                    labels: {},
                },
                series: [
                    {
                        data: [
                            {
                                name: "Opiates",
                                y: 14,
                            },
                            {
                                name: "Buprenorphine",
                                y: 8,
                            },
                            {
                                name: "Cannabinoids",
                                y: 2,
                            },
                            {
                                name: "Amphetamines",
                                y: 6,
                            },
                            {
                                name: "Barbiturates",
                                y: 4,
                            },
                            {
                                name: "CMP",
                                y: 5,
                            },
                            {
                                name: "Acute Hepatitis Panel",
                                y: 5,
                            },
                        ],
                        color: "#6201ee",
                    },
                ],
            },
        };
    },
    created() {
        this.kpis = [
            {
                color: "#6201ee",
                title: "TOTAL ORDERS",
                value: "2500",
                change: "4",
                changeSuffix: "%",
                changeText: "from last week",
                show: this.$store.state.user.components.includes(6),
            },
            {
                color: "#29c0b1",
                title: "TOTAL OFFLOADED",
                value: "493",
                change: "3",
                changeSuffix: "%",
                changeText: "from last week",
                show: this.$store.state.user.components.includes(7),
            },
            {
                color: "#2c50ee",
                title: "TOTAL NEW INBOUND",
                value: "512",
                change: "34",
                changeSuffix: "%",
                changeText: "from last week",
                show: this.$store.state.user.components.includes(8),
            },
            {
                color: "#4d83ff",
                title: "AVERAGE $ PAID",
                value: "157.43",
                valuePrefix: "$",
                change: "-12",
                changeSuffix: "%",
                changeText: "from last week",
                show: this.$store.state.user.components.includes(9),
            },
            {
                color: "#888",
                title: "NET $ GAINED",
                value: "81.44",
                valuePrefix: "$",
                change: "41",
                changeSuffix: "%",
                changeText: "from last week",
                show: this.$store.state.user.components.includes(10),
            },
            {
                color: "#222437",
                title: "COST PER ACCESSION",
                value: "32",
                valuePrefix: "$",
                change: "0",
                changeSuffix: "%",
                changeText: "from last week",
                show: this.$store.state.user.components.includes(11),
            },
        ];
    },
    watch: {
        dialog(n) {
            if (n == false) this.saveComponents();
        },
    },
    methods: {
        addSelected(type, data, cols) {
            this.components.push({
                type,
                data,
                cols,
                order: this.components.length + 1,
            });
            this.saveComponents();
        },
        editComponent(c) {
            this.componentEdit = c;
            this.dialog = true;
        },
        changeOrder(ord) {
            this.components.forEach((c) => {
                if (c.order >= this.componentEdit.order) c.order--;
                if (c.order >= ord) c.order++;
            });

            this.componentEdit.order = ord;
            this.saveComponents();
        },
        removeComponent() {
            let position = this.components.indexOf(this.componentEdit);
            this.components.splice(position, 1);
            this.dialog = false;
            this.components.forEach((c) => {
                if (c.order > position) c.order--;
            });
            this.saveComponents();
        },
        saveComponents() {
            window.localStorage.setItem(
                "dasboardComponents",
                JSON.stringify(this.components)
            );
        },
        loadComponents() {
            let data = window.localStorage.getItem("dasboardComponents");
            if (data) data = JSON.parse(data);
            if (data instanceof Array) this.components = data;
        },
    },
    mounted() {
        this.loadComponents();
    },
};
</script>

