<template>
    <span>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn @click="printManifest()" fab dark append:x-large="size=='x-large'" :large="size=='large'" :small="size=='small'" :x-small="size=='x-small'" class="mx-1">
                    <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
                    <v-icon v-on="on" v-else :color="color">{{icon}}</v-icon>
                </v-btn>
            </template>
            <span>Print Manifest</span>
        </v-tooltip>
    </span>
</template>

<script>
import gql from "graphql-tag";
export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        box_id: [Number, String],
        icon: {
            type: [String],
            default: "mdi-format-list-bulleted-square",
        },
        color: {
            type: [String],
            default: "amber lighten-1",
        },
        size: {
            type: [String],
            default: "x-small",
        }
    },
    methods: {
        async printManifest() {
            if (!this.box_id) return;
            try {
                this.loading = true;
                let pdf = await this.getManifestPDF(this.box_id);
                const blob = await fetch(pdf).then((r) => r.blob());

                const dataUrl = window.URL.createObjectURL(blob);
                window.open(dataUrl);
                //pdfWindow.print();
            } finally {
                this.loading = false;
            }
        },
        async getManifestPDF(box_id) {
            let res = await this.$apollo.query({
                query: gql`
                    query($box_id: ID!) {
                        ShippingManifest(box_id: $box_id)
                    }
                `,
                variables: {
                    "box_id": box_id,
                },
                fetchPolicy: "network-only",
            });

            return "data:application/pdf;base64," + res.data.ShippingManifest + "#toolbar=0&statusbar=0";
        },
    },
};
</script>
