<template>
    <ul>
        <li v-for="(item, ik) in activeRows" :key="ik">
            <div class="row-wrapper">
                <slot name="item-title" v-bind="item">
                    <slot
                        v-if="item._editing"
                        name="editing"
                        v-bind="{item,stopEditing,level,parent}"
                    >
                        <v-text-field
                            v-model="item[labelField]"
                            hide-details="auto"
                            dense
                            @keypress.enter="stopEditing(item)"
                            @click:append="stopEditing(item)"
                            append-icon="mdi-content-save"
                            autofocus
                        ></v-text-field>
                    </slot>
                    <slot v-else name="label" v-bind="{item,stopEditing,level,parent}">
                        <b v-if="item.bold">{{item[labelField]}}</b>
                        <i v-else-if="item.italic">{{item[labelField]}}</i>
                        <span v-else>{{item[labelField]}}</span>
                    </slot>
                </slot>
                <span class="actions">
                    <slot name="item-actions" v-bind="{item,stopEditing,level,parent}">
                        <v-btn
                            v-if="item._add!==undefined ? item._add : (item.add !== undefined ? item.add : canAdd >= level)"
                            x-small
                            icon
                            @click="add(item)"
                        >
                            <v-icon small>mdi-plus-thick</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="item._edit!==undefined ? item._edit : (item.edit !== undefined ? item.edit : canEdit >= level)"
                            x-small
                            icon
                            @click="edit(item)"
                        >
                            <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="item._delete!==undefined ? item._delete : (item.delete !== undefined ? item.delete : canDelete >= level)"
                            x-small
                            icon
                            @click="del(item)"
                        >
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </slot>
                </span>
            </div>
            <tree
                v-if="item.children"
                v-model="item.children"
                v-on="actions"
                :canAdd="canAdd"
                :canEdit="canEdit"
                :canDelete="canDelete"
                :deleteWithParam="deleteWithParam"
                :level="level+1"
                :parent="item"
                :labelField="labelField"
                :idField="idField"
            >
                <template v-slot:item-actions="subitem">
                    <slot name="item-actions" v-bind="subitem"></slot>
                </template>
                <template v-slot:item-title="subitem">
                    <slot name="item-title" v-bind="subitem"></slot>
                </template>
                <template v-slot:editing="subitem">
                    <slot name="editing" v-bind="subitem"></slot>
                </template>
                <template v-slot:label="subitem">
                    <slot name="label" v-bind="subitem"></slot>
                </template>
            </tree>
        </li>
    </ul>
</template>

<script>
import tree from "./Tree.vue";
export default {
    name: "tree",
    data() {
        return {
            rows: [],
        };
    },
    methods: {
        add(item) {
            if (this.$listeners.add) this.$emit("add", item);
            else {
                if (item.children == null) this.$set(item, "children", []);

                let obj = {
                    id: null,
                    _editing: true,
                    _delete: true,
                    _edit: false,
                    _add: false,
                };
                obj[this.labelField] = null;

                item.children.push(obj);
            }
        },
        edit(item) {
            if (this.$listeners.edit) this.$emit("edit", item);
            else {
                this.$set(item, "_editing", true);
                this.$set(item, "_add", false);
                this.$set(item, "_edit", false);
                this.$set(item, "_delete", true);
            }
        },
        del(item) {
            if (this.$listeners.delete) this.$emit("delete", item);
            else if (this.deleteWithParam) {
                if (item.id) this.$set(item, this.deleteWithParam, true);
                else {
                    let pos = this.rows.indexOf(item);
                    if (pos >= 0) this.rows.splice(pos, 1);
                }
            } else {
                let pos = this.rows.indexOf(item);
                if (pos >= 0) this.rows.splice(pos, 1);
            }
        },
        stopEditing(item) {
            this.$set(item, "_add", undefined);
            this.$set(item, "_edit", undefined);
            this.$set(item, "_delete", undefined);
            this.$set(item, "_editing", undefined);
            // Removes the entry if empty
            if (
                Object.keys(item).reduce((sum, v) => {
                    if (item[v] != null) return sum + 1;
                    return sum;
                }, 0) == 0
            ) {
                let pos = this.rows.indexOf(item);
                if (pos >= 0) this.rows.splice(pos, 1);
            }
        },
    },
    computed: {
        actions() {
            let functions = {};
            if (this.$listeners.add) functions.add = this.add;
            if (this.$listeners.edit) functions.edit = this.edit;
            if (this.$listeners.delete) functions.delete = this.del;
            return functions;
        },
        activeRows() {
            if (this.deleteWithParam && this.rows instanceof Array) {
                return this.rows.filter((row) => !row[this.deleteWithParam]);
            } else {
                return this.rows;
            }
        },
    },
    watch: {
        rows: {
            handler(n) {
                if (n != this.value) this.$emit("input", n);
            },
            deep: true,
        },
        value: {
            handler(n) {
                if (this.rows != n) this.rows = n;
            },
            deep: true,
            immediate: true,
        },
    },
    props: {
        /** Those are the items */
        value: Array,
        canAdd: {
            type: Number,
            default: Number.MAX_VALUE,
        },
        canEdit: {
            type: Number,
            default: Number.MAX_VALUE,
        },
        canDelete: {
            type: Number,
            default: Number.MAX_VALUE,
        },
        deleteWithParam: String,
        level: {
            type: Number,
            default: 1,
        },
        parent: Object,
        labelField: {
            type: String,
            default: "name",
        },
        idField: {
            type: String,
            default: "id",
        },
    },
    components: { tree },
};
</script>

<style scoped>
.row-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s;
    padding: 0px 1px 0px 5px;
}
.row-wrapper:hover {
    background-color: #f8f8f8;
}
.actions {
    white-space: nowrap;
}
</style>