<template>
    <v-card class="mx-auto pa-1" max-width="200px">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-img
                    v-on="on"
                    contain
                    aspect-ratio="1"
                    :src="require('../assets/samples-box.jpg')"
                    @click="contentsDialog=true"
                    style="cursor: pointer"
                ></v-img>
            </template>
            <span>Click to view/edit contents</span>
        </v-tooltip>
        <v-card-text class="pa-0 text-center">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon small v-if="isLate" v-on="on" color="warning">mdi-clock-alert</v-icon>
                </template>
                <span>Samples need to be dispatched quickly</span>
            </v-tooltip>
            {{contents.length}} samples
        </v-card-text>
        <v-card-text class="pa-1 text-center">
            <v-progress-linear v-model="full" height="15" reactive rounded :color="fullColor">
                <template v-slot="{ value }">
                    <strong>{{ Math.ceil(value) }}% full</strong>
                </template>
            </v-progress-linear>
        </v-card-text>

        <v-card-actions>
            <v-btn color="green lighten-2" @click="contentsDialog=true">
                <v-icon left>mdi-package-variant</v-icon>Ship Samples
            </v-btn>
        </v-card-actions>

        <v-dialog v-model="contentsDialog" v-if="contentsDialog" max-width="75vw">
            <v-card fluid>
                <v-toolbar dark dense color="primary" :elevation="0">
                    <v-toolbar-title>
                        Box Contents
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon small @click="contentsDialog=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-row>
                    <v-col cols="4">
                        <v-row dense>
                            <v-card-subtitle class="pa-0 ml-4">Destination:</v-card-subtitle>
                        </v-row>
                        <v-row dense>
                            <v-card-title class="pa-0 ml-4">{{destinationInfo.name}}</v-card-title>
                        </v-row>
                        <v-row dense>
                            <v-card-text class="font-italic pa-0 ml-4">
                                {{destinationInfo.address1}},<br />
                                <span v-if="destinationInfo.address2">{{destinationInfo.address2}},<br /></span>
                                {{destinationInfo.city}} {{destinationInfo.state}}, {{destinationInfo.zip}}
                            </v-card-text>
                        </v-row>

                        <v-row dense>
                            <v-card-text class="pa-0 ml-4 mt-2">
                                <v-select v-model="carrier" :items="carriers" label="Carrier" width="50%"></v-select>
                            </v-card-text>
                        </v-row>
                    </v-col>

                     <v-col cols="3" v-if="boxID>0">
                        <v-row dense>
                            <v-card-title class="pa-0">Box #{{boxID}}</v-card-title>
                        </v-row>
                        <v-row dense>
                            <v-card-text class="font-italic pa-0">Status: {{boxStatus}}</v-card-text>
                        </v-row>
                        <v-row dense>
                            <v-card-text class="font-italic pa-0">Date Created: {{new Date().toLocaleDateString()}}</v-card-text>
                        </v-row>
                        <v-row dense>
                            <v-card-text class="font-italic pa-0">Created By: {{$store.state.user.payload.name}}</v-card-text>
                        </v-row>
                        <v-card-actions v-if="boxID>0">
                            <PrintPackingSlip v-if="boxLabel" :label="boxLabel" />
                            <PrintManifest :box_id="boxID" />
                            <VoidShipment  v-if="trackingNum" :carrier="carrier" :tracking="trackingNum" />
                        </v-card-actions>
                    </v-col>
                    <v-col cols="3" v-else></v-col>

                    <v-col cols="5">
                        <v-row dense>
                            <v-btn v-if="boxStatus=='Saved'" color="primary" small class="ma-1" @click="addDialog=true" :disabled="!(this.samples.length < capacity)">Add Samples</v-btn>
                            <v-btn  v-if="boxStatus=='Saved'" color="primary" small class="ma-1" @click="Refill()" :disabled="!(this.samples.length < capacity)">Auto Refill</v-btn>
                            <v-btn v-if="boxStatus=='Saved'" color="green lighten-3" small class="ma-1" :disabled="carrier==null" @click="createBox(0)">Save</v-btn>
                            <v-btn v-if="boxStatus=='Saved'" color="green lighten-3" small class="ma-1" :disabled="carrier==null" @click="createBox(1)">Save & Ship</v-btn>
                        </v-row>
                        <v-row>
                            <v-spacer class="pa-2"></v-spacer>
                            <v-col cols="12" class="px-4">
                                <v-progress-linear v-model="full" height="15" reactive rounded :color="fullColor">
                                    <template v-slot="{ value }">
                                        <strong>{{ Math.ceil(value) }}% full</strong>
                                    </template>
                                </v-progress-linear>
                            </v-col>
                        </v-row>
                        <v-row justify="center" align="end">
                            <v-spacer class="pa-2"></v-spacer>
                            <v-alert
                                v-if="contents.length >= capacity"
                                tile
                                elevation="2"
                                type="info"
                                dense
                                text
                            >
                                <span class="text-small">You have reached the maximum number of samples for this box.</span>
                            </v-alert>
                        </v-row>
                    </v-col>
                </v-row>
                <crud
                    :headers="sample_headers"
                    model="ShippingSample"
                    :canAdd="false"
                    :canEdit="false"
                    :canDelete="false"
                    :conditions="[{id:{in:sampleIDs}}]"
                    :sort-by="['collected']"
                    :sort-desc="true"
                >
                    <template v-slot:item.actions="obj">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    fab
                                    dark
                                    x-small
                                    class="mx-1"
                                    color="red"
                                    @click="rmFromShipping(obj.item.id)"
                                >
                                    <v-icon v-on="on">mdi-flask-remove-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Remove sample from Shipping Manager</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    fab
                                    dark
                                    x-small
                                    class="mx-1"
                                    color="orange lighten-1"
                                    v-if="boxStatus == 'Saved'"
                                    @click="rmFromBox(obj.item.id)"
                                >
                                    <v-icon v-on="on">mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Remove sample from this box</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.collected="obj">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    class="ma-1"
                                    small
                                    color="warning"
                                    v-on="on"
                                    v-if="Math.abs(new Date() - new Date(obj.item.collected.replace(/-/g, '/'))) > 1*3600*1000"
                                >mdi-clock-alert</v-icon>
                            </template>
                            <span>Sample needs to be dispatched quickly</span>
                        </v-tooltip>
                        <span>{{ new Date(obj.item.collected).toLocaleString() }}</span>
                    </template>
                </crud>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteDialog" persistent max-width="40vw">
            <v-card>
                <v-card-title>
                    <span class="headline">Remove Sample</span>
                </v-card-title>

                <v-card-text>Are you sure you want to remove this sample from Shipping Manager? It won't be possible to ship the sample later!</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" outlined text @click="deleteDialog=false">Cancel</v-btn>
                    <v-btn color="blue darken-1" outlined @click="rmFromShippingNow()">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addDialog" persistent max-width="40vw">
            <v-card>
                <v-card-title>
                    <span class="headline">Add Sample</span>
                </v-card-title>

                <v-card-text
                    v-if="!(samples.length < capacity)"
                    class="warning"
                    justify="start"
                    align="center"
                >Cannot add more samples to this box!</v-card-text>

                <v-card-text>
                    <autocomplete
                        v-if="samples.length < capacity"
                        label="Search"
                        v-model="sampleToAdd"
                        model="ShippingSample"
                        item-text="search"
                        :conditions="[{facility_id:{eq:destination}},{id:{nin:sampleIDs}}]"
                    ></autocomplete>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" outlined text @click="addDialog=false">Close</v-btn>
                    <v-btn
                        color="blue darken-2"
                        dark
                        :disabled="!(samples.length < capacity)"
                        @click="addItem()"
                    >Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import crud from "./CRUD.vue";
import gql from "graphql-tag";
import autocomplete from "./AutoComplete";
import PrintPackingSlip from "../views/shipping/printPackingSlip.vue";
import VoidShipment from "../views/shipping/voidShipment.vue";
import PrintManifest from "../views/shipping/printManifest.vue";
export default {
    components: {
        crud,
        autocomplete,
        PrintPackingSlip,
        PrintManifest,
        VoidShipment,
    },
    props: {
        capacity: { type: Number, default: 5 },
        contents: { type: Array, default: () => [] },
        destination: { type: String, required: true },
    },
    data() {
        return {
            boxID: 0,
            carrier: null,
            contentsDialog: false,
            deleteDialog: false,
            addDialog: false,
            deleteItem: {},
            itemToAdd: {},
            sampleToAdd: 0,
            destinationInfo: {},
            shipLabel: null,
            boxLabel: null,
            boxStatus: "Saved",
            trackingNum: null,
            samples: this.contents,
            sample_headers: [
                { text: "ID", value: "id", editable: false, visible: false, isId: true },
                { text: "Label", value: "label", searchable: true },
                { text: "Patient Name", value: "patient"},
                { text: "Patient DOB", value: "dob"},
                { text: "Sample Type", value: "LabContainer.sample_name" },
                { text: "Container", value: "LabContainer.name" },
                { text: "Container #", value: "num" },
                { text: "Collected", value: "collected", slot: true },
                { text: "Actions", value: "actions", query: false, slot: true }
            ],
            ShippingSettings: [],
        };
    },
    methods: {
        isLate() {
            this.samples.forEach(obj => {
                let diff = Math.abs(
                    new Date() - new Date(obj.collected.replace(/-/g, "/"))
                );
                if (diff > 48 * 3600 * 1000) return true;
            });
            return false;
        },
        addItem() {
            if (this.sampleToAdd > 0) {
                this.$apollo
                    .query({
                        query: gql`
                            query toxSample($id: ID!) {
                                toxSample(id: $id) {
                                    id
                                    tox_id
                                    facility_id
                                    label
                                    num
                                    container_id
                                    test_type
                                    collected
                                    status
                                }
                            }
                        `,
                        variables: {
                            id: this.sampleToAdd
                        }
                    })
                    .then(res => {
                        this.itemToAdd = res.data.toxSample;
                        this.$set(
                            this.samples,
                            this.samples.length,
                            this.itemToAdd
                        );
                    });
            }
        },
        createBox(ship=0) {
            // Create new Box
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($inputdata: ShippingBoxInput!) {
                            ShippingBoxInsert(data: $inputdata) {
                                id
                                destination_id
                                origin_id
                                user_id
                                carrier
                                status
                                created
                            }
                        }
                    `,
                    variables: {
                        inputdata: {
                            destination_id: this.destination,
                            origin_id: this.$store.state.user.payload["l"],
                            user_id: this.$store.state.user.payload["id"],
                            carrier: this.carrier,
                            status: "Saved",
                            created: new Date()
                        }
                    }
                })
                .then(res => {
                    this.boxID = res.data.ShippingBoxInsert.id;
                    this.boxStatus = res.data.ShippingBoxInsert.status;

                    if (this.boxID > 0) {
                        // Add contents
                        this.$apollo
                            .mutate({
                                mutation: gql`
                                    mutation(
                                        $inputdata: [ShippingBoxContentSave]!
                                    ) {
                                        ShippingBoxContentsSave(
                                            data: $inputdata
                                        ) {
                                            box_id
                                            tox_id
                                            sample_id
                                            test_type
                                        }
                                    }
                                `,
                                variables: {
                                    inputdata: this.samples.map(obj => {
                                        return Object.assign(
                                            {},
                                            {
                                                box_id: this.boxID,
                                                tox_id: obj.tox_id,
                                                sample_id: obj.id,
                                                test_type: obj.test_type
                                            }
                                        );
                                    })
                                }
                            })
                            .then(() => {
                                // Set ship flag
                                this.$apollo.mutate({
                                    mutation: gql`
                                        mutation($inputdata: [toxSampleSave]!) {
                                            toxSamplesSave(data: $inputdata) {
                                                id
                                                ship
                                            }
                                        }
                                    `,
                                    variables: {
                                        inputdata: this.samples.map(obj => {
                                            return Object.assign(
                                                {},
                                                { id: obj.id, ship: 1 }
                                            );
                                        })
                                    }
                                });
                            }).finally(() => {
                                if(ship) {
                                    this.shipRequest();
                                }
                                else {
                                    this.contentsDialog = false;
                                }
                            });
                    }
                });
        },
        shipRequest() {
            this.$apollo.mutate({
                mutation: gql`mutation($box_id: Int!) {
                    ${this.carrier}ShipRequest(box_id: $box_id) {
                        ErrorCode
                        ErrorMessage
                        Status
                        ShippingLabel
                        LabelFileId
                        TrackingNumber
                    }
                }`,
                variables: {
                    box_id: this.boxID
                }
            }).then(res=>{
                if(res.data[this.carrier+"ShipRequest"].Status == 'Success') {
                    this.shipLabel = res.data[this.carrier+"ShipRequest"].ShippingLabel;
                    this.boxLabel = res.data[this.carrier+"ShipRequest"].LabelFileId;
                    this.trackingNum = res.data[this.carrier+"ShipRequest"].TrackingNumber;

                    this.$apollo.mutate({
                        mutation: gql`mutation($data: ShippingBoxInput!, $wh: [[ShippingBoxConditionAND]!]) {
                            ShippingBoxUpdate(data: $data, where: $wh) {
                                status
                            }
                        }`,
                        variables: {
                            data: {status: "Pending"},
                            wh: [{id: {eq: this.boxID}}]
                        }
                    }).then(result => {
                        this.boxStatus = result.data.ShippingBoxUpdate.status;
                    }).finally(() => {
                        this.printLabel();
                    });
                }
                else alert("Ship request failed! " + res.data[this.carrier+"ShipRequest"].ErrorMessage);
            });
        },
        printLabel() {
            let mywindow = window.open('', 'Print', 'height=400,width=800');
            mywindow.document.write('<html><head><title>Print Shipping Label</title>');
            mywindow.document.write('</head><body >');
            mywindow.document.write('<img src="data:image/gif;base64,'+this.shipLabel+'" width="100%" />');
            mywindow.document.write('</body></html>');
            setTimeout(() => {
                mywindow.document.close();
                mywindow.focus();
                mywindow.print();
                mywindow.close();
            }, 1200);
        },
        Refill() {
            this.$apollo
                .query({
                    query: gql`
                        query ShippingSamples(
                            $wh: [ShippingSampleConditionAND]
                            $opts: ShippingSampleOptions
                        ) {
                            ShippingSamples(where: $wh, options: $opts) {
                                id
                                label
                                facility_id
                                tox_id
                                test_type
                            }
                        }
                    `,
                    variables: {
                        wh: [
                            { facility_id: { eq: this.destination } },
                            { id: { nin: this.sampleIDs } }
                        ],
                        opts: {
                            page: 1,
                            itemsPerPage: this.capacity - this.samples.length,
                            sortBy: ["collected"],
                            sortDesc: [true]
                        }
                    }
                })
                .then(res => {
                    res.data.ShippingSamples.forEach(elem => {
                        this.samples.push(elem);
                    });
                });
        },
        rmFromShippingNow() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation(
                            $inputdata: toxSampleInput!
                            $wh: [toxSampleConditionAND]!
                        ) {
                            toxSampleUpdate(data: $inputdata, where: $wh) {
                                ship
                            }
                        }
                    `,
                    variables: {
                        inputdata: { ship: 2 },
                        wh: [{ id: { eq: this.deleteItem } }]
                    }
                })
                .then(() => {
                    this.deleteDialog = false;
                });
            this.$delete(
                this.samples,
                this.samples.findIndex(obj => obj.id == this.deleteItem)
            );
        },
        rmFromShipping(sample_id) {
            this.deleteDialog = true;
            this.deleteItem = sample_id;
        },
        rmFromBox(sample_id) {
            if (this.boxID > 0) {
                // Unset ship flag
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation($inputdata: [toxSampleSave]!) {
                                toxSampleSave(data: $inputdata) {
                                    id
                                    ship
                                }
                            }
                        `,
                        variables: {
                            inputdata: [{ id: sample_id, ship: 0 }]
                        }
                    })
                    .then(() => {
                        this.$delete(
                            this.samples,
                            this.samples.findIndex(obj => obj.id == sample_id)
                        );
                    });
            } else {
                this.$delete(
                    this.samples,
                    this.samples.findIndex(obj => obj.id == sample_id)
                );
            }
        },
        print() {
            window.print();
        }
    },
    computed: {
        full: function() {
            return 100 * (this.samples.length / this.capacity);
        },
        fullColor: function() {
            if (this.full == 100) return "red lighten-2";
            else if ((this.full < 100) & (this.full > 60))
                return "orange lighten-2";
            else return "green lighten-2";
        },
        sampleIDs: function() {
            return this.samples.map(obj => obj.id);
        },
        carriers: function() {
            return this.ShippingSettings.map(obj => obj.carrier)
        }
    },
    watch: {
        contentsDialog: function(newVal, oldVal) {
            if (oldVal === true && newVal === false) {
                this.boxID = 0;
                this.$emit("recalc-boxes");
            }
        },
        contents: function(newVal) {
            this.samples = newVal;
        },
        carriers: function(newVal) {
            if(newVal?.length == 1) this.carrier = newVal[0];
        }
    },
    apollo: {
        destinationInfo: {
            update: data => data.Facility,
            query() {
                return gql`query($id: ID!) {
                    Facility(id: $id) {
                        id
                        name
                        address1
                        address2
                        city
                        state
                        zip
                    }
                }`;
            },
            variables() {
                return {id: this.destination}
            }
        },
        ShippingSettings: {
            update: data => data.ShippingSettings,
            query() {
                return gql`query($wh: [ShippingSettingConditionAND]) {
                    ShippingSettings(where: $wh) {
                        carrier
                    }
                }`;
            },
             variables() {
                return {wh: [{facility_id: {eq: this.$store.state.user.payload['l']}}, {deleted:{is:null}}]}
            }
        }
    },
};
</script>