<template>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition">
        <template v-slot:activator="{on}">
            <v-text-field
                v-bind="$attrs"
                v-model="inputDate"
                prepend-inner-icon="mdi-calendar"
                v-on="menuOn(on)"
                v-mask="'##/##/####'"
                :readonly="isTouchScreen"
                :error-messages="errors"
            >
                <template v-if="mandatory!==undefined && mandatory!==false" v-slot:label>
                    <span style="color:red">* </span>{{$attrs.label}}
                </template>
            </v-text-field>
        </template>
        <v-date-picker ref="picker" v-model="pickerDate" :max="new Date().toISOString().substr(0, 10)" @input="menu = false" @change="save"></v-date-picker>
    </v-menu>
</template>
<script>
import isTouchScreen from "../plugins/detectTouchScreen";
import {mask} from 'vue-the-mask'
export default {
    inheritAttrs: false,
    props: [
        "value",
        "mandatory",
    ],
    directives: {
        mask
    },
    data() {
        return {
            menu: false,
            pickerDate: null,
            inputDate: null,
            jsDate: null,
            errors: [],
            isTouchScreen: isTouchScreen(),
            options: {year:"numeric",month:"2-digit", day:"2-digit"}
        };
    },
    methods: {
        menuOn(on) {
            if (isTouchScreen()) return on;
            else {
                return {
                    "click:prepend-inner": on.click
                };
            }
        },
        toISO(dt) {
            return (dt.getFullYear() + "-" + String(dt.getMonth() + 1).padStart(2, "0") + "-" + String(dt.getDate()).padStart(2, "0"));
        },
        dateToLocaleString(dt) {
            return String(dt.getMonth() + 1).padStart(2, "0") + "/" + String(dt.getDate()).padStart(2, "0") + "/" +  dt.getFullYear();
        },
        save(date) {
            this.$refs.menu.save(date);
        },
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        value: {
            handler(n) {
                if(n) {
                    const parts = n.split(/[- :]/);
                    const dt = new Date(parts[0], parts[1] - 1, parts[2]);
                    const td = new Date();
                    const age = Math.round(((td.getTime() - dt.getTime())/(1000*3600*24))/365.25);
                    if(dt instanceof Date && !isNaN(dt)) {
                        if(this.jsDate === null || dt.getTime() != this.jsDate.getTime()) {
                            if(age > 120 || dt.getTime() > td.getTime()) {
                                this.errors = ["Invalid Date"];
                                
                                this.jsDate = null;
                                setTimeout(() => {this.inputDate = null; this.pickerDate = null;}, 1600);
                            }
                            else {
                                this.jsDate = dt;
                                //this.inputDate = dt.toLocaleDateString("en-US",this.options);
                                this.inputDate = this.dateToLocaleString(dt);
                                this.pickerDate = this.toISO(dt);
                            }
                        }
                    }
                } else {
                    if(this.jsDate != null) {
                        this.jsDate = null;
                        this.inputDate = null;
                        this.pickerDate = null;
                    }
                }
            },
            immediate: true
        },
        pickerDate(n) {
            if(n) {
                const parts = n.split(/[- :]/);
                const dt = new Date(parts[0], parts[1] - 1, parts[2]);
                const td = new Date();
                const age = Math.round(((td.getTime() - dt.getTime())/(1000*3600*24))/365.25);
                if(dt instanceof Date && !isNaN(dt)) {
                    if(this.jsDate === null || dt.getTime() != this.jsDate.getTime()) {
                        if(age > 120 || dt.getTime() > td.getTime()) {
                            this.errors = ["Invalid Date"];

                            this.jsDate = null;
                            setTimeout(() => {this.inputDate = null; this.pickerDate = null;}, 1600);
                         }
                        else {
                            this.jsDate = dt;
                            //this.inputDate = dt.toLocaleDateString("en-US",this.options);
                            this.inputDate = this.dateToLocaleString(dt);

                            this.$emit("input", this.toISO(dt));
                        }
                    }
                }
            } else {
                if(this.jsDate != null) {
                    this.jsDate = null;
                    this.inputDate = null;

                    this.$emit("input", null);
                }
            }
        },
        inputDate(n) {
            if(n) {
                const parts = n.split("/");
                if(parts[0] <= 12 && parts[0] >= 1 && parts[1] <= 31 && parts[1] >= 1 && parts[2] > 999 && parts[2] <= 9999) {
                    const dt = new Date(parts[2], parts[0] - 1, parts[1]);
                    const td = new Date();
                    const age = Math.round(((td.getTime() - dt.getTime())/(1000*3600*24))/365.25);
                    if(dt instanceof Date && !isNaN(dt)) {
                        this.errors = [];
                        if(this.jsDate === null || dt.getTime() != this.jsDate.getTime()) {
                            if(age > 120 || dt.getTime() > td.getTime()) {
                                this.errors = ["Invalid Date"];

                                this.jsDate = null;
                                setTimeout(() => {this.inputDate = null; this.pickerDate = null;}, 1600);
                            }
                            else {
                                this.jsDate = dt;
                                this.pickerDate = this.toISO(dt);

                                this.$emit("input", this.pickerDate);
                            }
                        }
                    } else this.errors = ["Invalid Date"];
                } else this.errors = ["Invalid Date"];
            } else {
                this.errors = [];
                this.jsDate = null;
                this.pickerDate = null;

                this.$emit("input", null);
            }
        }
    }
};
</script>