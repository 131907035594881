<template>
    <v-container v-if="$store.state.user.components.includes(42)" fluid>
        <v-btn @click="dialog=true" dark :x-large="size=='x-large'" :large="size=='large'" :small="size=='small'" :x-small="size=='x-small'" class="mx-1">
            <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
            <v-icon v-else :color="color" class="mx-2">{{icon}}</v-icon> New Pickup Request
        </v-btn>

        <v-dialog v-model="dialog" v-if="dialog" max-width="35vw" eager>
            <v-card flat class="rounded-0">
                <v-toolbar dark dense color="primary" :elevation="0">
                    <v-toolbar-title>New Pickup Request</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon small @click="dialog=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row dense class="mx-2">
                        <v-col cols="6">
                            <v-select v-model="carrier" :items="carriers" label="Carrier"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field type="number" v-model="packages" label="Number of Packages"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="mx-2">
                        <v-col cols="6">
                            <DatePickerInput v-model="pickupDate" label="Pickup Date"></DatePickerInput>
                        </v-col>
                        <v-col cols="6">
                            <TimePickerInput v-model="readyTime" label="Ready Time"></TimePickerInput>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row dense>
                        <v-col cols="12" class="text-right">
                            <v-btn color="primary" @click="pickupRequest()">Send Request</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import DatePickerInput from "../../components/DatePickerInput.vue";
import TimePickerInput from "../../components/TimePickerInput.vue";

export default {
     props: {
        icon: {
            type: [String],
            default: "mdi-truck",
        },
        color: {
            type: [String],
            default: "lime",
        },
        size: {
            type: [String],
            default: "small",
        }
    },
    components: {
        DatePickerInput,
        TimePickerInput,
    },
    data() {
        return {
            loading: false,
            dialog: false,
            carrier: null,
            carriers: [],
            packages: 1,
            pickupDate: null,
            readyTime: null,
        };
    },
    methods: {
        async pickupRequest() {
            this.loading = true;
            this.dialog = false;

            await this.$apollo
                .mutate({
                    mutation: gql`mutation($origin_id: Int!, $boxes: Int!, $pickupDate: String!, $readyTime: String!) {
                        ${this.carrier}PickupRequest(origin_id: $origin_id, boxes: $boxes, pickupDate: $pickupDate, readyTime: $readyTime) {
                            Status
                            PRN
                            ErrorCode
                            ErrorMessage
                        }
                    }`,
                    variables: {
                        origin_id: this.$store.state.user.payload['l'],
                        boxes: this.packages,
                        pickupDate: this.pickupDate,
                        readyTime: this.readyTime,
                    },
                })
                .then((res) => {
                    if (res.data[this.carrier + "PickupRequest"].PRN != null) {
                        alert("Pickup request status: " + res.data[this.carrier + "PickupRequest"].Status + "\r\nPickup Reference Number: " + res.data[this.carrier + "PickupRequest"].PRN);
                    } else {
                        alert("Pickup request status: " + res.data[this.carrier + "PickupRequest"].Status + "\r\nError Message: " + res.data[this.carrier + "PickupRequest"].ErrorMessage);
                    }
                }).finally(() => {
                    this.loading = false;
                    //this.dialog = false;
                    this.$emit('pickup-requested');
                });
        },
    },
    mounted() {
        this.pickupDate = new Date().toISOString().split('T')[0];
        this.readyTime = new Date().toTimeString().split(" ")[0].slice(0,5);

        this.$apollo.query({
            query: gql`query ($where: [ShippingSettingConditionAND]) {
                ShippingSettings(where: $where) {
                    carrier
                }
            }`,
            variables: {
                where: [{facility_id: {eq: this.$store.state.user.payload['l']}}],
            }
        }).then(res => {
            this.carriers = res.data.ShippingSettings.map(item => {
                return item.carrier;
            });
        })
    },
    watch: {
        carriers: function(newVal) {
            if(newVal?.length == 1) this.carrier = newVal[0];
        },
    }
};
</script>
