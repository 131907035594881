<template>
    <div class="overlay">
        <v-card flat>
            <div class="blue" style="margin-top:-4em;">
                <v-img src="../assets/Logo-07.png" max-width="300" class="mx-auto mt-3 mt-sm-12" />
            </div>
            <v-card-subtitle class="pa-4">You have left this browser idle for 15 minutes. Please login:</v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-alert text type="info" v-if="info">{{info}}</v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-form autocomplete="off" v-model="valid" ref="reloginForm">
                            <v-text-field
                                v-if="!auth2"
                                outlined
                                type="password"
                                label="Password"
                                placeholder=" "
                                name="password"
                                autocomplete="off"
                                v-model="password"
                                :disabled="loading"
                                ref="password"
                                :rules="[passRequired]"
                                @keydown.enter="password && login()"
                                dense
                                class="detectAutoFill"
                            ></v-text-field>
                            <v-text-field
                                v-if="auth2"
                                outlined
                                label="Passcode"
                                maxlength="6"
                                v-model="passcode"
                                dense
                                type="tel"
                                v-mask="'######'"
                                :rules="[passCodeRequired]"
                            ></v-text-field>
                        </v-form>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn plain block v-on:click="login()" :disabled="!valid || loading" color="#a0bff8">Login</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn outlined block v-on:click="logout()" color="#a0bff8">Logout</v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="loading">
                    <v-col>
                        <v-progress-linear indeterminate></v-progress-linear>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <captcha
            action="login"
            sitekey="6LdC26kZAAAAACW3DgK_DN9rqyywZWTjJpUxKxg1"
            @reCAPTCHA3="getToken"
        />
    </div>
</template>

<script>
import gql from "graphql-tag";
import captcha from "../components/reCAPTCHAv3.vue";

export default {
    components: {
        captcha,
    },
    data() {
        return {
            error: "Your IP was blocked because of too many wrong attempts",
            info: null,
            password: null,
            captcha: null,
            loading: false,
            invalidLogins: 0,
            valid: false,
            auth2: false,
            passRequired: function (val) {
                return !!val || "This is a required field!";
            },
        }
    },
    methods: {
        getToken(t) {
            this.captcha = t;
        },
        login() {
            this.error = null;
            this.info = null;
            this.loading = true;
            let location = null;
            let locations = null;
            if (typeof localStorage !== "undefined") {
                let l = localStorage.getItem("location");
                let ls = localStorage.getItem("locations");
                try {
                    if (l != "undefined") location = JSON.parse(l);
                    if (ls != "undefined") locations = JSON.parse(ls);
                } catch (e) {
                    console.log(e);
                }
            }

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation(
                            $username: String!
                            $password: String!
                            $captcha: String!
                            $passcode: String
                            $location: ID
                            $locations: [ID]
                        ) {
                            Authorize(
                                username: $username
                                password: $password
                                captcha: $captcha
                                passcode: $passcode
                                location: $location
                                locations: $locations
                            ) {
                                token
                                auth2
                                components
                            }
                        }
                    `,
                    variables: {
                        username: this.$store.state.user.payload.username,
                        password: this.password,
                        captcha: this.captcha,
                        passcode: this.passcode,
                        location: location,
                        locations: locations,
                    },
                })
                .then((res) => {
                    if (res.data.Authorize.token) {
                        this.password = null;
                        this.passcode = null;

                        this.$store.dispatch("logIn", res.data.Authorize);
                        //let gotoPage = localStorage.getItem("last-page");
                        //this.$router.push(gotoPage || "/");
                        let query = Object.assign({}, this.$route.query);
                        delete query.password;
                        this.$router.query = query;
                    }
                    else if (res.data.Authorize.auth2.r) {
                        this.auth2 = true;
                        this.info = res.data.Authorize.auth2.t;
                    }
                    else {
                        this.error = "Wrong password or captcha error!";
                        this.invalidLogins++;
                        this.password = null;
                        this.passcode = null;
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.$refs.reloginForm.reset();
                });
        },
        // Log the user out
        logout() {
            this.$store.dispatch("logOut");
        },
    },
    computed: {
        passCodeRequired() {
            if (this.auth2) {
                return (val) =>
                    (val || "").length == 6 || "A 6-digit code is required!";
            } else return true;
        },
    },
}
</script>

<style scoped>
    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgb(227 227 227);
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>