<template>
    <v-card v-if="this.$store.state.user.components.includes(51)">
        <v-tabs vertical>
            <v-tab v-if="this.$store.state.user.components.includes(68)">
                <v-icon left>mdi-flask-outline</v-icon>Lab Settings
                <v-spacer></v-spacer>
            </v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(69)">
                <v-icon left>mdi-gate-xnor</v-icon>Reflex+ Rules
                <v-spacer></v-spacer>
            </v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(70)">
                <v-icon left>mdi-format-list-checks</v-icon>Custom Fields
                <v-spacer></v-spacer>
            </v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(66)">
                <v-icon left>mdi-truck</v-icon>Shipping
            </v-tab>

            <v-tab-item v-if="this.$store.state.user.components.includes(68)">
                <v-container>
                    <v-tabs>
                        <v-tab v-if="this.$store.state.user.components.includes(71)">Lab Panels</v-tab>
                        <v-tab v-if="this.$store.state.user.components.includes(72)">Prefered Lab</v-tab>
                        <v-tab v-if="this.$store.state.user.components.includes(92)">Collect Payments</v-tab>

                        <v-tab-item v-if="this.$store.state.user.components.includes(71)">
                            <tpanels :facility_id="facility_id" :facility="facility" />
                        </v-tab-item>

                        <v-tab-item v-if="this.$store.state.user.components.includes(72)">
                            <plab :facility_id="facility_id" :facility="facility" />
                        </v-tab-item>

                        <v-tab-item v-if="this.$store.state.user.components.includes(92)">
                            <tpay :facility_id="facility_id" />
                        </v-tab-item>
                    </v-tabs>
                </v-container>
            </v-tab-item>

            <v-tab-item v-if="this.$store.state.user.components.includes(69)">
                <reflex :facility_id="facility_id"></reflex>
            </v-tab-item>

            <v-tab-item v-if="this.$store.state.user.components.includes(70)">
                <customFields :facility_id="facility_id"></customFields>
            </v-tab-item>

            <v-tab-item v-if="this.$store.state.user.components.includes(66)">
                <shipping :facility_id="facility_id" />
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
import reflex from "../../views/facility/settings/reflex.vue";
import customFields from "../../views/facility/settings/customFields.vue";
import plab from "./settings/preferedLab.vue";
import tpanels from "./settings/toxPanels.vue";
import tpay from "./settings/toxPayments.vue"
import shipping from "./settings/sender/shipping.vue"
import gql from "graphql-tag";

export default {
    props: ["facility_id", "facility"],
    components: {
        reflex,
        customFields,
        plab,
        tpanels,
        tpay,
        shipping,
    },
    data() {
        return {
            facilities: [],
            prefered_lab: null
        };
    },
    methods: {
        getLabs() {
            this.$apollo
                .query({
                    query: gql`
                        {
                            Facilitys(
                                where: [
                                    { deleted: { is: null } }
                                    { role: { eq: "Receiver" } }
                                ]
                            ) {
                                id
                                name
                            }
                        }
                    `,
                    fetchMode: "network-only"
                })
                .then(res => {
                    if (res.data.Facilitys instanceof Array) {
                        this.facilities = res.data.Facilitys;

                        //this.prefered_lab = this.facility.pref_lab_id;
                    }
                });
        },
        savePrefLab() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($data: [FacilitySave]!) {
                            FacilitysSave(data: $data) {
                                id
                                pref_lab_id
                            }
                        }
                    `,
                    variables: {
                        data: [
                            {
                                id: this.facility.id,
                                pref_lab_id: this.prefered_lab
                            }
                        ]
                    }
                })
                .then(res => {
                    if (res.data.FacilitysSave instanceof Array) {
                        this.pref_lab_id = res.data.FacilitysSave.pref_lab_id;
                    }
                });
        }
    },
    watch: {
        facility: {
            handler(n) {
                this.prefered_lab = n.pref_lab_id;
            },
            immediate: true
        },
        prefered_lab(n) {
            if (n != this.facility.pref_lab_id) {
                this.savePrefLab();
            }
        }
    },
    mounted() {
        this.getLabs();
    },
}
</script>