<template>
     <v-card
        style="max-width: 860px;"
        class="mx-auto mt-12"
        v-resize="resized"
        :elevation="4"
    >
        <v-card-text class="pa-0 overflow-hidden">
            <v-row>
            <v-col
                class="order-last order-sm-1 order-md-1 pt-12 pb-12 pr-0 order-lg-0 order-xl-0 white"
                cols="12"
                md="12"
                lg="6"
            >
                <div class="pa-3 text-center">
                    <h2 class="mb-8">Recover Your Password</h2>

                    <v-form v-model="valid" v-show="form">
                        <h3 class="text-left mb-2 ml-10">Enter your username:</h3>
                        <v-text-field
                            solo
                            label="Username"
                            v-model="username"
                            :disabled="loading"
                            class="ml-10 mr-10"
                            :rules="[required]"
                        ></v-text-field>
                        <h3 v-if="question" class="text-left mb-2 ml-10">{{question}}</h3>
                        <v-text-field
                            v-show="!!question"
                            solo
                            label="Security Answer"
                            class="ml-10 mr-10"
                            v-model="answer"
                            :disabled="loading"
                            :rules="[required]"
                            ></v-text-field>
                        <v-row justify="center" class="mt-4">
                            <v-btn color="#a0bff8" width="33%" @click="next()">Proceed</v-btn>
                        </v-row>
                        <br/>
                        
                        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                        <i class="material-icons-new sharp-assessment"></i>
                    </v-form>
                    <v-alert :type="alertType" v-if="message">{{message}}</v-alert>
                </div>
            </v-col>
            <v-col
                class="login-welcome order-first order-sm-0 order-md-0 pt-12 pb-12 order-lg-1 order-xl-1"
                cols="12"
                md="12"
                lg="6"
            >
                <div class="welcome-container">
                    <h3 class="text-center white--text">Welcome to</h3>
                    <img
                        src="../assets/Logo-07.png"
                        height="220"
                        alt="Lab Logo"
                        class="d-block mx-auto mt-12"
                    />
                </div>
            </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
export default {
    data() {
        return {
            landscape: true,
            username: null,
            valid: false,
            alertType: "error",
            message: null,
            loading: false,
            question: null,
            answer: null,
            form: true,
            required: function(val) {
                return !!val || "This is a required field!";
            },
        };
    },
    methods: {
        resized() {
            this.landscape = window.innerWidth > window.innerHeight;
        },
        next() {
            this.error = null;
            this.loading = true;
            
            this.$apollo.mutate({
                mutation: gql`mutation($username: String!, $answer: String) {
                    ForgotPassword(username: $username, answer: $answer) {
                        Status
                        Message
                        Question
                    }
                }`,
                variables: {
                    username: this.username,
                    answer: this.answer,
                }
            }).then(res => {
                this.message = res.data.ForgotPassword.Message;
                this.question = res.data.ForgotPassword.Question;

                if(res.data.ForgotPassword.Status == "Fail") {
                    this.alertType = "error";
                    this.username = null;
                    this.question = null;
                    this.answer = null;
                }
                else if(res.data.ForgotPassword.Status == "Success") {
                    this.alertType = "success";
                    this.form = false;
                }
            }).finally(() => {
                this.loading = false;
            });
        },
    },
    mounted() {
        
    }
};
</script>

<style scoped>
.login-welcome {
    background-image: url("../assets/login_bar_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
</style>