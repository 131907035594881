<template>
    <v-container v-if="$store.state.user.components.includes(17)">
        <v-card>
            <v-container class="pt-2">
                <v-btn small color @click="addDialog=true">
                    <v-icon>mdi-plus</v-icon>Add Item
                </v-btn>
            </v-container>
            <crud
                ref="patients"
                :headers="headers"
                model="Patient"
                label="Patients"
                :multi-sort="true"
                :initialValues="{status:'Active'}"
                :canAdd="false"
            >
                <template v-slot:filters="{conditions, setConditions}">
                    Filters
                    <v-btn
                        v-on:click="toggleConditions(conditions, setConditions);"
                    >{{conditions.length == 0 ? 'Hide Deleted' : 'Show ALL', }}</v-btn>
                </template>
                <template v-slot:item.btn="obj">
                    <v-btn color="primary" :to="'/patients/dashboard/'+obj.item.id">Dashboard</v-btn>
                </template>
            </crud>

            <v-dialog v-model="addDialog" max-width="80vw">
                <v-card>
                    <v-toolbar dense :elevation="0">
                        <v-toolbar-title>New Record</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="8">
                                <v-row dense justify="center">
                                    <v-col cols="4">
                                        <v-text-field
                                            v-model="patient.first_name"
                                            label="First Name"
                                            :rules="[fieldRequired]"
                                        >
                                        <template v-slot:label>
                                            <span class="red--text">*</span> First Name
                                        </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            v-model="patient.middle_name"
                                            label="Middle Name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            v-model="patient.last_name"
                                            label="Last Name"
                                            :rules="[fieldRequired]"
                                        >
                                        <template v-slot:label>
                                            <span class="red--text"> *</span> Last Name
                                        </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row densejustify="center">
                                    <v-col cols="3">
                                        <v-select
                                            v-model="patient.gender"
                                            label="Gender"
                                            :items="['Male', 'Female', 'Other']"
                                            :rules="[fieldRequired]"
                                        >
                                        <template v-slot:label>
                                            <span class="red--text"> *</span> Gender
                                        </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="3">
                                        <dob
                                            label="Date of Birth"
                                            v-model="patient.dob"
                                            :mandatory="true"
                                            show-current
                                            hide-details="auto"
                                            :rules="[fieldRequired]"
                                        />
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="patient.address1"
                                            label="Address 1"
                                            :rules="[fieldRequired]"
                                        >
                                        <template v-slot:label>
                                            <span class="red--text"> *</span> Address 1
                                        </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="patient.address2" label="Address 2"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row densejustify="center">
                                    <v-col cols="4">
                                        <v-combobox
                                            label="ZIP Code"
                                            :rules="[fieldRequired]"
                                            :items="zItems"
                                            :loading="zLoading"
                                            :search-input.sync="zSearch"
                                            item-value="zip"
                                            item-text="zip"
                                            v-model="patient.zip"
                                            no-filter
                                            @change="zChange"
                                        >
                                            <template v-slot:label>
                                                <span class="red--text"> *</span> ZIP Code
                                            </template>
                                            <template v-slot:item="{item}">
                                                <v-list-item-content>
                                                    <v-list-item-title><b>{{item.zip}}</b></v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        City:&nbsp;
                                                        <b>{{item.city}}</b>
                                                        State:&nbsp;
                                                        <b>{{item.state}}</b>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-combobox
                                            label="City"
                                            :rules="[fieldRequired]"
                                            :items="cItems"
                                            :loading="cLoading"
                                            :search-input.sync="cSearch"
                                            item-value="id"
                                            item-text="city"
                                            v-model="patient.city"
                                            @change="cChange"
                                            no-filter
                                        >
                                            <template v-slot:label>
                                                <span class="red--text"> *</span> City
                                            </template>
                                            <template v-slot:item="{item}">
                                                <v-list-item-content>
                                                    <v-list-item-title><b>{{item.city}}</b></v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        State:&nbsp;
                                                        <b>{{item.state}}</b>
                                                        Zip:&nbsp;
                                                        <b>{{item.zip}}</b>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select
                                            v-model="patient.state"
                                            label="State"
                                            :items="this.$store.state.usStatesAbrv"
                                            :rules="[fieldRequired]"
                                        >
                                        <template v-slot:label>
                                            <span class="red--text"> *</span> State
                                        </template>
                                        </v-select>
                                    </v-col>
                                </v-row>

                                <v-row dense justify="center">
                                    <v-col cols="3">
                                        <v-text-field
                                            label="Phone"
                                            v-model="patient.phone"
                                            v-mask="'(###) ###-####'"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            label="Mobile"
                                            v-model="patient.mobile"
                                            v-mask="'(###) ###-####'"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field label="Email" v-model="patient.email"></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-radio-group v-model="patient.status" label="Status">
                                            <v-radio value="Active" label="Active"></v-radio>
                                            <v-radio value="Inactive" label="Inactive"></v-radio>
                                            <template v-slot:label>
                                                <span class="red--text"> *</span> Status
                                            </template>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="4">
                                <v-card-text>
                                    <h3 class="text-center">Capture ID / Driving License</h3>
                                    <v-container class="pa-0">
                                        <v-item-group>
                                            <v-row>
                                                <v-col
                                                    v-for="(item, i) in captured"
                                                    :key="i"
                                                    cols="12"
                                                    md="6"
                                                >
                                                    <v-item v-slot:default="{}">
                                                        <v-img
                                                            :src="'data:image/jpeg;base64,'+item"
                                                            width="120"
                                                            class="text-right"
                                                        ></v-img>
                                                    </v-item>
                                                </v-col>
                                            </v-row>
                                        </v-item-group>
                                    </v-container>

                                    <v-row justify="center" class="mt-3">
                                        <v-btn
                                            color="primary lighten-2"
                                            @click="captureDialog=true"
                                        >Capture Card</v-btn>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions style="display:block;" class="text-end">
                        <v-btn text @click="addDialog=false" color="primary lighten-2">Cancel</v-btn>
                        <v-btn
                            text
                            @click="savePatient()"
                            color="primary lighten-2"
                            :disabled="!valid"
                        >Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="captureDialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <ImageCapture
                    :ocr="true"
                    cardType="Id"
                    @dataExtracted="onDataExtracted"
                    @close-dialog="captureDialog=false"
                />
            </v-dialog>
        </v-card>
    </v-container>
</template>


<script>
import crud from "../components/CRUD";
import dob from "../components/DOB";
import gql from "graphql-tag";
import { mask } from "vue-the-mask";
import ImageCapture from "../components/ImageCapture.vue";

export default {
    components: {
        crud,
        dob,
        ImageCapture,
    },
    directives: {
        mask,
    },
    created() {
        this.headers = [
            {
                text: "ID",
                value: "id",
                editable: false,
                visible: false,
                isId: true,
            },
            {
                text: "First Name",
                value: "first_name",
                mandatory: true,
                searchable: true,
            },
            { text: "Middle Name", value: "middle_name" },
            {
                text: "Last Name",
                value: "last_name",
                mandatory: true,
                searchable: true,
            },
            {
                text: "Gender",
                value: "gender",
                mandatory: true,
                items: ["Male", "Female", "Other"],
                type: "select",
            },
            { text: "DOB", value: "dob", mandatory: true, type: "date" },
            { text: "Address 1", value: "address1", visible: false },
            { text: "Address 2", value: "address2", visible: false },
            { text: "City", value: "city", visible: false },
            {
                text: "State",
                value: "state",
                searchable: true,
                type: "select",
                items: this.$store.state.usStatesAbrv,
            },
            { text: "Zip", value: "zip", visible: false },
            { text: "Email", value: "email", visible: false },
            {
                text: "Phone",
                value: "phone",
                visible: false,
                mask: "(###) ###-####",
            },
            {
                text: "Mobile",
                value: "mobile",
                visible: false,
                mask: "(###) ###-####",
            },
            {
                text: "Status",
                value: "status",
                mandatory: true,
                type: "radio",
                items: ["Active", "Inactive"],
                fieldOptions: { column: false },
            },
        ];

        if (this.$store.state.user.components.includes(18))
            this.headers.splice(1, 0, {
                text: "Dashboard",
                value: "btn",
                query: false,
                slot: true,
            });
    },
    data() {
        return {
            currentConditions: null,
            addDialog: false,
            captureDialog: false,
            captured: [],
            patient: {
                first_name: null,
                last_name: null,
                middle_name: null,
                gender: null,
                dob: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                zip: null,
                email: null,
                phone: null,
                status: "Active",
            },
            zItems: [],
            cItems: [],
            zLoading: false,
            cLoading: false,
            zSearch: null,
            cSearch: null,
            valid: false,
            fieldRequired: function (val) {
                return !!val || "This is a required field!";
            },
            headers: [],
        };
    },
    methods: {
        toggleConditions(current, func) {
            if (current.length > 0) {
                this.currentConditions = current;
                func([]);
            } else func(this.currentConditions);
        },
        onDataExtracted(OCRData, cardFront, cardBack) {
            this.captureDialog = false;

            if (cardFront != null) this.captured.push(cardFront);
            if (cardBack != null) this.captured.push(cardBack);

            //var app = this;
            if (OCRData.ErrorCode == null) {
                if (OCRData.FirstName)
                    this.patient.first_name = OCRData.FirstName;
                if (OCRData.MiddleName)
                    this.patient.middle_name = OCRData.MiddleName;
                if (OCRData.Surname) this.patient.last_name = OCRData.Surname;
                if (OCRData.Sex) this.patient.gender = OCRData.Sex;
                if (OCRData.BirthDate) this.patient.dob = OCRData.BirthDate;
                if (OCRData.AddressLine1)
                    this.patient.address1 = OCRData.AddressLine1;
                if (OCRData.AddressLine2)
                    this.patient.address2 = OCRData.AddressLine2;
                if (OCRData.AddressCity)
                    this.patient.city = OCRData.AddressCity;
                if (OCRData.AddressState)
                    this.patient.state = OCRData.AddressState;
                if (OCRData.AddressPostalCode)
                    this.patient.zip = OCRData.AddressPostalCode;
            } else {
                alert(
                    "Error Code: " +
                        OCRData.ErrorCode +
                        "\r\nError Description: " +
                        OCRData.ErrorDescription +
                        "\r\nContext: " +
                        OCRData.Context
                );
            }
        },
        uploadCapturedImages(record_id) {
            for (var i = 0; i < this.captured.length; i++) {
                let formData = new FormData();
                formData.append(
                    "file",
                    this.b64ToBlob(this.captured[i]),
                    "card-" + i + ".jpg"
                );

                this.axios
                    .post(
                        "https://lab-synapse.com/gql/storage/?type=id&ownerType=patients&ownerID=" +
                            record_id,
                        formData
                    )
                    .then((res) => {
                        if (res.data.status == "uploaded") {
                            this.capured[i] = null;
                        } else alert("Uploading failed!");
                    });
            }
        },
        b64ToBlob(data) {
            var byteString = atob(data);
            var u8arr = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                u8arr[i] = byteString.charCodeAt(i);
            }
            return new Blob([u8arr], { type: "image/jpeg" });
        },
        savePatient() {
            var app = this;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($data: [PatientSave]!) {
                            PatientsSave(data: $data) {
                                id
                                first_name
                                last_name
                                middle_name
                                gender
                                dob
                                address1
                                address2
                                city
                                state
                                zip
                                phone
                                mobile
                                email
                                status
                            }
                        }
                    `,
                    variables: {
                        data: [this.patient],
                    },
                })
                .then((res) => {
                    if (res.data.PatientsSave[0].id > 0) {
                        app.uploadCapturedImages(res.data.PatientsSave[0].id);
                        app.patient = {
                            first_name: null,
                            last_name: null,
                            middle_name: null,
                            gender: null,
                            dob: null,
                            address1: null,
                            address2: null,
                            city: null,
                            state: null,
                            zip: null,
                            email: null,
                            phone: null,
                            status: "Active",
                        };
                        app.captured.length = 0;
                        app.addDialog = false;
                        app.$refs.patients.$apollo.queries.DataPG.refetch();
                    }
                });
        },
        findCity(val) {
            this.cLoading = true;
            this.$apollo
                .query({
                    query: gql`{
                        ZipCodes(where:{city:{like:"${val}%"}},options:{page:1,itemsPerPage:20}) {
                            id
                            zip
                            city
                            state
                        }
                    }`,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.ZipCodes instanceof Array) {
                        this.cItems = res.data.ZipCodes;
                    }
                })
                .finally(() => {
                    this.cLoading = false;
                });
        },
        findZip(val) {
            this.zLoading = true;
            this.$apollo
                .query({
                    query: gql`{
                        ZipCodes(where:{zip:{like:"${val}%"}},options:{page:1,itemsPerPage:20}) {
                            zip
                            city
                            state
                        }
                    }`,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.ZipCodes instanceof Array) {
                        this.zItems = res.data.ZipCodes;
                    }
                })
                .finally(() => {
                    this.zLoading = false;
                });
        },
        cChange(v) {
            if (v instanceof Object) {
                this.patient.zip = v.zip;
                this.patient.state = v.state;
                this.patient.city = v.city;
            }
        },
        zChange(v) {
            if (v instanceof Object) {
                this.patient.city = v.city;
                this.patient.state = v.state;
                this.patient.zip = v.zip;
            }
        },
    },
    watch: {
        cSearch(n) {
            if (n && n.length > 1) {
                let found = this.cItems.filter((v) => v.city == n);
                if (found.length <= 0) this.findCity(n);
            }
        },
        zSearch(n) {
            if (n && n.length > 1) {
                let found = this.zItems.filter((v) => v.zip == n);
                if (found.length <= 0) this.findZip(n);
            }
        },
        patient: {
            handler(n) {
                if (
                    n.first_name != null &&
                    n.last_name != null &&
                    n.dob != null &&
                    n.gender != null &&
                    n.state != null &&
                    n.zip != null &&
                    n.city != null &&
                    (n.address1 != null) & (n.status != null)
                )
                    this.valid = true;
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
</style>