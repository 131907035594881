<template>
    <v-card flat tile>
        <v-card flat tile color="grey lighten-2">
            <v-card-title>Upload New File</v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="4" lg="4" md="6" sm="6">
                        <v-select dense solo v-model="uploadFileType" label="File type" :items="[{text: 'Picture', value:'picture'}, {text: 'ID Card', value: 'id'}, {text:'Driver\'s License', value:'dl'}, {text:'Insurance', value:'insurance'}, {text:'Attachment',value:'attachment'}]">
                        </v-select>
                    </v-col>
                    <v-col cols="8" lg="8" md="6" sm="6">
                        <fileupload class="pa-0" @uploaded="refreshFiles" :disabled="uploadFileType==null" :ownerId="this.$route.params.id" ownerType="patients" placeHolder="Select file" :type="uploadFileType" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card flat tile>
            <crud ref="files" label="Files" model="File" :headers="files" :conditions="[{status:{eq:'OK'}}, {owner_id: {eq: $route.params.id}}, {owner_type:{eq:'patients'}}, {deleted:{is:null}}]" :can-edit="false" :can-add="false" :sort-by="['created']" :sort-desc="true">
                <template v-slot:item.view="obj">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn small class="mx-1" text :href="'/gql/storage/?id='+obj.item.id" target="_blank">
                                <v-icon v-on="on">mdi-file-eye-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>View File</span>
                    </v-tooltip>
                </template>
            </crud>
        </v-card>
    </v-card>
</template>

<script>
import crud from "../../components/CRUD.vue"
import fileupload from "../../components/FileUpload.vue"
export default {
    components: {
        crud,
        fileupload,
    },
    data() {
        return {
            uploadFileType: null,
            files: [
                {value: "id", editable: false, isId: true, visible: false},
                {text: "Type", value: "type", mandatory:true, type:"select", items:[{text: "Picture", value:"picture"}, {text:"Logo", value:"logo"}, {text:"ID", value:"id"}, {text:"Insurance", value:"ins"}, {text:"Attachment",value:"attachment"}]},
                {text: "Name", value: "original_file", searchable:true},
                {text: "Directory", value: "directory", visible:false},
                {text: "Created", value: "created", type:"date"},
                {text: "View", value:"view", query: false, slot:true}
            ],
        }
    },
    methods: {
        refreshFiles() {
            this.$refs.files.$apollo.queries.DataPG.refetch();
        }
    }
}
</script>