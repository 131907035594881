<template>
        <v-card class="pa-3" flat>
            <v-card-text class="pt-1">
                <v-alert v-if="force" text type="error" class="mt-3">Your password has expired and must be changed!</v-alert>
                <v-form v-model="valid">
                    <h2 class="text-center pb-4">Change Password</h2>

                    <v-text-field
                        solo
                        type="password"
                        label="Current Password"
                        placeholder="Current Password"
                        name="password"
                        v-model="password"
                        autocomplete="current-password"
                        class="ml-4 mr-4"
                        :rules="[v => !!v || 'This is a required field!']"
                        dense
                    >
                    </v-text-field>    
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                solo
                                type="password"
                                label="New Password"
                                placeholder="New Password"
                                name="password1"
                                autocomplete="new-password"
                                v-model="password1"
                                class="ml-4 mr-4 mt-10 mb-2"
                                :rules="[r1]"
                                dense
                                v-on="on"
                            >
                                <v-icon slot="append" :color="color1">{{icon1}}</v-icon>
                            </v-text-field>
                        </template>
                        <span>
                            <ul>
                            <li v-if="password1 && password1.length > 14" class="green--text">Password must be at least <b>15 characters</b> in length.</li>
                            <li v-else class="red--text">Password must be at least <b>15 characters</b> in length.</li>
                            <li v-if="compare()" class="green--text">Password must not contain significant portions <br/><b>(3 or more contiguous characters)</b> of your username or full name.</li>
                            <li v-else class="red--text">Password must not contain significant portions <br/><b>(3 or more contiguous characters)</b> of your username or full name.</li>
                            <li>Password will be screened against commonly used weak passwords.</li> 
                            <!--
                            <li>Password must contain characters from <b>at least three</b> of the following<br/>four categories arranged in any order:</li>
                                <ul>
                                    <li v-if="password1 && /[A-Z]{1,}/g.test(password1)" class="green--text">English uppercase characters <b>(A through Z)</b></li>
                                    <li v-else class="red--text">English uppercase characters <b>(A through Z)</b></li>
                                    <li v-if="password1 && /[a-z]{1,}/g.test(password1)" class="green--text">English lowercase characters <b>(a through z)</b></li>
                                    <li v-else class="red--text">English lowercase characters <b>(a through z)</b></li>
                                    <li v-if="password1 && /[0-9]{1,}/g.test(password1)" class="green--text">Digits <b>(0 through 9)</b></li>
                                    <li v-else class="red--text">Digits <b>(0 through 9)</b></li>
                                    <li v-if="password1 && /[\W_]{1,}/g.test(password1)" class="green--text">Non-alphabetic characters: <b>~!@#$%^*&;?.+_</b></li>
                                    <li v-else class="red--text">Non alphabetic characters: <b>~!@#$%^*&;?.+_</b></li>
                                </ul>
                            -->
                            </ul>
                        </span>
                    </v-tooltip>
                    <v-text-field
                        solo
                        type="password"
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        name="password2"
                        v-model="password2"
                        autocomplete="new-password"
                        class="ml-4 mr-4"
                        :rules="[r1]"
                        dense
                    >
                    <v-icon slot="append" :color="color2">{{icon2}}</v-icon>
                    </v-text-field>
                </v-form>

                 <div>
                    <v-row dense>
                        <v-col cols="12" md="12" >
                            <v-row justify="center">
                                <v-btn @click="changePassword()" color="#a0bff8" :disabled="!valid || password1!=password2">Change Password</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <div>
                        <v-alert class="mt-3" :type="alertType" v-if="message">{{message}}</v-alert>
                    </div>
                    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                    <i class="material-icons-new sharp-assessment"></i>
                </div>
            </v-card-text>
        </v-card>
</template>

<script>
import gql from "graphql-tag";

export default {
    props: {
        force: {type: Boolean, default: false},
    },
    data() {
        return {
            id: null,
            first_name: null,
            last_name: null,
            username: null,
            chunks: [],
            loading: false,
            valid: false,
            password: null,
            password1: null,
            password2: null,
            message: null,
            alertType: "error",
            r1: function(v) {
                return (v || "").length > 14 || "Password doesn't meet complexity requirements!";
            },
        }
    },
    methods: {
        compare() {
            let result = true;
            this.chunks.forEach(element => {
                if(this.password1?.toLowerCase().indexOf(element) != -1) result = false;
            });
            return result;
        },
        changePassword() {
            this.message = null;
            this.loading = true;
            
            this.$apollo.mutate({
                mutation: gql`mutation ChangePassword($current: String!, $pass1: String!, $pass2: String!) {
                    ChangePassword(current_password: $current, new_password1: $pass1, new_password2: $pass2) {
                        Status
                        Message
                    }
                }`,
                variables: {
                    current: this.password,
                    pass1: this.password1,
                    pass2: this.password2,
                }
            }).then(res => {
                this.message = res.data.ChangePassword.Message;

                if(res.data.ChangePassword.Status == "Fail") this.alertType = "error";
                else {
                    this.alertType = "success";
                }
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    computed: {
        complexity() {
            let cnt = 0;
            //if(/[0-9]{1,}/g.test(this.password1 || "")) cnt++
            //if(/[a-z]{1,}/g.test(this.password1 || "")) cnt++;
            //if(/[A-Z]{1,}/g.test(this.password1 || "")) cnt++;
            //if(/[\W_]{1,}/g.test(this.password1 || "")) cnt++
            if(this.compare()) cnt += 2;
            if(this.password1?.length >= 15) cnt += 2;

            return cnt;
        },
        color1() {
            return ["error", "success"][Math.floor(this.complexity/4)];
        },
        icon1() {
            if(this.complexity == 0) return null;
            if(this.complexity >= 4) return "mdi-check";
            return "mdi-alert";
        },
        color2() {
            return ["error", "success"][((this.password1 && this.password1 == this.password2) ? 1 : 0)];
        },
        icon2() {
            if(!this.password2) return null;
            if(this.password2 && this.password1 == this.password2) return "mdi-check";
            else return "mdi-alert";
        }
    },
    mounted() {
        this.id = this.$store.state.user.payload.id;

        this.$apollo.query({
            query: gql`query Users($wh: [UserConditionAND]) {
                Users(where: $wh) {
                    first_name,
                    last_name,
                    username
                }
            }`,
            variables: {
                wh: [{id:{eq: this.id}}]
            }
        }).then(res => {
            this.first_name = res.data.Users[0].first_name;
            this.last_name = res.data.Users[0].last_name;
            this.username = res.data.Users[0].username;

            for(let i=0; i<this.first_name.length-3; i++) {
                this.chunks.push(this.first_name.substr(i,3).toLowerCase());
            }
            for(let i=0; i<this.last_name.length-3; i++) {
                this.chunks.push(this.last_name.substr(i,3).toLowerCase());
            }
            for(let i=0; i<this.username.length-3; i++) {
                this.chunks.push(this.username.substr(i,3).toLowerCase());
            }
        })
    }
}
</script>