<template>
    <v-card flat tile>
        <v-card-text>
            <v-row>
                <v-col cols="4">
                    <v-select :items="['Today', 'This Week', 'This Month', 'This Year']" solo dense label="Filter by date" placeholder="Today">
                    </v-select>
                </v-col>
                <v-col cols="4">
                    <v-select :items="['Toxicology', 'Chemistry', 'Genetics']" solo dense label="Report Type" placeholder="Toxicology">
                    </v-select>
                </v-col>
                <v-col cols="4">
                    <v-btn>
                        Filter
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <highcharts class="elevation-6" :options="optionsLine"></highcharts>
                </v-col>
                <v-col cols="12" md="6">
                    <highcharts class="elevation-6" :options="optionsColumn"></highcharts>
                </v-col>
            </v-row>
            <LabReportTable/>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {
             optionsLine: {
                chart: {
                    type: "line"
                },
                title: {
                    text: "Line chart"
                },
                subtitle: {
                    text: "Chart"
                },
                series: [
                    {
                        data: [10, 0, 8, 2, 6, 4, 5, 5],
                        color: "#ba68c8"
                    }
                ]
            },
            optionsColumn: {
                chart: {
                    type: "column"
                },
                title: {
                    text: "Line chart"
                },
                subtitle: {
                    text: "Chart"
                },
                series: [
                    {
                        data: [10, 0, 8, 2, 6, 4, 5, 5],
                        color: "#ba68c8"
                    }
                ]
            }
        }
    },
    methods: {

    }
}
</script>