<template>
    <v-autocomplete
        v-bind="$attrs"
        v-model="selectedItem"
        :items="proposedItems"
        :item-value="itemValue"
        :item-text="itemText"
        :search-input.sync="search"
        :loading="isLoading || loading"
        :no-data-text="noDataText===undefined?'Type in to search':noDataText"
        :append-icon="appendIcon===undefined ?(!search ? 'mdi-magnify' : null):appendIcon"
        :no-filter="noFilter===undefined?true:noFilter"
        v-on="$listeners"
    >
        <template v-if="$attrs.mandatory!==undefined && $attrs.mandatory!==false" v-slot:label>
            {{$attrs.label}}
            <span style="color:red">*</span>
        </template>
    </v-autocomplete>
</template>
<script>
import gql from "graphql-tag";
export default {
    inheritAttrs: false,
    props: {
        value: [String, Number, Array, Object],
        model: { type: String, required: true },

        items: [Array],
        initialItems: [Array],
        itemValue: { type: String, default: "id" },
        itemText: { type: String, default: "name" },

        conditions: Array,
        minSymbols: { type: Number, default: 3 },
        maxResults: { type: Number, default: 20 },

        loading: [Boolean, String],
        noDataText: String,
        appendIcon: String,
        noFilter : [Boolean]
    },
    data() {
        return {
            initialItemsSet: false,
            selectedItem: null,
            isLoading: false,
            search: null,
            proposedItems: [],
            extConditions: [],
        };
    },
    methods: {
        getItems(q) {
            if (this.isLoading) return;

            this.isLoading = true;

            this.$apollo
                .query({
                    query: gql`
                    query($opts:${this.model}Options,$wh:[${this.model}ConditionAND]) {
                        ${this.model}s(where: $wh, options: $opts) {
                            ${this.itemValue}
                            ${this.itemText}
                        }
                    }
                    `,
                    fetchPolicy: "network-only",
                    variables: {
                        wh: this.getCondition(q),
                        opts: { page: 1, itemsPerPage: this.maxResults },
                    },
                })
                .then((res) => {
                    this.proposedItems = res.data[this.model + "s"];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        getCondition(q) {
            let searchCondition = [
                {
                    [this.itemText]: {
                        like: "%" + q + "%",
                    },
                },
            ];
            if (this.extConditions instanceof Array)
                return this.extConditions.concat(searchCondition);
            else return searchCondition;
        },
        getItemByValue(value) {
            let res = null;
            this.proposedItems.forEach((v) => {
                if (v[this.itemValue] == value) res = v[this.itemText];
            });
            return res;
        },
    },
    watch: {
        conditions: {
            handler(n) {
                if (n != this.extConditions) this.extConditions = n;
            },
            immediate: true,
        },
        search(val) {
            if (val) {
                if (val.length >= this.minSymbols) {
                    if (this.selectedItem instanceof Object) {
                        if (
                            val !=
                            this.getItemByValue(
                                this.selectedItem[this.itemValue]
                            )
                        )
                            this.getItems(val);
                    } else {
                        if (val != this.getItemByValue(this.selectedItem))
                            this.getItems(val);
                    }
                }
            } else this.selectedItem = null;
        },
        //selectedItem(n) { // NOT NEEDED WHEN EVENTS ARE EXPOSED VIA v-on="$listeners"
        //if (n != this.value) this.$emit("input", n);
        //},
        value: {
            handler(n) {
                if (n != this.selectedItem) {
                    // Prevents emittion loop-back and clearing items in return object mode
                    if (
                        this.$attrs["return-object"] !== undefined &&
                        this.$attrs["return-object"] !== false
                    ) {
                        if (n instanceof Object) this.proposedItems = [n];
                    }
                    this.selectedItem = n;
                }
            },
            immediate: true,
        },
        items: {
            handler(n) {
                if (n instanceof Array) this.proposedItems = n;
            },
            immediate: true,
        },
        initialItems: {
            handler(n) {
                if (n instanceof Array && !this.initialItemsSet) {
                    this.proposedItems = n;
                    this.initialItemsSet = true;
                }
            },
            immediate: true,
        },
    },
};
</script>