<template>
    <v-container fluid>
        <v-row justify="center" align="start">
            <v-btn color="primary lighten-1" @click="uploadFile()" :disabled="disabled">
                <v-icon>mdi-cloud-upload</v-icon><span class="ml-2"> {{label}}</span>
            </v-btn>
            <input type="file" name="file" style="visibility: hidden;" ref="fileInput" />
        </v-row>
        <v-row v-if="error!==false || uploading" justify="center">
            <v-col cols="12" align-self="center">
                <v-alert v-if="error!==false" type="error" dense class="mx-4">{{error}}</v-alert>
                <v-row align="center" justify="center">
                    <v-progress-circular v-if="uploading" color="primary" indeterminate>Uploading...</v-progress-circular>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: {
        ownerId: { type: String, mandatory: true },
        ownerType: { type: String, mandatory: true },
        type: { type: String, mandatory: true },
        accept: { type: String, default: ".gif,.jpg,.jpeg,.bmp,.svg,.pdf" },
        endPoint: {
            type: String,
            default: "https://lab-synapse.com/gql/storage/"
        },
        placeholder: String,
        label: {type: String, default: "Upload File"},
        disabled: Boolean,
    },
    data() {
        return {
            uploading: false,
            error: false,
            src: undefined,
            upload: this.uploadFile,
            debug: null,
        };
    },
    watch: {
        src: {
            handler(n) {
                if (n) {
                    this.src = n;
                } else {
                    this.src = this.placeholder;
                }
            },
            immediate: true
        },
        placeholder: {
            handler(n) {
                if (!this.src) {
                    this.src = n;
                }
            },
            immediate: true
        }
    },
    methods: {
        uploadFile() {
            this.$refs.fileInput.value = null;
            this.$refs.fileInput.accept = this.accept;
            this.$refs.fileInput.click();

            this.$refs.fileInput.removeEventListener(
                "change",
                this._uploadFile
            );
            this.$refs.fileInput.addEventListener("change", this._uploadFile);
        },
        _uploadFile(e) {
            const file = e.target.files[0];
            if (file && this.type && this.ownerType && this.ownerId) {
                this.uploading = true;
                this.error = false;

                const formData = new FormData();
                formData.append("file", file, file.filename);
                this.axios
                    .post(
                        this.endPoint +
                            "?type=" +
                            this.type +
                            "&ownerType=" +
                            this.ownerType +
                            "&ownerID=" +
                            this.ownerId,
                        formData
                    )
                    .then(res => {
                        if (res.data.status == "uploaded") {
                            this.src =
                                this.endPoint + "?id=" + res.data.fileID;
                            this.$emit("uploaded", this.src);
                        } else this.error = "Uploading failed!";
                    })
                    .catch(res => {
                        this.error = "Uploading failed!";
                        this.debug = res;
                    })
                    .finally(() => {
                        this.uploading = false;
                        e.target.value = null;
                    });
            } else {
                this.error = "Missing Upload Parameter!";
            }
        }
    }
};
</script>