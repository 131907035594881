<template>
    <v-container v-if="this.$store.state.user.components.includes(71)">
        <v-data-table
            :headers="panels_headers"
            :items="remDeleted(panels)"
            :loading="panels_loading"
            :options.sync="panels_options"
            :server-items-length="panels_total_records"
        >
            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-2" @click="editRow(item)">mdi-pencil</v-icon>
                <v-icon @click="delRow(item)">mdi-delete</v-icon>
            </template>

            <template v-slot:item.Map="{ item }">
                <span v-for="(map, mk) in item.Map" :key="mk">
                    {{map.general_test_name}}
                    <span v-if="mk<item.Map.length-1">,</span>
                </span>
            </template>

            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Tox Order Panels</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="mb-2" v-on:click="newItem()">New Item</v-btn>
                </v-toolbar>
            </template>
        </v-data-table>

        <v-dialog v-model="panels_dialog" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="headline">Map for {{panels_form.name}}</span>
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-text-field label="Panel Name" v-model="panels_form.name"></v-text-field>
                        <autocomplete
                            label="Add General Test"
                            model="GeneralTest"
                            item-value="id"
                            item-text="name"
                            return-object
                            clearable
                            v-model="panels_code_to_add"
                        ></autocomplete>
                        <v-row v-for="(map,mk) in remDeleted(panels_form.Map)" :key="mk" dense>
                            <v-col>
                                <b>
                                    <li>{{map.general_test_name}}</li>
                                </b>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn small icon>
                                    <v-icon v-on:click="deleteItem(map)">mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveDialog">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import autocomplete from "../../../components/AutoComplete";

export default {
    data() {
        return {
            panels: [],
            panels_headers: [
                { value: "name", text: "Name" },
                { value: "Map", text: "Map" },
                { text: "Actions", value: "actions", sortable: false }
            ],
            panels_loading: false,
            panels_dialog: false,
            panels_form: { name: null, Map: [] },
            panels_code_to_add: null,
            panels_mode_edit: false,
            panels_options: null,
            panels_type: null,
            panels_total_records: 0
        };
    },
    methods: {
        newItem() {
            let item = { name: null, Map: [] };
            this.panels_form = item;
            this.panels_mode_edit = false;
            this.panels_dialog = true;
        },
        remDeleted(arr) {
            if (arr instanceof Array)
                return arr.filter(v => v["_delete"] != true);
        },
        closeDialog() {
            this.panels_mode_edit = false;
            this.panels_dialog = false;
        },
        saveDialog() {
            this.saveData(this.panels_form, this.panels_mode_edit);
            this.panels_dialog = false;
        },
        deleteItem(item) {
            this.$set(item, "_delete", true);
        },
        editRow(row) {
            this.panels_form = JSON.parse(JSON.stringify(row));
            this.panels_mode_edit = row;
            this.panels_dialog = true;
        },
        delRow(row) {
            this.$set(row, "_delete", true);
            row.Map.forEach(m => {
                this.$set(m, "_delete", true);
            });
            this.saveData(row);
        },
        getPanels() {
            this.panels_loading = true;
            this.$apollo
                .query({
                    query: gql`

                        query($opts:FacilityToxPanelOptions)    {
                            FacilityToxPanelsPG(options:$opts, where: {deleted:{is:null}, facility_id: { eq: ${this.facility_id} }}) {
                                totalRecords
                                data {
                                    id
                                    name
                                    facility_id
                                    Map(where: {deleted:{is:null}}) {
                                        id
                                        general_test_id
                                        general_test_name
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        opts: this.panels_options
                    },
                    fetchPolicy: "network-only"
                })
                .then(res => {
                    if (res.data.FacilityToxPanelsPG instanceof Object) {
                        this.panels = res.data.FacilityToxPanelsPG.data;
                        this.panels_total_records =
                            res.data.FacilityToxPanelsPG.totalRecords;
                        if (
                            res.data.FacilityToxPanelsPG.data.Map instanceof
                            Array
                        ) {
                            res.data.FacilityToxPanelsPG.data.Map.forEach();
                        }
                    }

                    //console.log(this.panels);
                })
                .finally(() => {
                    this.panels_loading = false;
                });
        },
        saveData(data /*,item*/) {
            this.panels_loading = true;

            data.Map.forEach(v => {
                delete v["general_test_name"];
                delete v["__typename"];
            });
            data.facility_id = this.facility_id;
            delete data["__typename"];

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($data: [FacilityToxPanelSave]!) {
                            FacilityToxPanelsSave(data: $data) {
                                id
                            }
                        }
                    `,
                    variables: {
                        data: [data]
                    }
                })
                .then(res => {
                    //console.log(res, item);
                    if (res.data.UserToxPanelsSave instanceof Array) {
                        //item.GeneralTestMap = res.data.generalTestMapsSave.filter(
                        //v => v.deleted === null
                        //);
                    }
                })
                .finally(() => {
                    this.panels_loading = false;
                    this.getPanels();
                });
        }
    },
    watch: {
        panels_code_to_add(n) {
            if (n) {
                this.panels_form.Map.push({
                    general_test_id: n.id,
                    general_test_name: n.name
                });

                setTimeout(() => {
                    this.panels_code_to_add = null;
                }, 10);
            }
        },
        panels_options() {
            this.getPanels();
        }
    },
    components: {
        autocomplete
    },
    mounted() {
        this.getPanels();
    },
    props: ["facility_id", "facility"]
};
</script>