<template>
    <v-container fill-height fluid class="pa-auto">
        <embed v-if="pdf" :src="pdf" width="99%" height="99%" type="application/pdf" />
        <div v-else style="text-align: center;">
            <slot></slot>
        </div>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
export default {
    props: ["value"],
    data() {
        return {
            id: this.value || this.$route.params.ereq_id,
        };
    },
    watch: {
        value(n) {
            if (n) {
                this.pdf = null;
                this.id = n;
            }
        },
    },
    apollo: {
        pdf: {
            query: gql`
                query ($id: ID!) {
                    ToxPdfEreq(id: $id)
                }
            `,
            variables() {
                return { id: this.id };
            },
            fetchPolicy: "network-only",
            update({ ToxPdfEreq }) {
                let pre = "data:application/pdf;base64,";
                return pre + ToxPdfEreq + "#toolbar=0&statusbar=0";
            },
        },
    },
};
</script>