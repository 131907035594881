<template>
    <span>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn @click="voidShipment()" fab dark :x-large="size=='x-large'" :large="size=='large'" :small="size=='small'" :x-small="size=='x-small'" class="mx-1">
                    <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
                    <v-icon v-on="on" v-else :color="color">{{icon}}</v-icon>
                </v-btn>
            </template>
            <span>Void Shipment</span>
        </v-tooltip>
    </span>
</template>

<script>
import gql from "graphql-tag";
export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        carrier: {
          type: [String],
          default: "UPS",  
        },
        tracking: {
            type: [String],
            default: null,
        },
        icon: {
            type: [String],
            default: "mdi-close-circle",
        },
        color: {
            type: [String],
            default: "red lighten-1",
        },
        size: {
            type: [String],
            default: "x-small",
        }
    },
    methods: {
        async voidShipment() {
            if(!this.tracking) return;

            this.loading = true;
            await this.$apollo
                .mutate({
                    mutation: gql`mutation($tracking: String!) {
                        ${this.carrier}VoidRequest(tracking: $trackingNum) {
                            ErrorCode
                            ErrorMessage
                            Description
                        }
                    }`,
                    variables: {
                        trackingNum: this.tracking,
                    },
                })
                .then((res) => {
                    if (res.data[this.carrier + "VoidRequest"].ErrorCode != null)
                        alert("Error Code: " + res.data[this.carrier + "VoidRequest"].ErrorCode + "\r\nError Message: " + res.data[this.carrier + "VoidRequest"].ErrorMessage);
                    else
                        alert("Shipping void result: " + res.data[this.carrier + "VoidRequest"].Description);
                }).finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
