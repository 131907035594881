<template>
    <v-container v-if="this.$store.state.user.components.includes(72)">
        <v-checkbox
            v-for="facility in facilities"
            :key="facility.id"
            :label="facility.name"
            v-model="prefered_lab"
            :value="facility.id"
        ></v-checkbox>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
export default {
    props: ["facility_id", "facility"],
    data() {
        return {
            facilities: [],
            prefered_lab: null
        };
    },
    methods: {
        getLabs() {
            this.$apollo
                .query({
                    query: gql`
                        {
                            Facilitys(
                                where: [
                                    { deleted: { is: null } }
                                    { role: { eq: "Receiver" } }
                                ]
                            ) {
                                id
                                name
                            }
                        }
                    `,
                    fetchMode: "network-only"
                })
                .then(res => {
                    if (res.data.Facilitys instanceof Array) {
                        this.facilities = res.data.Facilitys;

                        //this.prefered_lab = this.facility.pref_lab_id;
                    }
                });
        },
        savePrefLab() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($data: [FacilitySave]!) {
                            FacilitysSave(data: $data) {
                                id
                                pref_lab_id
                            }
                        }
                    `,
                    variables: {
                        data: [
                            {
                                id: this.facility.id,
                                pref_lab_id: this.prefered_lab
                            }
                        ]
                    }
                })
                .then(res => {
                    if (res.data.FacilitysSave instanceof Array) {
                        this.pref_lab_id = res.data.FacilitysSave.pref_lab_id;
                    }
                });
        }
    },
    watch: {
        facility: {
            handler(n) {
                this.prefered_lab = n.pref_lab_id;
            },
            immediate: true
        },
        prefered_lab(n) {
            if (n != this.facility.pref_lab_id) {
                this.savePrefLab();
            }
        }
    },
    mounted() {
        this.getLabs();
    }
};
</script>