<template>
    <v-card :loading="loading">
        <v-card-title>
            SignPad
            <v-spacer></v-spacer>
            <v-btn icon @click="close()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text v-resize="onResize">
            <canvas id="signaturepad" ref="signaturepad" :style="{width,height}" />
        </v-card-text>
        <v-card-actions>
            <v-menu>
                <template v-slot:activator="{on,attrs}">
                    <v-btn :color="penColor" outlined v-on="on" v-bind="attrs">
                        Color
                        <v-icon>mdi-pen</v-icon>
                    </v-btn>
                </template>
                <v-color-picker v-model="penColor"></v-color-picker>
            </v-menu>

            <v-spacer></v-spacer>
            <v-btn outlined color="green" width="100" @click="save()">Save</v-btn>
            <v-btn outlined @click="clear()">Clear</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import SignaturePad from "signature_pad";

export default {
    mounted() {
        this.canvas = this.$refs.signaturepad;

        this.signaturePad = new SignaturePad(this.canvas, {
            penColor: this.penColor,
        });
        setTimeout(() => {
            this.resizeCanvas();
        }, 10);
    },

    props: {
        value: {},
        color: {
            type: String,
            default: "#0000FF",
        },
        type: {
            type: String,
            default: "image/png",
        },
        width: {
            type: [String, Number],
            default: "100%",
        },
        height: {
            type: [String, Number],
            default: "300px",
        },
        loading: [String,Boolean]
    },
    methods: {
        onResize() {
            if (this.canvas) this.resizeCanvas();
        },
        resizeCanvas() {
            var ratio = Math.max(window.devicePixelRatio || 1, 1);
            this.canvas.width = this.canvas.offsetWidth * ratio;
            this.canvas.height = this.canvas.offsetHeight * ratio;
            this.canvas.getContext("2d").scale(ratio, ratio);
            if (this.signaturePad) this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
            if (this.value) this.signaturePad.fromDataURL(this.value);
        },
        close() {
            this.$emit("close");
        },
        clear() {
            this.signaturePad.clear();
            this.$emit("input", null);
        },
        save() {
            //const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if (!this.signaturePad.isEmpty())
                this.$emit("input", this.signaturePad.toDataURL(this.type));
            else this.$emit("input", null);

            this.close();
        },
    },
    data() {
        return {
            penColor: "#0000FF",
            canvas: null,
            signaturePad: null,
        };
    },
    watch: {
        color(n) {
            this.penColor = n;
        },
        penColor(n) {
            this.signaturePad.penColor = n;
        },
        value(n) {
            this.signaturePad.fromDataURL(n);
        },
    },
};
</script>

<style scoped>
#signaturepad {
    border: 1px solid black;
    background-color: white;
}
</style>