<template>
    <span v-if="$store.state.user.components.includes(43)">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn @click="cancelPickup()" fab dark :x-large="size=='x-large'" :large="size=='large'" :small="size=='small'" :x-small="size=='x-small'" class="mx-1">
                    <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
                    <v-icon v-on="on" v-else :color="color">{{icon}}</v-icon>
                </v-btn>
            </template>
            <span>Cancel Pickup</span>
        </v-tooltip>
    </span>
</template>

<script>
import gql from "graphql-tag";
export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        carrier: {
          type: [String],
          default: "UPS",  
        },
        prn: {
            type: [String],
            default: null,
        },
        icon: {
            type: [String],
            default: "mdi-cancel",
        },
        color: {
            type: [String],
            default: "red",
        },
        size: {
            type: [String],
            default: "x-small",
        }
    },
    methods: {
        async cancelPickup() {
            this.loading = true;

            await this.$apollo.mutate({
                mutation: gql`mutation ($prn: String!) {
                    ${this.carrier}PickupCancelRequest(prn: $prn) {
                        Status
                        ErrorCode
                        ErrorMessage
                    }
                }`,
                variables: {
                    prn: this.prn,
                 }
            }).then((res) => {
                if (res.data[this.carrier + "PickupCancelRequest"].Status == "Success")
                    alert("Pickup has been cancelled successfully");
                else
                    alert(res.data[this.carrier + "PickupCancelRequest"].ErrorMessage);
            }).finally(() => {
                 this.loading = false;
            });
        },
    },
};
</script>
