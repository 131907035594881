<template>
    <v-card v-if="this.$store.state.user.components.includes(51)">
        <v-tabs vertical>
            <v-tab v-if="this.$store.state.user.components.includes(54)">
                <v-icon left>mdi-flask-outline</v-icon>Order Codes
                <v-spacer></v-spacer>
            </v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(59)">
                <v-icon left>mdi-pill</v-icon>Medications
                <v-spacer></v-spacer>
            </v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(60)">
                <v-icon left>mdi-bank</v-icon>Insurances
                <v-spacer></v-spacer>
            </v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(61)">
                <v-icon left>mdi-currency-usd</v-icon>CPT Codes
                <v-spacer></v-spacer>
            </v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(66)">
                <v-icon left>mdi-truck</v-icon>Shipping
                <v-spacer></v-spacer>
            </v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(67)">
                <v-icon left>mdi-cart</v-icon>Supply Items
                <v-spacer></v-spacer>
            </v-tab>
            <v-tab v-if="this.$store.state.user.components.includes(89)">
                <v-icon left>mdi-clipboard-edit-outline</v-icon>Ereq Settings
                <v-spacer></v-spacer>
            </v-tab>
            <v-tabs-slider></v-tabs-slider>

            <v-tab-item v-if="this.$store.state.user.components.includes(54)">
                <orderCodes :facility_id="facility_id"></orderCodes>
            </v-tab-item>

            <v-tab-item v-if="this.$store.state.user.components.includes(59)">
                <medications :facility_id="facility_id"></medications>
            </v-tab-item>

            <v-tab-item v-if="this.$store.state.user.components.includes(60)">
                <insurances :facility_id="facility_id"></insurances>
            </v-tab-item>

            <v-tab-item v-if="this.$store.state.user.components.includes(61)">
                <cptCodes :facility_id="facility_id"></cptCodes>
            </v-tab-item>

            <v-tab-item v-if="this.$store.state.user.components.includes(66)">
                <shipping :facility_id="facility_id"></shipping>
            </v-tab-item>

            <v-tab-item v-if="this.$store.state.user.components.includes(67)">
                <supplies :facility_id="facility_id"></supplies>
            </v-tab-item>

            <v-tab-item v-if="this.$store.state.user.components.includes(89)">
                <ereqSettings :facility_id="facility_id"></ereqSettings>
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
import orderCodes from "../../views/facility/settings/orderCodes.vue";
import medications from "../../views/facility/settings/medications.vue";
import insurances from "../../views/facility/settings/insurances.vue";
import cptCodes from "../../views/facility/settings/cptCodes.vue";
import shipping from "./settings/receiver/shipping.vue";
import supplies from "../../views/facility/settings/supplyItems.vue"
import ereqSettings from "../../views/facility/settings/ereqSettings.vue"

export default {
    props: ["facility_id"],
    components: {
        orderCodes,
        medications,
        insurances,
        cptCodes,
        shipping,
        supplies,
        ereqSettings
    }
}
</script>