<template>
    <v-btn @click="printPDF()" icon>
        <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
        <v-tooltip bottom v-esle>
            <template v-slot:activator="{on}">
                <v-icon :color="color" v-on="on">{{icon}}</v-icon>
            </template>
            <span>Print eReq</span>
        </v-tooltip>
    </v-btn>
</template>

<script>
import gql from "graphql-tag";
export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        id: [Number, String],
        icon: {
            type: [String],
            default: "mdi-printer",
        },
        color: {
            type: [String],
            default: "primary",
        },
    },
    methods: {
        async printPDF() {
            if (!this.id) return;
            try {
                this.loading = true;
                let pdf = await this.getPDF(this.id);
                const blob = await fetch(pdf).then((r) => r.blob());

                const dataUrl = window.URL.createObjectURL(blob);

                // Open the window
                const pdfWindow = window.open(dataUrl);

                // Call print on it
                pdfWindow.print();
            } finally {
                this.loading = false;
            }
        },
        async getPDF(id) {
            let res = await this.$apollo.query({
                query: gql`
                    query ($id: ID!) {
                        ToxPdfEreq(id: $id)
                    }
                `,
                variables: {
                    id: id,
                },
                fetchPolicy: "network-only",
            });
            let pre = "data:application/pdf;base64,";
            return pre + res.data.ToxPdfEreq + "#toolbar=0&statusbar=0";
        },
    },
};
</script>
