<template>
    <v-card>
        <v-row dense class="pa-2">
            <v-col cols="12">
                <v-switch inset v-model="collectPayments" label="Collect payments from patients for laboratory services" @change="saveSetting()"></v-switch>
            </v-col>
        </v-row>

        <crud 
            v-if="collectPayments>0"
            :canAdd="false"
            :canDelete="false"
            :headers="headers"
            :conditions="[{'client_facility_id':{'eq': facility_id}},{'deleted':{'is':null}}]"
            label="Test Codes Prices"
            model="ClientOrderCodePrice"
        >
             <template v-slot:[`item.cost`] = "obj">
                {{parseFloat((obj.item.lab_cost || 0) + (obj.item.lab_markup || 0)).toFixed(2)}}
            </template>

            <template v-slot:[`item.price`] = "obj">
                {{parseFloat((obj.item.lab_cost || 0) + (obj.item.lab_markup || 0) + (obj.item.markup || 0)).toFixed(2)}}
            </template>
        </crud>
    </v-card>
</template>

<script>
import gql from "graphql-tag";
import crud from "../../../components/CRUD.vue"

export default {
    props: ["facility_id"],
    components: {
        crud
    },
    data() {
        return {
            headers: [
                {text: "ID", value: "id", isId: true, visible: false, editable: false,},
                {text: "Laboratory", value: "Laboratory.name", model: "Facility", modelId: "lab_facility_id", modelFields: {value: "id", text: "name"}, type: "select", fieldOptions: {"disabled":"disabled"}},
                {text: "Test Code", value: "LabOrderCode.name", model: "LabOrderCode", modelId: "order_code_id", modelFields: {value: "id", text: "name"}, type: "select", fieldOptions: {"disabled":"disabled"}},
                {text: "Lab's Cost", value: "lab_cost", editable: false, visible: false,},
                {text: "Lab's Markup", value: "lab_markup", editable: false, visible: false,},
                {text: "Cost, $", value:  "cost", editable: false, query: false, slot:true},
                {text: "Markup, $", value:  "markup", editable: true},
                {text: "Price, $", value:  "price", editable: false, query: false, slot: true},
            ],
            collectPayments: 0,
            settingId: 0,
        }
    },
    methods: {
        saveSetting() {
            let s = [{'facility_id': this.facility_id, 'type': "CollectPaymentsForLabServices", 'value': this.collectPayments}];

            if(this.settingId > 0) s[0]['id'] = this.settingId;

            this.$apollo.mutate({
                mutation: gql`mutation facilitySettingsSave($data: [facilitySettingSave]!) {
                    facilitySettingsSave(data: $data) {
                        id
                    }
                }`,
                variables: {
                    data: s,
                }
            }).then(res => {
                this.settingId = res.data.facilitySettingsSave[0]['id'];
            });
        }
    },
    mounted(){
        this.$apollo.query({
            query: gql`query facilitySettings($wh: [facilitySettingConditionAND]) {
                facilitySettings(where: $wh) {
                    id
                    value
                }
            }`,
            variables: {
                wh: [{deleted:{is:null}}, {facility_id: {eq: this.facility_id}}, {type: {eq:"CollectPaymentsForLabServices"}}]
            }
        }).then(res => {
            if(res.data.facilitySettings.length > 0) {
                this.collectPayments = res.data.facilitySettings[0].value;
                this.settingId = res.data.facilitySettings[0].id;
            }
        })
    }
}
</script>