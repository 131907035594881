<template>
    <div id="container">
        <label>
            <span id="label_span">{{label}}</span>
            <select id="select">
                
                <option v-for="(opt,ok) in options" :key="ok" value="opt.value">{{opt.label}}</option>
            </select>
        </label>
    </div>
</template>

<script>
export default {
    props:["label", "value", "options"],
    data() {
        return {}
    }
}
</script>

<style scoped>
#container {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    position: relative;
}
#select {
    width:100%;
    height: 35px;
    padding: 15px 5px 5px 5px;
    color: black;
    font-size:larger;
}
#label_span {
    position:absolute;
    color: blue;
    font-size: smaller;
    top: -2px;
    left: 5px;
}
</style>