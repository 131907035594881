<template>
    <v-card v-if="this.$store.state.user.components.includes(53)">
        <v-card-actions class="text-right">
            <fileupload @uploaded="refreshFiles" :ownerId="this.facility_id" ownerType="facilities" label="Upload New File" type="samplePDF" :accept="'.pdf'" />
        </v-card-actions>

        <v-card-title>
            <h3>Sample eReqs / Reports</h3>
        </v-card-title>
        
        <v-divider></v-divider>
        
        <v-row dense>
            <v-col cols="6" sm="6" lg="4" v-for="(file, fk) in files" :key="fk">
                <v-card @click="showFile(file.id)">
                    <iframe width="100%" height="480px" :src="'https://lab-synapse.com/gql/storage/?id='+file.id"></iframe>
                    <v-card-actions class="d-flex flex-row-reverse">
                        <v-btn icon small color="red" @click.stop="delFile(file.id)">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>

                        <v-btn icon small color="primary" @click.stop="showFile(file.id)">
                            <v-icon>mdi-magnify-plus-outline</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <!--
        <v-window v-model="slide" reverse>
            <v-window-item v-for="(file, i) in files" :key="i">
                <v-card>
                    <iframe width="100%" height="640px" :src="'/gql/storage/?id='+file.id"></iframe>
                </v-card>
                <v-card-actions class="justify-space-between">
                    <v-btn text @click="prev">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                <v-item-group v-model="slide" class="text-center" mandatory>
                    <v-item v-for="n in files.length" :key="`btn-${n}`" v-slot="{ active, toggle }">
                        <v-btn :input-value="active" icon @click="toggle"><v-icon>mdi-record</v-icon></v-btn>
                    </v-item>
                </v-item-group>
                <v-btn text @click="next">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                </v-card-actions>
            </v-window-item> 
        </v-window>
        -->
    </v-card>
</template>

<script>
import fileupload from "../../components/FileUpload.vue"
import gql from "graphql-tag"

export default {
    props: ["facility_id"],
    components: {
        fileupload
    },
    data() {
        return {
            dialog: false,
            files: [],
        }
    },
    methods: {
        refreshFiles() {
            this.$apollo.queries.files.refetch();
        },
        showFile(file_id) {
            window.open("https://lab-synapse.com/gql/storage/?id="+file_id, "_blank");
        },
        delFile(file_id) {
            this.$apollo.mutate({
                mutation: gql`mutation($wh: [FileConditionAND]!) {
                    FileDelete(where: $wh) {
                        id
                    }
                }`,
                variables: {
                    wh: [{id: {"eq":file_id}}]
                }
            }).
            then(() => {
                this.refreshFiles();
            });
        }
    },
    apollo: {
        files: {
            query() {
                return gql`query Files($wh: [FileConditionAND], $opt: FileOptions) {
                    Files(where: $wh, options: $opt) {
                        id
                        original_file

                    }
                }`
            },
            variables() {
                return {
                    wh: [{status:{eq: "OK"}}, {deleted:{is:null}}, {owner_type: {eq: "facilities"}}, {owner_id: {eq: this.facility_id}}, {type: {eq: "samplePDF"}}],
                }
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data["Files"];
            }
        }
    }
}
</script>