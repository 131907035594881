<template>
    <v-container fluid v-if="$store.state.user.components.includes(18)">
        <v-row>
            <v-col cols="12" md="2">
                <v-card tile white class="elevation-6" dark v-if="$store.state.user.components.includes(27)">
                    <v-container class="text-center">
                        <v-avatar class="mx-auto" size="96">
                            <imageupload
                                v-model="profileImage"
                                :placeholder="require('../../assets/noPicture.jpg')"
                                :owner-id="patient.id"
                                owner-type="patients"
                                type="picture"
                                accept=".gif, .jpg, .jpeg, .bmp, .svg, .png"
                                max-height="164px"
                                :uploadOnClick="true"
                                style="cursor: pointer"
                                @click="upload()"
                            ></imageupload>
                        </v-avatar>
                        <v-card-text>
                            <h3>{{patient.first_name}} {{patient.middle_name}} {{patient.last_name}}</h3>
                            <p>{{patient.gender}}
                            <br />{{patient.dob}}<br />(Age: {{getAge(patient.dob)}})</p>
                        </v-card-text>
                    </v-container>
                </v-card>
                <v-card tile color="grey lighten-2" v-if="$store.state.user.components.includes(28)">
                    <v-card-title class="primary--text text-center">Quick Actions:</v-card-title>
                </v-card>
                <v-card tile v-if="$store.state.user.components.includes(28)">
                    <v-row dense v-if="$store.state.user.components.includes(29)">
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn width="80%" dark small color="primary" v-on="on" :to="'/labtestNewP/'+$route.params.id"><v-icon style="right: 15px">mdi-flask-plus</v-icon> New Lab Test</v-btn>
                                    </template>
                                    <span>New Lab Test</span>
                                </v-tooltip>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!--
                    <v-row dense>
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn width="80%" dark small color="primary" v-on="on" @click="sendSMS=true"><v-icon style="right: 28px">mdi-message-text</v-icon> Send SMS</v-btn>
                                    </template>
                                    <span>Send SMS</span>
                                </v-tooltip>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn dark width="80%" small color="primary" v-on="on" @click="sendMail=true"><v-icon style="right: 25px">mdi-email</v-icon> Send Email</v-btn>
                                    </template>
                                    <span>Send Email</span>
                                </v-tooltip>
                            </v-row>
                        </v-col>
                    </v-row>
                    -->
                    <v-row dense v-if="$store.state.user.components.includes(30)">
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn dark width="80%" small color="primary" v-on="on" @click="newRandom=true"><v-icon >mdi-dice-multiple</v-icon> Random Drug Test</v-btn>
                                    </template>
                                    <span>Random Drug Test</span>
                                </v-tooltip>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="$store.state.user.components.includes(31)">
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn width="80%" small dark color="primary" v-on="on" @click="newReminder=true"><v-icon style="right: 18px">mdi-bell-plus</v-icon> New Reminder</v-btn>
                                    </template>
                                    <span>New Reminder</span>
                                </v-tooltip>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col cols="12" md="10">
                <v-tabs background-color="#f1f1f1" class="elevation-6" light centered grow>
                    <v-tab v-if="$store.state.user.components.includes(19)">Demographics</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(20)">Medications</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(21)">Insurance</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(22)">Diagnoses</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(23)">Allergies</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(24)">Lab Reports</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(25)">Reminders</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(26)">Files</v-tab>
                    
                    <v-tab-item v-if="$store.state.user.components.includes(19)">
                        <demographics />
                    </v-tab-item>

                    <v-tab-item v-if="$store.state.user.components.includes(20)">
                        <medications />
                    </v-tab-item>

                    <v-tab-item v-if="$store.state.user.components.includes(21)">
                        <insurance :patient="patient"></insurance>
                    </v-tab-item>

                    <v-tab-item v-if="$store.state.user.components.includes(22)">
                        <diagnoses />
                    </v-tab-item>

                    <v-tab-item v-if="$store.state.user.components.includes(23)">
                        <allergies />
                    </v-tab-item>

                    <v-tab-item v-if="$store.state.user.components.includes(24)">
                        <labreports />
                    </v-tab-item>

                    <v-tab-item v-if="$store.state.user.components.includes(25)">
                        <reminders />
                    </v-tab-item>

                    <v-tab-item v-if="$store.state.user.components.includes(26)">
                        <files />
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>
        <v-dialog v-model="sendMail" max-width="40vw">
            <v-card fluid>
                <v-toolbar dark dense color="primary" :elevation="0">
                    <v-toolbar-title>
                        <v-icon>mdi-email</v-icon> Send Mail
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon small @click="sendMail=false;">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                     <v-row dense>
                        <v-col cols="12">
                            <v-text-field label="Patient Email" v-model="patient.email" solo dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12">
                            <v-textarea label="Message" rows="5" v-model="email.message" solo dense></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row justify="center" align="center">
                        <v-btn color="primary" @click="sendMail=false">Send</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="sendSMS" max-width="40vw">
            <v-card fluid>
                <v-toolbar dark dense color="primary" :elevation="0">
                    <v-toolbar-title>
                        <v-icon>mdi-message-text</v-icon> Send SMS
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon small @click="sendSMS=false;">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field label="Patient Mobile" v-model="patient.mobile" solo dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12">
                            <v-textarea label="Message" rows="5" v-model="sms.message" solo dense></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row justify="center" align="center">
                        <v-btn color="primary" @click="sendSMS=false">Send</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="newRandom" max-width="40vw">
            <v-card fluid>
                <v-toolbar dark color="primary" dense flat>
                    <v-toolbar-title>
                        <v-icon>mdi-dice-multiple</v-icon> Random Drug Test Outcome
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn right icon small @click="newRandom=false;">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="3">
                            <v-btn text fab x-large :loading="random.loading">
                                <template v-slot:loader>
                                    <span class="custom-loader"><v-icon light color="blue lighten-3" x-large>mdi-dice-multiple</v-icon></span>
                                </template>
                                <v-icon x-large light color="blue lighten-3">mdi-dice-multiple</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="9">
                            <v-card-text v-if="random.outcome" v-show="random.finished">
                                Yes, patient is selected to perform an unexpected drug test!
                                <v-spacer class="pa-2"></v-spacer>
                                <v-btn color="primary" :to="'/labtestNewP/'+$route.params.id">Perform Lab Test</v-btn>
                            </v-card-text>
                            <v-card-text v-else v-show="random.finished">
                                No, patient is not selected to perform an unexpected drug test!
                                <v-spacer class="pa-2"></v-spacer>
                                <v-btn color="primary" @click="newRandom=false">Close</v-btn>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="newReminder" max-width="40vw">
            <v-card fluid>
                <v-toolbar dark dense color="primary" :elevation="0">
                    <v-toolbar-title>
                        New Reminder
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon small @click="newReminder=false;">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                     <v-row dense>
                        <v-col cols="12">
                            <v-textarea label="Message" rows="4" v-model="reminder.text" outlined dense></v-textarea>
                        </v-col>
                     </v-row>
                     <v-row dense>
                        <v-col cols="12">
                            <v-radio-group v-model="reminder.popup" label="Popup:" row>
                                <v-radio label="Yes" value="1"></v-radio>
                                <v-radio label="No" value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row justify="center" align="center">
                        <v-btn color="primary" @click="addReminder()">Save</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar v-for="(item, key) in remindersToShow" :key="key" top v-model="item.popup" timeout="-1">
            {{item.text}}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="item.popup = false">Close</v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>
<script>
import imageupload from "../../components/ImageUpload"
import demographics from "./Demographics.vue"
import insurance from "../../components/PatientInsurance.vue"
import diagnoses from "./Diagnoses.vue"
import allergies from "./Allergies.vue"
import reminders from "./Reminders.vue"
import labreports from "./LabReports.vue"
import files from "./Files.vue"
import medications from "./Medications.vue"
import gql from "graphql-tag"
import {mask} from 'vue-the-mask'
export default {
    components: {
        imageupload,
        demographics,
        insurance,
        diagnoses,
        allergies,
        reminders,
        labreports,
        files,
        medications,
    },
    directives: {
        mask
    },
    data() {
        return {
            medications: [],
            profileImage: null,
            patient: {},
            reminder: {text: null, popup: null},
            remindersToShow: [],
            sendMail: false,
            sendSMS: false,
            newRandom: false,
            newReminder: false,
            random: {loading:true, outcome:null, finished:false},
            sms: {message:null},
            email: {message:null},
        };
    },
    watch :{
        newRandom: function(newVal) {
            if(newVal) {
                setTimeout(() => {
                    let dt = new Date();
                    let dob = new Date(this.patient.dob);
                    this.random.finished = true;
                    this.random.loading = false;
                    this.random.outcome = Boolean((dob.getFullYear() + dob.getMonth() + dob.getDate() + dt.getFullYear() + dt.getDate() + dt.getMonth()) % 2);
                }, 2000)
            }
            else {
                this.random.loading = true;
                this.random.finished = false;
                this.random.outcome = null;
            }
        }
    },
    methods: {
        getAge(dateString) {
            let today = new Date();
            let dob = new Date(dateString);
            let age = today.getFullYear() - dob.getFullYear();
            let m = today.getMonth() - dob.getMonth();
            if(m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
                age--;
            }
            return age;
        },
        addReminder() {
            this.$apollo.mutate({
                mutation: gql`mutation($data: [ReminderSave]!) {
                    RemindersSave(data: $data) {
                        id
                    }
                }`,
                variables: {
                    data: [{patient_id: this.$route.params.id, text: this.reminder.text, popup: this.reminder.popup}]
                }
            }).then(res => {
                if(res.data.RemindersSave[0].id > 0) this.newReminder = false;
            })
        },
        getReminders() {
            this.$apollo.query({
                query: gql`query Reminders($wh: [ReminderConditionAND]) {
                    Reminders(where: $wh) {
                        text
                        popup
                    }
                }`,
                variables: {
                    wh: [{patient_id: {eq:this.$route.params.id}}, {popup:{eq:1}}, {deleted:{is:null}}]
                }
            }).then(res => {
                this.remindersToShow = res.data.Reminders;
            });
        },
        getPatientDetails() {
            this.$apollo.query({
                query: gql`query Patient($id: ID!) {
                    Patient(id: $id) {
                        id
                        first_name
                        last_name
                        middle_name
                        dob
                        gender
                        address1
                        address2
                        city
                        state
                        zip
                        email
                        phone
                        mobile
                        status
                    }
                }`,
                variables: {
                    id: this.$route.params.id
                }
            }).then(res => {
                this.patient = res.data.Patient;
            });
        },
        getProfileImage() {
            this.$apollo.query({
                query: gql`query Files($wh: [FileConditionAND]) {
                    Files(where: $wh) {
                        id
                    }
                }`,
                variables: {
                    wh: [{type: {eq: 'picture'}}, {owner_type: {eq: 'patients'}}, {owner_id: {eq: this.$route.params.id}}, {status:{eq:'OK'}}, {deleted:{is:null}}]
                }
            }).then(res => {
                    if(res.data.Files.length > 0) this.profileImage = res.data.Files[0].id;
                });
        }
    },
    mounted() {
        this.getPatientDetails();
        this.getReminders();
        this.getProfileImage();
    },
};
</script>
<style scoped>
.v-input--is-readonly {
    opacity: 0.4;
}
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
@-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>