<template>
    <v-card flat tile>
        <crud label="Diagnoses" model="PatientDiagnosis" :headers="diagnoses" :conditions="[{patient_id:{eq:this.$route.params.id}}]"></crud>
    </v-card>
</template>

<script>
import crud from "../../components/CRUD.vue"
export default {
    components: {
        crud,
    },
    data() {
        return {
            diagnoses: [
                {value: "id", editable: false, isId: true, visible:false},
                {text: "Diagnosis", searchable: true, value: "Diagnosis.icd10_code", type:"autocomplete",  model: "Diagnosis", modelId: "diagnosis_id", modelFields: { value: "id", text: "name"}, mandatory:true, conditions:[{OR:[{inactive:{is:null}}, {inactive:{more:new Date().toISOString().substring(0,10)}}]}]},
                {text: "Description", searchable: true, value: "Diagnosis.icd10_description",  model: "Diagnosis", modelId: "diagnosis_id", modelFields: { value: "id", text: "icd10_description"}, editable:false},
                {text: "Start Date", type: "date", value: "start_date"},
                {text: "End Date", type: "date", value: "end_date"},
                {text: "Notes", value: "notes"},
            ],
        }
    },
    methods: {

    }
}
</script>