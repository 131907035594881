<template>
    <v-btn @click="printPDF()" icon>
        <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
        <v-tooltip bottom v-else>
            <template v-slot:activator="{on}">
                <v-icon :color="color" v-on="on">{{icon}}</v-icon>
            </template>
            <span>Print Label</span>
        </v-tooltip>
    </v-btn>
</template>

<script>
import gql from "graphql-tag";
export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        ids: [Array],
        icon: {
            type: [String],
            default: "mdi-barcode",
        },
        color: {
            type: [String],
            default: "primary",
        },
    },
    methods: {
        async printPDF() {
            if (!this.ids) return;
            try {
                this.loading = true;
                let pdf = await this.getPDF(this.ids);
                const blob = await fetch(pdf).then((r) => r.blob());

                const dataUrl = window.URL.createObjectURL(blob);

                // Open the window
                const pdfWindow = window.open(dataUrl);

                // Call print on it
                pdfWindow.print();
            } finally {
                this.loading = false;
            }
        },
        async getPDF(ids) {
            let res = await this.$apollo.query({
                query: gql`
                    query ($ids: [ID]!) {
                        ToxPdfLabel(ids: $ids)
                    }
                `,
                variables: {
                    ids: ids,
                },
                fetchPolicy: "network-only",
            });
            let pre = "data:application/pdf;base64,";
            return pre + res.data.ToxPdfLabel + "#toolbar=0&statusbar=0";
        },
    },
};
</script>
