<template>
    <v-btn @click="printPDF()" icon :disabled="disabled">
        <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
        <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
                <v-icon :color="color" v-on="on">{{icon}}</v-icon>
            </template>
            <span>Print Result</span>
        </v-tooltip>
    </v-btn>
</template>

<script>
import gql from "graphql-tag";
export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        ids: [Array],
        toxIds: [Array],
        icon: {
            type: [String],
            default: "mdi-file-chart",
        },
        color: {
            type: [String],
            default: "primary",
        },
        autoprint: {
            type: Boolean,
            default: true,
        },
        disabled: [Boolean, String],
    },
    methods: {
        async printPDF() {
            if (!this.ids && !this.toxIds) return;
            try {
                this.loading = true;
                let pdf = await this.getPDF(this.ids, this.toxIds);
                const blob = await fetch(pdf).then((r) => r.blob());

                const dataUrl = window.URL.createObjectURL(blob);

                // Open the window
                const pdfWindow = window.open(dataUrl);

                // Call print on it
                if (this.autoprint) pdfWindow.print();
            } finally {
                this.loading = false;
            }
        },
        async getPDF(ids, toxIds) {
            let res = await this.$apollo.query({
                query: gql`
                    query ($ids: [ID], $toxIds: [ID]) {
                        ToxPdfResult(ids: $ids, toxIds: $toxIds)
                    }
                `,
                variables: {
                    ids: ids,
                    toxIds: toxIds,
                },
                fetchPolicy: "network-only",
            });
            let pre = "data:application/pdf;base64,";
            return pre + res.data.ToxPdfResult + "#toolbar=0&statusbar=0";
        },
    },
};
</script>
