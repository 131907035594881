<template>
  <v-container>
    <v-row>
      <v-col style="padding-top: 0">
        <v-card>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="12" md="3" class="custom-section">
                <v-row no-gutters>
                  <v-expansion-panels :accordion="true">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Patient Details
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-avatar
                          class="profile mb-5"
                          color="grey"
                          size="80"
                          tile
                        >
                          <v-img src="../assets/noPicture.jpg"></v-img>
                        </v-avatar>
                        <v-form>
                          <v-text-field
                            label="First Name"
                            placeholder=" "
                            dense
                          ></v-text-field>
                          <v-text-field
                            label="Last Name"
                            placeholder=" "
                            dense
                          ></v-text-field>
                          <v-select
                            label="Gender"
                            placeholder=" "
                            dense
                          ></v-select>
                          <dpinput
                            label="Date of Birth"
                            placeholder=""
                            dense
                          ></dpinput>
                        </v-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-col>
              <v-col cols="12" md="3" v-for="i in 3" :key="i" class="custom-section">
                <v-expansion-panels :accordion="true">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Insurance {{ i }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form>
                          <v-text-field
                            label="First Name"
                            placeholder=" "
                            dense
                          ></v-text-field>
                          <v-text-field
                            label="Last Name"
                            placeholder=" "
                            dense
                          ></v-text-field>
                          <v-select
                            label="Gender"
                            placeholder=" "
                            dense
                          ></v-select>
                          <dpinput
                            label="Date of Birth"
                            placeholder=""
                            dense
                          ></dpinput>
                        </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" style="padding-top: 0">
                <v-card>
                  <v-card-text style="height: 160px" class="pt-2">
                    <v-row no-gutters>
                      <v-col>
                        <h4>Prescribed Medications</h4>
                      </v-col>
                      <v-col align="right">
                        <v-btn small v-on:click="medications.push({})">
                          <v-icon>mdi-plus</v-icon>Medication
                        </v-btn>
                      </v-col>
                    </v-row>
                    <br />
                    <v-row>
                      <v-col
                        cols="2"
                        v-for="(med, mkey) in medications"
                        :key="'mk_' + mkey"
                        dense
                      >
                        <autocomplete
                          model="Medication"
                          item-value="id"
                          item-text="name"
                          label="Medication"
                          v-model="med.drug"
                          hide-details="auto"
                          dense
                          height="20"
                          placeholder=" "
                        ></autocomplete>
                      </v-col>
                      <v-col cols="1" align="right">
                        <v-btn icon outlined>
                          <v-icon v-on:click="medications.splice(mkey, 1)"
                            >mdi-delete-outline</v-icon
                          >
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" style="padding-top: 0">
                <v-card>
                  <v-card-text style="height: 160px" class="pt-2">
                    <v-row no-gutters>
                      <v-col>
                        <h4>Point of Care Test</h4>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" style="padding-top: 8px">
                        <v-data-table
                          :headers="poc_headers"
                          :items="pocs"
                          :items-per-page="1"
                          dense
                        >
                          <template v-slot:item.pos="{ item }">
                            <v-checkbox
                              hide-details="auto"
                              style="margin:0 0 3px 0"
                              v-model="item.result"
                              value="pos"
                            ></v-checkbox>
                          </template>
                          <template v-slot:item.neg="{ item }">
                            <v-checkbox
                              hide-details="auto"
                              style="margin:0 0 3px 0"
                              height="1"
                              v-model="item.result"
                              value="neg"
                            ></v-checkbox>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" style="padding-top: 0; padding-bottom: 0">
                <v-card height="160px">
                  <h4 style="padding: 5px 7px">Laboratories &amp; Containers</h4>
                  <v-card-text style="overflow-y: scroll;">
                    <v-list two-line dense subheader>
                      <v-list-item-group multiple v-model="selectedLabs">
                        <v-list-item
                          v-for="lab in labs"
                          :key="lab.id"
                          :value="lab"
                        >
                          <v-list-item-action>
                            <v-checkbox
                              readonly
                              color="primary"
                              v-model="selectedLabIDs"
                              :value="lab.id"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              lab.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              v-if="lab.inNetwork"
                              style="color:green;"
                              >In Network</v-list-item-subtitle
                            >
                            <v-list-item-subtitle v-else style="color:red;">
                              Out of Network
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-rating
                                dense
                                x-small
                                half-increments
                                color="primary"
                                :value="(lab.rank / 100) * 6"
                              ></v-rating>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              :to="'facility/' + lab.id"
                              icon
                              color="primary"
                              target="_blank"
                            >
                              <v-icon>mdi-information-outline</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="8" style="padding-top: 0">
                <v-card height="160px">
                  <h4 style="padding: 5px 7px">Laboratory Tests &amp; Container</h4>
                  <v-card-text class="pt-0">
                    <v-row>
                      <v-col cols="6" style="padding-top: 0">
                        <h4>Select Category</h4>
                        <v-treeview
                          return-object
                          dense
                          selectable
                          open-on-click
                          v-model="selectedTests"
                          :items="groupedOrderCodes"
                        ></v-treeview>
                      </v-col>
                      <v-col style="padding-top:0;">
                        <h4>Diagnosis</h4>

                        <br />
                        <v-row dense>
                          <v-col cols="2" align-self="center">
                            <h4>ALL</h4>
                          </v-col>
                          <v-col v-for="i in 4" :key="i">
                            <autocomplete
                              model="Diagnose"
                              item-value="icd10_raw"
                              item-text="name"
                              label="Diagnosis"
                              hide-details="auto"
                              dense
                              clearable
                              placeholder=" "
                              return-object
                            ></autocomplete>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="2" align-self="center">
                            <h3></h3>
                          </v-col>
                          <v-col v-for="i in 4" :key="i">
                            <autocomplete
                              model="Diagnose"
                              item-value="icd10_raw"
                              item-text="name"
                              label="Diagnosis"
                              hide-details="auto"
                              dense
                              clearable
                              return-object
                              placeholder=" "
                            ></autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5"  style="padding-top: 0">
                <v-card style="height: 160px">
                  <v-card-text>
                    <h4>Containers</h4>
                    <v-row>
                      <v-col style="padding-top: 0; padding-bottom: 0">
                        <h4>Attached Documents</h4>
                      </v-col>
                      <v-col class="text-right"  style="padding-top: 0">
                        <v-btn small v-on:click="uploadFile()">
                          <v-icon>mdi-plus</v-icon>File
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3"  style="padding-top: 0">
                        <v-avatar class="profile" color="grey" size="70" tile>
                        </v-avatar>
                      </v-col>
                      <v-col cols="3"  style="padding-top: 0">
                        <v-avatar class="profile" color="grey" size="70" tile>
                        </v-avatar>
                      </v-col>
                      <v-col cols="3"  style="padding-top: 0">
                        <v-avatar class="profile" color="grey" size="70" tile>
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="7" style="padding-top: 0">
                <v-card style="height: 160px">
                  <v-card-text>
                    <h4>Physician &amp; Collector</h4>
                    <v-row>
                      <v-col cols="12" md="5" style="padding-top: 0">
                        <v-row>
                          <v-col cols="12" md="6" style="padding-top: 0">
                            <autocomplete
                              model="User"
                              item-value="id"
                              item-text="name"
                              label="Collector"
                              hide-details="auto"
                              dense
                            ></autocomplete>
                            <autocomplete
                              model="User"
                              item-value="id"
                              item-text="name"
                              label="Physician"
                              hide-details="auto"
                               dense
                            ></autocomplete>
                          </v-col>
                          <v-col cols="12" md="6" style="padding-top: 0">
                            <datepicker
                              label="Date"
                              hide-details="auto"
                               dense
                            ></datepicker>
                            <timepicker
                              label="Time"
                              hide-details="auto"
                            ></timepicker>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col style="height: 160px;padding-top: 0; margin-top: -20px" cols="12" md="7" >
                        <SignPad></SignPad>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import autocomplete from "../components/AutoComplete.vue";
import datepicker from "../components/DatePickerInput";
//import textfield from "../components/custom/textField";
//import dropdown from "../components/custom/dropDown";
import dpinput from "../components/DatePickerInput.vue";
import SignPad from "../components/SignPad";
import gql from "graphql-tag";
import { copy } from "../tools/copy";
export default {
  props: ["patient", "value", "step"],

  data() {
    return {
      insType: 0,
      insTypes: ["Primary", "Secondary", "Tertiary"],
      ins: {},

      isLoading: false,
      editable: false,
      validated: true,

      items: [],
      insurances: {
        Primary: {},
        Secondary: {},
        Tertiary: {},
      },
      pictures: {
        Primary: null,
        Secondary: null,
        Tertiary: null,
      },
      ins_docs: [],

      stepAllowed: true,
      uploading_picture: false,
      lastInsured: {},
      medications: [{}, {}, {}, {}, {}, {}, {}, {}],
      pocs: [],
      poc_headers: [
        { value: "name", text: "Name" },
        { value: "pos", text: "Positive" },
        { value: "neg", text: "Negative" },
      ],
    };
  },
  watch: {
    patient(n) {
      if (n.id) this.getInsurances(n.id);
    },
    insType(n) {
      this.ins = this.insurances[this.insTypes[n]];
    },

    insurances: {
      handler(n) {
        if (n !== this.value) this.$emit("input", n);
      },
      deep: true,
    },
  },
  methods: {
    onUpdateOCR(OCRData) {
      if (OCRData.IsFinished && OCRData.IsDataExtracted && !OCRData.IsFaulted) {
        if (OCRData.ID_Number) this.ins.policy_number = OCRData.ID_Number;
        if (OCRData.GroupNumber) this.ins.policy_group = OCRData.GroupNumber;
        if (OCRData.Subscriber_FirstName)
          this.ins.ins_first_name = OCRData.Subscriber_FirstName;
        if (OCRData.Subscriber_MiddleName)
          this.ins.ins_middle_name = OCRData.Subscriber_MiddleName;
        if (OCRData.Subscriber_LastName)
          this.ins.ins_last_name = OCRData.Subscriber_LastName;
      }
    },
    updatePayer(val) {
      this.ins.company_id = val.id;
      this.ins.InsuranceCompany.name = val.name;
      this.items.push(val);
      //items[insTypes[insType]]
    },
    relationshipChanged() {
      if (this.ins.relationship == "Self") {
        this.lastInsured[this.insType] = copy(this.ins);
        this.ins.ins_first_name = this.patient.first_name;
        this.ins.ins_last_name = this.patient.last_name;
        this.ins.ins_middle_name = this.patient.middle_name;
        this.ins.insured_dob = this.patient.dob;
        this.ins.insured_ssn = this.patient.ssn;
        this.ins.insured_gender = this.patient.gender;
        this.ins.insured_address1 = this.patient.address1;
        this.ins.insured_address2 = this.patient.address2;
        this.ins.insured_city = this.patient.city;
        this.ins.insured_state = this.patient.state;
        this.ins.insured_zip = this.patient.zip;
        this.ins.insured_phone = this.patient.phone;
      } else {
        this.ins.ins_first_name = this.lastInsured[this.insType].ins_first_name;
        this.ins.ins_last_name = this.lastInsured[this.insType].ins_last_name;
        this.ins.ins_middle_name = this.lastInsured[
          this.insType
        ].ins_middle_name;
        this.ins.insured_dob = this.lastInsured[this.insType].insured_dob;
        this.ins.insured_ssn = this.lastInsured[this.insType].insured_ssn;
        this.ins.insured_gender = this.lastInsured[this.insType].insured_gender;
        this.ins.insured_address1 = this.lastInsured[
          this.insType
        ].insured_address1;
        this.ins.insured_address2 = this.lastInsured[
          this.insType
        ].insured_address2;
        this.ins.insured_city = this.lastInsured[this.insType].insured_city;
        this.ins.insured_state = this.lastInsured[this.insType].insured_state;
        this.ins.insured_zip = this.lastInsured[this.insType].insured_zip;
        this.ins.insured_phone = this.lastInsured[this.insType].insured_phone;
      }
    },

    save() {
      let insurance = [];
      if (this.insurances.Primary.company_id > 0) {
        let Primary = copy(this.insurances.Primary);
        delete Primary["InsuranceCompany"];
        delete Primary["__typename"];
        insurance.push(Primary);
      }
      if (this.insurances.Secondary.company_id > 0) {
        let Secondary = copy(this.insurances.Secondary);
        delete Secondary["InsuranceCompany"];
        delete Secondary["__typename"];
        insurance.push(Secondary);
      }
      if (this.insurances.Tertiary.company_id > 0) {
        let Tertiary = copy(this.insurances.Tertiary);
        delete Tertiary["InsuranceCompany"];
        delete Tertiary["__typename"];
        insurance.push(Tertiary);
      }
      if (insurance.length < 1) return;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation($insurance: [PatientInsuranceSave]!) {
              PatientInsurancesSave(data: $insurance) {
                id
                patient_id
                InsuranceCompany {
                  name
                }
                company_id
                priority
                policy_number
                policy_group
                group_name
                effective_date
                exp_date
                relationship
                ins_first_name
                ins_last_name
                ins_middle_name
                insured_dob
                insured_ssn
                insured_gender
                insured_address1
                insured_address2
                insured_city
                insured_state
                insured_zip
                insured_phone
                accident
                injury_date
                injury_place
              }
            }
          `,
          variables: {
            insurance,
          },
        })
        .then((res) => {
          let data = {};
          res.data.PatientInsurancesSave.forEach((v) => {
            if (v.priority == 1) data.Primary = [v];
            if (v.priority == 2) data.Secondary = [v];
            if (v.priority == 3) data.Tertiary = [v];
          });
          this.saveToVar(data);
          this.editable = false;
        })
        .catch((err) => {
          console.log(err);
          if (this.patient.id) this.getInsurances(this.patient.id);
        });
    },
    getInsurances(id) {
      this.isLoading = true;
      this.$apollo
        .query({
          query: gql`
                    query {
                        Primary: PatientInsurances(
                            options: {
                                page: 1
                                itemsPerPage: 1
                                sortBy: [id]
                                sortDesc: [true]
                            }
                            where: [
                                { patient_id: { eq: "${id}" } }
                                { priority: { eq: 1 } }
                                { deleted: { is: null } }
                            ]
                        ) {
                            ...insFields
                        }
                        Secondary: PatientInsurances(
                            options: {
                                page: 1
                                itemsPerPage: 1
                                sortBy: [id]
                                sortDesc: [true]
                            }
                            where: [
                                { patient_id: { eq: "${id}" } }
                                { priority: { eq: 2 } }
                                { deleted: { is: null } }
                            ]
                        ) {
                            ...insFields
                        }
                        Tertiary: PatientInsurances(
                            options: {
                                page: 1
                                itemsPerPage: 1
                                sortBy: [id]
                                sortDesc: [true]
                            }
                            where: [
                                { patient_id: { eq: "${id}" } }
                                { priority: { eq: 3 } }
                                { deleted: { is: null } }
                            ]
                        ) {
                            ...insFields
                        }
                    }
                    fragment insFields on PatientInsurance {
                        id
                        patient_id
                        InsuranceCompany {
                            name
                        }
                        company_id
                        priority
                        policy_number
                        policy_group
                        group_name
                        effective_date
                        exp_date
                        relationship
                        ins_first_name
                        ins_last_name
                        ins_middle_name
                        insured_dob
                        insured_ssn
                        insured_gender
                        insured_address1
                        insured_address2
                        insured_city
                        insured_state
                        insured_zip
                        insured_phone
                        accident
                        injury_date
                        injury_place
                    }
                `,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          this.saveToVar(res.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    saveToVar(data) {
      if (data.Primary && data.Primary[0]) {
        this.insurances.Primary = data.Primary[0];
        this.items.push({
          id: data.Primary[0].company_id,
          name: data.Primary[0].InsuranceCompany
            ? data.Primary[0].InsuranceCompany.name
            : null,
        });
      } else
        this.insurances.Primary = {
          priority: 1,
          patient_id: this.patient.id,
        };

      if (data.Secondary && data.Secondary[0]) {
        this.insurances.Secondary = data.Secondary[0];
        this.items.push({
          id: data.Secondary[0].company_id,
          name: data.Secondary[0].InsuranceCompany
            ? data.Secondary[0].InsuranceCompany.name
            : null,
        });
      } else
        this.insurances.Secondary = {
          priority: 2,
          patient_id: this.patient.id,
        };

      if (data.Tertiary && data.Tertiary[0]) {
        this.insurances.Tertiary = data.Tertiary[0];
        this.items.push({
          id: data.Tertiary[0].company_id,
          name: data.Tertiary[0].InsuranceCompany
            ? data.Tertiary[0].InsuranceCompany.name
            : null,
        });
      } else
        this.insurances.Tertiary = {
          priority: 3,
          patient_id: this.patient.id,
        };

      this.ins = this.insurances[
        this.insTypes[this.insType ? this.insType : 0]
      ];
    },
    prevStep() {
      this.$emit("step", 1);
    },
    nextStep() {
      this.$emit("step", 3);
    },
    getPocs() {
      this.$apollo
        .query({
          query: gql`
            {
              POCs(where: [{ deleted: { is: null } }]) {
                id
                name
                abbrv
              }
            }
          `,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          if (res.data.POCs instanceof Array) {
            this.pocs = res.data.POCs;
          }
        });
    },
  },

  components: {
    autocomplete,
    datepicker,
    //textfield,
    //dropdown,
    dpinput,
    SignPad,
  },
  mounted() {
    this.getPocs();
  },
};
</script>
