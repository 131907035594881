<template>
    <v-container v-if="this.$store.state.user.components.includes(49)">
        <v-row>
            <v-col>
                <v-text-field
                    outlined
                    label="Address 1"
                    v-model="facility.address1"
                    prepend-icon="mdi-home"
                    dense
                    :readonly="readonly"
                    @change="updateFromAddress()"
                ></v-text-field>

                <v-text-field
                    outlined
                    label="Address 2"
                    v-model="facility.address2"
                    prepend-icon="mdi-home"
                    dense
                    :readonly="readonly"
                ></v-text-field>

                <v-text-field
                    outlined
                    label="City"
                    v-model="facility.city"
                    dense
                    prepend-icon="mdi-city"
                    :readonly="readonly"
                    @change="updateFromAddress()"
                ></v-text-field>

                <v-select
                    outlined
                    :items="$store.state.usStatesAbrv"
                    label="State"
                    dense
                    v-model="facility.state"
                    :readonly="readonly"
                    prepend-icon="mdi-map"
                    @change="updateFromAddress()"
                ></v-select>

                <v-text-field
                    outlined
                    label="ZIP"
                    v-model="facility.zip"
                    dense
                    :readonly="readonly"
                    prepend-icon="mdi-postage-stamp"
                    @change="updateFromAddress()"
                ></v-text-field>

                <v-text-field
                    outlined
                    label="Phone"
                    v-model="facility.phone"
                    dense
                    :readonly="readonly"
                    prepend-icon="mdi-phone"
                ></v-text-field>

                <v-text-field
                    outlined
                    label="Fax"
                    v-model="facility.fax"
                    dense
                    :readonly="readonly"
                    prepend-icon="mdi-fax"
                ></v-text-field>

                <v-text-field
                    outlined
                    label="Email"
                    v-model="facility.email"
                    dense
                    :readonly="readonly"
                    prepend-icon="mdi-email"
                ></v-text-field>
            </v-col>
            <v-col align="center">
                <GmapMap
                    :center="markerLocation"
                    :zoom="4"
                    map-type-id="roadmap"
                    style="width: 100%; height: 300px"
                    ref="mapRef"
                >
                    <gmap-marker
                        :position="markerLocation"
                        :draggable="true"
                        @drag="updateLocation"
                        ref="markerRef"
                    ></gmap-marker>
                </GmapMap>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'
import gql from "graphql-tag";
export default {
    props: ["value","readonly"],
    data() {
        return {
            facility: {
                address1: null,
                address2: null,
                city: null,
                state: null,
                zip: null,
                phone: null,
                fax: null,
                email: null
            },
            markerLocation: {lat:0, lng:0},
        }
    },
    watch:{
        value: {
            handler(n) {
                if(n instanceof Object) {
                    this.facility = n;
                }
            }, immediate:true
        },
        facility: {
            handler(n) {
                this.$emit('input',n)
            }, 
            deep:true
        }
    },
    methods: {
        updateLocation: function(data) {
            this.facility.lat = data.latLng.lat();
            this.facility.lng = data.latLng.lng();
            this.saveLocation();
        },
        updateFromAddress: function() {
            if(this.facility.address1 && this.facility.city && this.facility.state && this.facility.zip) {
                const address = this.facility.address1 + ", " + this.facility.city + ", " + this.facility.state + " " + this.facility.zip + ", USA";
                const geocoder = new this.google.maps.Geocoder();
                let app = this;
                geocoder.geocode({'address': address}, (results, status) => {
                    if(status == 'OK') {
                        app.markerLocation = results[0].geometry.location;
                        app.facility.lat = results[0].geometry.location.lat();
                        app.facility.lng = results[0].geometry.location.lng();

                        app.saveLocation();
                    }
                });
            }
        },
        saveLocation() {
            this.$apollo.mutate({
                mutation: gql`mutation($data: [FacilitySave]!) {
                    FacilitysSave(data: $data) {
                        lat
                        lng
                    }
                }`,
                variables: {
                    data: [{'id': this.facility.id, "lat": this.facility.lat, "lng": this.facility.lng}]
                }
            });
        }
    },
    mounted() {
        this.$refs.mapRef.$mapPromise.then(() => {
            if(!this.facility.lat && !this.facility.lng)
                this.updateFromAddress()
            else {
                this.markerLocation = {lat: parseFloat(this.facility.lat), lng: parseFloat(this.facility.lng)}
            }
        })
    },
    computed: {
        google: gmapApi
    }
}
</script>

<style scoped>
.v-input--is-readonly {
    opacity: 0.5;
}
</style>