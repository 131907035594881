<template>
    <v-container fluid v-if="$store.state.user.components.includes(88)">
        <v-card>
            <crud
                :headers="headers"
                model="SupplyOrder"
                :multi-sort="true"
                :canAdd="false"
                label="Supply Orders"
                v-model="selected"
                show-select
                show-expand
                single-expand 
                item-expanded="loadDetails"
                :conditions="conditions"
            >
                <template v-slot:expanded-item="{ item }">
                    <crud 
                        :headers="headersDetails"
                        label="Ordered Items"
                        :canSearch="false"
                        :canAdd="false"
                        :canEdit="true"
                        :canDelete="false"
                        model="SupplyOrderDetail"
                        :conditions="[{order_id:{eq:item.id}}]"
                        :multi-sort="true"
                    >
                    </crud>
                </template>
            </crud>
            <v-card-actions class="justify-center">
                <v-btn color="primary" small :disabled="selected.length==0" @click="acceptOrders()">Accept Selected</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
import crud from "../../components/CRUD.vue"
export default {
    components: {
        crud
    },
    data() {
        return {
            headers: [
                {text: "Order #", value: "id", isId: true, visible:true, editable: false},
                {text: "Facility", value: "Facility.name", model: "Facility", modelId: "facility_id", modelFields: { value: "id", text: "name" }, searchable:true, editable:false},
                {text: "Ordered By", value: "User.name", model: "User", modelId: "user_id", modelFields: {value:"id", text:"name"}, editable:false},
                {text: "Items", value: "items", editable:false},
                {text: "Status", value: "status", mandatory: true},
                {text: "Order Date", value:"created", type: "date", editable: false},
                {text: "Amount", value: "amount", editable: false,},
                {text: "Notes", value: "notes"},
                {text: "Ship To", value: "shipTo", editable: false}
            ],
            headersDetails: [
                {text: "Item Name", value: "SupplyItem.name", model: "SupplyItem", modelId: "item_id", modelFields: {value:"id", text:"name"}, editable: false},
                {text: "Vendor", value: "vendor", editable: false},
                {text: "Quantity", value: "qty", editable: false},
                {text: "Price", value: "price", editable: false},
                {text: "Tracking #", value: "tracking"},
            ],
            conditions: [{deleted:{is:null}}],
            selected: [],
        }
    },
    methods: {
        loadDetails({item}) {
            this.parent_id = item.id;
        },
        acceptOrders() {
            
        }
    },
    mounted() {
        if(this.$route.params.status) this.conditions.push({"status":{"eq":this.$route.params.status}});
    }
}
</script>