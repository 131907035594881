<template>
    <span>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn @click="printPackingSlip()" fab dark :x-large="size=='x-large'" :large="size=='large'" :small="size=='small'" :x-small="size=='x-small'" class="mx-1">
                    <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
                    <v-icon v-on="on" v-else :color="color">{{icon}}</v-icon>
                </v-btn>
            </template>
            <span>Print Packing Slip</span>
        </v-tooltip>
    </span>
</template>

<script>

export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        label: {
            type: [String],
            default: null,
        },
        icon: {
            type: [String],
            default: "mdi-printer",
        },
        color: {
            type: [String],
            default: "cyan accent-4",
        },
        size: {
            type: [String],
            default: "x-small"
        }
    },
    methods: {
        async printPackingSlip() {
            if(!this.label) return;

            try {
                this.loading = true;

                const blob = await this.getPackingSlip();
                const dataUrl = window.URL.createObjectURL(blob);
                window.open(dataUrl);
            } finally {
                this.loading = false;
            }
        },
        async getPackingSlip() {
            let res = await this.axios.request({url: "https://lab-synapse.com/gql/storage/?id="+this.label, method: "get", responseType: "blob"});
            return res.data;
        },
    },
};
</script>