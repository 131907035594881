<template>
    <v-container v-if="this.$store.state.user.components.includes(65)">
        <v-row>
            <v-col>
                <v-row dense>
                    <v-col>
                        <autocomplete
                            model="InsuranceGroup"
                            label="Payer Group"
                            v-model="insGroup"
                            hide-details="auto"
                        />
                    </v-col>
                    <v-col>
                        <date label="DOS" v-model="dos"  hide-details="auto"/>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="auto" v-for="lab in labs" :key="lab.id">
                        <v-checkbox
                            v-model="selectedLabs"
                            :value="lab"
                            :label="lab.name"
                            dense
                            hide-details="auto"
                        ></v-checkbox>
                    </v-col>
                </v-row>

                <v-divider></v-divider>
                <v-treeview
                    return-object
                    dense
                    selectable
                    open-on-click
                    v-model="selectedTests"
                    :items="groupedOrderCodes"
                ></v-treeview>
                <v-divider></v-divider>
                <!--{{selectedTestsNoGroups}}-->

                <ul>
                    <b>Mapped Tests</b>
                    <li v-for="test in mappedTests" :key="test.id">
                        ({{test.id}}) {{test.name}}
                        <ul>
                            <li
                                v-for="code in test.codes"
                                :key="code.id"
                            >({{code.id}}) {{code.name}}</li>
                        </ul>
                        <br />
                    </li>
                </ul>
                <v-divider></v-divider>
                <ul>
                    <b>Unmapped Order Codes</b>
                    <li v-for="code in unmappedCodes" :key="code.id">({{code.id}}) {{code.name}}</li>
                </ul>
            </v-col>
            <v-col>
                <ul>
                    <b>Mapped CPTs</b>
                    <li v-for="cpt in mappedCPTs" :key="cpt.id">
                        ({{cpt.id}}) {{cpt.cpt}} [{{cpt.type}} ({{cpt.count_from}}-{{cpt.count_to}})] {{cpt.valid ? 'VALID' : 'INVALID'}}
                        <ul v-if="cpt.type=='group_based'">
                            <li v-for="group in cpt.groups" :key="group.id">
                                <span
                                    :style="cpt.gIDs[group.id] ? null : {color:'red'}"
                                >({{group.id}}) {{group.name}}</span>
                                <ul>
                                    <li v-for="test in group.tests" :key="test.id">
                                        <span
                                            :style="cpt.gIDs[group.id] && cpt.gIDs[group.id][test.id] ? null : {color:'red'}"
                                        >({{test.id}}) {{test.name}}</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul v-if="cpt.type=='test_based'">
                            <li v-for="test in cpt.tests" :key="test.id">
                                <span
                                    :style="cpt.tIDs[test.id] ? null : {color:'red'}"
                                >({{test.id}}) {{test.name}}</span>
                            </li>
                        </ul>
                        <br />
                    </li>
                </ul>

                <hr />

                <ul>
                    <b>Unmapped Tests</b>
                    <li v-for="test in unmappedTests" :key="test.id">({{test.id}}) {{test.name}}</li>
                </ul>

                <hr />
                <b>Missing CPTs</b>
                {{missingCPTs}}
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import autocomplete from "../../../components/AutoComplete";
import date from "../../../components/DatePickerInput";
export default {
    data() {
        return {
            insID: null, // Input
            labs: [], // From Query
            codeGroups: [], // From Query

            selectedTests: [], //Treeview
            selectedLabs: [], // Checkbox

            //----------------------------

            cptTestCodeMap: {},
            testMap: {},
            cptMap: {},
            cptTestMap: {},

            unmappedCodes: [],
            mappedTests: [],
            mappedCPTs: [],
            unmappedTests: [],
            foundCPTs: [],
            missingCPTs: [],

            //------------------------------

            insGroup: null,
            dos: null
        };
    },

    computed: {
        orderCodes() {
            let codes = [];
            this.selectedLabs.forEach(v => {
                codes = codes.concat(v.LabOrderCodes);
            });
            return codes;
        },
        groupedOrderCodes() {
            let groupsMap = {};
            let rootGroups = [];
            let subGroups = [];

            this.codeGroups.forEach(v => {
                let obj = {
                    id: "G" + v.id,
                    name: v.name,
                    group_id: v.id,
                    group_parent_id: v.parent_id,
                    isGroup: true
                };
                groupsMap[v.id] = obj;

                if (v.parent_id == null) rootGroups.push(obj);
                else subGroups.push(obj);
            });

            subGroups.forEach(v => {
                if (groupsMap[v.group_parent_id] === undefined)
                    groupsMap[v.group_parent_id] = {};
                if (groupsMap[v.group_parent_id]["children"] === undefined)
                    groupsMap[v.group_parent_id]["children"] = [];

                groupsMap[v.group_parent_id]["children"].push(v);
                //groupsMap[v.group_id]["parent"]=groupsMap[v.group_parent_id];
            });

            this.orderCodes.forEach(v => {
                if (groupsMap[v.group_id] === undefined)
                    groupsMap[v.group_id] = {};
                if (groupsMap[v.group_id]["children"] === undefined)
                    groupsMap[v.group_id]["children"] = [];

                this.activateBranchRecursion(groupsMap, v.group_id);
                groupsMap[v.group_id]["children"].push({
                    id: v.id,
                    name: "(" + v.order_code + ") " + v.order_name,
                    order_name: v.order_name,
                    order_code: v.order_code,
                    order_abrv: v.order_abrv
                });
            });

            this.clearBranchRecursion(rootGroups);

            return rootGroups;
        },
        selectedTestsNoGroups() {
            return this.selectedTests.filter(v => !v.isGroup);
        }
        //----------------------------------------------
    },
    methods: {
        activateBranchRecursion(tree, nodeID) {
            if (tree[nodeID]) {
                tree[nodeID]["active"] = true;
                if (tree[nodeID]["group_parent_id"])
                    this.activateBranchRecursion(
                        tree,
                        tree[nodeID]["group_parent_id"]
                    );
            }
        },
        clearBranchRecursion(branches) {
            if (branches instanceof Array) {
                for (let k = branches.length - 1; k >= 0; k--) {
                    let v = branches[k];
                    if (v.isGroup && v.active === undefined)
                        branches.splice(k, 1);
                    else if (v.children instanceof Array)
                        this.clearBranchRecursion(v.children);
                }
            }
        },
        getLabs() {
            this.isLoading = true;
            this.$apollo
                .query({
                    query: gql`
                        query {
                            Facilitys(where: [{ deleted: { is: null } }]) {
                                id
                                name
                                rank
                                LabOrderCodes(
                                    where: [{ deleted: { is: null } }]
                                ) {
                                    id
                                    order_code
                                    order_name
                                    order_abrv
                                    group_id
                                    group_name
                                    group_parent_id
                                }
                                Payers(where:[{company_id:{eq:${this.insID ||
                                    null}}}]) {
                                    id
                                    subscribed
                                    in_network
                                }
                            }
                            LabOrderCodeGroups(
                                where: [{ deleted: { is: null } }]
                            ) {
                                id
                                name
                                parent_id
                            }
                            
                        }
                    `,
                    fetchPolicy: "network-only"
                })
                .then(res => {
                    if (res.data.Facilitys instanceof Array) {
                        this.labs = res.data.Facilitys;
                        this.labs.forEach(v => {
                            if (v.Payers instanceof Array) {
                                v.inNetwork = v.Payers[0]?.in_network == 1;
                            }
                        });
                    }
                    if (res.data.LabOrderCodeGroups instanceof Array) {
                        this.codeGroups = res.data.LabOrderCodeGroups;
                    }
                })
                .finally(() => {});
        },
        //-------------------------------------------
        getTestMap() {
            this.$apollo
                .query({
                    query: gql`{
                        CptTestCodeMaps(where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                            id
                            code_id
                            test_id
                        }
                        CptMaps(where:[
                            {facility_id:{eq:${this.facility_id}}}, 
                            {deleted:{is:null}}, 
                            {payer_group_id:{eq:${this.insGroup}}}, 
                            {OR:[{active_from:{is:null}},{active_from:{leq:"${this.dos}"}}]}, 
                            {OR:[{active_to:{is:null}},{active_to:{meq:"${this.dos}"}}]}
                        ]) {
                            id
                            facility_id
                            payer_group_id
                            priority
                            type
                            cpt
                            description
                            price
                            count_from
                            count_to
                            active_from
                            active_to
                        }
                        CptTestMaps(where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                            id
                            facility_id
                            test_id
                            cpt_id
                            deleted
                        }
                        CptTests(where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                            id
                            facility_id
                            name
                            group_id
                            group_name
                        }
                    }`,
                    fetchPolicy: "no-cache"
                })
                .then(res => {
                    this.cptTestCodeMap = {};
                    this.cptMap = {};
                    this.cptTestMap = {};
                    this.testMap = {};

                    if (res.data.CptTestCodeMaps instanceof Array) {
                        res.data.CptTestCodeMaps.forEach(v => {
                            if (this.cptTestCodeMap[v.code_id] === undefined)
                                this.cptTestCodeMap[v.code_id] = [];
                            this.cptTestCodeMap[v.code_id].push(v.test_id);
                        });
                    }

                    if (res.data.CptMaps instanceof Array) {
                        res.data.CptMaps.forEach(v => {
                            this.cptMap[v.id] = v;
                        });
                    }

                    if (res.data.CptTestMaps instanceof Array) {
                        res.data.CptTestMaps.forEach(v => {
                            if (this.cptTestMap[v.test_id] === undefined)
                                this.cptTestMap[v.test_id] = [];
                            this.cptTestMap[v.test_id].push(v);
                        });
                    }

                    if (res.data.CptTests instanceof Array) {
                        res.data.CptTests.forEach(v => {
                            this.testMap[v.id] = v;
                        });
                    }
                });
        },
        mapTests() {
            let temp = [];

            this.mappedTests = [];
            this.unmappedCodes = [];

            this.selectedTestsNoGroups.forEach(v => {
                if (this.cptTestCodeMap[v.id] instanceof Array) {
                    //Aggregate tests - remove duplicates
                    this.cptTestCodeMap[v.id].forEach(vv => {
                        if (temp[vv] == undefined) temp[vv] = [];
                        temp[vv].push(v);
                    });
                } else {
                    this.unmappedCodes.push(v);
                }
            });

            Object.keys(temp).forEach(k => {
                let v = temp[k];
                this.mappedTests.push({ ...this.testMap[k], codes: v });
            });
        },
        mapCPTs() {
            let temp = {};
            let cpts = [];
            this.mappedCPTs = [];
            this.missingCPTs = [];
            this.unmappedTests = [];

            this.mappedTests.forEach(v => {
                if (this.cptTestMap[v.id] instanceof Array) {
                    //Aggregate tests - remove duplicates
                    this.cptTestMap[v.id].forEach(vv => {
                        if (temp[vv.cpt_id] === undefined) temp[vv.cpt_id] = [];
                        temp[vv.cpt_id].push(v);
                    });
                } else {
                    this.unmappedTests.push(v);
                }
            });
            //converts temp obj to array of cpt objects and sorts descending
            Object.keys(temp).forEach(k => {
                if (this.cptMap[k] === undefined) this.missingCPTs.push(k);
                else {
                    let groups = {};
                    let grs = []; // Generate test groups
                    let tIDs = {};
                    let gIDs = {};
                    temp[k].forEach(vv => {
                        if (groups[vv.group_id] === undefined) {
                            groups[vv.group_id] = {
                                id: vv.group_id,
                                name: vv.group_name,
                                tests: []
                            };
                        }
                        groups[vv.group_id].tests.push(vv);
                        if (gIDs[vv.group_id] === undefined)
                            gIDs[vv.group_id] = {};
                        gIDs[vv.group_id][vv.id] = true;
                        tIDs[vv.id] = true;
                    });

                    grs = Object.keys(groups).map(k => groups[k]);

                    cpts.push({
                        ...this.cptMap[k],
                        groups: grs,
                        tests: temp[k],
                        tIDs,
                        gIDs
                    });
                }
            });
            cpts.sort((a, b) => b.priority - a.priority);

            cpts.forEach((v, k) => {
                let len = -1;
                if (v.type == "test_based") {
                    len = Object.keys(v.tIDs).length;
                }
                if (v.type == "group_based") {
                    len = Object.keys(v.gIDs).length;
                }
                v.valid = v.count_from <= len && len <= v.count_to;

                if (v.valid) {
                    for (let i = k + 1; i < cpts.length; i++) {
                        Object.keys(v.tIDs).forEach(tid => {
                            if (cpts[i].tIDs[tid]) delete cpts[i].tIDs[tid];

                            Object.keys(cpts[i].gIDs).forEach(kk => {
                                if (cpts[i].gIDs[kk]) {
                                    if (cpts[i].gIDs[kk][tid] !== undefined)
                                        delete cpts[i].gIDs[kk][tid];

                                    if (
                                        Object.keys(cpts[i].gIDs[kk]).length <=
                                        0
                                    )
                                        delete cpts[i].gIDs[kk];
                                }
                            });
                        });
                    }
                }
            });
            this.mappedCPTs = cpts;
        }
    },
    watch: {
        //---------------------------------------------
        selectedTestsNoGroups() {
            this.mapTests();
            this.mapCPTs();
        },
        insGroup() {
            this.getTestMap();
        },
        dos() {
            this.getTestMap();
        }
    },
    props: ["facility_id"],
    mounted() {
        this.getLabs();
        this.getTestMap();
    },
    components: {
        autocomplete,
        date
    }
};
</script>