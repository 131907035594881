<template>
    <v-card flat tile>
        <crud label="Allergies" model="PatientAllergy" :headers="allergies" :conditions="[{patient_id:{eq:this.$route.params.id}}]"></crud>
    </v-card>
</template>

<script>
import crud from "../../components/CRUD.vue"
export default {
    components: {
        crud,
    },
    data() {
        return {
            allergies: [
                {value: "id", editable: false, isId: true, visible:false},
                {text: "Type", searchable: true, value: "type", type:"radio",  mandatory:true, items: ["Allergy", "Intolerance", "Unknown"]},
                {text: "Allergen", searchable: true, value: "allergen", type:"text", mandatory:true},
                {text: "Criticality", type: "radio", value: "criticality", items: ["Low", "Medium", "High", "Fatal"]},
                {text: "Reaction", type: "radio", value: "reaction", items: ["Itching,", "Rash", "Abdominal Pain", "Dizziness"]},
                {text: "Onset", type: "radio", value: "onset", items: ["Childhood,", "Adulthood", "Unknown"]},
                {text: "Onset Date", type: "date", value: "onset_date"},
                {text: "Notes", value: "notes"},
            ],
        }
    },
    methods: {

    }
}
</script>