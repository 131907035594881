<template>
    <v-card flat tile>
        <v-card flat tile>
            <v-card-text>
                <h2 class="font-weight-regular text-center mb-10 text-uppercase">Medications <v-icon color="primary" @click="addMedications=true">mdi-plus-circle</v-icon></h2>
                <v-timeline>
                    <v-timeline-item v-for="(item, key) in medications" :key="item.id" small fill-dot class="text-left" :color="item.deleted?'grey':'green lighten-2'">
                        <v-card>
                            <v-card-title class="primary lighten-2 pa-1">
                                <v-icon dark size="32" class="mr-4">mdi-pill</v-icon>
                                <span class="white--text font-weight-light">{{item.Medication.name}}</span>
                            </v-card-title>
                            <v-container>
                                <span v-if="item.prescribed">Prescribed: {{formatDate(item.prescribed)}}<br/></span>
                                <span v-if="item.dosage">Dosage: {{item.dosage}} {{item.measure}}<br/></span>
                                <span v-if="item.frequency">Frequency: {{item.frequency}}<br/></span>
                                <span v-if="item.notes">Notes: {{item.notes}}<br /></span>
                                <span v-if="item.ended" style="color:red;">Ended: {{formatDate(item.ended)}}</span>
                                <v-divider></v-divider>
                                <v-row dense justify="end" v-if="!item.ended">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-2" color="orange lighten-1" fab small v-on="on" @click="editMed(key)">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Edit Medication</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-2" color="red lighten-1" dark v-on="on" fab small @click="delMed(key)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Medication</span>
                                    </v-tooltip>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </v-card-text>
        </v-card>
        <v-dialog v-model="addMedications" max-width="100%">
            <v-card fluid>
                <v-toolbar dark dense color="primary" :elevation="0">
                    <v-toolbar-title>
                        Add Medications
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon small @click="addMedications=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row dense class="mt-5">
                        <v-col md="2">
                            <autocompelte dense label="Medication" outlined model="Medication" itemValue="id" itemText="name" v-model="addMeds[0].medication_id"></autocompelte>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-text-field dense label="Dose" outlined v-model="addMeds[0].dosage"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-select dense :items="['ML', 'MG', 'MCG', 'NG', 'CAPS', 'GTT', 'SS', 'TBSP', 'TSP', 'TABS']" label="Measure" outlined v-model="addMeds[0].measure"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select dense :items="['p.r.n - as needed','q.d. - once a day','b.i.d - twice a day','t.i.d - three times a day','q.i.d - four times a day','5X a day - five times a day','q.3h - every three hours','q.4h - every four hours','q.6h - every six hours','q.o.d - every other hour']" label="Frequency" outlined v-model="addMeds[0].frequency"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select dense :items="['Orally','Intramuscularly','Subcutaneous','Intravenous','Rectally']" label="Method" outlined v-model="addMeds[0].method"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field small dense label="Notes" outlined v-model="addMeds[0].notes"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                            <datepicker dense label="Prescribed" outlined v-model="addMeds[0].prescribed"></datepicker>
                        </v-col>
                        <v-col cols="12" md="1">
                            <datepicker dense label="Ended" outlined v-model="addMeds[0].ended"></datepicker>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="2">
                            <autocompelte dense label="Medication" outlined model="Medication" itemValue="id" itemText="name" v-model="addMeds[1].medication_id"></autocompelte>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-text-field dense label="Dose" outlined v-model="addMeds[1].dosage"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-select dense :items="['ML', 'MG', 'MCG', 'NG', 'CAPS', 'GTT', 'SS', 'TBSP', 'TSP', 'TABS']" label="Measure" outlined v-model="addMeds[1].measure"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select dense :items="['p.r.n - as needed','q.d. - once a day','b.i.d - twice a day','t.i.d - three times a day','q.i.d - four times a day','5X a day - five times a day','q.3h - every three hours','q.4h - every four hours','q.6h - every six hours','q.o.d - every other hour']" label="Frequency" outlined v-model="addMeds[1].frequency"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select dense :items="['Orally','Intramuscularly','Subcutaneous','Intravenous','Rectally']" label="Method" outlined v-model="addMeds[1].method"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field dense label="Notes" outlined v-model="addMeds[1].notes"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                            <datepicker dense label="Prescribed" outlined v-model="addMeds[1].prescribed"></datepicker>
                        </v-col>
                        <v-col cols="12" md="1">
                            <datepicker dense label="Ended" outlined v-model="addMeds[1].ended"></datepicker>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="2">
                            <autocompelte dense label="Medication" outlined model="Medication" itemValue="id" itemText="name" v-model="addMeds[2].medication_id"></autocompelte>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-text-field dense label="Dose" outlined v-model="addMeds[2].dosage"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-select dense :items="['ML', 'MG', 'MCG', 'NG', 'CAPS', 'GTT', 'SS', 'TBSP', 'TSP', 'TABS']" label="Measure" outlined v-model="addMeds[2].measure"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select dense :items="['p.r.n - as needed','q.d. - once a day','b.i.d - twice a day','t.i.d - three times a day','q.i.d - four times a day','5X a day - five times a day','q.3h - every three hours','q.4h - every four hours','q.6h - every six hours','q.o.d - every other hour']" label="Frequency" outlined v-model="addMeds[2].frequency"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select dense :items="['Orally','Intramuscularly','Subcutaneous','Intravenous','Rectally']" label="Method" outlined v-model="addMeds[2].method"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field dense label="Notes" outlined v-model="addMeds[2].notes"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                            <datepicker dense label="Prescribed" outlined v-model="addMeds[2].prescribed"></datepicker>
                        </v-col>
                        <v-col cols="12" md="1">
                            <datepicker dense label="Ended" outlined v-model="addMeds[2].ended"></datepicker>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="2">
                            <autocompelte dense label="Medication" outlined model="Medication" itemValue="id" itemText="name" v-model="addMeds[3].medication_id"></autocompelte>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-text-field dense label="Dose" outlined v-model="addMeds[3].dosage"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-select dense :items="['ML', 'MG', 'MCG', 'NG', 'CAPS', 'GTT', 'SS', 'TBSP', 'TSP', 'TABS']" label="Measure" outlined v-model="addMeds[3].measure"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select dense :items="['p.r.n - as needed','q.d. - once a day','b.i.d - twice a day','t.i.d - three times a day','q.i.d - four times a day','5X a day - five times a day','q.3h - every three hours','q.4h - every four hours','q.6h - every six hours','q.o.d - every other hour']" label="Frequency" outlined v-model="addMeds[3].frequency"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select dense :items="['Orally','Intramuscularly','Subcutaneous','Intravenous','Rectally']" label="Method" outlined v-model="addMeds[3].method"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field dense label="Notes" outlined v-model="addMeds[3].notes"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                            <datepicker dense label="Prescribed" outlined v-model="addMeds[3].prescribed"></datepicker>
                        </v-col>
                        <v-col cols="12" md="1">
                            <datepicker dense label="Ended" outlined v-model="addMeds[3].ended"></datepicker>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="2">
                            <autocompelte dense label="Medication" outlined model="Medication" itemValue="id" itemText="name" v-model="addMeds[4].medication_id"></autocompelte>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-text-field dense label="Dose" outlined v-model="addMeds[4].dosage"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-select dense :items="['ML', 'MG', 'MCG', 'NG', 'CAPS', 'GTT', 'SS', 'TBSP', 'TSP', 'TABS']" label="Measure" outlined v-model="addMeds[4].measure"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select dense :items="['p.r.n - as needed','q.d. - once a day','b.i.d - twice a day','t.i.d - three times a day','q.i.d - four times a day','5X a day - five times a day','q.3h - every three hours','q.4h - every four hours','q.6h - every six hours','q.o.d - every other hour']" label="Frequency" outlined v-model="addMeds[4].frequency"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select dense :items="['Orally','Intramuscularly','Subcutaneous','Intravenous','Rectally']" label="Method" outlined v-model="addMeds[4].method"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field dense label="Notes" outlined v-model="addMeds[4].notes"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1">
                            <datepicker dense label="Prescribed" outlined v-model="addMeds[4].prescribed"></datepicker>
                        </v-col>
                        <v-col cols="12" md="1">
                            <datepicker dense label="Ended" outlined v-model="addMeds[4].ended"></datepicker>
                        </v-col>
                    </v-row>
                    <v-row dense class="text-center">
                        <v-col cols="12">
                            <v-btn color="primary" @click="_addMeds()">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-if="editedIndex >= 0" v-model="editMedication" max-width="60vw">
            <v-card fluid>
                <v-toolbar dark dense color="primary" :elevation="0">
                    <v-toolbar-title>
                        Edit Medication
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon small @click="editMedication=false;">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row dense class="mt-4">
                        <v-col cols="4">
                            <autocompelte dense label="Medication" :items="[{id:medications[editedIndex].medication_id, name: medications[editedIndex].Medication.name}]" outlined model="Medication" itemValue="id" itemText="name" v-model="medications[editedIndex].medication_id"></autocompelte>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense label="Dose" outlined v-model="medications[editedIndex].dosage"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-select dense :items="['ML', 'MG', 'MCG', 'NG', 'CAPS', 'GTT', 'SS', 'TBSP', 'TSP', 'TABS']" label="Measure" outlined v-model="medications[editedIndex].measure"></v-select>
                        </v-col>
                        <v-col cols="3">
                            <datepicker dense label="Prescribed" outlined v-model="medications[editedIndex].prescribed"></datepicker>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="3">
                            <v-select dense :items="['p.r.n - as needed','q.d. - once a day','b.i.d - twice a day','t.i.d - three times a day','q.i.d - four times a day','5X a day - five times a day','q.3h - every three hours','q.4h - every four hours','q.6h - every six hours','q.o.d - every other hour']" label="Frequency" outlined v-model="medications[editedIndex].frequency"></v-select>
                        </v-col>
                        <v-col cols="3">
                            <v-select dense :items="['Orally','Intramuscularly','Subcutaneous','Intravenous','Rectally']" label="Method" outlined v-model="medications[editedIndex].method"></v-select>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field dense label="Notes" outlined v-model="medications[editedIndex].notes"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <datepicker dense label="Ended" outlined v-model="medications[editedIndex].ended"></datepicker>
                        </v-col>
                    </v-row>
                    <v-row justify="end" dense>
                        <v-btn color="primary" @click="saveMed()">Save</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import gql from "graphql-tag"
import autocompelte from "../../components/AutoComplete.vue"
import datepicker from "../../components/DatePickerInput.vue"
export default {
    components: {
        autocompelte,
        datepicker,
    },
    data() {
        return {
            addMedications: false,
            editMedication: false,
            editedIndex: -1,
            medications: [],
            addMeds: [
                {medication_id:null, dosage:null, measure:null, frequency:null, method:null, prescribed: null, ended: null, notes:null},
                {medication_id:null, dosage:null, measure:null, frequency:null, method:null, prescribed: null, ended:null, notes:null},
                {medication_id:null, dosage:null, measure:null, frequency:null, method:null, prescribed: null, ended:null, notes:null},
                {medication_id:null, dosage:null, measure:null, frequency:null, method:null, prescribed: null, ended:null, notes:null},
                {medication_id:null, dosage:null, measure:null, frequency:null, method:null, prescribed: null, ended:null, notes:null}
            ],
        }
    },
    computed: {
        today: function() {
            let dt = new Date();

            return (dt.getFullYear() + "-" + String(dt.getMonth() + 1).padStart(2, "0") + "-" + String(dt.getDate()).padStart(2, "0"));
        }
    },
    mounted() {
        let app = this;
        this.addMeds.map(function(el) {
            el.prescribed = app.today;
        })
    },
    methods: {
        formatDate(str) {
            let parts = str.split("-");
            return(parts[1].padStart(2, "0") + "/" + parts[2].padStart(2, "0") + "/" + parts[0]);
        },
        _addMeds() {
            this.$apollo.mutate({
                mutation: gql`mutation($inputdata: [PatientMedicationSave]!) {
                    PatientMedicationsSave(data: $inputdata) {
                        patient_id
                        medication_id
                        dosage
                        measure
                        frequency
                        method
                        prescribed
                        ended
                        notes
                    }
                }`,
                variables: {
                    inputdata: this.addMeds.filter(obj => {if(obj.medication_id > 0) return obj;}).map(el => {return Object.assign({}, {patient_id: this.$route.params.id}, el)}),
                }
            }).then(() => {
                this.addMedications = false;
                this.$apollo.queries.medications.refetch();
            });
        },
        delMed(key) {
            this.$apollo.mutate({
                mutation: gql`mutation($wh: [PatientMedicationConditionAND]!) {
                    PatientMedicationDelete(where: $wh) {
                        id
                        deleted
                    }
                }`,
                variables: {
                    wh: [{id:{eq:this.medications[key].id}}]
                }
            }).then(res => {
                if(res.data.PatientMedicationDelete[0].id > 0) {
                    this.medications[key].deleted = res.data.PatientMedicationDelete[0].deleted;
                }
            })
        },
        editMed(key) {
            this.editMedication = true;
            this.editedIndex = key;
        },
        saveMed() {
            if(this.editedIndex >= 0) {
                this.$apollo.mutate({
                    mutation: gql`mutation($inputdata: [PatientMedicationSave]!) {
                        PatientMedicationsSave(data: $inputdata) {
                            id
                            patient_id
                            medication_id
                            dosage
                            measure
                            frequency
                            method
                            prescribed
                            ended
                            notes
                        }
                    }`,
                    variables: {
                        inputdata: [{
                            id: this.medications[this.editedIndex].id,
                            patient_id: this.medications[this.editedIndex].patient_id,
                            medication_id: this.medications[this.editedIndex].medication_id,
                            dosage: this.medications[this.editedIndex].dosage,
                            measure: this.medications[this.editedIndex].measure,
                            frequency: this.medications[this.editedIndex].frequency,
                            method: this.medications[this.editedIndex].method,
                            prescribed: this.medications[this.editedIndex].prescribed,
                            ended: this.medications[this.editedIndex].ended,
                            notes: this.medications[this.editedIndex].notes}]
                    }
                }).then(() => {
                    this.editedIndex = -1;
                    this.editMedication = false;
                });
            }
        },
    },
    apollo: {
        medications: {
            query() {
                return gql`query PatientMedications($wh: [PatientMedicationConditionAND], $opt: PatientMedicationOptions) {
                    PatientMedications(where: $wh, options: $opt) {
                        id
                        patient_id
                        medication_id
                        dosage
                        measure
                        frequency
                        method
                        prescribed
                        ended
                        notes
                        Medication {
                            name
                        }
                    }
                }`
            },
            variables() {
                return {
                    wh: [{patient_id:{eq: this.$route.params.id}}, {deleted:{is:null}}],
                    opt: {sortBy: ['prescribed'], sortDesc: [true]}
                }
            },
            fetchPolicy: "no-cache",
            update(data) {
                return data["PatientMedications"];
            }
        }
    },
}
</script>