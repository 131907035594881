<template>
    <v-container v-if="this.$store.state.user.components.includes(63)">
        <v-row v-if="loadingItems">
            <v-col>
                {{loadingItems}}
                <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-col>
        </v-row>

        <v-row v-if="!loadingItems">
            <v-col>
                <v-radio-group v-model="groupBy">
                    <v-radio label="Group by Test" value="test"></v-radio>
                    <v-radio label="Group by Order Code" value="code"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col>
                <v-text-field label="Search" v-model="searchItems"></v-text-field>
            </v-col>
            <v-col>
                <v-select
                    v-if="groupBy=='code'"
                    :items="codeItems"
                    item-text="order_name"
                    item-value="id"
                    label="Add Code Map"
                    v-on:input="addToMap($event,null)"
                ></v-select>
                <v-select
                    v-if="groupBy=='test'"
                    :items="testItems"
                    item-text="name"
                    item-value="id"
                    label="Add Test Map"
                    v-on:input="addToMap(null,$event)"
                ></v-select>
            </v-col>
            <v-col cols="auto">
                <v-btn v-on:click="saveTestMap()">Save</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn v-on:click="getTestMap()">Reload</v-btn>
            </v-col>
        </v-row>

        <v-row v-if="!loadingItems">
            <v-col v-for="item in items" :key="item.id" cols="12" lg="6" xl="4">
                <v-card>
                    <v-card-text>
                        <v-row dense>
                            <v-col>
                                <h2 v-if="groupBy=='test'">Test</h2>
                                <h2 v-if="groupBy=='code'">Code</h2>
                            </v-col>
                            <v-col>
                                <h2 v-if="groupBy=='test'">Codes</h2>
                                <h2 v-if="groupBy=='code'">Tests</h2>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.name}}</v-list-item-title>
                                            <v-list-item-subtitle>item.code</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn
                                                icon
                                                small
                                                v-if="groupBy=='code'"
                                                v-on:click="remFromMap(item.id,undefined)"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                small
                                                v-if="groupBy=='test'"
                                                v-on:click="remFromMap(undefined,item.id)"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                                <v-select
                                    v-if="groupBy=='test'"
                                    :items="codeItems"
                                    item-text="order_name"
                                    item-value="id"
                                    label="Add Code"
                                    hide-details="auto"
                                    v-on:input="addToMap($event,item.id)"
                                ></v-select>
                                <v-select
                                    v-if="groupBy=='code'"
                                    :items="testItems"
                                    item-text="name"
                                    item-value="id"
                                    label="Add Test"
                                    hide-details="auto"
                                    v-on:input="addToMap(item.id,$event)"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-list>
                                    <v-list-item v-for="itm in item.items" :key="itm.id">
                                        <v-list-item-content>
                                            <v-list-item-title>{{itm.name}}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn
                                                icon
                                                small
                                                v-if="groupBy=='code'"
                                                v-on:click="remFromMap(item.id,itm.id)"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                small
                                                v-if="groupBy=='test'"
                                                v-on:click="remFromMap(itm.id,item.id)"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
export default {
    data() {
        return {
            loadingItems: false,
            searchItems: null,
            codeItems: [],
            testItems: [],
            codesMap: {},
            testsMap: {},
            map: [],
            groupBy: "code"
        };
    },
    methods: {
        addToMap(code, test) {
            let found = false;
            this.map.forEach(v => {
                if (code === null) {
                    if (v.test_id == test && v["_delete"] === undefined)
                        found = true;
                }
                if (test === null) {
                    if (v.code_id == code && v["_delete"] === undefined)
                        found = true;
                }
                if (
                    v.code_id == code &&
                    v.test_id == test &&
                    v["_delete"] === undefined
                ) {
                    found = true;
                }
            });
            if (!found) this.map.push({ code_id: code, test_id: test });
        },
        remFromMap(code, test) {
            let t = this;
            let del = function(k, v) {
                if (v.id != null) t.$set(v, "_delete", true);
                else t.map.splice(k, 1);
            };
            for (let k = this.map.length; k >= 0; k--) {
                if (this.map[k] === undefined) continue;
                let v = this.map[k];

                if (code === undefined) {
                    if (v.test_id == test) del(k, v);
                } else if (test === undefined) {
                    if (v.code_id == code) del(k, v);
                } else if (v.code_id == code && v.test_id == test) {
                    del(k, v);
                }
            }
        },
        getTestMap() {
            this.loadingItems = "Loading ...";
            this.$apollo
                .query({
                    query: gql`{
                        CptTestCodeMaps(where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                            id
                            code_id
                            test_id
                        }
                        CptTests(where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                            id
                            name
                            group_id
                            group_name
                        }
                        LabOrderCodes(where:[{facility_id:{eq:${this.facility_id}}},{deleted:{is:null}}]) {
                            id
                            order_code
                            order_name
                            order_abrv
                        }
                    }`,
                    fetchPolicy: "no-cache"
                })
                .then(res => {
                    if (res.data.CptTests instanceof Array) {
                        this.testItems = res.data.CptTests;
                        res.data.CptTests.forEach(row => {
                            this.testsMap[row.id] = row;
                        });
                    }

                    if (res.data.LabOrderCodes instanceof Array) {
                        this.codeItems = res.data.LabOrderCodes;
                        res.data.LabOrderCodes.forEach(row => {
                            this.codesMap[row.id] = row;
                        });
                    }

                    if (res.data.CptTestCodeMaps instanceof Array) {
                        this.map = res.data.CptTestCodeMaps;
                    }
                })
                .finally(() => {
                    this.loadingItems = false;
                });
        },
        saveTestMap() {
            this.loadingItems = "Saving ...";
            this.map.forEach(v => {
                v["facility_id"] = this.facility_id;
                delete v["__typename"];
                delete v["deleted"];
                delete v["deleted_by"];
            });
            this.map = this.map.filter(
                v => v.test_id != null && v.code_id != null
            );
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($data: [CptTestCodeMapSave]!) {
                            CptTestCodeMapsSave(data: $data) {
                                id
                                test_id
                                code_id
                                deleted
                            }
                        }
                    `,
                    variables: {
                        data: this.map
                    }
                })
                .then(res => {
                    if (res.data.CptTestCodeMapsSave instanceof Array) {
                        this.map = res.data.CptTestCodeMapsSave.filter(
                            v => !v.deleted
                        );
                    }
                })
                .finally(() => {
                    this.loadingItems = false;
                });
        }
    },
    computed: {
        items() {
            let temp = {};
            let res = [];
            if (this.groupBy == "code") {
                this.map.forEach(row => {
                    if (row["_delete"] === undefined) {
                        if (temp[row.code_id] === undefined)
                            temp[row.code_id] = [];
                        if (row.test_id != null) {
                            temp[row.code_id].push({
                                id: row.test_id,
                                name: this.testsMap[row.test_id]?.name
                            });
                        }
                    }
                });
                Object.keys(temp).forEach(k => {
                    res.push({
                        id: k == "null" ? null : k,
                        name: this.codesMap[k]?.order_name,
                        items: temp[k]
                    });
                });
            } else {
                this.map.forEach(row => {
                    if (row["_delete"] === undefined) {
                        if (temp[row.test_id] === undefined)
                            temp[row.test_id] = [];
                        if (row.code_id != null) {
                            temp[row.test_id].push({
                                id: row.code_id,
                                name: this.codesMap[row.code_id]?.order_name
                            });
                        }
                    }
                });
                Object.keys(temp).forEach(k => {
                    res.push({
                        id: k == "null" ? null : k,
                        name: this.testsMap[k]?.name,
                        items: temp[k]
                    });
                });
            }
            return res.filter(
                v =>
                    this.searchItems == null ||
                    v.name.search(new RegExp(this.searchItems, "i")) >= 0
            );
        }
    },
    props: ["facility_id"],
    mounted() {
        this.getTestMap();
    }
};
</script>