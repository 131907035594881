<template>
    <v-card flat tile class="pa-4">
        <v-form>
            <v-row dense>
                <v-col cols="12" md="3">
                    <v-text-field v-model="patient.first_name" :readonly="readonly" :rules="[rules.required]">
                        <template v-slot:label>
                            <span class="red--text">* </span>First Name
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="patient.last_name" :readonly="readonly" :rules="[rules.required]">
                        <template v-slot:label>
                           <span class="red--text">* </span>Last Name
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="1">
                    <v-text-field v-model="patient.middle_name" label="MI" :readonly="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <dob :mandatory="true" v-model="patient.dob" label="DOB" :disabled="readonly" :rules="[rules.required]"></dob>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select :items="['Male','Female','Other']" v-model="patient.gender" :readonly="readonly" :rules="[rules.required]">
                        <template v-slot:label>
                            <span class="red--text">* </span>Gender
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" md="3">
                    <v-text-field v-model="patient.address1" label="Address1" :readonly="readonly" :rules="[rules.required]">
                        <template v-slot:label>
                            <span class="red--text">* </span>Address 1
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="patient.address2" label="Address2" :readonly="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-combobox
                        label="ZIP Code"
                        :rules="[rules.required]"
                        :disabled="readonly"
                        :items="zItems"
                        :loading="zLoading"
                        :search-input.sync="zSearch"
                        item-value="zip"
                        item-text="zip"
                        v-model="patient.zip"
                        no-filter
                        @change="zChange"
                    >
                        <template v-slot:label>
                            <span class="red--text"> *</span> ZIP Code
                        </template>
                        <template v-slot:item="{item}">
                            <v-list-item-content>
                                <v-list-item-title><b>{{item.zip}}</b></v-list-item-title>
                                <v-list-item-subtitle>City:&nbsp; <b>{{item.city}}</b>State:&nbsp;<b>{{item.state}}</b></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="12" md="3">
                    <v-combobox
                        label="City"
                        :rules="[rules.required]"
                        :disabled="readonly"
                        :items="cItems"
                        :loading="cLoading"
                        :search-input.sync="cSearch"
                        item-value="id"
                        item-text="city"
                        v-model="patient.city"
                        @change="cChange"
                        no-filter
                    >
                        <template v-slot:label>
                            <span class="red--text"> *</span> City
                        </template>
                        <template v-slot:item="{item}">
                            <v-list-item-content>
                                <v-list-item-title><b>{{item.city}}</b></v-list-item-title>
                                <v-list-item-subtitle>State:&nbsp;<b>{{item.state}}</b>Zip:&nbsp;<b>{{item.zip}}</b></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="12" md="1">
                    <v-select v-model="patient.state" label="State" :items="$store.state.usStatesAbrv" :readonly="readonly" :rules="[rules.required]">
                        <template v-slot:label>
                            <span class="red--text">* </span>State
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" md="3">
                    <v-text-field v-model="patient.email" label="Email" :readonly="readonly" :rules="[rules.email]"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="patient.phone" :readonly="readonly" v-mask="'(###) ###-####'">
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field v-model="patient.mobile" label="Mobile" :readonly="readonly" v-mask="'(###) ###-####'"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select v-model="patient.status" :items="['Active','Inactive']" :readonly="readonly" :rules="[rules.required]">
                        <template v-slot:label>
                            <span class="red--text">* </span>Status
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12">
                    <v-btn color="secondary" @click="readonly=!readonly"><span v-if="readonly">Edit</span><span v-else>Cancel</span></v-btn>
                    <v-btn class="ml-6" color="primary" @click="updateProfile()">Save</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-card>
</template>

<script>
import dob from "../../components/DOB.vue"
import gql from "graphql-tag"
import {mask} from 'vue-the-mask'
export default {
    components: {
        dob,
    },
    directives: {
        mask,
    },
    data() {
        return {
            readonly: true,
            patient: {},
            rules: {
                required: value => !!value || 'Required',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return (!value || pattern.test(value)) || 'Invalid e-mail.'
                },
            },
            zItems: [],
            cItems: [],
            zLoading: false,
            cLoading: false,
            zSearch: null,
            cSearch: null,
        }
    },
    methods: {
        updateProfile() {
            this.$apollo.mutate({
                mutation: gql`mutation($inputdata: PatientInput!, $wh: [PatientConditionAND]!) {
                    PatientUpdate(data: $inputdata, where: $wh) {
                        first_name
                        last_name
                        middle_name
                        dob
                        gender
                        address1
                        address2
                        city
                        state
                        zip
                        email
                        phone
                        mobile
                        status
                    }
                }`,
                variables: {
                    inputdata: {first_name: this.patient.first_name, last_name:this.patient.last_name, middle_name: this.patient.middle_name, dob:this.patient.dob, gender:this.patient.gender, address1: this.patient.address1, address2: this.patient.address2, city:this.patient.city, state:this.patient.state, zip:this.patient.zip, email:this.patient.email, phone:this.patient.phone, mobile:this.patient.mobile, status:this.patient.status},
                    wh: [{id:{eq:this.patient.id}}]
                }
            }).then(()=>{
                this.readonly = true;
            });
        },
        findCity(val) {
            this.cLoading = true;
            this.$apollo
                .query({
                    query: gql`{
                        ZipCodes(where:{city:{like:"${val}%"}},options:{page:1,itemsPerPage:20}) {
                            id
                            zip
                            city
                            state
                        }
                    }`,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.ZipCodes instanceof Array) {
                        this.cItems = res.data.ZipCodes;
                    }
                })
                .finally(() => {
                    this.cLoading = false;
                });
        },
        findZip(val) {
            this.zLoading = true;
            this.$apollo
                .query({
                    query: gql`{
                        ZipCodes(where:{zip:{like:"${val}%"}},options:{page:1,itemsPerPage:20}) {
                            zip
                            city
                            state
                        }
                    }`,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.ZipCodes instanceof Array) {
                        this.zItems = res.data.ZipCodes;
                    }
                })
                .finally(() => {
                    this.zLoading = false;
                });
        },
        cChange(v) {
            if (v instanceof Object) {
                this.patient.zip = v.zip;
                this.patient.state = v.state;
                this.patient.city = v.city;
            }
        },
        zChange(v) {
            if (v instanceof Object) {
                this.patient.city = v.city;
                this.patient.state = v.state;
                this.patient.zip = v.zip;
            }
        },
    },
    watch: {
        cSearch(n) {
            if (n && n.length > 1) {
                let found = this.cItems.filter((v) => v.city == n);
                if (found.length <= 0) this.findCity(n);
            }
        },
        zSearch(n) {
            if (n && n.length > 1) {
                let found = this.zItems.filter((v) => v.zip == n);
                if (found.length <= 0) this.findZip(n);
            }
        },
    },
    mounted() {
        this.$apollo
            .query({
                query: gql`
                    query Patient($id: ID!) {
                        Patient(id: $id) {
                            id
                            first_name
                            last_name
                            middle_name
                            dob
                            gender
                            address1
                            address2
                            city
                            state
                            zip
                            email
                            phone
                            mobile
                            status
                        }
                    }
                `,
                variables: {
                    id: this.$route.params.id
                }
            })
            .then(res => {
                this.patient = res.data.Patient;
            });
    }
}
</script>