import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import IdleVue from 'idle-vue'
import highcharts from './plugins/highcharts';
import * as VueGoogleMaps from 'vue2-google-maps'

import { createProvider } from './vue-apollo'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBMzfq8YwKo4-lzmVvEK71FSqaHyMi7UAc',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
});

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

const vm = new Vue({
  router,
  store,
  vuetify,
  highcharts,
  apolloProvider: createProvider(),
  render: h => h(App),
    beforeCreate() {
        this.$store.dispatch("initialiseStore");
    }
}).$mount('#app')

Vue.use(IdleVue, {
  eventEmitter: vm,
  store,
  idleTime: 900000, // 15 min
  startAtIdle: false,
  events:['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll']
});