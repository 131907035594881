export function copy(varToCopy) {
        let res;
        if (varToCopy instanceof Array) res = copyArray(varToCopy);
        else if (varToCopy instanceof Object)
            res = copyObject(varToCopy);
        else res = varToCopy;
        return res;
    }
export function copyObject(obj) {
        let res = {};
        Object.keys(obj).forEach(k => {
            if (obj[k] instanceof Array) res[k] = copyArray(obj[k]);
            else if (obj[k] instanceof Object)
                res[k] = copyObject(obj[k]);
            else /*if (k.substring(0, 2) != "__")*/ res[k] = obj[k];
        });
        return res;
    }
export function copyArray(arr) {
        let res = [];
        arr.forEach((v, k) => {
            if (v instanceof Array) res[k] = copyArray(v);
            else if (v instanceof Object) res[k] = copyObject(v);
            else res[k] = v;
        });
        return res;
    }
export function getScalarsFromObject(obj) {
    if(!(obj instanceof Object)) return {};
    let res = {};
    Object.keys(obj).forEach(k=>{
        if(k.substring(0, 2) != "__" && !(obj[k] instanceof Object)) res[k] = obj[k];
    });
    return res;

}