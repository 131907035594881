<template>
    <v-container>
        <v-card>
            <v-card-title>
                <v-text-field v-model="search" label="search"></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="gqlBinder.pending" @click="gqlBinder.mutate()">Mutate</v-btn>
            </v-card-title>
            <v-card-subtitle>{{gqlBinder.loading}}</v-card-subtitle>
            <v-card-subtitle>{{gqlBinder.error}}</v-card-subtitle>
            <v-card-subtitle>{{gqlBinder.data}}</v-card-subtitle>
            <v-card-subtitle>{{gqlBinder.model}}</v-card-subtitle>
            <v-card-subtitle>{{gqlBinder.mutations}}</v-card-subtitle>
            <v-card-text>
                <v-row v-for="(Patient,key) in gqlBinder.data" :key="key">
                    <v-col cols="4" v-for="prow in Patient" :key="prow.id">
                        <v-text-field v-model="prow.first_name" :label="prow.id"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<style scoped>
.border {
    border: 1px solid black;
}
</style>
<script>
import { gqlBinder } from "../mixins/gqlBinder";
export default {
    mixins: [gqlBinder],
    data() {
        return {
            search: null
        };
    },
    watch: {
        search(n) {
            this.gqlBinder.model = {
                Patients: {
                    id: null,
                    first_name: null,
                    $where: [{ name: { like: "%" + n + "%" } }],
                    _model:"Patient"
                }
            };
        }
    }
};
</script>