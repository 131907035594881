<template>
    <v-container v-if="this.$store.state.user.components.includes(50)">
        <crud
            model="User"
            :headers="user_headers"
            label="Users"
            :initialValues="initialValues"
            :conditions="[{deleted:{is:null}}, {facility_id:{eq:facility_id}}]"
        >
            <template v-slot:field.npi="{values,on,bind}">
                <v-text-field
                    v-bind="bind"
                    v-on="on"
                    v-mask="['###-###-####']"
                    :class="values.is_provider ? 'required': null"
                    :rules="values.is_provider ? [(val)=>(val && val.length==12) ||'Invalid'] : []"
                ></v-text-field>
            </template>
        </crud>
    </v-container>
</template>
<script>
import crud from "../../components/CRUD";
import { mask } from "vue-the-mask";   
export default {
    directives: {
        mask,
    },
    data() {
        return {
            initialValues: {
                facility_id: this.facility_id,
            },
            user_headers: [
                {
                    value: "id",
                    text: "ID",
                    visible: false,
                    editable: false,
                    isId: true,
                },
                {
                    value: "name",
                    text: "Name",
                    editable: false,
                },
                {
                    value: "first_name",
                    text: "First Name",
                    mandatory: true,
                    searchable: true,
                    visible: false,
                },
                {
                    value: "middle_name",
                    text: "Middle Name",
                    searchable: true,
                    visible: false,
                },
                {
                    value: "last_name",
                    text: "Last Name",
                    mandatory: true,
                    searchable: true,
                    visible: false,
                },
                { value: "username", text: "Username", mandatory: true },
                { value: "password", text: "Password", visible: false },
                {
                    value: "email",
                    text: "Email",
                    searchable: true,
                    visible: false,
                },
                {
                    value: "phone",
                    text: "Phone",
                    searchable: true,
                    visible: false,
                },
                {
                    value: "facility_id",
                    visible: false,
                    editable: false,
                },
                {
                    value: "Role.name",
                    text: "Role",
                    type: "select",
                    mandatory: true,
                    model: "UserRole",
                    modelId: "role_id",
                    modelFields: { value: "id", text: "name" },
                },
                {
                    value: "status",
                    text: "Status",
                    type: "select",
                    mandatory: true,
                    items: ["Active", "Inactive", "Pending"],
                },
                {
                    value: "is_provider",
                    text: "Provider",
                    type: "switch",
                    visible: true,
                },
                {
                    value: "npi",
                    text: "NPI",
                    type: "text",
                    mask: "####-###-###",
                    mandatory: true,
                },
            ],
        };
    },
    components: {
        crud,
    },
    props: ["facility_id"],
};
</script>